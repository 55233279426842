import React, { useEffect, useRef, useState } from "react";
import "../../../../assets/css/home.css";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../module/myCrypto";
import axiosPrivate from "../../../services/api/axios";
import pdf from "../../../../assets/icon40/ray-pdf.svg";
import list from "../../../../assets/icon40/list.svg";
import add from "../../../../assets/icons/add.svg";
import moment from "moment";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

const PermisConduire = () => {
  const { companieID } = useParams();
  const fileInput = useRef();
  const [driverDatas, setDriverDatas] = useState([]);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [data, setData] = useState(null); // Ajoutez cet état pour stocker les données
  const [attachement, setAttachement] = useState("");
  const [contactData, setContactData] = useState({
    numPermis: "",
    nom: "",
    prenom: "",
    categorie_permis: "",
    date_delivrance_pc: "",
    date_expiration_pc: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getPermisDeadline = async () => {
      await axiosPrivate
        .get(`drivers/drivers-deadline/${companyId}`)
        .then((res) => {
          setDriverDatas(res.data);
        })
        .catch((err) => console.log(err));
    };

    const getAllVehicle = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicle();
    getPermisDeadline();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOpenModalClick = (
    numPermis,
    nom,
    prenom,
    categorie_permis,
    date_delivrance_pc,
    date_expiration_pc
  ) => {
    // Votre logique ici...
    setData([
      numPermis,
      nom,
      prenom,
      categorie_permis,
      date_delivrance_pc,
      date_expiration_pc,
    ]);
    setContactData({
      ...contactData,
      numPermis: numPermis,
      nom: nom,
      prenom: prenom,
      categorie_permis: categorie_permis,
      date_delivrance_pc: date_delivrance_pc,
      date_expiration_pc: date_expiration_pc,
    });
    setIsOpen(true);
  };

  const handleCloseModalClick = () => {
    setIsOpen(false);
  };

  const validateForm = () => {
    let err = {};
    if (contactData.compagnie === "") {
      err.compagnie = toast.error(
        "😡 Veuillez entrer la compagnie d'assurance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.numPolice === "") {
      err.numPolice = toast.error("😡 Veuillez entrer le numéro de police!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.numAttestation === "") {
      err.numAttestation = toast.error(
        "😡 Veuillez entrer le numéro d'attestation",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.duree === "") {
      err.duree = toast.error("😡 Veuillez entrer la durée du contrat!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.montant === "") {
      err.montant = toast.error(
        "😡 Veuillez entrer le montant de l'assurance",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_assurance === "") {
      err.date_assurance = toast.error("😡 Veuillez choisir la date d'achat", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_debut === "") {
      err.date_debut = toast.error("😡 Veuillez choisir la date de debut!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_fin === "") {
      err.date_fin = toast.error("😡 Veuillez entrer la date de fin", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      await axiosPrivate
        .put(`drivers/update-driver-pc/${data[0]}/${companieID}`, {
          numPermis: encrypt(contactData.numPermis),
          nom: encrypt(contactData.nom),
          prenom: encrypt(contactData.prenom),
          categorie_permis: encrypt(contactData.categorie_permis),
          date_delivrance_pc: encrypt(contactData.date_delivrance_pc),
          date_expiration_pc: encrypt(contactData.date_expiration_pc),
          companieID: user.companyID,
          userID: user.userID,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/personnels/conducteurs/${companyId}`);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  return (
    <>
      <div id="header-main">
        <div className="title">
          <h2>Alertes permis de conduire des conducteurs</h2>
        </div>
        <div className="links">
          <a href="" title="Exporter en PDF" className="ng-star-inserted">
            <img src={pdf} alt="" />
          </a>
          <a
            href={`/personnels/conducteurs/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <div className="ui-widget-header ng-star-inserted">
        <p>
          <a
            aria-controls="collapseExample"
            aria-expanded="false"
            className="collapse-filtre collapsed"
            data-toggle="collapse"
            href="#collapseExample"
          >
            Filtre
          </a>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="bloc-filtre55"></div>
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-blue">{driverDatas.length}</span>
            <span className="label">Nombre d'alertes</span>
          </div>
        </div>
        <div className="scroll with-hover with-selected ui-table ui-widget">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-head">
                <tr className="ng-star-inserted">
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Nom
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Prenoms
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Contact
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Catégorie permis
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    N° Permis
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Date expiration
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Nombre de jours restants
                  </th>
                  <th
                    style={{ width: "50px", padding: "0.3rem 0.3rem" }}
                    className="ng-star-inserted"
                  ></th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {driverDatas.map((assurances) => {
                  const dateEcheance = new Date(assurances.date_expiration_pc);
                  const today = moment();
                  const diffDays = today.diff(moment(dateEcheance), "days");
                  const daysMessage =
                    diffDays >= 0
                      ? `${diffDays} jours passés depuis la date d'échéance`
                      : `${Math.abs(
                          diffDays
                        )} jours restants jusqu'à la date d'échéance`;

                  return (
                    <tr
                      className="ui-selectable-row ng-star-inserted"
                      tabIndex="0"
                    >
                      <td
                        style={{ width: "40px", padding: ".3rem .3rem" }}
                        className="ng-star-inserted"
                      >
                        {assurances.nom}
                      </td>
                      <td className="ng-star-inserted">{assurances.prenom}</td>
                      <td className="ng-star-inserted">
                        {assurances.contact_chauffeur}
                      </td>
                      <td className="ng-star-inserted">
                        {assurances.categorie_permis}
                      </td>
                      <td className="ng-star-inserted">
                        {assurances.numPermis}
                      </td>
                      <td className="ng-star-inserted">
                        {new Date(
                          assurances.date_expiration_pc
                        ).toLocaleDateString("en-GB")}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="ng-star-inserted c-red"
                      >
                        {daysMessage}
                      </td>
                      <td className="ng-star-inserted">
                        <a
                          data-target="#popupAddAction-assurance"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOpenModalClick(
                              assurances.numPermis,
                              assurances.nom,
                              assurances.prenom,
                              assurances.categorie_permis,
                              assurances.date_delivrance_pc,
                              assurances.date_expiration_pc
                            )
                          }
                        >
                          <img src={add} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && data && (
        <div className="ng-star-inserted">
          <div
            aria-labelledby="mySmallModalLabel"
            className="modal fade show"
            id="popupAddAction-assurance"
            tabIndex="-1"
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <form
              novalidate=""
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="ng-untouched ng-pristine ng-valid"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Permis du conducteur
                    </h5>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={handleCloseModalClick}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group col2">
                      <label className="form">Nom</label>
                      <input
                        type="text"
                        placeholder="Numéro police"
                        className="ng-pristine ng-invalid ng-touched"
                        name="nom"
                        value={contactData.nom}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Prenoms</label>
                      <input
                        type="text"
                        placeholder="Numéro police"
                        className="ng-pristine ng-invalid ng-touched"
                        name="prenom"
                        value={contactData.prenom}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">N° Permis</label>
                      <input
                        type="text"
                        className="large addExpress ng-untouched ng-pristine ng-valid"
                        name="numPermis"
                        value={contactData.numPermis}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Catégorie du permis</label>
                      <div className="large">
                        <div className="box-select-large">
                          <select
                            name="categorie_permis"
                            value={contactData.categorie_permis}
                            onChange={handleChange}
                          >
                            <option>Catégorie du permis</option>
                            <option value="B"> CATEGORIE B</option>
                            <option value="CE">CATEGORIE CE</option>
                            <option value="DE"> CATEGORIE DE</option>
                            <option value="BCDE">CATEGORIE BCDE</option>
                            <option value="ABCDE"> CATEGORIE ABCDE</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col2">
                      <label className="form">
                        Date de délivrance du permis
                      </label>
                      <input
                        type="date"
                        placeholder="N° attestation"
                        className="ng-pristine ng-invalid ng-touched"
                        name="date_delivrance_pc"
                        value={contactData.date_delivrance_pc}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">
                        Date d'expiration du permis
                      </label>
                      <input
                        type="date"
                        placeholder="Type assurance"
                        className="ng-pristine ng-invalid ng-touched"
                        name="date_expiration_pc"
                        value={contactData.date_expiration_pc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="save">Enregistrer</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default PermisConduire;
