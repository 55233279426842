import React, { useEffect, useState } from "react";
import axiosPrivate from "../../../services/api/axios";
import "../../../../assets/css/home.css";
import { Viewer } from "@react-pdf-viewer/core";
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { openPlugin } from '@react-pdf-viewer/open';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/open/lib/styles/index.css';

function DriverContratModal({ closeContratModal, data }) {
  const [driverNom, setDriverNom] = useState("");
  const [driverPrenom, setDriverPrenom] = useState("");
  //const [data, getFile] = useState({ name: "", path: "" });

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const openPluginInstance = openPlugin();
  const getFilePluginInstance = getFilePlugin();

  useEffect(() => {
    axiosPrivate
      .get(`drivers/getDriver/${data[0]}/${data[1]}`)
      .then(
        (response) => {
          setDriverNom(response.data.nom);
          setDriverPrenom(response.data.prenom);
        },
        { mode: "cors" }
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div
      aria-labelledby="mySmallModalLabel"
      className="modal fade show"
      id="popupAttachement"
      tabIndex="-1"
      style={{ display: "block" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true" onClick={() => closeContratModal(false)}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div id="viewOnglet">
              <div className="ng-star-inserted">
                <div id="header-main">
                  <div className="title ng-star-inserted">
                    <h3>
                      Contrat de {driverPrenom} {driverNom}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="ng-star-inserted">
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "550px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`https://apiserver.raymobileci.com/${data[1]}/chauffeurs/contrats/${data[2]}`}
                      plugins={[
                        defaultLayoutPluginInstance,
                        getFilePluginInstance,
                        openPluginInstance
                      ]}
                      localization={fr_FR}
                    />
                  </Worker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverContratModal;
