import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import axiosPrivate from '../../../services/api/axios';
import { useAuth } from '../../../context/AuthProvider';
import { decrypt } from '../../../module/myCrypto';

function PieChart() {
  const [sumAcquisition, setSumAcquisition] = useState([]);
  const [sumAdministratif, setSumAdministratif] = useState([]);
  const [sumEnergie, setSumEnergie] = useState([]);
  const [sumMaintenanceDepense, setSumMaintenanceDepense] = useState([]);
  
  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchData = async () => {
      await axiosPrivate.get(`depenses/sumAcquisitionDepense/${companyId}/Acquisition`)
      .then((res) => {
        setSumAcquisition(res.data[0]);
      })
      .catch((err) => console.log(err));
    };

    const fetchAdministratif = async () => {
        await axiosPrivate.get(`depenses/sumAdministratifDepense/${companyId}/Administratif`)
        .then((res) => {
          setSumAdministratif(res.data[0]);
        })
        .catch((err) => console.log(err));
      };

      const fetchEnergie = async () => {
          await axiosPrivate.get(`depenses/sumEnergieDepense/${companyId}/Energie`)
          .then((res) => {
            setSumEnergie(res.data[0]);
          })
          .catch((err) => console.log(err));
        };

        const fetchMaintenance = async () => {
          await axiosPrivate
            .get(`depenses/sumMaintenanceDepense/${companyId}/Maintenance`)
            .then((res) => {
              setSumMaintenanceDepense(res.data[0]);
            })
            .catch((err) => console.log(err));
        };

    fetchData();
    fetchAdministratif();
    fetchEnergie();
    fetchMaintenance();
  }, []);

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Montant total',
        data: [sumAcquisition.total_montant, sumAdministratif.total_montant, sumEnergie.total_montant, sumMaintenanceDepense.total_montant],
        backgroundColor: [
          'rgba(245, 5, 121, 1)',
          'rgba(5, 133, 245, 1)',
          'rgba(245, 97, 5, 1)',
          'rgba(102, 101, 105, 1)',
        ],
        borderColor: [
          'rgba(245, 5, 121, 1)',
          'rgba(5, 133, 245, 1)',
          'rgba(245, 97, 5, 1)',
          'rgba(102, 101, 105, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      labels: {
        fontSize: 20 // Taille de la légende
      }
    }
  };

  return (
    <div style={{ margin: "auto", width: "20vw" }}>
      <Pie data={data} options={options} />
    </div>
  );
}

export default PieChart;
