import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../../module/myCrypto";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import DriverContratModal from "../../components/ContratDriverModal";
import DeleteDriverModal from "../../components/DeleteDriverModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

const ListeUtilisateur = () => {
  const navigate = useNavigate();
  const [driverData, setDriverData] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const downloadLink = useRef(null);
  const [userEmail, setUserEmail] = useState("");
  const [userNom, setUserNom] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);
  const userId = decrypt(user.userID);
  const role = decrypt(user.role);

  useEffect(() => {
    const getAllUsers = () => {
      axiosPrivate
        .get(`users/getAllUsers/${companyId}`)
        .then((res) => {
          setDriverData(res.data);
        })
        .catch((err) => console.log(err));
    };
  
    getAllUsers(); // Appel initial pour obtenir tous les utilisateurs
  
    const intervalId = setInterval(getAllUsers, 5000); // Mettez à jour toutes les 5 secondes
  
    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []); // Liste de dépendances vide
  

  useEffect(() => {
    if (!showDeleteModal && deleteModal != null) {
      window.location.reload();
    }
  }, [showDeleteModal, deleteModal]);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((userNom || userEmail || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get("users/search-users", {
          params: { userNom, userEmail, companyId },
        });
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [userNom, userEmail, companyId]);

  const handleClickOpen = () => {
    navigate(`/personnels/utilisateur/ajouter/${companyId}`);
  };

  function handleOnClickModal(userID, companieID, driverContrat) {
    setShowDetailModal(true);
    setModal([userID, companieID, driverContrat]);
  }

  function handleOnClickDeleteModal(userID, nom, prenom, companie) {
    setShowDeleteModal(true);
    setDeleteModal([userID, nom, prenom, companie]);
  }

  const handleCSVClick = async () => {
    const headers = [
      "",
      "Véhicules",
      "Nom & Prenoms",
      "N° Permis",
      "Date expiration",
      "Contact",
      "N° d'urgence",
      "Date recrutement",
      "Recette",
    ];
    const tableData = driverData.map((driver) => [
      "",
      driver["vehicleID"],
      driver["nom"] + " " + driver["prenom"],
      driver["userID"],
      moment(driver["date_expiration_pc"]).format("DD/MM/YYYY"),
      driver["contact_chauffeur"],
      driver["contact_proche"],
      moment(driver["date_recrutement"]).format("DD/MM/YYYY"),
      driver["conducteurs"]
        ? driver["conducteurs"].map(
            (conducteur) => `${conducteur.nom} ${conducteur.prenom}`
          )
        : " ",
    ]);
    tableData.unshift(headers);

    // Convertir chaque sous-tableau en une chaîne de caractères, séparée par des virgules
    const csvData = tableData.map((row) => row.join(","));

    // Convertir le tableau de chaînes de caractères en une seule chaîne de caractères, avec chaque élément séparé par un saut de ligne
    const csvString = csvData.join("\n");

    if (downloadLink.current) {
      downloadLink.current.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvString
      )}`;
      downloadLink.current.download = "liste-conducteurs.csv";
      downloadLink.current.click();
    }
  };

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");
  
    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;
  
    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;
  
    doc.addImage(image, "JPEG", 10, 5, 60, 45);
  
    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);
  
    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des utilisateurs";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre
  
    const headers = [
      "",
      "Nom & Prenoms",
      "Email",
      "Contact",
      "Rôle",
      "Compagnie",
      "Statut du Compte",
    ];
  
    const tableData = isSearching ? searchResults.map((result) => {
      let company = result.company.nom;
  
      return [
        "",
        result["nom"] + " " + result["prenom"],
        result["email"],
        result["contact"],
        result["role"],
        company, // Ajoutez les versements ici
        result["isBlocked"],
      ];
    }) : driverData.map((driver) => {
      let company = driver.company.nom;
      return [
        "",
        driver["nom"] + " " + driver["prenom"],
        driver["email"],
        driver["contact"],
        driver["role"],
        company, // Ajoutez les versements ici
        driver["isBlocked"],
      ];
    });
  
    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });
  
    doc.save("liste-utilisateurs.pdf");
  };

  const handleStatusChange = (userID) => {
    // Encode l'ID utilisateur
    let encodedUserID = encodeURIComponent(userID);
    const updatedDrivers = driverData.map((d) => {
      if (d.userID === userID) {
        return {
          ...d,
          isBlocked: d.isBlocked === "Bloque" ? "Actif" : "Bloque",
        };
      }
      return d;
    });
    setDriverData(updatedDrivers);

    // Trouvez le conducteur mis à jour
    const updatedDriver = updatedDrivers.find((d) => d.userID === userID);

    // Obtenez l'ID de l'utilisateur actuellement connecté
    const currentUserId = userId;

    if (updatedDriver.isBlocked === "Bloque") {
      axiosPrivate
        .put(`users/bloque-user/${encodedUserID}`, {
          nom: encrypt(updatedDriver.nom),
          prenom: encrypt(updatedDriver.prenom),
          isBlocked: updatedDriver.isBlocked,
        })
        .then((res) => {
          toast.error(`👏 ${res.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
          window.location.reload(); // Rafraîchit la page

          // Si l'utilisateur bloqué est l'utilisateur actuellement connecté
          if (updatedDriver.userID === currentUserId) {
            localStorage.removeItem("token");
            window.location.href = "/login"; // redirige vers la page de connexion
          }
        })
        .catch((err) => console.log(err));
    } else {
      axiosPrivate
        .put(`users/debloque-user/${encodedUserID}`, {
          nom: encrypt(updatedDriver.nom),
          prenom: encrypt(updatedDriver.prenom),
          isBlocked: updatedDriver.isBlocked,
        })
        .then((res) => {
          toast.success(`👏 ${res.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
          window.location.reload(); // Rafraîchit la page
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Utilisateur</h2>
          </div>
          <div className="links">
            {role === "admin" && (
              <a
                href=""
                title="Ajouter un conducteur"
                className="ng-star-inserted"
              >
                <img src={add} alt="" onClick={handleClickOpen} />
              </a>
            )}
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={handlePDFClick} />
            </a>
            <a
              ref={downloadLink}
              title="Exporter en csv"
              className="ng-star-inserted"
            >
              <img
                src={excel}
                alt=""
                onClick={handleCSVClick}
                ref={downloadLink}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par nom</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par nom utilisateur"
                  name="userNom"
                  value={userNom}
                  onChange={(e) => setUserNom(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par email</span>
                <input
                  type="email"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par permis de conduire"
                  name="userEmail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-black">{driverData.length}</span>
            <span className="label">Nombre d'utilisateur</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Nom & Prenoms
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">Email</th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Contact
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">Rôle</th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Compagnie
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Statut du Compte
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Action sur le compte
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? driverData.map((drivers) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                          key={drivers.userID}
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [drivers.userID]:
                                          !subMenuOpen[drivers.userID],
                                      })
                                    }
                                    open={!!subMenuOpen[drivers.userID]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[drivers.userID] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteModal(
                                          drivers.userID,
                                          drivers.nom,
                                          drivers.prenom,
                                          drivers.companieID
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.nom} {drivers.prenom}
                          </td>
                          <td className="ng-star-inserted">{drivers.email}</td>
                          <td className="ng-star-inserted">
                            {drivers.contact}
                          </td>
                          <td className="ng-star-inserted">{drivers.role}</td>
                          <td className="ng-star-inserted">
                            {drivers.company.nom}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.isBlocked === "Actif" ? (
                              <button
                                className="accountActif"
                                alt="COMPTE ACTIF"
                              >
                                <i>C</i>
                                <i>O</i>
                                <i>M</i>
                                <i>P</i>
                                <i>T</i>
                                <i>E</i>
                                <i>&nbsp;</i>
                                <i>&nbsp;</i>
                                <i>A</i>
                                <i>C</i>
                                <i>T</i>
                                <i>I</i>
                                <i>F</i>
                              </button>
                            ) : (
                              <button
                                className="accountBlocked"
                                alt="COMPTE BLOQUE"
                              >
                                <i>C</i>
                                <i>O</i>
                                <i>M</i>
                                <i>P</i>
                                <i>T</i>
                                <i>E</i>
                                <i>&nbsp;</i>
                                <i>&nbsp;</i>
                                <i>B</i>
                                <i>L</i>
                                <i>O</i>
                                <i>Q</i>
                                <i>U</i>
                                <i>E</i>
                              </button>
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            <label className="form">{drivers.isBlocked}</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="viewCheckbox"
                                className="ng-untouched ng-pristine ng-valid"
                                style={{ cursor: "pointer" }}
                                checked={drivers.isBlocked === "Bloque"}
                                onChange={() =>
                                  handleStatusChange(drivers.userID)
                                }
                                value={drivers.isBlocked ? "Actif" : "Bloque"}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                          key={result.userID}
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.userID]:
                                          !subMenuOpen[result.userID],
                                      })
                                    }
                                    open={!!subMenuOpen[result.userID]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.userID] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteModal(
                                          result.userID,
                                          result.nom,
                                          result.prenom,
                                          result.companieID
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.nom} {result.prenom}
                          </td>
                          <td className="ng-star-inserted">{result.email}</td>
                          <td className="ng-star-inserted">{result.contact}</td>
                          <td className="ng-star-inserted">{result.role}</td>
                          <td className="ng-star-inserted">
                            {result.company.nom}
                          </td>
                          <td className="ng-star-inserted">
                            {result.isBlocked === "Actif" ? (
                              <button
                                className="accountActif"
                                alt="COMPTE ACTIF"
                              >
                                <i>C</i>
                                <i>O</i>
                                <i>M</i>
                                <i>P</i>
                                <i>T</i>
                                <i>E</i>
                                <i>&nbsp;</i>
                                <i>&nbsp;</i>
                                <i>A</i>
                                <i>C</i>
                                <i>T</i>
                                <i>I</i>
                                <i>F</i>
                              </button>
                            ) : (
                              <button
                                className="accountBlocked"
                                alt="COMPTE BLOQUE"
                              >
                                <i>C</i>
                                <i>O</i>
                                <i>M</i>
                                <i>P</i>
                                <i>T</i>
                                <i>E</i>
                                <i>&nbsp;</i>
                                <i>&nbsp;</i>
                                <i>B</i>
                                <i>L</i>
                                <i>O</i>
                                <i>Q</i>
                                <i>U</i>
                                <i>E</i>
                              </button>
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            <label className="form">{result.isBlocked}</label>
                            <label className="switch">
                              <input
                                type="checkbox"
                                id="viewCheckbox"
                                className="ng-untouched ng-pristine ng-valid"
                                style={{ cursor: "pointer" }}
                                checked={result.isBlocked === "Bloque"}
                                onChange={() =>
                                  handleStatusChange(result.userID)
                                }
                                value={result.isBlocked ? "Actif" : "Bloque"}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="mySmallModalLabel"
          className="modal fade"
          id="popupDelete"
          role="dialog"
          tabIndex="-1"
        ></div>
      </div>
      {showDetailModal && setModal != null && (
        <DriverContratModal
          closeContratModal={setShowDetailModal}
          data={modal}
        />
      )}
      {showDeleteModal && setDeleteModal != null && (
        <DeleteDriverModal
          closeDeleteDriverModal={setShowDeleteModal}
          data={deleteModal}
        />
      )}
    </>
  );
};

export default ListeUtilisateur;
