import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import { useAuth } from "../../../../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const AjouterEncaissement = () => {
  const fileInput = useRef();
  const [optionList, setOptionList] = useState([]);
  const navigate = useNavigate();
  const [contrat, setContrat] = useState("");
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [contactData, setContactData] = useState({
    nature_caisse: "Encaissement",
    type_caisse: "",
    date_caisse: date,
    motif: "",
    reference: "",
    montant: "",
    intitule: "",
    auteur: "",
    observation: "",
    justification: "Encaissement justifie",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const dateDuJour = new Date().toLocaleDateString();
    setDate(dateDuJour);
  }, []);

  useEffect(() => {
    const getAllVehicles = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicles();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickOpen = () => {
    navigate(`/finances/caisses/encaissement/${companyId}`);
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", contrat);
      const res = await axiosPrivate.post("upload-encaissement", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};

    if (contactData.type_caisse === "") {
      err.type_caisse = toast.error("😡 Veuillez entrer le type de caisse!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.auteur === "") {
      err.auteur = toast.error("😡 Veuillez entrer l'auteur du versement!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_caisse === "") {
      err.date_caisse = toast.error(
        "😡 Veuillez selectionner la date d'encaissement",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.motif === "") {
      err.motif = toast.error("😡 Veuillez entrer le motif de l'encaissement", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.reference === "") {
      err.reference = toast.error(
        "😡 Veuillez entrer la référence de l'encaissement",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.montant === "") {
      err.montant = toast.error("😡 Veuillez entrer le montant encaissé", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.intitule === "") {
      err.intitule = toast.error(
        "😡 Veuillez entrer l'intitulé de l'encaissement'",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.justification === "Depense justifie") {
      if (contrat === "") {
        err.attachement = toast.error(
          "😡 Veuillez télécharger le reçu de justification",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    let datas;

    if (isValid) {
      let contratUrl = "";

      if (contrat) contratUrl = await uploadContrat();

      try {
        await axiosPrivate
          .post(
            "finances/encaissement/create",
            {
              nature_caisse: encrypt(contactData.nature_caisse),
              type_caisse: encrypt(contactData.type_caisse),
              date_caisse: encrypt(contactData.date_caisse),
              motif: encrypt(contactData.motif),
              reference: encrypt(contactData.reference),
              montant: encrypt(contactData.montant),
              intitule: encrypt(contactData.intitule),
              auteur: encrypt(contactData.auteur),
              observation: contactData.observation,
              justification: encrypt(contactData.justification),
              attachement: contratUrl,
              userID: user.userID,
              companyID: user.companyID,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/finances/caisses/encaissement/${companyId}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    } else {
    }
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title">
            <h2>Encaissement</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter encaissement"
              className="ng-star-inserted"
            >
              <img src={list} alt="" onClick={handleClickOpen} />
            </a>
          </div>
        </div>
        <form
          className="ng-untouched ng-pristine ng-valid"
          onSubmit={handleSubmitForm}
        >
          <div className="form-l">
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Critères du mouvement</label>
                <div className="form-group col2">
                  <label className="form">Type d'opération</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="text"
                      name="nature_caisse"
                      value={contactData.nature_caisse}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date d'encaissement</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="date"
                      name="date_caisse"
                      value={contactData.date_caisse}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Référence</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="text"
                      name="reference"
                      value={contactData.reference}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant</label>
                  <input
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    pinputtext=""
                    pkeyfilter="int"
                    type="number"
                    min="0"
                    name="montant"
                    value={contactData.montant}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      F.CFA
                    </span>
                  </div>
                </div>
                <div className="form-group col9 ng-star-inserted">
                  <label className="form">Motif</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="text"
                      name="motif"
                      value={contactData.motif}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form">Intitulé</label>
                  <textarea
                    type="text"
                    name="intitule"
                    cols="3"
                    rows="5"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.intitule}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Données du mouvement</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type encaissement</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="type_caisse"
                        value={contactData.type_caisse}
                        onChange={handleChange}
                      >
                        <option>le type d'encaissement</option>
                        <option value="Alimentation Caisse">
                          Alimentation Caisse
                        </option>
                        <option value="Paiement">Paiement</option>
                        <option value="Retour caisse">Retour en caisse</option>
                        <option value="Versement">Versement</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Auteur</label>
                  <input
                    type="text"
                    placeholder="Auteur"
                    className="ng-pristine ng-invalid ng-touched"
                    name="auteur"
                    value={contactData.auteur}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form">Observation</label>
                  <textarea
                    type="text"
                    name="observation"
                    cols="30"
                    rows="3"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.observation}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Justification?</label>
                  <div className="radio-container">
                    <input
                      checked={contactData.justification === "Encaissement justifie"}
                      type="radio"
                      name="justification"
                      value="Encaissement justifie"
                      id="male"
                      onChange={handleChange}
                    />
                    <label for="male">Justifié</label>
                    <input
                      checked={
                        contactData.justification === "Encaissement non-justifie"
                      }
                      type="radio"
                      name="justification"
                      value="Encaissement non-justifie"
                      id="female"
                      onChange={handleChange}
                    />
                    <label for="female">Non-justifié</label>
                  </div>
                </div>
                {contactData.justification === "Encaissement justifie" && (
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Justificatif encaissement</label>
                    <input
                      accept=".pdf"
                      className="large"
                      type="file"
                      name="carte"
                      id="carte"
                      ref={fileInput}
                      onChange={(event) => setContrat(event.target.files[0])}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-button">
              <button className="save" type="submit">
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AjouterEncaissement;
