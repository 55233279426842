import React, { useEffect, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import list from "../../../../../../assets/icon40/list.svg";
import photo from "../../../../../../assets/images/avt.jpg";
import { useAuth } from "../../../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import PictureModal from "../../../components/PictureModal";

const EditProfil = () => {
    const { userID, companieID } = useParams();
    const navigate = useNavigate();
    const [photoProfile, setPhotoProfile] = useState("");
    const [formError, setFormError] = useState({});
    const [phoneUser, setPhoneUser] = useState("");
    const [isActive, setActive] = useState("#nav-modif-info-generale-tab");
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [modal, setModal] = useState("");
    const [type, settype] = useState(false);
    const [types, setTypes] = useState(false);
    const [eye, seteye] = useState(true);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setpassword] = useState("password");
    const [passwordConfirm, setpasswordConfirm] = useState("passwordConfirm");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [warnpassword, setwarnpassword] = useState(false);
    const toggleClass = (e) => {
      setActive(e.currentTarget.hash);
    };
    const [contactData, setContactData] = useState({
      userID: "",
      companieID: "",
      nom: "",
      prenom: "",
      contact: "",
      photo: "",
      email: "",
      password: "",
      role: "",
      isBlocked: "",
    });
  
    const {
      authState: { user },
    } = useAuth();
  
    const company = decrypt(user.companyName);
    const companyID = decrypt(user.companyID);
    const userPhoto = decrypt(user.photo);
    const userName = decrypt(user.nom);
    const userPrenom = decrypt(user.prenom);
    const userEmail = decrypt(user.email);
    const userPhone = decrypt(user.contact);
    const userRole = decrypt(user.role);
  
    useEffect(() => {
      axiosPrivate.get(`users/getUser/${userID}`).then((res) => {
        setContactData({
          ...contactData,
          userID: res.data.userID,
          companieID: res.data.companieID,
          nom: res.data.nom,
          prenom: res.data.prenom,
          contact: res.data.contact,
          photo: res.data.photo,
          email: res.data.email,
          password: res.data.password,
          role: res.data.role,
          isBlocked: res.data.isBlocked,
        });
        setPhoneUser(res.data.contact);
      });
    }, []);
  
    const handleChange = (event) => {
      setContactData(() => ({
        ...contactData,
        [event.target.name]: event.target.value,
      }));
    };
  
    const validateForm = () => {
      let err = {};
      if (contactData.nom === "") {
        err.nom = toast.error("😡 Veuillez entrer votre nom!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.prenom === "") {
        err.prenom = toast.error("😡 Veuillez entrer votre prenom!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (phoneUser === "") {
        err.phoneUser = toast.error(
          "😡 Veuillez entrer le numéro de téléphone!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
  
      setFormError({
        ...err,
      });
  
      return Object.keys(err).length < 1;
    };
  
    const submitProfile = async (e) => {
      e.preventDefault();
      let isValid = validateForm();
    
      if (isValid) {
        try {
          await axiosPrivate.put(`users/update/${userID}`, {
            userID: user.userID,
            companieID: user.companyID,
            nom: encrypt(contactData.nom),
            prenom: encrypt(contactData.prenom),
            contact: encrypt(phoneUser),
            email: encrypt(contactData.email),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            if (response.data.code === 101 || response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
        } catch (error) {
          
        }
      } else {
        
      }
    };

  return (
    <>
      <div className="ng-star-inserted">
        <div className="silver">
          <div id="header-main">
            <div className="title">
              <h2>Editer mon profil</h2>
            </div>
            <div className="links">
              <a href={`/`} className="ng-star-inserted">
                <img src={list} />
              </a>
            </div>
          </div>
          <div className="compte">
            <div className="left">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    aria-controls="nav-modif-info-generale-tab"
                    aria-selected={
                      isActive === "#nav-modif-info-generale-tab"
                        ? "true"
                        : "false"
                    }
                    onClick={toggleClass}
                    className={
                      isActive === "#nav-modif-info-generale-tab"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-modif-info-generale-tab"
                    id="nav-modif-info-generale-tab"
                    role="tab"
                  >
                    Modifier les informations générales
                  </a>
                </div>
              </nav>
            </div>
            <div className="right">
              <div id="nav-tabContent" className="tab-content">
                <div
                  aria-labelledby="nav-modif-info-generale-tab"
                  className={
                    isActive === "#nav-modif-info-generale-tab"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-modif-info-generale"
                  role="tabpanel"
                >
                  <form
                    onSubmit={submitProfile}
                    className="ng-untouched ng-pristine ng-valid"
                  >
                    <div className="form-content">
                      <div className="group">
                        <label className="title">Authentification</label>
                        <label className="value">{userEmail}</label>
                      </div>
                      <div className="form-group">
                        <label className="form">Nom</label>
                        <input
                          type="text"
                          className="large ng-untouched ng-pristine ng-valid"
                          name="nom"
                          value={contactData.nom}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form">Prenom</label>
                        <input
                          type="text"
                          className="large ng-untouched ng-pristine ng-valid"
                          name="prenom"
                          value={contactData.prenom}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form">N° Téléphone</label>
                        <PhoneInput
                          international={true}
                          countryCallingCodeEditable={false}
                          placeholder="Entrer le numero du conducteur"
                          value={phoneUser}
                          onChange={(phoneUser) => setPhoneUser(phoneUser)}
                          defaultCountry="CI"
                          flags={flags}
                          labels={fr}
                          limitMaxLength={10}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                    <div className="form-button">
                      <button className="save" type="submit">
                        Enregistrer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProfil