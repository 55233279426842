import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/list.svg";

const EditerAssurance = () => {
  const { numeroPolice, vehiculeID } = useParams();
  const fileInput = useRef();
  const flag = useRef(false);
  const [optionList, setOptionList] = useState([]);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [attachement, setAttachement] = useState("");
  const [contactData, setContactData] = useState({
    numPolice: "",
    type_assurance: "",
    date_effet: "",
    date_echeance: "",
    numAttestation: "",
    date_edition: "",
    categorie: "",
    assureur: "",
    montant: "",
    date_impression: "",
    cle_securite: "",
    companieID: "",
    userID: "",
    vehicleID: "",
    contratAssurancePDF: "",
    createdAt: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchAllVehicleData = async () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchAssuranceData = async () => {
      await axiosPrivate
        .get(`charges/get-assurance/${numeroPolice}`)
        .then((res) => {
          setContactData({
            ...contactData,
            numPolice: res.data.numPolice,
            type_assurance: res.data.type_assurance,
            date_effet: res.data.date_effet,
            date_echeance: res.data.date_echeance,
            numAttestation: res.data.numAttestation,
            date_edition: res.data.date_edition,
            categorie: res.data.categorie,
            assureur: res.data.assureur,
            montant: res.data.montant,
            date_impression: res.data.date_impression,
            cle_securite: res.data.cle_securite,
            oldAssurancePDF: res.data.attachement,
            vehicleID: res.data.vehicleID,
            createdAt: res.data.createdAt
          });
        })
        .catch((err) => console.log(err));
    };

    fetchAllVehicleData();
    fetchAssuranceData();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", attachement);
      const res = await axiosPrivate.post("upload-assurance", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.vehicleID === "") {
      err.vehicleID = toast.error("😡 Veuillez choisir le véhicule!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.compagnie === "") {
      err.compagnie = toast.error(
        "😡 Veuillez entrer la compagnie d'assurance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.numPolice === "") {
      err.numPolice = toast.error("😡 Veuillez entrer le numéro de police!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.numAttestation === "") {
      err.numAttestation = toast.error(
        "😡 Veuillez entrer le numéro d'attestation",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.duree === "") {
      err.duree = toast.error("😡 Veuillez entrer la durée du contrat!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.montant === "") {
      err.montant = toast.error(
        "😡 Veuillez entrer le montant de l'assurance",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_assurance === "") {
      err.date_assurance = toast.error("😡 Veuillez choisir la date d'achat", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_debut === "") {
      err.date_debut = toast.error("😡 Veuillez choisir la date de debut!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_fin === "") {
      err.date_fin = toast.error("😡 Veuillez entrer la date de fin", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    let contratUrl = "";

    if (attachement) contratUrl = await uploadContrat();


    if (isValid) {
      await axiosPrivate
        .put(`charges/assurances/update/${numeroPolice}/${vehiculeID}`, {
          numPolice: encrypt(contactData.numPolice),
          type_assurance: encrypt(contactData.type_assurance),
          date_effet: encrypt(contactData.date_effet),
          date_echeance: encrypt(contactData.date_echeance),
          numAttestation: encrypt(contactData.numAttestation),
          date_edition: encrypt(contactData.date_edition),
          categorie: encrypt(contactData.categorie),
          assureur: encrypt(contactData.assureur),
          montant: encrypt(contactData.montant),
          date_impression: encrypt(contactData.date_impression),
          cle_securite: encrypt(contactData.cle_securite),
          companieID: user.companyID,
          userID: user.userID,
          vehicleID: encrypt(contactData.vehicleID),
          attachement: contratUrl,
          oldAssurancePDF: contactData.oldAssurancePDF,
          createdAt: contactData.createdAt
        })
        .then((response) => {
          console.log(response);
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 103)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/charges/assurances/${companyId}`);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  return (
    <div className="ng-star-inserted">
      <div className="silver">
        <div id="header-main">
          <div className="title">
            <h2>Assurance</h2>
          </div>
        <div className="links">
          <a
            href={`/charges/assurances/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
        </div>
        <form
          className="ng-pristine ng-invalid ng-touched"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="form-l">
            <div className="form-content left">
              <div className="form-block">
                <label className="title-content">Véhicules</label>
                <div className="form-group col9 ng-star-inserted">
                  <label className="form">Attribuer un véhicule</label>
                  <div className="large">
                    <div className="box-select-large">
                      <select
                        name="vehicleID"
                        value={contactData.vehicleID}
                        onChange={handleChange}
                      >
                        <option>Attribuer un véhicule</option>
                        {optionList.map((item) => (
                          <option
                            key={item.immatriculation}
                            value={item.immatriculation}
                          >
                            {item.immatriculation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-content right ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Informations générales</label>
                <div className="form-group noHeight">
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Compagnie d'assurance</label>
                    <input
                      type="text"
                      placeholder="Compagnie d'assurance"
                      className="ng-pristine ng-invalid ng-touched"
                      name="assureur"
                      value={contactData.assureur}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Numéro police</label>
                    <input
                      type="text"
                      placeholder="Numéro police"
                      className="ng-pristine ng-invalid ng-touched"
                      name="numPolice"
                      value={contactData.numPolice}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">N° attestation</label>
                  <input
                    type="text"
                    placeholder="Numéro de l'attestation"
                    className="ng-pristine ng-invalid ng-touched"
                    name="numAttestation"
                    value={contactData.numAttestation}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type assurance</label>
                  <input
                    type="text"
                    placeholder="Type assurance"
                    className="ng-pristine ng-invalid ng-touched"
                    name="type_assurance"
                    value={contactData.type_assurance}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Clé securité</label>
                  <input
                    type="text"
                    placeholder="Clé de l'assurance"
                    className="ng-pristine ng-invalid ng-touched"
                    name="cle_securite"
                    value={contactData.cle_securite}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Catégorie</label>
                  <input
                    type="number"
                    min="0"
                    placeholder="Catégorie"
                    className="ng-pristine ng-invalid ng-touched"
                    name="categorie"
                    value={contactData.categorie}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant de l'assurance</label>
                  <input
                    type="number"
                    min="0"
                    step="5"
                    placeholder="Montant de l'assurance"
                    className="ng-pristine ng-invalid ng-touched"
                    name="montant"
                    value={contactData.montant}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date edition</label>
                  <input
                    type="date"
                    min="0"
                    placeholder="Date d'edition"
                    className="ng-pristine ng-invalid ng-touched"
                    name="date_edition"
                    value={contactData.date_edition}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group noHeight">
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Date Effet</label>
                    <input
                      type="date"
                      placeholder="Date assurance"
                      className="ng-pristine ng-invalid ng-touched"
                      name="date_effet"
                      value={contactData.date_effet}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Date Echeance</label>
                    <input
                      type="date"
                      placeholder="Date début"
                      className="ng-pristine ng-invalid ng-touched"
                      name="date_echeance"
                      value={contactData.date_echeance}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Date Impression</label>
                    <input
                      type="date"
                      placeholder="Date fin"
                      className="ng-pristine ng-invalid ng-touched"
                      name="date_impression"
                      value={contactData.date_impression}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group ng-star-inserted">
                    <label className="form">
                      Télécharger le contrat d'assurance
                    </label>
                    <div className="large">
                      <input
                        accept=".pdf"
                        className="large"
                        type="file"
                        name="contrat"
                        id="contrat"
                        ref={fileInput}
                        onChange={(event) =>
                          setAttachement(event.target.files[0])
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditerAssurance;
