import React, { useEffect, useState } from "react";
import "../../../../../assets/css/home.css";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import ChargeModal from "../../components/ChargeModal";
import DeleteChargeModal from "../../components/DeleteChargeModal";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const MaintenanceTermine = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [demandeData, setDemandeData] = useState([]);
  const [heure, setHeure] = useState(new Date());
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [allInterventionData, setAllInterventionData] = useState([]);
  const [sumMainOeuvre, setSumMainOeuvre] = useState([]);
  const [sumPiece, setSumPiece] = useState([]);
  const [sumMaintenace, setSumMaintenance] = useState([]);
  const [numDemande, setNumDemande] = useState("");
  const [numVehicle, setNumVehicle] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  const statutName = "Termine";

  useEffect(() => {
    const fetchAssuranceData = async () => {
      await axiosPrivate
        .get(`maintenance/maintenance-termine/${companyId}`)
        .then((res) => {
          setAllInterventionData(res.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchSumMainOeuvre = async () => {
      await axiosPrivate
        .get(`maintenance/sum-main-oeuvre/${companyId}`)
        .then((res) => {
          setSumMainOeuvre(res.data[0].total_montant);
        })
        .catch((err) => console.log(err));
    };

    const fetchSumPiece = async () => {
      await axiosPrivate
        .get(`maintenance/sum-piece/${companyId}`)
        .then((res) => {
          setSumPiece(res.data[0].total_montant);
        })
        .catch((err) => console.log(err));
    };

    const fetchSumMaintenance = async () => {
      await axiosPrivate
        .get(`maintenance/sum-maintenance/${companyId}`)
        .then((res) => {
          setSumMaintenance(res.data[0].total_montant);
        })
        .catch((err) => console.log(err));
    };

    fetchAssuranceData();
    fetchSumMainOeuvre();
    fetchSumPiece();
    fetchSumMaintenance();
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if (
        (numVehicle || numDemande || statutName || companyId) &&
        !isCancelled
      ) {
        setIsSearching(true);
        const response = await axiosPrivate.get(
          "maintenance/search-intervention",
          {
            params: { numVehicle, numDemande, statutName, companyId },
          }
        );
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numVehicle, numDemande, statutName, companyId]);

  const nombreMainOeuvre = sumMainOeuvre.toLocaleString("fr-FR");
  const nombrePiece = sumPiece.toLocaleString("fr-FR");
  const nombreMaintenance = sumMaintenace.toLocaleString("fr-FR");

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");

    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;

    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;

    doc.addImage(image, "JPEG", 10, 5, 60, 45);

    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);

    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des interventions terminées";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre

    const headers = [
      "",
      "Véhicule",
      "N° Intervention",
      "Garage",
      "Travaux réalisés",
      "Main d'oeuvre",
      "Montant pièce",
      "Montant total",
      "Date debut",
      "Date fin",
    ];

    const tableData = isSearching
      ? searchResults.map((result) => {
          return [
            "",
            result["vehicleID"],
            result["numIntervention"],
            result["garage"],
            result["traveau_realise"],
            result["montant_main_doeuvre"],
            result["montant_piece"],
            result["montant_total"],
            moment(result["date_debut"]).format("DD/MM/YYYY"),
            moment(result["date_fin"]).format("DD/MM/YYYY"),
          ];
        })
      : allInterventionData.map((demande) => {
          return [
            "",
            demande["vehicleID"],
            demande["numIntervention"],
            demande["garage"],
            demande["traveau_realise"],
            demande["montant_main_doeuvre"],
            demande["montant_piece"],
            demande["montant_total"],
            moment(demande["date_debut"]).format("DD/MM/YYYY"),
            moment(demande["date_fin"]).format("DD/MM/YYYY"),
          ];
        });

    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });

    doc.save("liste-demande-termine.pdf");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Maintenances éffectuées</h2>
          </div>
          <div className="links">
            <a title="Exporter en PDF" className="ng-star-inserted">
            <img
                src={pdf}
                alt=""
                onClick={handlePDFClick}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="numVehicle"
                  value={numVehicle}
                  onChange={(e) => setNumVehicle(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par N° demande</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par Visite technique"
                  name="numDemande"
                  value={numDemande}
                  onChange={(e) => setNumDemande(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap4">
            <span className="value c-black"></span>
            <span className="value c-blue">{allInterventionData.length}</span>
            <span className="label">Nombre d'enregistrement</span>
          </div>
          <div className="recap4">
            <span className="value c-red bold">{nombreMainOeuvre} F.CFA</span>
            <span className="label">Montant main d'oeuvre</span>
          </div>
          <div className="recap4">
            <span className="value c-red bold">{nombrePiece} F.CFA</span>
            <span className="label">Montant pièce</span>
          </div>
          <div className="recap4">
            <span className="value c-red bold">{nombreMaintenance} F.CFA</span>
            <span className="label">Montant Total</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th colspan="1"></th>
                  <th colspan="4">Demande d'intervention</th>
                  <th colspan="14">Réparation</th>
                </tr>
                <tr className="ng-star-inserted">
                  <th style={{ width: "3em" }}></th>
                  <th
                    className="ng-star-inserted"
                    tabIndex="0"
                    style={{ width: "5%" }}
                  >
                    N° demande
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Description{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "5%" }}>
                    {" "}
                    Véhicule{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "8%" }}>
                    {" "}
                    Date début{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Garage{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Travaux à réaliser{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "5%" }}>
                    {" "}
                    Kilométrage{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Montant main d'oeuvre{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Montant pièce{" "}
                  </th>
                  <th className="ng-star-inserted" style={{ width: "15%" }}>
                    {" "}
                    Montant total{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? allInterventionData.map((demande) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td className="ng-star-inserted"></td>
                          <td className="ng-star-inserted">
                            {demande.numDemandeIntervention}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.demande_intervention?.description}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(
                              demande.demande_intervention?.date_demande
                            ).toLocaleDateString("en-GB")}
                          </td>
                          <td className="ng-star-inserted">{demande.garage}</td>
                          <td className="ng-star-inserted">
                            {demande.traveau_realise}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.demande_intervention?.kilometrage_demande}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.montant_main_doeuvre}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.montant_piece}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.montant_total}
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td className="ng-star-inserted"></td>
                          <td className="ng-star-inserted">
                            {result.numDemandeIntervention}
                          </td>
                          <td className="ng-star-inserted">
                            {result.demande_intervention?.description}
                          </td>
                          <td className="ng-star-inserted">
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(
                              result.demande_intervention?.date_demande
                            ).toLocaleDateString("en-GB")}
                          </td>
                          <td className="ng-star-inserted">{result.garage}</td>
                          <td className="ng-star-inserted">
                            {result.traveau_realise}
                          </td>
                          <td className="ng-star-inserted">
                            {result.demande_intervention?.kilometrage_demande}
                          </td>
                          <td className="ng-star-inserted">
                            {result.montant_main_doeuvre}
                          </td>
                          <td className="ng-star-inserted">
                            {result.montant_piece}
                          </td>
                          <td className="ng-star-inserted">
                            {result.montant_total}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
      {showDetailModal && setModal != null && (
        <ChargeModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showChargeModal && setChargeModal != null && (
        <DeleteChargeModal
          closeDeleteChargeModal={setShowChargeModal}
          data={chargeModal}
        />
      )}
    </>
  );
};

export default MaintenanceTermine;
