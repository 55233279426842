import React, { useEffect, useState } from "react";
import "../../../../assets/css/home.css";
import encaissement from "../../../../assets/icon40/encaissement.svg";
import pdf from "../../../../assets/icon40/ray-pdf.svg";
import attachement from "../../../../assets/icon40/clipboard-list.svg";
import iconEdit from "../../../../assets/icons/dropdown-edit.svg";
import { decrypt } from "../../../module/myCrypto";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import axiosPrivate from "../../../services/api/axios";
import defaultLogo from "../../../../assets/images/raymobileci.jpeg";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const ListVersement = () => {
  const navigate = useNavigate();
  const [allCaisseData, setAllCaisseData] = useState([]);
  const [sumAllCash, setSumAllCash] = useState([]);
  const [sumAllCashWaitYango, setSumAllCashWaitYango] = useState([]);
  const [sumAllCashWaitTaxi, setSumAllCashWaitTaxi] = useState([]);
  const [sum, setSum] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [dropdownList, setDropdownList] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [countTaxiVersements, setCountTaxiVersements] = useState([]);
  const [countYangoVersements, setCountYangoVersements] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [driverName, setDriverName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getAllCashs = () => {
      axiosPrivate
        .get(`versements/find-allcash/${companyId}`)
        .then((response) => {
          setAllCaisseData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getSumAllCash = () => {
      axiosPrivate
        .get(`versements/sum-allcash/${companyId}`)
        .then((response) => {
          setSumAllCash(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getSumAllCashWaitYango = () => {
      axiosPrivate
        .get(`versements/sum-allcash-wait-yango/${companyId}`)
        .then((response) => {
          setSumAllCashWaitYango(response.data.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllCashWaitTaxi = () => {
      axiosPrivate
        .get(`versements/sum-allcash-wait-taxi/${companyId}`)
        .then((response) => {
          setSumAllCashWaitTaxi(response.data.total);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const countTaxiVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-taxi/${companyId}`)
        .then((res) => {
          setCountTaxiVersements(res.data[0].total);
        })
        .catch((err) => console.log(err));
    };
    const countYangoVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-yango/${companyId}`)
        .then((res) => {
          setCountYangoVersements(res.data[0].total);
        })
        .catch((err) => console.log(err));
    };

    const fetchChauffeurs = () => {
      axiosPrivate
        .get(`drivers/driver-vehicle/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    /*

    const getAllJustifie = () => {
      axiosPrivate
        .get(`finances/sum-justifie/${companyId}`)
        .then((response) => {
          setSumAllJustifie(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllNonJustifie = () => {
      axiosPrivate
        .get(`finances/sum-non-justifie/${companyId}`)
        .then((response) => {
          setSumAllNonJustifie(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };*/

    getAllCashs();
    getSumAllCash();
    getSumAllCashWaitYango();
    getAllCashWaitTaxi();
    countTaxiVersement();
    countYangoVersement();
    fetchChauffeurs();
  }, []);

  const handleSelect = (item) => {
    setDriverName(item.numPermis);
    setSelectedItem(item.nom + " " + item.prenom); // Mettre à jour l'item sélectionné
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    if (inputValue !== "") {
      axiosPrivate
        .get("drivers/search-drivers-id", {
          params: { nom: inputValue, companyId },
        })
        .then((response) => {
          setDropdownList(response.data);
          setIsDropdownVisible(true);
        })
        .catch((error) => {
          console.error("Il y avait une erreur!", error);
        });
    } else {
      setDropdownList([]); // Réinitialiser la liste déroulante lorsque l'input est vide
      setSelectedItem(""); // Réinitialiser l'item sélectionné lorsque l'input est vide
      setIsDropdownVisible(false);
    }
  }, [inputValue, companyId]);

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      if ((driverName || vehicleNumber || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get("versements/search-versement", {
          params: { driverName, vehicleNumber, companyId },
        });
        if (!isCancelled) {
          setSearchResults(response.data);
          //setIsSearching(true); // Définir isSearching sur false ici
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [driverName, vehicleNumber, companyId]);

  const nombreTaxiVersement = countTaxiVersements?.toLocaleString("fr-FR");
  const nombreYangoVersement = countYangoVersements?.toLocaleString("fr-FR");
  const totalVerser = countTaxiVersements + countYangoVersements;

  useEffect(() => {
    setSum(Number(sumAllCashWaitYango) + Number(sumAllCashWaitTaxi));
  }, [sumAllCashWaitYango, sumAllCashWaitTaxi]);

  const handleClickEncaissementOpen = () => {
    navigate("/finances/caisses/encaissement/ajouter");
  };

  function handleOnClickChargeModal(
    numPolice,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([numPolice, attachement, vehicleID, cartegrise, companie]);
  }

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");

    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;

    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;

    doc.addImage(image, "JPEG", 10, 5, 60, 45);

    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);

    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des versements";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre

    const headers = [
      "",
      "Conducteurs",
      "Véhicules",
      "Montant versé",
      "Date course",
      "Type Tranport",
      "Utilisateurs",
    ];

    const tableData = isSearching 
  ? searchResults.map((result) => {
      return [
        "",
        result.conducteur ? `${result.conducteur.nom} ${result.conducteur.prenom}` : "N/A",
        result.vehicleID,
        result.montant,
        new Date(result.calendar_event.start).toLocaleDateString("fr-FR", options),
        result.vehicule?.type_transport,
        `${result.user.nom} ${result.user.prenom}`,
      ];
    })
  : allCaisseData.map((caisses) => {
      return [
        "",
        `${caisses.conducteur.nom} ${caisses.conducteur.prenom}`,
        caisses.vehicleID,
        caisses.montant,
        new Date(caisses.calendar_event.start).toLocaleDateString("fr-FR", options),
        caisses.vehicule.type_transport,
        `${caisses.user.nom} ${caisses.user.prenom}`,
      ];
    });


    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });

    doc.save("liste-versements.pdf");
  };

  //const soldeCaisse =  Number(sumAllEncaissement) - Number(sumAllDecaissement);
  let options = { year: "numeric", month: "long", day: "numeric" };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title">
            <h2>Versements</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter encaissement"
              className="ng-star-inserted"
            >
              <img
                src={encaissement}
                alt=""
                onClick={handleClickEncaissementOpen}
              />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img
                src={pdf}
                alt=""
                onClick={handlePDFClick}
                style={{ cursor: "pointer" }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Conducteur</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par nom conducteur"
                  name="inputValue"
                  value={selectedItem} // Utilisez selectedItem ici
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    if (e.target.value === '') {
                      setSelectedItem(''); // Réinitialiser selectedItem lorsque l'input est vide
                      setIsSearching(false);
                    } else {
                      setSelectedItem(e.target.value); // Mettre à jour selectedItem lorsque vous tapez dans le champ
                      setIsSearching(true);
                    }
                  }}
                />

                {isDropdownVisible && dropdownList.length > 0 && (
                  <ul>
                    {dropdownList.map((item, index) => {
                      return (
                        <li key={index} onClick={() => handleSelect(item)}>
                          {item.nom} {item.prenom}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par Véhicule"
                  name="vehicleNumber"
                  value={vehicleNumber}
                  onChange={(e) => setVehicleNumber(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap">
          <div className="recap5">
            <span className="value c-blue">{allCaisseData.length}</span>
            <span className="label">Nombre d'enregistrements</span>
          </div>
          <div className="recap5">
            <span className="value c-blue bold">{totalVerser} F.CFA</span>
            <span className="label">Montant total versé</span>
          </div>
          <div className="recap5">
            <span className="value c-red bold">{sum} F.CFA</span>
            <span className="label">Versement total en attente</span>
          </div>
        </div>
        <div className="scroll with-hover with-selected ui-table ui-widget">
          <table>
            <thead className="ui-table-thead">
              <tr className="ng-star-inserted">
                <th style={{ width: "50px", padding: "0.3rem 0.3rem" }}></th>
                <th className="ng-star-inserted">Conducteur</th>
                <th className="ng-star-inserted">Véhicule</th>
                <th className="ng-star-inserted">Montant</th>
                <th className="ng-star-inserted">Date course</th>
                <th className="ng-star-inserted">Type Transport</th>
                <th className="ng-star-inserted">Enregistré par</th>
                <th className="ng-star-inserted">Voir détail</th>
              </tr>
            </thead>
            <tbody className="ui-table-tbody">
              {isSearching === false
                ? allCaisseData.map((caisses) => {
                    return (
                      <tr
                        className="ui-selectable-row ng-star-inserted"
                        tabIndex="0"
                      >
                        <td
                          style={{ width: "40px", padding: ".3rem .3rem" }}
                          className="ng-star-inserted"
                        >
                          <div
                            className={
                              subMenuOpen ? "dropdown show" : "dropdown"
                            }
                          >
                            <button
                              aria-expanded="true"
                              aria-haspopup="true"
                              className="btn btn-default btn-xs dropdown-toggle-no-caret"
                              data-toggle="dropdown"
                              id="dropdownMenu1"
                              type="button"
                            >
                              <span className="glyphicon glyphicon-option-vertical">
                                <i
                                  className="fa-solid fa-ellipsis-vertical"
                                  onClick={() =>
                                    setSubMenuOpen({
                                      ...subMenuOpen,
                                      [caisses.reference]:
                                        !subMenuOpen[caisses.reference],
                                    })
                                  }
                                  open={!!subMenuOpen[caisses.reference]}
                                ></i>
                              </span>
                            </button>
                            {subMenuOpen[caisses.reference] ? (
                              <ul
                                aria-labelledby="dropdownMenu1"
                                className={
                                  subMenuOpen
                                    ? "dropdown-menu dropdown-menu-right show"
                                    : "dropdown-menu dropdown-menu-right"
                                }
                                x-placement="top-end"
                                style={{
                                  position: "absolute",
                                  willChange: "transform",
                                  top: "0px",
                                  left: "0px",
                                  transform: "translate3d(0px, 38px, 0px)",
                                }}
                              >
                                <li className="c-blue ng-star-inserted">
                                  <a
                                    href={`/finances/caisses/encaissement/edit/${caisses.reference}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={iconEdit} />
                                    Modifier
                                  </a>
                                </li>
                              </ul>
                            ) : (
                              <ul></ul>
                            )}
                          </div>
                        </td>
                        <td className="ng-star-inserted">
                          {caisses.conducteur.nom} {caisses.conducteur.prenom}
                        </td>
                        <td className="ng-star-inserted">
                          {caisses.vehicleID}
                        </td>
                        <td className="ng-star-inserted">{caisses.montant}</td>
                        <td className="ng-star-inserted">
                          {new Date(
                            caisses.calendar_event.start
                          ).toLocaleDateString("fr-FR", options)}
                        </td>
                        <td className="ng-star-inserted">
                          {caisses.vehicule.type_transport}
                        </td>
                        <td className="ng-star-inserted">
                          {caisses.user.nom} {caisses.user.prenom}
                        </td>
                        <td className="ng-star-inserted">
                          <span className="ng-star-inserted">
                            <a
                              data-target="#popupAttachement"
                              data-toggle="modal"
                              style={{ cursor: "pointer" }}
                              /*onClick={() =>
              handleOnClickModal(
                caisses.numPolice,
                caisses.attachement,
                `L'Assurance`,
                caisses.vehicleID,
                companyId
              )
            }*/
                            >
                              <img src={attachement} />
                            </a>
                          </span>
                        </td>
                      </tr>
                    );
                  })
                : searchResults.map((result) => {
                    return (
                      <tr
                        className="ui-selectable-row ng-star-inserted"
                        tabIndex="0"
                      >
                        <td
                          style={{ width: "40px", padding: ".3rem .3rem" }}
                          className="ng-star-inserted"
                        >
                          <div
                            className={
                              subMenuOpen ? "dropdown show" : "dropdown"
                            }
                          >
                            <button
                              aria-expanded="true"
                              aria-haspopup="true"
                              className="btn btn-default btn-xs dropdown-toggle-no-caret"
                              data-toggle="dropdown"
                              id="dropdownMenu1"
                              type="button"
                            >
                              <span className="glyphicon glyphicon-option-vertical">
                                <i
                                  className="fa-solid fa-ellipsis-vertical"
                                  onClick={() =>
                                    setSubMenuOpen({
                                      ...subMenuOpen,
                                      [result.reference]:
                                        !subMenuOpen[result.reference],
                                    })
                                  }
                                  open={!!subMenuOpen[result.reference]}
                                ></i>
                              </span>
                            </button>
                            {subMenuOpen[result.reference] ? (
                              <ul
                                aria-labelledby="dropdownMenu1"
                                className={
                                  subMenuOpen
                                    ? "dropdown-menu dropdown-menu-right show"
                                    : "dropdown-menu dropdown-menu-right"
                                }
                                x-placement="top-end"
                                style={{
                                  position: "absolute",
                                  willChange: "transform",
                                  top: "0px",
                                  left: "0px",
                                  transform: "translate3d(0px, 38px, 0px)",
                                }}
                              >
                                <li className="c-blue ng-star-inserted">
                                  <a
                                    href={`/finances/caisses/encaissement/edit/${result.reference}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={iconEdit} />
                                    Modifier
                                  </a>
                                </li>
                              </ul>
                            ) : (
                              <ul></ul>
                            )}
                          </div>
                        </td>
                        <td className="ng-star-inserted">
                          {result.conducteur ? result.conducteur.nom : "N/A"}{" "}
                          {result.conducteur.prenom}
                        </td>
                        <td className="ng-star-inserted">{result.vehicleID}</td>
                        <td className="ng-star-inserted">{result.montant}</td>
                        <td className="ng-star-inserted">
                          {new Date(
                            result.calendar_event.start
                          ).toLocaleDateString("fr-FR", options)}
                        </td>
                        <td className="ng-star-inserted">
                          {result.vehicule?.type_transport}
                        </td>
                        <td className="ng-star-inserted">
                          {result.user.nom} {result.user.prenom}
                        </td>
                        <td className="ng-star-inserted">
                          <span className="ng-star-inserted">
                            <a
                              data-target="#popupAttachement"
                              data-toggle="modal"
                              style={{ cursor: "pointer" }}
                              /*onClick={() =>
              handleOnClickModal(
                caisses.numPolice,
                caisses.attachement,
                `L'Assurance`,
                caisses.vehicleID,
                companyId
              )
            }*/
                            >
                              <img src={attachement} />
                            </a>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListVersement;
