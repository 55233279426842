import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconView from "../../../../../assets/icons/dropdown-view.svg";
import iconValidate from "../../../../../assets/icons/dropdown-cloturer.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

const MaintenanceEnCours = () => {
  const [carDatas, setCardata] = useState([]);
  const flag = useRef(false);
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [allMaintenanceCourData, setAllMaintenanceCourData] = useState([]);
  const [allMaintenanceTermineData, setAllMaintenanceTermineData] = useState(
    []
  );
  const [numDemande, setNumDemande] = useState("");
  const [numVehicle, setNumVehicle] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const listStatut = "En maintenance";

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchMaintenanceEnCoursData = async () => {
      await axiosPrivate
        .get(`maintenance/maintenance-en-cours/${companyId}`)
        .then((res) => {
          setAllMaintenanceCourData(res.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchMaintenanceTermineData = async () => {
      await axiosPrivate
        .get(`maintenance/maintenance-termine/${companyId}`)
        .then((res) => {
          setAllMaintenanceTermineData(res.data);
        })
        .catch((err) => console.log(err));
    };

    fetchMaintenanceEnCoursData();
    fetchMaintenanceTermineData();
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if (
        (numVehicle || numDemande || listStatut || companyId) &&
        !isCancelled
      ) {
        setIsSearching(true);
        const response = await axiosPrivate.get(
          "maintenance/search-intervention-encours",
          {
            params: { numVehicle, numDemande, listStatut, companyId },
          }
        );
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numVehicle, numDemande, listStatut, companyId]);

  const handleValidateDemande = async (
    numDemandeIntervention,
    statutVehicule,
    vehicleID
  ) => {
    await axiosPrivate
      .put(
        `maintenance/valider-demande-intervention/${numDemandeIntervention}/${statutVehicule}/${vehicleID}`
      )
      .then((res) => {
        if (res.data.code === 100) {
          toast.success(`👏 ${res.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
          navigate(`/maintenances/demande-intervention/${companyId}`);
        } else {
          toast.error(`😡 Erreur lors de la validation `, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
            style: {
              bodyClassName: "grow-font-size",
              progressClassName: "fancy-progress-bar",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  function handleOnClickModal(
    numDemandeIntervention,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([
      numDemandeIntervention,
      attachement,
      vehicleID,
      cartegrise,
      companie,
    ]);
  }

  function handleOnClickChargeModal(
    numDemandeIntervention,
    vehicleID,
    companie,
    content
  ) {
    setShowChargeModal(true);
    setChargeModal([numDemandeIntervention, vehicleID, companie, content]);
  }

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");

    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;

    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;

    doc.addImage(image, "JPEG", 10, 5, 60, 45);

    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);

    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des interventions en cours";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre

    const headers = [
      "",
      "N° Demande",
      "Véhicule",
      "Kilométrage",
      "Statut",
      "Type Intervention",
      "Degré d'Intervention",
      "Degré de gravité",
      "Date demande",
    ];

    const tableData = isSearching
      ? searchResults.map((result) => {
          return [
            "",
            result["numDemandeIntervention"],
            result["vehicleID"],
            result["kilometrage_demande"],
            result["statut_vehicule"],
            result["type_intervention"],
            result["degre_intervention"],
            result["degre_gravite"],
            moment(result["date_demande"]).format("DD/MM/YYYY"),
          ];
        })
      : allMaintenanceCourData.map((demande) => {
          return [
            "",
            demande["numDemandeIntervention"],
            demande["vehicleID"],
            demande["kilometrage_demande"],
            demande["statut_vehicule"],
            demande["type_intervention"],
            demande["degre_intervention"],
            demande["degre_gravite"],
            moment(demande["date_demande"]).format("DD/MM/YYYY"),
          ];
        });

    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });

    doc.save("liste-demande-en-cours.pdf");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Maintenace en cours...</h2>
          </div>
          <div className="links">
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img
                src={pdf}
                alt=""
                onClick={handlePDFClick}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="numVehicle"
                  value={numVehicle}
                  onChange={(e) => setNumVehicle(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par N° demande</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par Visite technique"
                  name="numDemande"
                  value={numDemande}
                  onChange={(e) => setNumDemande(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-blue">
              {allMaintenanceCourData.length}
            </span>
            <span className="label">Maintenance(s) en cours...</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    N° Demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Kilométrage
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Statut
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Type Intervention
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Degré d'Intervention
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Degré de gravité
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Statut demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Générer le bon
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? allMaintenanceCourData.map((demande) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [demande.numDemandeIntervention]:
                                          !subMenuOpen[
                                            demande.numDemandeIntervention
                                          ],
                                      })
                                    }
                                    open={
                                      !!subMenuOpen[
                                        demande.numDemandeIntervention
                                      ]
                                    }
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[demande.numDemandeIntervention] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/terminer-reparation/${companyId}/${demande.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconValidate} />
                                      terminer reparation
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          demande.numDemandeIntervention,
                                          demande.vehicleID,
                                          companyId,
                                          `La demande d'intervention`
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {demande.numDemandeIntervention}
                          </td>
                          <td
                            className="c-blue ng-star-inserted"
                            style={{ fontWeight: "bold" }}
                          >
                            {demande.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.kilometrage_demande}
                          </td>
                          {demande.statut_vehicule === "Immobilise" ? (
                            <td className="ng-star-inserted">
                              <span
                                style={{
                                  width: "70px",
                                  minWidth: "100px",
                                  color: "#fff",
                                  padding: "2px 6px",
                                  backgroundColor: "#da3738",
                                  fontSize: "11px",
                                  fontWeight: "bold",
                                  borderRadius: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {demande.statut_vehicule}
                              </span>
                            </td>
                          ) : (
                            <td className="ng-star-inserted">
                              {demande.statut_vehicule}
                            </td>
                          )}
                          <td className="ng-star-inserted">
                            {demande.type_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.degre_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.degre_gravite}
                          </td>
                          <td className="ng-star-inserted">
                            <span
                              style={{
                                width: "70px",
                                minWidth: "100px",
                                color: "#fff",
                                padding: "2px 6px",
                                backgroundColor: "#0029c2",
                                fontSize: "11px",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {demande.statut_demande}
                            </span>
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(demande.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(demande.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.numDemandeIntervention]:
                                          !subMenuOpen[
                                            result.numDemandeIntervention
                                          ],
                                      })
                                    }
                                    open={
                                      !!subMenuOpen[
                                        result.numDemandeIntervention
                                      ]
                                    }
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.numDemandeIntervention] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/terminer-reparation/${companyId}/${result.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconValidate} />
                                      terminer reparation
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          result.numDemandeIntervention,
                                          result.vehicleID,
                                          companyId,
                                          `La demande d'intervention`
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.numDemandeIntervention}
                          </td>
                          <td
                            className="c-blue ng-star-inserted"
                            style={{ fontWeight: "bold" }}
                          >
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {result.kilometrage_demande}
                          </td>
                          {result.statut_vehicule === "Immobilise" ? (
                            <td className="ng-star-inserted">
                              <span
                                style={{
                                  width: "70px",
                                  minWidth: "100px",
                                  color: "#fff",
                                  padding: "2px 6px",
                                  backgroundColor: "#da3738",
                                  fontSize: "11px",
                                  fontWeight: "bold",
                                  borderRadius: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {result.statut_vehicule}
                              </span>
                            </td>
                          ) : (
                            <td className="ng-star-inserted">
                              {result.statut_vehicule}
                            </td>
                          )}
                          <td className="ng-star-inserted">
                            {result.type_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {result.degre_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {result.degre_gravite}
                          </td>
                          <td className="ng-star-inserted">
                            <span
                              style={{
                                width: "70px",
                                minWidth: "100px",
                                color: "#fff",
                                padding: "2px 6px",
                                backgroundColor: "#0029c2",
                                fontSize: "11px",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {result.statut_demande}
                            </span>
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default MaintenanceEnCours;
