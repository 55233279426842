import { useEffect, useState } from "react";
import "./assets/css/loader.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import LoginPage from "./pages/app/registrations/login";
import RegisterPage from "./pages/app/registrations/register";
import Error from "./pages/utils/Error";
import DashboardPage from "./pages/app/admin/dashboard/DashboardPage";
import HomePage from "./pages/app/admin/home";
import { AuthProvider, useAuth } from "./pages/context/AuthProvider";
import ListeVehicule from "./pages/app/admin/flottes/vehicules";
import AjoutVehicule from "./pages/app/admin/flottes/vehicules/add";
import VehicleProfile from "./pages/app/admin/flottes/vehicules/profile";
import VehicleEdit from "./pages/app/admin/flottes/vehicules/edit";
import ConducteurList from "./pages/app/admin/personnels/conducteurs";
import AjouterConducteur from "./pages/app/admin/personnels/conducteurs/add";
import ListeAssurance from "./pages/app/admin/administration/assurances";
import AjouterAssurance from "./pages/app/admin/administration/assurances/add";
import CarteGriseListe from "./pages/app/admin/administration/carte-grises/cartegrise.list";
import AddCarteGrise from "./pages/app/admin/administration/carte-grises/add";
import EditCarteGrise from "./pages/app/admin/administration/carte-grises/edit";
import VignetteList from "./pages/app/admin/administration/vignettes";
import EditerAssurance from "./pages/app/admin/administration/assurances/edit";
import EditerConducteur from "./pages/app/admin/personnels/conducteurs/edit";
import VignetteAdd from "./pages/app/admin/administration/vignettes/add";
import EditerVignette from "./pages/app/admin/administration/vignettes/edit";
import ProfileConducteur from "./pages/app/admin/personnels/conducteurs/profile";
import ListConstatation from "./pages/app/admin/sinistre";
import AjouterConstatation from "./pages/app/admin/sinistre/add";
import ListVisiteTechnique from "./pages/app/admin/administration/visite-technique";
import AjouterVisiteTechnique from "./pages/app/admin/administration/visite-technique/add";
import DemandeIntervention from "./pages/app/admin/maintenances/interventions";
import EditerDemandeIntervention from "./pages/app/admin/maintenances/interventions/edit";
import EffectueDemande from "./pages/app/admin/maintenances/interventions/add";
import MaintenanceEnCours from "./pages/app/admin/maintenances/en-cours";
import CompanieProfile from "./pages/app/admin/personnels/company";
import CompanieEditer from "./pages/app/admin/personnels/company/edit";
import MaintenanceTermine from "./pages/app/admin/maintenances/intervention-termine";
import TerminerReparation from "./pages/app/admin/maintenances/intervention-termine/add";
import UserProfile from "./pages/app/admin/personnels/users/profile";
import EditProfil from "./pages/app/admin/personnels/users/edit";
import ModifierAcces from "./pages/app/admin/personnels/users/edit-acces";
import ResetPassword from "./pages/app/registrations/reset-password";
import ListeEncaissement from "./pages/app/admin/finance/encaissement";
import ListeDecaissement from "./pages/app/admin/finance/decaissement";
import AjouterEncaissement from "./pages/app/admin/finance/encaissement/add/ajouterEncaissement";
import AjouterDecaissement from "./pages/app/admin/finance/decaissement/add/ajouterDecaissement";
import EditEncaissement from "./pages/app/admin/finance/encaissement/edit";
import EditerDecaissement from "./pages/app/admin/finance/decaissement/edit";
import PlanningVehicule from "./pages/app/admin/planning";
import ListVersement from "./pages/app/admin/versement";
import AddVersement from "./pages/app/admin/versement/add";
import ListeUtilisateur from "./pages/app/admin/personnels/users";
import Assurances from "./pages/app/admin/alertes/assurance";
import CarteGrise from "./pages/app/admin/alertes/carteGrise";
import Maintenance from "./pages/app/admin/alertes/maintenance";
import PermisConduire from "./pages/app/admin/alertes/permisConduire";
import Reparation from "./pages/app/admin/alertes/reparation";
import Vignettes from "./pages/app/admin/alertes/vignette";
import VisiteTechnique from "./pages/app/admin/alertes/visiteTechnique";
import EditVisiteTechnique from "./pages/app/admin/administration/visite-technique/edit";
import AjouterUtilisateur from "./pages/app/admin/personnels/users/add";
import Loading from "./assets/images/raymobileci.avif";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const ProtectedRoute = ({ children }) => {
    if (!user) {
      return <LoginPage />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <HomePage />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: <DashboardPage />,
        },
        {
          path: "/companie/profile/:companyID/:userID",
          element: <CompanieProfile />,
        },
        {
          path: "/companie/editer/:companyID",
          element: <CompanieEditer />,
        },
        {
          path: "/flottes/vehicules/:companyID",
          element: <ListeVehicule />,
        },
        {
          path: "/flottes/vehicules/ajouter",
          element: <AjoutVehicule />,
        },
        {
          path: "/flottes/vehicules/profile/:vehiculeId/:acquisition/:contrat",
          element: <VehicleProfile />,
        },
        {
          path: "/flottes/vehicules/edit/:vehiculeId/:acquisition/:numContrat",
          element: <VehicleEdit />,
        },
        {
          path: "/personnels/users/profile/:companieID/:userID",
          element: <UserProfile />,
        },
        {
          path: "/personnels/users/edit/:companieID/:userID",
          element: <EditProfil />,
        },
        {
          path: "/personnels/users/reset-password/:companieID/:userID",
          element: <ModifierAcces />,
        },
        {
          path: "/personnels/conducteurs/:companieID",
          element: <ConducteurList />,
        },
        {
          path: "/personnels/conducteurs/ajouter/:companieID",
          element: <AjouterConducteur />,
        },
        {
          path: "/personnels/conducteurs/profile/:numPermis/:companieID",
          element: <ProfileConducteur />,
        },
        {
          path: "/personnels/conducteurs/edit/:numPermis/:companieID",
          element: <EditerConducteur />,
        },
        {
          path: "/personnels/utilisateur/:companieID",
          element: <ListeUtilisateur />,
        },
        {
          path: "/personnels/utilisateur/ajouter/:companieID",
          element: <AjouterUtilisateur />,
        },
        {
          path: "/charges/assurances/:companieID",
          element: <ListeAssurance />,
        },
        {
          path: "/charges/assurances/ajouter",
          element: <AjouterAssurance />,
        },
        {
          path: "/charges/assurances/edit/:numeroPolice/:vehiculeID",
          element: <EditerAssurance />,
        },
        {
          path: "/charges/carte-grise/:companieID",
          element: <CarteGriseListe />,
        },
        {
          path: "/charges/carte-grise/ajouter/:companieID",
          element: <AddCarteGrise />,
        },
        {
          path: "/charges/carte-grise/edit/:numCarteGrise",
          element: <EditCarteGrise />,
        },
        {
          path: "/charges/vignettes/:companieID",
          element: <VignetteList />,
        },
        {
          path: "/charges/vignettes/create",
          element: <VignetteAdd />,
        },
        {
          path: "/charges/vignettes/edit/:numVignette/:vehicleID",
          element: <EditerVignette />,
        },
        {
          path: "/administratif/constatation/:companieID",
          element: <ListConstatation />,
        },
        {
          path: "/administratif/constatation/ajouter/:companieID",
          element: <AjouterConstatation />,
        },
        {
          path: "/charges/visite-technique/:companieID",
          element: <ListVisiteTechnique />,
        },
        {
          path: "/charges/visite-technique/create/:companieID",
          element: <AjouterVisiteTechnique />,
        },
        {
          path: "/charges/visite-technique/edit/:numVisite/:vehicleID",
          element: <EditVisiteTechnique />,
        },
        {
          path: "/maintenances/demande-intervention/create/:companieID",
          element: <EffectueDemande />,
        },
        {
          path: "/maintenances/demande-intervention/:companieID",
          element: <DemandeIntervention />,
        },
        {
          path: "/maintenances/editer-intervention/:numDemandeIntervention",
          element: <EditerDemandeIntervention />,
        },
        {
          path: "/maintenances/intervention-en-cours/:companieID",
          element: <MaintenanceEnCours />,
        },
        {
          path: "/maintenances/intervention-termine/:companieID",
          element: <MaintenanceTermine />,
        },
        {
          path: "/maintenances/terminer-reparation/:companieID/:numDemandeIntervention",
          element: <TerminerReparation />,
        },
        {
          path: "/finances/caisses/encaissement/:companieID",
          element: <ListeEncaissement />,
        },
        {
          path: "/finances/caisses/encaissement/ajouter",
          element: <AjouterEncaissement />,
        },
        {
          path: "/finances/caisses/encaissement/edit/:reference",
          element: <EditEncaissement />,
        },
        {
          path: "/finances/caisses/decaissement/:companieID",
          element: <ListeDecaissement />,
        },
        {
          path: "/finances/caisses/decaissement/ajouter",
          element: <AjouterDecaissement />,
        },
        {
          path: "/finances/caisses/decaissement/edit/:reference",
          element: <EditerDecaissement />,
        },
        {
          path: "/planning/planification/:companieID",
          element: <PlanningVehicule />,
        },
        {
          path: "/versements/:companieID",
          element: <ListVersement />,
        },
        {
          path: "/versements/create/:event",
          element: <AddVersement />,
        },
        {
          path: "/alertes/assurances/:companieID",
          element: <Assurances />,
        },
        {
          path: "/alertes/carte-grise/:companieID",
          element: <CarteGrise />,
        },
        {
          path: "/alertes/maintenances/:companieID",
          element: <Maintenance />,
        },
        {
          path: "/alertes/permis-conduire/:companieID",
          element: <PermisConduire />,
        },
        {
          path: "/alertes/reparations/:companieID",
          element: <Reparation />,
        },
        {
          path: "/alertes/vignettes/:companieID",
          element: <Vignettes />,
        },
        {
          path: "/alertes/visite-technique/:companieID",
          element: <VisiteTechnique />,
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/reset-password/:token",
      element: <ResetPassword />,
    },
    {
      path: "*",
      element: <Error />,
    },
  ]);

  return (
    <>
      {loading ? (
        <div className="appContainer">
          <div className="spinnerContainer">
            <div className="center">
              <div className="imageContainer">
                <img src={Loading} alt="Description de l'image" />
              </div>
            </div>
            <div className="spinner"></div>
            <div className="loader">
              <p>RayMobileCI</p>
              <div className="words">
                <span className="word">Taxi/VTC</span>
                <span className="word">Assurances</span>
                <span className="word">VTC</span>
                <span className="word">Location</span>
                <span className="word">Gestion</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
