import React, { useEffect, useState } from 'react';
import "../../../../assets/css/home.css";
import add from "../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../assets/icon40/excel.svg";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvider';
import axiosPrivate from '../../../services/api/axios';
import { decrypt } from '../../../module/myCrypto';

const ListConstatation = () => {
  const navigate = useNavigate();
  const [driverData, setDriverData] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);

  const {
    authState: { user },
  } = useAuth();

  useEffect(() => {
    const getAllSinistres = () => {
      axiosPrivate
        .get(`drivers/getAllDrivers/${companyId}`)
        .then((res) => {
          setDriverData(res.data);
          //
        })
        .catch((err) => console.log(err));
    }
    
    
    getAllSinistres();
  }, []);

  useEffect(() => {
    if (!showDeleteModal && deleteModal != null) {
      window.location.reload();
    }
  }, [showDeleteModal, deleteModal]);

  const companyId = decrypt(user.companyID);

  const handleClickOpen = () => {
    navigate(`/administratif/constatation/ajouter/${companyId}`);
  };

  function handleOnClickModal(numPermis, companieID, driverContrat) {
    setShowDetailModal(true);
    setModal([numPermis, companieID, driverContrat]);
  }

  function handleOnClickDeleteModal(numPermis, nom, prenom, companie) {
    setShowDeleteModal(true);
    setDeleteModal([numPermis, nom, prenom, companie]);
  }

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Constatations</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter un conducteur"
              className="ng-star-inserted"
            >
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a href="" title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded="false"
              className="collapse-filtre collapsed"
              data-toggle="collapse"
              href="#collapseExample"
            >
              {" "}
              Liste des constatations{" "}
            </a>
          </p>
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-black">{"driverData.length"}</span>
            <span className="label">Nombre d'enregistrements</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Nom & Prenoms
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Contact
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Catégorie du permis
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    N° permis
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date d'expiration
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">Genre</th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Versements
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Contrat
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {/*driverData.map((drivers) => (
                  <tr
                    className="ui-selectable-row ng-star-inserted"
                    tabIndex="0"
                  >
                    <td
                      style={{ width: "40px", padding: ".3rem .3rem" }}
                      className="ng-star-inserted"
                    >
                      <div
                        className={subMenuOpen ? "dropdown show" : "dropdown"}
                      >
                        <button
                          aria-expanded="true"
                          aria-haspopup="true"
                          className="btn btn-default btn-xs dropdown-toggle-no-caret"
                          data-toggle="dropdown"
                          id="dropdownMenu1"
                          type="button"
                        >
                          <span className="glyphicon glyphicon-option-vertical">
                            <i
                              className="fa-solid fa-ellipsis-vertical"
                              onClick={() =>
                                setSubMenuOpen({
                                  ...subMenuOpen,
                                  [drivers.numPermis]:
                                    !subMenuOpen[drivers.numPermis],
                                })
                              }
                              open={!!subMenuOpen[drivers.numPermis]}
                            ></i>
                          </span>
                        </button>
                        {subMenuOpen[drivers.numPermis] ? (
                          <ul
                            aria-labelledby="dropdownMenu1"
                            className={
                              subMenuOpen
                                ? "dropdown-menu dropdown-menu-right show"
                                : "dropdown-menu dropdown-menu-right"
                            }
                            x-placement="top-end"
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "0px",
                              left: "0px",
                              transform: "translate3d(0px, 38px, 0px)",
                            }}
                          >
                            <li className="c-blue ng-star-inserted">
                              <a
                                href={`/personnels/conducteurs/profile/${drivers.numPermis}/${drivers.companieID}`}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={iconView} />
                                Consulter
                              </a>
                            </li>
                            <li
                              className="divider ng-star-inserted"
                              role="separator"
                            ></li>
                            <li className="c-blue ng-star-inserted">
                              <a
                                href={`/personnels/conducteurs/edit/${drivers.numPermis}/${drivers.companieID}`}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={iconEdit} />
                                Modifier
                              </a>
                            </li>
                            <li
                              className="divider ng-star-inserted"
                              role="separator"
                            ></li>
                            <li className="c-red ng-star-inserted">
                              <a
                                data-target="#popupDelete"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickDeleteModal(
                                    drivers.numPermis,
                                    drivers.nom,
                                    drivers.prenom,
                                    drivers.companieID
                                  )
                                }
                              >
                                <img src={iconDelete} />
                                Supprimer
                              </a>
                            </li>
                            <li
                              className="divider ng-star-inserted"
                              role="separator"
                            ></li>
                            <li className="c-blue ng-star-inserted">
                              <a
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                              >
                                <img src={iconPrint} />
                                Imprimer
                              </a>
                            </li>
                          </ul>
                        ) : (
                          <ul></ul>
                        )}
                      </div>
                    </td>
                    <td className="ng-star-inserted">
                      {drivers.nom} {drivers.prenom}
                    </td>
                    <td className="ng-star-inserted">{drivers.vehicleID}</td>
                    <td className="ng-star-inserted">
                      {drivers.contact_chauffeur}
                    </td>
                    <td className="ng-star-inserted">
                      {drivers.categorie_permis}
                    </td>
                    <td className="ng-star-inserted">{drivers.numPermis}</td>
                    <td className="ng-star-inserted">
                      {new Date(drivers.date_expiration_pc).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td className="ng-star-inserted">{drivers.genre}</td>
                    <td className="ng-star-inserted">{} F.CFA</td>
                    <td className="ng-star-inserted">
                      <span className="ng-star-inserted">
                        <a
                          data-target="#popupAttachement"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOnClickModal(drivers.numPermis, drivers.companieID, drivers.driverContrat)}
                        >
                          <img src={attachement} />
                        </a>
                      </span>
                    </td>
                  </tr>
                      ))*/}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="mySmallModalLabel"
          className="modal fade"
          id="popupDelete"
          role="dialog"
          tabIndex="-1"
        ></div>
      </div>
      {/*showDetailModal && setModal != null && (
        <DriverContratModal
          closeContratModal={setShowDetailModal}
          data={modal}
        />
      )*/}
      {/*showDeleteModal && setDeleteModal != null && (
        <DeleteDriverModal
        closeDeleteDriverModal={setShowDeleteModal}
          data={deleteModal}
        />
      )*/}
    </>
  )
}

export default ListConstatation