import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/list.svg";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";

const AjouterConducteur = () => {
  const fileInput = useRef();
  const flag = useRef(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [photoDriver, setPhotoDriver] = useState("");
  const [contrat, setContrat] = useState("");
  const [phoneChauffeur, setPhoneChauffeur] = useState("");
  const [contactProche, setContactProche] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [contactData, setContactData] = useState({
    numPermis: "",
    nom: "",
    prenom: "",
    date_naissance: "",
    lieu_naissance: "",
    genre: "Masculin",
    date_recrutement: "",
    categorie_permis: "",
    date_delivrance_pc: "",
    date_expiration_pc: "",
    type_piece: "",
    numero_piece: "",
    date_piece: "",
    adresse_chauffeur: "",
    contact_chauffeur: "",
    nom_proche: "",
    contact_proche: "",
    driverImage: "",
    driverContrat: "",
    vehicleID: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    if (flag.current === false) {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", photoDriver);
      const res = await axiosPrivate.post("uploadDriverImage", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", contrat);
      const res = await axiosPrivate.post("uploadDriverContrat", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.numPermis === "") {
      err.numPermis = toast.error("😡 Veuillez entrer le numéro du permis!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.nom === "") {
      err.nom = toast.error("😡 Veuillez choisir le nom du conducteur!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.prenom === "") {
      err.prenom = toast.error("😡 Veuillez choisir un prenom du conducteur!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_naissance === "") {
      err.date_naissance = toast.error(
        "😡 Veuillez entrer la date de naissance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.lieu_naissance === "") {
      err.lieu_naissance = toast.error(
        "😡 Veuillez entrer le lieu de naissance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.genre === "") {
      err.genre = toast.error("😡 Veuillez selectionner le genre", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_recrutement === "") {
      err.date_recrutement = toast.error(
        "😡 Veuillez entrer la date de recrutement!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.categorie_permis === "") {
      err.categorie_permis = toast.error(
        "😡 Veuillez selectionner la catégorie du permis",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_delivrance_pc === "") {
      err.date_delivrance_pc = toast.error(
        "😡 Veuillez entrer la date de délivrance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_expiration_pc === "") {
      err.date_expiration_pc = toast.error(
        "😡 Veuillez entrer la date d'expiration du permis!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.type_piece === "") {
      err.type_piece = toast.error(
        "😡 Veuillez selectionner le type de pièce!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.numero_piece === "") {
      err.numero_piece = toast.error("😡 Veuillez entrer le numéro de pièce!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_piece === "") {
      err.date_piece = toast.error(
        "😡 Veuillez entrer la date d'expiration de la pièce!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.adresse_chauffeur === "") {
      err.adresse_chauffeur = toast.error(
        "😡 Veuillez entrer l'adresse géographique du conducteur'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (phoneChauffeur === "") {
      err.phoneChauffeur = toast.error(
        "😡 Veuillez entrer le contact du conducteur'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.nom_proche === "") {
      err.nom_proche = toast.error("😡 Veuillez entrer le nom du proche'!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactProche === "") {
      err.contactProche = toast.error(
        "😡 Veuillez entrer le contact du proche'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contrat === "") {
      err.contrat = toast.error(
        "😡 Veuillez selectionner le contrat du conducteur'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.vehicleID === "") {
      err.vehicleID = toast.error(
        "😡 Veuillez attribuer un véhicule au conducteur'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      let imgUrl = "";
      let contratUrl = "";
      if (photoDriver) imgUrl = await upload();

      if (contrat) contratUrl = await uploadContrat();

      try {
        axiosPrivate
          .post(
            "drivers/create",
            {
              companieID: user.companyID,
              userID: user.userID,
              numPermis: encrypt(contactData.numPermis),
              nom: encrypt(contactData.nom),
              prenom: encrypt(contactData.prenom),
              date_naissance: encrypt(contactData.date_naissance),
              lieu_naissance: encrypt(contactData.lieu_naissance),
              genre: encrypt(contactData.genre),
              date_recrutement: encrypt(contactData.date_recrutement),
              categorie_permis: encrypt(contactData.categorie_permis),
              date_delivrance_pc: encrypt(contactData.date_delivrance_pc),
              date_expiration_pc: encrypt(contactData.date_expiration_pc),
              driverImage: imgUrl,
              driverContrat: contratUrl,
              type_piece: encrypt(contactData.type_piece),
              numero_piece: encrypt(contactData.numero_piece),
              date_piece: encrypt(contactData.date_piece),
              adresse_chauffeur: encrypt(contactData.adresse_chauffeur),
              contact_chauffeur: encrypt(phoneChauffeur),
              nom_proche: encrypt(contactData.nom_proche),
              contact_proche: encrypt(contactProche),
              vehicule: encrypt(contactData.vehicleID),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/personnels/conducteurs/${companyId}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    }
  };

  return (
    <div className="ng-star-inserted">
      <div id="header-main">
        <div className="title">
          <h2>Personnels</h2>
        </div>
        <div className="links">
          <a
            href={`/personnels/conducteurs/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="ng-untouched ng-pristine ng-valid"
      >
        <div className="form-l">
          <div className="form-content ng-star-inserted">
            <div className="form-block">
              <label className="title-content">Information du conducteur</label>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Nom</label>
                <input
                  type="text"
                  placeholder="Nom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="nom"
                  value={contactData.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Prenoms</label>
                <input
                  type="text"
                  placeholder="Prenom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="prenom"
                  value={contactData.prenom}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Date de naissance</label>
                <input
                  type="date"
                  placeholder="Date de naissance"
                  className="ng-pristine ng-invalid ng-touched"
                  name="date_naissance"
                  value={contactData.date_naissance}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Lieu de naissance</label>
                <input
                  type="text"
                  placeholder="Lieu de naissance"
                  className="ng-pristine ng-invalid ng-touched"
                  name="lieu_naissance"
                  value={contactData.lieu_naissance}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Genre</label>
                <div className="radio-container">
                  <input
                    checked={contactData.genre === "Masculin"}
                    type="radio"
                    name="genre"
                    value="Masculin"
                    id="male"
                    onChange={handleChange}
                  />
                  <label for="male">Masculin</label>
                  <input
                    checked={contactData.genre === "Feminin"}
                    type="radio"
                    name="genre"
                    value="Feminin"
                    id="female"
                    onChange={handleChange}
                  />
                  <label for="female">Feminin</label>
                </div>
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Numéro de téléphone</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero du conducteur"
                  value={phoneChauffeur}
                  onChange={(phoneChauffeur) =>
                    setPhoneChauffeur(phoneChauffeur)
                  }
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={10}
                  rules={{ required: true }}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Type de pièce</label>
                <div className="large">
                  <div className="box-select">
                    <select
                      name="type_piece"
                      value={contactData.type_piece}
                      onChange={handleChange}
                    >
                      <option>le type de pièce</option>
                      <option value="CNI">Carte Nationale d'Identité</option>
                      <option value="Carte Consulaire">Carte Consulaire</option>
                      <option value="Carte de Séjour">Carte de Séjour</option>
                      <option value="Passport">Passport</option>
                      <option value="Permis de Conduire">
                        Permis de Conduire
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Numéro de la pièce</label>
                <input
                  type="text"
                  placeholder="Numéro de la pièce"
                  className="ng-pristine ng-invalid ng-touched"
                  name="numero_piece"
                  value={contactData.numero_piece}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Date de validité de pièce</label>
                <input
                  type="date"
                  placeholder="Date de validité"
                  className="ng-pristine ng-invalid ng-touched"
                  name="date_piece"
                  value={contactData.date_piece}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Adresse géographique</label>
                <input
                  type="text"
                  placeholder="Addresse géographique conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="adresse_chauffeur"
                  value={contactData.adresse_chauffeur}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Photo du conducteur</label>
                <input
                  type="file"
                  name="Photo"
                  id="Photo"
                  onChange={(event) => setPhotoDriver(event.target.files[0])}
                  accept="image/*"
                  multiple={false}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">le contrat du conducteur</label>
                <input
                  type="file"
                  name="Photo"
                  id="Photo"
                  onChange={(event) => setContrat(event.target.files[0])}
                  accept="application/pdf"
                  multiple={false}
                />
              </div>
            </div>
          </div>
          <div className="form-content">
            <div className="form-block ng-star-inserted">
              <label className="title-content">
                Informations Professionnelles
              </label>
              <div className="form-group">
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Catégorie du permis</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="categorie_permis"
                        value={contactData.categorie_permis}
                        onChange={handleChange}
                      >
                        <option>Catégorie du permis</option>
                        <option value="B"> CATEGORIE B</option>
                        <option value="CE">CATEGORIE CE</option>
                        <option value="DE"> CATEGORIE DE</option>
                        <option value="BCDE">CATEGORIE BCDE</option>
                        <option value="ABCDE"> CATEGORIE ABCDE</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Numéro du permis</label>
                  <input
                    type="text"
                    placeholder="Numéro du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="numPermis"
                    value={contactData.numPermis}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date de délivrance du permis</label>
                  <input
                    type="date"
                    placeholder="Numéro du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="date_delivrance_pc"
                    value={contactData.date_delivrance_pc}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date d'expiration du permis</label>
                  <input
                    type="date"
                    placeholder="Date d'expiration du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="date_expiration_pc"
                    value={contactData.date_expiration_pc}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Attribuer un véhicule</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="vehicleID"
                        value={contactData.vehicleID}
                        onChange={handleChange}
                      >
                        <option>Attribuer un véhicule</option>
                        {optionList.map((item) => (
                          <option
                            key={item.immatriculation}
                            value={item.immatriculation}
                          >
                            {item.immatriculation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date de recrutement</label>
                  <input
                    type="date"
                    placeholder="Date d'expiration du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="date_recrutement"
                    value={contactData.date_recrutement}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label className="title-content">
                Personne à joindre en cas d'urgence
              </label>
              <div className="form-group">
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Nom du proche</label>
                  <input
                    type="text"
                    placeholder="Nom du proche"
                    className="ng-pristine ng-invalid ng-touched"
                    name="nom_proche"
                    value={contactData.nom_proche}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Contact du proche</label>
                  <PhoneInput
                    international={true}
                    countryCallingCodeEditable={false}
                    placeholder="Entrer le numero du proche"
                    value={contactProche}
                    onChange={(contactProche) =>
                      setContactProche(contactProche)
                    }
                    defaultCountry="CI"
                    flags={flags}
                    labels={fr}
                    limitMaxLength={10}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AjouterConducteur;
