import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconView from "../../../../../assets/icons/dropdown-view.svg";
import iconValidate from "../../../../../assets/icons/dropdown-cloturer.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import ChargeModal from "../../components/ChargeModal";
import DeleteChargeModal from "../../components/DeleteChargeModal";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import jsPDF from "jspdf";
import moment from "moment";
import "jspdf-autotable";

const DemandeIntervention = () => {
  const [demandeData, setDemandeData] = useState([]);
  const [heure, setHeure] = useState(new Date());
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [allDemandeInterventionData, setAllDemandeInterventionData] = useState(
    []
  );
  const [sumAllDemandeIntervention, setSumAllDemandeIntervention] = useState(
    []
  );
  const [numDemande, setNumDemande] = useState("");
  const [numVehicle, setNumVehicle] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const listStatut = "En Attente";

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchAssuranceData = async () => {
      await axiosPrivate
        .get(`maintenance/demande-intervention/${companyId}`)
        .then((res) => {
          setAllDemandeInterventionData(res.data);
        })
        .catch((err) => console.log(err));
    };

    fetchAssuranceData();
    const intervalId = setInterval(() => {
      fetchAssuranceData();
    }, 5000); // Mettez à jour toutes les 5 secondes

    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((numVehicle || numDemande || listStatut || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get(
          "maintenance/search-demande-intervention",
          {
            params: { numVehicle, numDemande, listStatut, companyId },
          }
        );
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numVehicle, numDemande, listStatut, companyId]);

  const handleClickOpen = () => {
    navigate(`/maintenances/demande-intervention/create/${companyId}`);
  };

  const handleValidateDemande = async (
    numDemandeIntervention,
    statutVehicule,
    vehicleID
  ) => {
    await axiosPrivate
      .put(
        `maintenance/valider-demande-intervention/${numDemandeIntervention}/${statutVehicule}/${vehicleID}`
      )
      .then((res) => {
        if (res.data.code === 100) {
          toast.success(`👏 ${res.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
          navigate(`/maintenances/intervention-en-cours/${companyId}`);
        } else {
          toast.error(`😡 Erreur lors de la validation `, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
            style: {
              bodyClassName: "grow-font-size",
              progressClassName: "fancy-progress-bar",
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleImprimerDemande = async (numDemandeIntervention) => {
    await axiosPrivate
      .get(
        `maintenance/imprimer-demande-intervention/${numDemandeIntervention}`
      )
      .then((res) => {
        setDemandeData(res.data);

        const doc = new jsPDF("p", "pt", "a4");

        const image = `https://apiserver.raymobileci.com/${res.data.companieID}/images/logos/${res.data.company.logo}`;

        doc.addImage(image, "JPEG", 40, 15, 60, 45);

        const companieName = decrypt(user.companyName);

        doc.setFont(undefined, "bold");
        doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
        doc.text(companieName, 350, 45);

        // Ajoutez un titre
        doc.setFont("helvetica"); // Définissez la police
        doc.setFontSize(18); // Définissez la taille de la police
        doc.setTextColor(0, 0, 0); // Définissez la couleur du texte en bleu

        const title = `Demande d'intervention N° ${res.data.numDemandeIntervention}`;
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        doc.text(title, pageWidth / 2, 100, { align: "center" }); // Centrez le titre

        // Dimensions du rectangle (sans les marges)
        // Calculez les dimensions de la page
        const pageWidths = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Calculez les marges (10 % à gauche et à droite)
        const leftMargin = pageWidths * 0.1;
        const rightMargin = pageWidths * 0.9;

        // Calculez la hauteur du rectangle (1/4 de la hauteur de la page)
        const rectHeight = pageHeight / 4;

        // Dessinez le rectangle
        doc.rect(leftMargin, 120, rightMargin - leftMargin, rectHeight);

        // Dessinez la ligne verticale au milieu de la page
        const middleX = (leftMargin + rightMargin) / 2;
        doc.line(middleX, 120, middleX, pageHeight / 2 - 90);

        // Dessinez la ligne horizontale au milieu de la page
        const middleY = 45 + rectHeight / 2;
        doc.line(leftMargin, middleY, rightMargin, middleY);

        // Ajoutez les titres centrés
        const title1 = "Véhicule";
        const title2 = "Conducteur";

        const titleX1 = leftMargin + (rightMargin - leftMargin) / 4; // Position horizontale du titre 1
        const titleX2 = leftMargin + (3 * (rightMargin - leftMargin)) / 4; // Position horizontale du titre 2
        const titleY = 140; // Position verticale des titres

        doc.setFontSize(14); // Taille de police pour les titres
        doc.setFont(undefined, "bold");
        doc.text(titleX1, titleY, title1, null, null, "center"); // Titre 1 centré
        doc.text(titleX2, titleY, title2, null, null, "center"); // Titre 2 centré

        // Ajoutez du texte au rectangle
        const immat = `Immatriculation: ${res.data.vehicleID}`; // Remplacez par le texte souhaité
        const marque = `Marque: ${res.data.vehicule.marque}`; // Remplacez par le texte souhaité
        const modele = `Modèle: ${res.data.vehicule.modele}`; // Remplacez par le texte souhaité
        const numChassis = `N° chassis: ${res.data.vehicule.numero_chassis}`; // Remplacez par le texte souhaité
        const kilo = `Kilométrage: ${res.data.kilometrage_demande}`; // Remplacez par le texte souhaité
        const type = `Type de transport: ${res.data.vehicule.type_transport}`; // Remplacez par le texte souhaité

        const nom = `Nom: ${res.data.conducteur.nom}`; // Remplacez par le texte souhaité
        const prenom = `Prenoms: ${res.data.conducteur.prenom}`; // Remplacez par le texte souhaité
        const contact = `N° téléphone : ${res.data.conducteur.contact_chauffeur}`; // Remplacez par le texte souhaité
        const categorie = `Catégorie du permis: ${res.data.conducteur.categorie_permis}`; // Remplacez par le texte souhaité
        const numPermis = `N° du permis: ${res.data.numPermis}`; // Remplacez par le texte souhaité
        const textX = leftMargin + 10; // Position horizontale du texte
        const textY = 170; // Position verticale du texte

        const text2X = leftMargin + 250; // Position horizontale du texte

        const suiteImmat = textY + 25;
        const suiteMark = suiteImmat + 25;
        const suiteModel = suiteMark + 25;
        const suiteChassis = suiteModel + 25;
        const suiteKilo = suiteChassis + 25;

        doc.setFontSize(13); // Taille de police
        doc.setFont(undefined, "normal");
        doc.text(textX, textY, immat);
        doc.text(textX, suiteImmat, marque);
        doc.text(textX, suiteMark, modele);
        doc.text(textX, suiteModel, numChassis);
        doc.text(textX, suiteChassis, kilo);
        doc.text(textX, suiteKilo, type);

        doc.text(text2X, textY, nom);
        doc.text(text2X, suiteImmat, prenom);
        doc.text(text2X, suiteMark, contact);
        doc.text(text2X, suiteModel, categorie);
        doc.text(text2X, suiteChassis, numPermis);

        /* ----------------- 2e tableau ---------------*/
        // Calculez les dimensions de la page
        const secondPageWidth = doc.internal.pageSize.getWidth();
        const secondPageHeight = doc.internal.pageSize.getHeight();

        // Calculez les marges (10 % à gauche et à droite)
        const secondLeftMargin = secondPageWidth * 0.1;
        const secondRightMargin = secondPageWidth * 0.9;

        // Calculez la hauteur du rectangle (1/4 de la hauteur de la page)
        const secondRectHeight = secondPageHeight / 4;

        // Dessinez le rectangle
        // Ajustez le trait horizontal pour diviser le rectangle sans déborder
        doc.rect(
          secondLeftMargin,
          350,
          secondRightMargin - secondLeftMargin,
          secondRectHeight
        );

        // Dessinez la ligne verticale au milieu de la page
        const secondMiddleX = (secondLeftMargin + secondRightMargin) / 2;
        doc.line(secondMiddleX, 515, secondMiddleX, secondPageHeight / 2 - 25);

        // Dessinez la ligne horizontale au milieu de la page
        const secondMiddleY = 290 + secondRectHeight / 2;
        doc.line(
          secondLeftMargin,
          secondMiddleY,
          secondRightMargin,
          secondMiddleY
        );

        const secondTextY = 370; // Position verticale du texte
        const suiteDescrip = secondTextY + 45;
        const suiteDemande = suiteDescrip + 30;
        const suiteKilometrage = suiteDemande + 30;
        const suiteMainte = suiteKilometrage + 30;
        const suiteGravite = suiteMainte + 30;
        const suiteCategorie = suiteGravite + 30;
        doc.setFontSize(12); // Taille de police
        doc.text(
          textX,
          secondTextY,
          `Description:     ${res.data.description}`
        );
        doc.text(
          textX,
          suiteDescrip,
          `Date demande:     ${new Date(
            res.data.date_demande
          ).toLocaleDateString("en-GB")}`
        );
        doc.text(
          textX,
          suiteDemande,
          `Kilométrage:     ${res.data.kilometrage_demande}`
        );
        doc.text(
          textX,
          suiteKilometrage,
          `Type Maintenance:     ${res.data.type_intervention}`
        );
        doc.text(
          textX,
          suiteMainte,
          `Degré de gravité:     ${res.data.degre_gravite}`
        );
        doc.text(
          textX,
          suiteGravite,
          `Commentaire:     ${res.data.commentaire}`
        );

        doc.text(310, suiteDescrip, `${res.data.numDemandeIntervention}`);
        doc.text(
          310,
          suiteDemande,
          `Urgence:     ${res.data.degre_intervention}`
        );
        doc.text(310, suiteKilometrage, `Catégorie:     ${res.data.categorie}`);
        doc.text(
          310,
          suiteMainte,
          `Statut véhicule:     ${res.data.statut_vehicule}`
        );

        /* ----------------- colonne 2e tableau ---------------*/
        const colonne1 = secondMiddleY + 30;
        const colonne2 = colonne1 + 30;
        const colonne3 = colonne2 + 30;
        const colonne4 = colonne3 + 30;
        doc.line(secondLeftMargin, colonne1, secondRightMargin, colonne1);
        doc.line(secondLeftMargin, colonne2, secondRightMargin, colonne2);
        doc.line(secondLeftMargin, colonne3, secondRightMargin, colonne3);
        doc.line(secondLeftMargin, colonne4, secondRightMargin, colonne4);

        /* ----------------- 3e tableau ---------------*/
        // Calculez les dimensions de la page
        const thirdPageWidth = doc.internal.pageSize.getWidth();

        // Calculez les marges (10 % à gauche et à droite)
        const thirdLeftMargin = thirdPageWidth * 0.1;

        // Dessinez le premier rectangle
        doc.rect(thirdLeftMargin, 600, 220, 100);
        doc.line(thirdLeftMargin, 625, 280, 625); // Tracer un trait horizontal de 1 unité de hauteur

        // Dessinez le deuxième rectangle avec un espace entre eux
        doc.rect(315, 600, 220, 100);
        doc.line(315, 625, 535, 625); // Tracer un trait horizontal de 1 unité de hauteur

        const nom_user = decrypt(user.nom);
        const nom_prenom = decrypt(user.prenom);
        const conPrenom = nom_prenom.split(" ").pop() + " " + nom_user;

        const titre = "Signature du chauffeur";
        const signatureTitleY = 620; // Position verticale des titres

        doc.setFontSize(14); // Taille de police pour les titres
        doc.setFont(undefined, "bold");
        doc.text(titleX1, signatureTitleY, titre, null, null, "center"); // Titre 1 centré
        doc.text(420, signatureTitleY, conPrenom, null, null, "center"); // Titre 1 centré

        /* ----------------- Footer tableau ---------------*/
        let options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: "UTC",
        };

        const dateJour = new Date().toLocaleDateString("fr-FR", options);

        const dateJours = `édité le ${dateJour}`;

        doc.setFontSize(12); // Taille de police
        doc.text(350, 825, dateJours);

        // Enregistrez le PDF avec un nom de fichier (utilisez le chemin spécifié dans l'argument "path")
        doc.save(`demande-intervention-${res.data.numDemandeIntervention}.pdf`);
      })
      .catch((err) => console.log(err));
  };

  function handleOnClickModal(
    numDemandeIntervention,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([
      numDemandeIntervention,
      attachement,
      vehicleID,
      cartegrise,
      companie,
    ]);
  }

  function handleOnClickChargeModal(
    numDemandeIntervention,
    vehicleID,
    companie,
    content
  ) {
    setShowChargeModal(true);
    setChargeModal([numDemandeIntervention, vehicleID, companie, content]);
  }

  const nombresumAllDemandeIntervention =
    sumAllDemandeIntervention.total_montant?.toLocaleString("fr-FR");

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");

    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;

    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;

    doc.addImage(image, "JPEG", 10, 5, 60, 45);

    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);

    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des demandes d'interventions";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre

    const headers = [
      "",
      "N° Demande",
      "Véhicule",
      "Kilométrage",
      "Statut",
      "Type Intervention",
      "Degré d'Intervention",
      "Degré de gravité",
      "Date demande",
    ];

    const tableData = isSearching
      ? searchResults.map((result) => {
          return [
            "",
            result["numDemandeIntervention"],
            result["vehicleID"],
            result["kilometrage_demande"],
            result["statut_vehicule"],
            result["type_intervention"],
            result["degre_intervention"],
            result["degre_gravite"],
            moment(result["date_demande"]).format("DD/MM/YYYY"),
          ];
        })
      : allDemandeInterventionData.map((demande) => {
        return [
          "",
          demande["numDemandeIntervention"],
          demande["vehicleID"],
          demande["kilometrage_demande"],
          demande["statut_vehicule"],
          demande["type_intervention"],
          demande["degre_intervention"],
          demande["degre_gravite"],
          moment(demande["date_demande"]).format("DD/MM/YYYY"),
        ];
        });

    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });

    doc.save("liste-demande-maintenance.pdf");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Demandes d'intervention</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter demande d'intervention"
              className="ng-star-inserted"
            >
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img
                src={pdf}
                alt=""
                onClick={handlePDFClick}
                style={{ cursor: "pointer" }}
              />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="numVehicle"
                  value={numVehicle}
                  onChange={(e) => setNumVehicle(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par N° demande</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par Visite technique"
                  name="numDemande"
                  value={numDemande}
                  onChange={(e) => setNumDemande(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-blue">
              {allDemandeInterventionData.length}
            </span>
            <span className="label">Nombre d'enregistrement</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    N° Demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Kilométrage
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Statut
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Type Intervention
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Degré d'Intervention
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Degré de gravité
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Statut demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date demande
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Générer le bon
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? allDemandeInterventionData.map((demande) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [demande.numDemandeIntervention]:
                                          !subMenuOpen[
                                            demande.numDemandeIntervention
                                          ],
                                      })
                                    }
                                    open={
                                      !!subMenuOpen[
                                        demande.numDemandeIntervention
                                      ]
                                    }
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[demande.numDemandeIntervention] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/consulter-intervention/${demande.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconView} />
                                      Consulter
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/editer-intervention/${demande.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      onClick={() =>
                                        handleValidateDemande(
                                          demande.numDemandeIntervention,
                                          demande.statut_vehicule,
                                          demande.vehicleID
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconValidate} />
                                      Valider
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          demande.numDemandeIntervention,
                                          demande.vehicleID,
                                          companyId,
                                          `La demande d'intervention`
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {demande.numDemandeIntervention}
                          </td>
                          <td
                            className="c-blue ng-star-inserted"
                            style={{ fontWeight: "bold" }}
                          >
                            {demande.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.kilometrage_demande}
                          </td>
                          {demande.statut_vehicule === "Immobilise" ? (
                            <td className="ng-star-inserted">
                              <span
                                style={{
                                  width: "70px",
                                  minWidth: "100px",
                                  color: "#fff",
                                  padding: "2px 6px",
                                  backgroundColor: "#da3738",
                                  fontSize: "11px",
                                  fontWeight: "bold",
                                  borderRadius: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {demande.statut_vehicule}
                              </span>
                            </td>
                          ) : (
                            <td className="ng-star-inserted">
                              {demande.statut_vehicule}
                            </td>
                          )}
                          <td className="ng-star-inserted">
                            {demande.type_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.degre_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {demande.degre_gravite}
                          </td>
                          <td className="ng-star-inserted">
                            <span
                              style={{
                                width: "70px",
                                minWidth: "100px",
                                color: "#fff",
                                padding: "2px 6px",
                                backgroundColor: "#0029c2",
                                fontSize: "11px",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {demande.statut_demande}
                            </span>
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(demande.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleImprimerDemande(
                                    demande.numDemandeIntervention
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.numDemandeIntervention]:
                                          !subMenuOpen[
                                            result.numDemandeIntervention
                                          ],
                                      })
                                    }
                                    open={
                                      !!subMenuOpen[
                                        result.numDemandeIntervention
                                      ]
                                    }
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.numDemandeIntervention] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/consulter-intervention/${result.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconView} />
                                      Consulter
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/maintenances/editer-intervention/${result.numDemandeIntervention}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      onClick={() =>
                                        handleValidateDemande(
                                          result.numDemandeIntervention,
                                          result.statut_vehicule,
                                          result.vehicleID
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconValidate} />
                                      Valider
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          result.numDemandeIntervention,
                                          result.vehicleID,
                                          companyId,
                                          `La demande d'intervention`
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.numDemandeIntervention}
                          </td>
                          <td
                            className="c-blue ng-star-inserted"
                            style={{ fontWeight: "bold" }}
                          >
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {result.kilometrage_demande}
                          </td>
                          {result.statut_vehicule === "Immobilise" ? (
                            <td className="ng-star-inserted">
                              <span
                                style={{
                                  width: "70px",
                                  minWidth: "100px",
                                  color: "#fff",
                                  padding: "2px 6px",
                                  backgroundColor: "#da3738",
                                  fontSize: "11px",
                                  fontWeight: "bold",
                                  borderRadius: "5px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {result.statut_vehicule}
                              </span>
                            </td>
                          ) : (
                            <td className="ng-star-inserted">
                              {result.statut_vehicule}
                            </td>
                          )}
                          <td className="ng-star-inserted">
                            {result.type_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {result.degre_intervention}
                          </td>
                          <td className="ng-star-inserted">
                            {result.degre_gravite}
                          </td>
                          <td className="ng-star-inserted">
                            <span
                              style={{
                                width: "70px",
                                minWidth: "100px",
                                color: "#fff",
                                padding: "2px 6px",
                                backgroundColor: "#0029c2",
                                fontSize: "11px",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {result.statut_demande}
                            </span>
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_demande).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleImprimerDemande(
                                    result.numDemandeIntervention
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabindex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabindex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabindex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabindex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabindex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
      {showDetailModal && setModal != null && (
        <ChargeModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showChargeModal && setChargeModal != null && (
        <DeleteChargeModal
          closeDeleteChargeModal={setShowChargeModal}
          data={chargeModal}
        />
      )}
    </>
  );
};

export default DemandeIntervention;
