import React, { useContext, useEffect, useState } from "react";
import { toast, Flip, Bounce } from "react-toastify";
import useMobile from "../../app/hooks/useMobile";
import FormHeader from "../FormComponent/FormHeader";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import axiosPrivate from "../../services/api/axios";
import "../../../assets/css/registration/register.css";
import "react-toastify/dist/ReactToastify.css";
import { encrypt } from "../../module/myCrypto";
import Plan from "../../../assets/images/ray_taxi.webp";
import Logo from "../../../assets/images/raymobileci.avif";

const CompanyInformation = () => {
  const { step, setStep, setCompanie } = useContext(AuthContext);
  const mobile = useMobile("(max-width:940px)");
  const [formError, setFormError] = useState({});
  const [contactCompany, setContactCompany] = useState();
  const [logoCompanie, setLogoCompanie] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nomCompany: "",
    emailCompany: "",
    adresseCompany: "",
    typeCompte: "",
    registre_commerce: "",
    compte_contribuable: "",
    forme_juridique: "",
    numero_idu: "",
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (formData.typeCompte === "Entreprise") {
      setShowModal(true);
    }
  }, [formData.typeCompte]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Affiche la taille de l'écran dans la console
    //console.log(windowSize);

    // N'oubliez pas de nettoyer l'écouteur d'événements
    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]); // Exécutez à nouveau l'effet si `windowSize` change

  const handleChange = (event) => {
    // console.log(event);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const validateForm = () => {
    let err = {};
    if (formData.nomCompany === "") {
      err.nomCompany = toast.error("😡 Veuillez entrer un nom de compagnie!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.emailCompany === "") {
      err.emailCompany = toast.error("😡 Veuillez entrer un Email!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(formData.emailCompany)) {
        err.emailCompany = toast.error("😡 Email invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (contactCompany === "") {
      err.contactCompany = toast.error(
        "😡 Veuillez entrer un contact de la compagnie!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    } else {
      let regexPhone = /^\+?[1-9][0-9]{12,14}$/;
      if (!regexPhone.test(contactCompany)) {
        err.contactCompany = toast.error("😡 Numéro de téléphone invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (formData.adresseCompany === "") {
      err.adresseCompany = toast.error(
        "😡 Veuillez entrer la situation géographique!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (formData.typeCompte === "") {
      err.typeCompte = toast.error("😡 Veuillez entrer le type de compte!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.typeCompte === "Entreprise") {
      if (formData.numero_idu === "") {
        err.numero_idu = toast.error("😡 Veuillez entrer le numéro IDU!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (formData.registre_commerce === "") {
        err.registre_commerce = toast.error(
          "😡 Veuillez entrer le registre de commerce!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (formData.compte_contribuable === "") {
        err.compte_contribuable = toast.error(
          "😡 Veuillez entrer le numéro de compte contribuable!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (formData.forme_juridique === "") {
        err.forme_juridique = toast.error(
          "😡 Veuillez entrer la forme juridique!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", logoCompanie);
      const res = await axiosPrivate.post("upload-logo-company", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    let imgUrl = "";
    let numeroIdu = "";
    let registreCommerce = "";
    let compteContribuable = "";
    let formeJuridique = "";

    if (formData.typeCompte === "Entreprise") {
      numeroIdu = encrypt(formData.numero_idu);
      registreCommerce = encrypt(formData.registre_commerce);
      compteContribuable = encrypt(formData.compte_contribuable);
      formeJuridique = encrypt(formData.forme_juridique);
    } else {
      numeroIdu = "";
      registreCommerce = "";
      compteContribuable = "";
      formeJuridique = "";
    }

    if (logoCompanie) imgUrl = await upload();

    

    if (isValid) {
      axiosPrivate
        .post("companies/create", {
          nom: encrypt(formData.nomCompany),
          email: encrypt(formData.emailCompany),
          contact: encrypt(contactCompany),
          adresse: formData.adresseCompany,
          typeCompte: encrypt(formData.typeCompte),
          numero_idu: numeroIdu,
          registre_commerce: registreCommerce,
          compte_contribuable: compteContribuable,
          forme_juridique: formeJuridique,
          logo: imgUrl,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 103)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setStep(step + 1);
            setCompanie(response.data.company);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
    }
  };

  return (
    <div className="registerContainer">
      <div className="form-image">
        <img src={Plan} alt="" />
      </div>
      <div className="container">
        <div className="form-header">
          <div className="title">
            <h1>Raymobile-CI</h1>
            <FormHeader
              //title="Information de la compagnie"
              info="Veuillez renseigner les informations de l'entreprise"
            />
          </div>
          <div className="login-button">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="form-container">
          <form encType="multipart/form-data" onSubmit={submitHandler}>
            <div className="user-details">
              <div className="input-box">
                <span className="details">Nom de l'entreprise</span>
                <input
                  type="text"
                  id="nomCompany"
                  name="nomCompany"
                  placeholder="Nom de l'entreprise ou nom du propriétaire"
                  value={formData.nomCompany}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Email de l'entreprise</span>
                <input
                  type="email"
                  id="emailCompany"
                  name="emailCompany"
                  placeholder="Email de l'entreprise"
                  value={formData.emailCompany}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Contact de l'entreprise</span>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero de la compagnie"
                  value={contactCompany}
                  onChange={(contactCompany) =>
                    setContactCompany(contactCompany)
                  }
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={true}
                />
              </div>
              <div className="input-box">
                <span className="details">Situation géographique</span>
                <input
                  type="text"
                  id="adresseCompany"
                  name="adresseCompany"
                  placeholder="Situation géographique de l'entreprise"
                  value={formData.adresseCompany}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Logo de l'entreprise</span>
                <input
                  type="file"
                  id="fileCompany"
                  name="file"
                  accept="image/*"
                  onChange={(event) => setLogoCompanie(event.target.files[0])}
                />
              </div>
              <div className="input-box">
                <span className="details">Type compte</span>
                <select
                  value={formData.typeCompte}
                  onChange={handleChange}
                  name="typeCompte"
                  placeholder="Le type de compte"
                >
                  <option>Le type de Compte</option>
                  <option value="Entreprise">Compte Entreprise</option>
                  <option value="Particulier">Compte Particulier</option>
                </select>
              </div>
            </div>
            {/*formData.typeCompte === "Entreprise" &&
              window.innerWidth > 650 && (
                <>
                  <div id="input-box" className="input-box">
                    <span className="details">Le IDU de l'entreprise</span>
                    <input
                      type="text"
                      id="adresseCompany"
                      name="numero_idu"
                      placeholder="Veuillez entrer l'IDU de l'entreprise"
                      value={formData.numero_idu}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">le RCCM de l'entreprise</span>
                    <input
                      type="text"
                      id="adresseCompany"
                      name="registre_commerce"
                      placeholder="Veuillez entrer le registre de commerce"
                      value={formData.registre_commerce}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">Le compte contribuable</span>
                    <input
                      type="text"
                      id="adresseCompany"
                      name="compte_contribuable"
                      placeholder="Veuillez entrer le compte contribuable"
                      value={formData.compte_contribuable}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <span className="details">La forme juridique</span>
                    <select
                      value={formData.forme_juridique}
                      onChange={handleChange}
                      name="forme_juridique"
                      placeholder="La forme juridique"
                    >
                      <option>La forme juridique</option>
                      <option value="Entreprise Individuelle">
                        Entreprise Individuelle
                      </option>
                      <option value="SA">SA</option>
                      <option value="SARL">SARL</option>
                      <option value="SARLU">SARLU</option>
                      <option value="SAS">SAS</option>
                      <option value="SASU">SASU</option>
                    </select>
                  </div>
                </>
              )*/}
            {formData.typeCompte === "Particulier" ? (
              <div className="flexParent">
                <div className="buttonWrapper">
                  <button className="button" type="submit">
                    Suivant
                  </button>
                </div>
              </div>
            ) : null}
            <p className="sign-in">
              Vous avez un compte?
              <Link to="/"> Connectez-vous</Link>
            </p>
          </form>
        </div>
      </div>
      {showModal &&
        (
        (
          <>
            <div className="popup-overlay"></div>
            <div className="popup">
              <div className="popup-close" onClick={() => setShowModal(false)}>&times;</div>
              <div className="popup-form">
                <div className="avatar">
                  <img src={Logo} alt="" />
                </div>
                <div className="header">Information entreprise</div>
                <form encType="multipart/form-data" onSubmit={submitHandler}>
                  <div className="form-element">
                    <div className="element">
                      <input
                        type="text"
                        id="adresseCompany"
                        name="registre_commerce"
                        placeholder="Veuillez entrer le registre de commerce"
                        value={formData.registre_commerce}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="element">
                      <input
                        type="text"
                        id="adresseCompany"
                        name="compte_contribuable"
                        placeholder="Veuillez entrer le compte contribuable"
                        value={formData.compte_contribuable}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="element">
                      <input
                        type="text"
                        id="adresseCompany"
                        name="numero_idu"
                        placeholder="Veuillez entrer l'IDU de l'entreprise"
                        value={formData.numero_idu}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="element">
                      <select
                        value={formData.forme_juridique}
                        onChange={handleChange}
                        name="forme_juridique"
                        placeholder="La forme juridique"
                      >
                        <option>La forme juridique</option>
                        <option value="Entreprise Individuelle">
                          Entreprise Individuelle
                        </option>
                        <option value="SA">SA</option>
                        <option value="SARL">SARL</option>
                        <option value="SARLU">SARLU</option>
                        <option value="SAS">SAS</option>
                        <option value="SASU">SASU</option>
                      </select>
                    </div>
                    <div className="flex-parent">
                      <div className="button-wrapper">
                        <button className="element-button" type="submit">
                          Suivant
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default CompanyInformation;
