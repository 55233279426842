import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as moment from "moment";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from "./calendar/toolbar";
import axiosPrivate from "../../../services/api/axios";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../module/myCrypto";
import "../../../../assets/css/home.css";
import { useNavigate } from "react-router-dom";
import supprimer from "../../../../assets/icon40/supprimer.svg";
import modifier from "../../../../assets/icon40/modifier.svg";
import encaissement from "../../../../assets/icon40/encaissement.svg";

moment.locale("fr");
const localizer = momentLocalizer(moment);

const PlanningVehicule = (props) => {
  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsEditOpen, setModalIsEditOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [getEvents, setGetEvents] = useState([]);
  const [getEventID, setGetEventID] = useState([]);
  const [eventDate, setEventDate] = useState(null);
  const [eventStart, setEventStart] = useState("");
  const [eventEnd, setEventEnd] = useState("");
  const [contactData, setContactData] = useState({
    numPermis: "",
    vehicleID: "",
    start: "",
    end: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);
  const aujourdhui = moment();
  const [companiesId, setCompanyId] = useState(companyId); // Remplacez "votreCompanyId" par votre valeur initiale
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchChauffeurs = () => {
      axiosPrivate
        .get(`drivers/driver-vehicle/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    // Mettez à jour l'état des événements ici
    const updateEvents = (newEvent) => {
      setEvents([...events, newEvent]);
    };

    fetchChauffeurs();
    updateEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const res = await axiosPrivate.get(`calendar/get-events/${companiesId}`); // Remplacez par votre endpoint
      const events = res.data;

      let eventsByDate = events.reduce((acc, event) => {
        const date = new Date(event.start).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(event);
        return acc;
      }, {});

      let eventsWithCount = [];
      for (let date in eventsByDate) {
        const start = new Date(eventsByDate[date][0].start);
        const end = new Date(eventsByDate[date][0].end);

        let title;
        if (new Date() > end) {
          title = `${eventsByDate[date].length} Versement(s) en attente`;
        } else {
          title = `${eventsByDate[date].length} Conducteur(s)`;
        }

        eventsWithCount.push({
          start: new Date(
            Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
          ),
          end: new Date(
            Date.UTC(
              end.getFullYear(),
              end.getMonth(),
              end.getDate(),
              23,
              59,
              59
            )
          ),
          title: title,
        });
      }

      setEvents(eventsWithCount);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchEventsSafe = async () => {
      try {
        await fetchEvents();
      } catch (error) {
        console.error(error);
      }
    };
  
    const intervalId = setInterval(fetchEventsSafe, 1000); // Mettez à jour toutes les 5 secondes
  
    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, [fetchEvents, companyId]); // Ajoutez toutes les dépendances ici

  const eventStyleGetter = (event, end) => {
    let backgroundColor = {}; // couleur par défaut
    //const now = moment();
    // Obtenez la date actuelle
    const now = moment().format("YYYY-MM-DD");

    // Formatez la date au format YYYY-MM-DD
    //const formattedNow = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
    const endDate = moment(end).format("YYYY-MM-DD");

    if (moment(now).isBefore(endDate)) {
      backgroundColor = {
        backgroundColor: "#2405f2",
        boxShadow: "rgba(92, 142, 250, 1) 0px 7px 0px 0px",
      };
    } else if (moment(now).isSame(endDate)) {
      backgroundColor = {
        backgroundColor: "#f205b3",
        boxShadow: "rgba(198, 126, 237, 1) 0px 7px 0px 0px",
      };
    } else if (moment(now).isAfter(endDate)) {
      backgroundColor = {
        backgroundColor: "#f20d05",
        boxShadow: "rgba(250, 105, 112, 1) 0px 7px 0px 0px",
      };
    }

    /*const style = {
      backgroundColor,
      fontSize: "13px",
      padding: "1em 3.3em",
      cursor: "pointer",
      transform: "perspective(200px) rotateX(15deg)",
      color: "white",
      fontWeight: 900,
      border: "none",
      borderRadius: "5px",
      opacity: 1,
      display: "block",
      boxShadow: "rgba(63, 94, 251, 0.2) 0px 40px 29px 0px",
      willChange: "transform",
      transition: "all 0.3s",
    };*/

    const style = {
      ...backgroundColor,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50px",
      position: "positive",
      padding: "0 20px",
      fontSize: "13px",
      fontWeight: "bold",
      textTransform: "uppercase",
      border: 0,
      borderRadius: "12px",
      overflow: "hidden",
      transition: "31ms cubic-bezier(.5, .7, .4, 1)",
      ":before": {
        content: "attr(alt)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        inset: 0,
        fontSize: "15px",
        fontWeight: "bold",
        color: "white",
        letterSpacing: "4px",
        opacity: 1,
      },
      ":active": {
        boxShadow: "none",
        transition: "translateY(7px)",
        transition: "35ms cubic-bezier(.5, .7, .4, 1)",
      },
      ":hover:before": {
        transition: "all .0s",
        tranform: "translateY(100%)",
        opacity: 0,
      },
      i: {
        color: "#fff",
        fontSize: "15px",
        fontWeight: "bold",
        letterSpacing: "4px",
        fontStyle: "normal",
        transition: "all 2s ease",
        transform: "translateY(-20px)",
        opacity: 0,
      },
      ":hover i": {
        transition: "all .2s ease",
        transform: "translateY(0px)",
        opacity: 1,
      },
      ":hover i:nth-child(1)": {
        transitionDelay: "0.045s",
      },
      ":hover i:nth-child(2)": {
        transitionDelay: "calc(0.045s * 3)",
      },
      ":hover i:nth-child(3)": {
        transitionDelay: "calc(0.045s * 4)",
      },
      ":hover i:nth-child(4)": {
        transitionDelay: "calc(0.045s * 5)",
      },
      ":hover i:nth-child(6)": {
        transitionDelay: "calc(0.045s * 6)",
      },
      ":hover i:nth-child(7)": {
        transitionDelay: "calc(0.045s * 7)",
      },
      ":hover i:nth-child(8)": {
        transitionDelay: "calc(0.045s * 8)",
      },
      ":hover i:nth-child(9)": {
        transitionDelay: "calc(0.045s * 9)",
      },
      ":hover i:nth-child(10)": {
        transitionDelay: "calc(0.045s * 10)",
      },
    };

    return {
      style,
    };
  };

  const handleChange = (event) => {
    const selectedDriverLicenseNumber = event.target.value;

    // Supposons que vous ayez un tableau de conducteurs où chaque conducteur est un objet qui contient des informations sur le conducteur et le véhicule associé
    const selectedDriver = optionList.find(
      (driver) => driver.numPermis === selectedDriverLicenseNumber
    );

    if (selectedDriver) {
      const parentVehicleValue = selectedDriver.vehicleID; // Remplacez 'vehicleID' par la clé appropriée pour le véhicule parent dans votre objet conducteur

      setContactData((prevState) => ({
        ...prevState,
        numPermis: selectedDriverLicenseNumber,
        vehicleID: parentVehicleValue,
      }));
    }
  };

  const handleSelect = (event) => {
    setSelectedDate({
      start: moment(event.start).startOf("day").format("YYYY-MM-DD 00:00:00"),
      end: moment(event.end)
        .subtract(1, "days")
        .endOf("day")
        .format("YYYY-MM-DD 23:59:59"),
    });

    

    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleListClose = () => {
    setModalIsOpen(false);
  };

  const handleEditClose = () => {
    setModalIsEditOpen(false);
  };

  const validateForm = () => {
    let err = {};
    if (contactData.numPermis === "") {
      err.numPermis = toast.error("😡 Veuillez selectionner un conducteur", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const onEventClick = async (date) => {
    // Fermer le modal actuel
    setModalIsOpen(true);
    setEventDate(date)

    const dateEvent = moment(date).format('YYYY-MM-DD HH:mm:ss');

    // Récupérer les événements de cette date depuis le backend
    await axiosPrivate.get(`calendar/get-events-date/${companyId}/${dateEvent}`).then((res) => {
      setSelectedEvents(res.data);
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  const isEventPast = eventDate && new Date(eventDate) < new Date();

  const modifyEvent = async (event) => {
    setGetEventID(event);
    // Fermer le modal actuel
    setModalIsOpen(false);

    // Ouvrir le modal
    setModalIsEditOpen(true);

    // Récupérer les événements de cette date depuis le backend
    await axiosPrivate.get(`calendar/find-event/${event}`).then((res) => {
      setContactData({
        numPermis: res.data.numPermis,
        vehicleID: res.data.vehicleID,
        start: res.data.start,
        end: res.data.end,
      });
      setGetEvents(res.data);
    });
  };

  const driverVersement = (event) => {
    navigate(`/versements/create/${event}`);
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      try {
        axiosPrivate
          .post(
            "calendar/add-event",
            {
              companieID: user.companyID,
              userID: user.userID,
              numPermis: encrypt(contactData.numPermis),
              vehicule: encrypt(contactData.vehicleID),
              start: selectedDate.start,
              end: selectedDate.end,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              setIsOpen(false);
              //window.location.reload(); // Ajoutez cette ligne pour rafraîchir la page
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    } else {
    }
  };

  const handleEditEventSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      try {
        axiosPrivate
          .put(
            `calendar/update-event/${getEventID}`,
            {
              companieID: user.companyID,
              userID: user.userID,
              numPermis: encrypt(contactData.numPermis),
              vehicule: encrypt(contactData.vehicleID),
              start: moment(contactData.start).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(contactData.end).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              setModalIsEditOpen(false);
              //window.location.reload(); // Ajoutez cette ligne pour rafraîchir la page
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    } else {
    }
  };

  const handleDeleteClick = async (recordId) => {
    setModalIsOpen(false);
    setRecordToDelete(recordId);
    setDeleteModalIsOpen(true);
  };

  const handleConfirmDelete = async () => {
    await axiosPrivate
      .post(`calendar/delete-event/${recordToDelete}`)
      .then((res) => {
        if (res.data.code === 100) {
          toast.success(`👏 ${res.data.msg}`, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
          setDeleteModalIsOpen(false);
          //window.location.reload(); // Ajoutez cette ligne pour rafraîchir la page
        }
      })
      .catch((error) => console.error("Error deleting data: ", error));
  };

  return (
    <div>
      <Calendar
        ref={calendarRef}
        popup
        selectable
        localizer={localizer}
        defaultDate={moment().toDate()}
        defaultView="month"
        style={{ height: "100vh" }}
        components={{ toolbar: CustomToolbar }}
        onSelectSlot={handleSelect}
        events={events} // Utilisez l'état des événements ici
        views={["month", "week", "day", "agenda"]}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter} // Utilisez eventStyleGetter ici
        onSelectEvent={(event) => onEventClick(event.end)}
      />

      {isOpen && (
        <div
          aria-labelledby="mySmallModalLabel"
          className="modal fade show"
          id="popupAttachement"
          tabIndex="-1"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true" onClick={handleClose}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div id="main" className="calendar-column">
                  <h1>Planifier un conducteur</h1>
                  <h3></h3>
                  <form onSubmit={handleEventSubmit}>
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Date début</label>
                      <input
                        type="datetime-local"
                        className="large ng-untouched ng-pristine ng-valid"
                        name="start"
                        value={selectedDate.start}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Date fin</label>
                      <input
                        type="datetime-local"
                        className="large ng-untouched ng-pristine ng-valid"
                        name="end"
                        value={selectedDate.end}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col9 ng-star-inserted">
                      <label className="form">Sélectionner le conducteur</label>
                      <div className="large">
                        <div className="box-select-large">
                          <select
                            name="numPermis"
                            value={contactData.numPermis}
                            onChange={handleChange}
                          >
                            <option>Sélectionner le conducteur</option>
                            {optionList.map((item) => (
                              <option
                                key={item.numPermis}
                                value={item.numPermis}
                              >
                                {item.nom} {item.prenom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <button className="accept">Planifer</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalIsOpen && (
        <div
          aria-labelledby="mySmallModalLabel"
          className="modal fade show"
          id="popupAttachement"
          tabIndex="-1"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true" onClick={handleListClose}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div id="main" className="calendar-column">
                  <h1>{isEventPast ? 'Liste des versements en attente' : 'Liste des planifications'}</h1>
                  <h3></h3>
                  <table>
                    <thead className="ui-table-thead">
                      <tr className="ng-star-inserted">
                        <th
                          style={{ width: "40px", padding: ".3rem .3rem" }}
                          className="ng-star-inserted"
                        ></th>
                        <th className="ui-sortable-column ng-star-inserted">
                          Nom & Prenoms
                        </th>
                        <th className="ui-sortable-column ng-star-inserted">
                          Véhicule
                        </th>
                        <th className="ui-sortable-column ng-star-inserted">
                          Date début
                        </th>
                        <th className="ui-sortable-column ng-star-inserted">
                          Date fin
                        </th>
                      </tr>
                    </thead>
                    <tbody className="ui-table-tbody">
                      {selectedEvents.map((event) => (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          ></td>
                          <td className="ng-star-inserted">
                            {event.conducteur.nom} {event.conducteur.prenom}
                          </td>
                          <td className="ng-star-inserted">
                            {event.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(event.start).toLocaleDateString("en-GB")}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(event.end).toLocaleDateString("en-GB")}
                          </td>
                          {moment(event.start).isBefore(aujourdhui, "day") ? (
                            <>
                              <td className="ng-star-inserted">
                                <span className="ng-star-inserted">
                                  <a
                                    data-target="#popupAttachement"
                                    data-toggle="modal"
                                    style={{ cursor: "pointer" }}
                                    title="Effectur le versement"
                                    onClick={() => driverVersement(event.id)}
                                  >
                                    <img src={encaissement} />
                                  </a>
                                </span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="ng-star-inserted">
                                <span className="ng-star-inserted">
                                  <a
                                    data-target="#popupAttachement"
                                    data-toggle="modal"
                                    style={{ cursor: "pointer" }}
                                    title="Modifier"
                                    onClick={() => modifyEvent(event.id)}
                                  >
                                    <img src={modifier} />
                                  </a>
                                </span>
                              </td>
                              <td className="ng-star-inserted">
                                <span className="ng-star-inserted">
                                  <a
                                    data-target="#popupAttachement"
                                    data-toggle="modal"
                                    style={{ cursor: "pointer" }}
                                    title="Supprimer"
                                    onClick={() => handleDeleteClick(event.id)}
                                  >
                                    <img src={supprimer} />
                                  </a>
                                </span>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalIsEditOpen && (
        <div
          aria-labelledby="mySmallModalLabel"
          className="modal fade show"
          id="popupAttachement"
          tabIndex="-1"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true" onClick={handleEditClose}>
                    ×
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div id="main" className="calendar-column">
                  <h1>Modifier le programme de {getEvents.conducteur?.nom}</h1>
                  <h3></h3>
                  <form onSubmit={handleEditEventSubmit}>
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Date début</label>
                      <input
                        type="datetime-local"
                        className="large ng-untouched ng-pristine ng-valid"
                        name="start"
                        value={moment(contactData.start).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        )}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Date fin</label>
                      <input
                        type="datetime-local"
                        className="large ng-untouched ng-pristine ng-valid"
                        name="end"
                        value={moment(contactData.end).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        )}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col9 ng-star-inserted">
                      <label className="form">Sélectionner le conducteur</label>
                      <div className="large">
                        <div className="box-select-large">
                          <select
                            name="numPermis"
                            value={contactData.numPermis}
                            onChange={handleChange}
                          >
                            <option>Sélectionner le conducteur</option>
                            {optionList.map((item) => (
                              <option
                                key={item.numPermis}
                                value={item.numPermis}
                              >
                                {item.nom} {item.prenom}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <button className="accept">Modifier</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModalIsOpen && (
        <div
          aria-labelledby="mySmallModalLabel"
          className="modal fade show"
          id="popupDelete"
          role="dialog"
          tabIndex="-1"
          aria-modal="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <h6 className="center c-red">
                  Voulez-vous vraiment supprimer cette planification ?
                </h6>
                <h6 className="center c-red"></h6>
                <div className="c-red">
                  <ul></ul>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="cancel"
                  data-dismiss="modal"
                  id="confirm-modaldismiss"
                  type="cancel"
                  onClick={() => setDeleteModalIsOpen(false)}
                >
                  Annuler
                </button>
                <button
                  className="delete"
                  type="button"
                  onClick={handleConfirmDelete}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanningVehicule;
