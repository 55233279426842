import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/list.svg";

const EditVisiteTechnique = () => {
  const { numVisite, vehicleID } = useParams();
  const fileInput = useRef();
  const flag = useRef(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [photoDriver, setPhotoDriver] = useState("");
  const [contrat, setContrat] = useState("");
  const [checked, setChecked] = useState("");
  const [phoneChauffeur, setPhoneChauffeur] = useState("");
  const [contactProche, setContactProche] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [optionListVisite, setOptionListVisite] = useState([]);
  const [contactData, setContactData] = useState({
    numVisite: "",
    date_debut: "",
    date_fin: "",
    montant_visite: "",
    frais_timbre: "",
    centre_visite: "",
    commentaire: "",
    vehicleID: "",
    oldVisite: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companieID = decrypt(user.companyID);

  useEffect(() => {
    const getAllVehicule = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companieID}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    const getAllVisiteTechnique = () => {
      axiosPrivate
        .get(`charges/get-visite-technique/${numVisite}/${vehicleID}`)
        .then((res) => {
          setContactData({
            ...contactData,
            numVisite: res.data.numVisite,
            date_debut: res.data.date_debut,
            date_fin: res.data.date_fin,
            montant_visite: res.data.montant_visite,
            frais_timbre: res.data.frais_timbre,
            centre_visite: res.data.centre_visite,
            commentaire: res.data.commentaire,
            vehicleID: res.data.vehicleID,
            oldVisite: res.data.attachement,
          });
        })
        .catch((err) => console.log(err));
    };

    getAllVehicule();
    getAllVisiteTechnique();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", contrat);
      const res = await axiosPrivate.post("upload-visite", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.vehicleID === "") {
      err.vehicleID = toast.error("😡 Veuillez selectionner le véhicule!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.numVisite === "") {
      err.numVisite = toast.error(
        "😡 Veuillez entrer le numero de la vignette!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_debut === "") {
      err.date_debut = toast.error("😡 Veuillez choisir la date de debut!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_fin === "") {
      err.date_fin = toast.error("😡 Veuillez selectionner la date de fin", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.montant_visite === "") {
      err.montant_visite = toast.error(
        "😡 Veuillez entrer le montant de la visite technique",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.centre_visite === "") {
      err.centre_visite = toast.error(
        "😡 Veuillez entrer le centre de la visite technique",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    let contratUrl = "";

    if (contrat) contratUrl = await uploadContrat();

    if (isValid) {
      await axiosPrivate
        .put(`charges/update-visite-technique/${numVisite}`, {
          companieID: user.companyID,
          userID: user.userID,
          vehicleID: encrypt(contactData.vehicleID),
          numVisite: encrypt(contactData.numVisite),
          date_debut: encrypt(contactData.date_debut),
          date_fin: encrypt(contactData.date_fin),
          montant_visite: encrypt(contactData.montant_visite),
          frais_timbre: encrypt(contactData.frais_timbre),
          centre_visite: encrypt(contactData.centre_visite),
          commentaire: contactData.commentaire,
          contratUrl: contratUrl,
          oldVisite: contactData.oldVisite,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/charges/visite-technique/${companieID}`);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  return (
    <div className="ng-star-inserted">
      <div id="header-main">
        <div className="title">
          <h2>Visite technique</h2>
        </div>
        <div className="links">
          <a
            href={`/charges/visite-technique/${companieID}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <form
        novalidate=""
        className="ng-untouched ng-pristine ng-valid"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <div className="form-l">
          <div className="form-content">
            <div className="form-block">
              <div className="form-group ng-star-inserted">
                <label className="form">Sélectionner un véhicule</label>
                <div className="large">
                  <div className="box-select-large">
                    <select
                      name="vehicleID"
                      value={contactData.vehicleID}
                      onChange={handleChange}
                    >
                      <option>Sélectionner un véhicule</option>
                      {optionList.map((item) => (
                        <option
                          key={item.immatriculation}
                          value={item.immatriculation}
                        >
                          {item.immatriculation}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-content">
            <div className="form-block">
              <label className="title-content">Informations générales</label>
              <div className="form-group col2">
                <label className="form">Numéro</label>
                <input
                  type="text"
                  placeholder="Numéro de la visite"
                  className="ng-pristine ng-invalid ng-touched"
                  name="numVisite"
                  value={contactData.numVisite}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2">
                <label className="form">Centre de la visite</label>
                <input
                  type="text"
                  placeholder="Centre de la visite"
                  className="ng-pristine ng-invalid ng-touched"
                  name="centre_visite"
                  value={contactData.centre_visite}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Date de debut</label>
                <input
                  type="date"
                  placeholder="Date de debut"
                  className="ng-pristine ng-invalid ng-touched"
                  name="date_debut"
                  value={contactData.date_debut}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Date de fin</label>
                <input
                  type="date"
                  placeholder="Date de fin"
                  className="ng-pristine ng-invalid ng-touched"
                  name="date_fin"
                  value={contactData.date_fin}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2">
                <label className="form">Montant de la visite</label>
                <input
                  type="number"
                  min="0"
                  placeholder="Montant de la visite"
                  className="inputGroup ng-untouched ng-pristine ng-valid"
                  name="montant_visite"
                  value={contactData.montant_visite}
                  onChange={handleChange}
                />
                <div className="labelGroup">
                  <span className="labelGroupText" id="basic-addon2">
                    F.CFA
                  </span>
                </div>
              </div>
              <div className="form-group col2">
                <label className="form">Frais de timbre</label>
                <input
                  type="number"
                  min="0"
                  placeholder="Frais de timbre"
                  className="inputGroup ng-untouched ng-pristine ng-valid"
                  name="frais_timbre"
                  value={contactData.frais_timbre}
                  onChange={handleChange}
                />
                <div className="labelGroup">
                  <span className="labelGroupText" id="basic-addon2">
                    F.CFA
                  </span>
                </div>
              </div>
              <div className="form-group ng-star-inserted">
                <label className="form">Télécharger la carte grise</label>
                <div className="large">
                  <input
                    accept=".pdf"
                    className="large"
                    type="file"
                    name="carte"
                    id="carte"
                    ref={fileInput}
                    onChange={(event) => setContrat(event.target.files[0])}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form">Commentaire</label>
                <textarea
                  name="commentaire"
                  type="text"
                  cols="3"
                  rows="5"
                  className="larg ng-untouched ng-pristine ng-valid"
                  value={contactData.commentaire}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="form-button-98">
          <button className="save" type="submit" disabled="">
            Modifier
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditVisiteTechnique;
