import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconView from "../../../../../assets/icons/dropdown-view.svg";
import iconPrint from "../../../../../assets/icons/dropdown-print.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import DriverContratModal from "../../components/ContratDriverModal";
import DeleteDriverModal from "../../components/DeleteDriverModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

const ConducteurList = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const downloadLink = useRef(null);
  const [numPermis, setNumpermis] = useState("");
  const [nom, setNom] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getAllDrivers = () => {
      axiosPrivate
        .get(`drivers/getAllDrivers/${companyId}`)
        .then((res) => {
          setDriverData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllDrivers();
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((numPermis || nom || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get("drivers/search-drivers", {
          params: { numPermis, nom, companyId },
        });
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numPermis, nom, companyId]);

  useEffect(() => {
    if (!showDeleteModal && deleteModal != null) {
      window.location.reload();
    }
  }, [showDeleteModal, deleteModal]);

  const handleClickOpen = () => {
    navigate(`/personnels/conducteurs/ajouter/${companyId}`);
  };

  function handleOnClickModal(numPermis, companieID, driverContrat) {
    setShowDetailModal(true);
    setModal([numPermis, companieID, driverContrat]);
  }

  function handleOnClickDeleteModal(numPermis, nom, prenom, companie) {
    setShowDeleteModal(true);
    setDeleteModal([numPermis, nom, prenom, companie]);
  }

  const handleCSVClick = async () => {
    const headers = [
      "",
      "Véhicules",
      "Nom & Prenoms",
      "N° Permis",
      "Date expiration",
      "Contact",
      "N° d'urgence",
      "Date recrutement",
      "Recette",
    ];
    const tableData = driverData.map((driver) => [
      "",
      driver["vehicleID"],
      driver["nom"] + " " + driver["prenom"],
      driver["numPermis"],
      moment(driver["date_expiration_pc"]).format("DD/MM/YYYY"),
      driver["contact_chauffeur"],
      driver["contact_proche"],
      moment(driver["date_recrutement"]).format("DD/MM/YYYY"),
      driver["conducteurs"]
        ? driver["conducteurs"].map(
            (conducteur) => `${conducteur.nom} ${conducteur.prenom}`
          )
        : " ",
    ]);
    tableData.unshift(headers);

    // Convertir chaque sous-tableau en une chaîne de caractères, séparée par des virgules
    const csvData = tableData.map((row) => row.join(","));

    // Convertir le tableau de chaînes de caractères en une seule chaîne de caractères, avec chaque élément séparé par un saut de ligne
    const csvString = csvData.join("\n");

    if (downloadLink.current) {
      downloadLink.current.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvString
      )}`;
      downloadLink.current.download = "liste-conducteurs.csv";
      downloadLink.current.click();
    }
  };

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");
  
    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;
  
    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;
  
    doc.addImage(image, "JPEG", 10, 5, 60, 45);
  
    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);
  
    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des Conducteurs";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre
  
    const headers = [
      "",
      "Véhicules",
      "Nom & Prenoms",
      "N° Permis",
      "Contact",
      "N° d'urgence",
      "Date recrutement",
      "Versements",
    ];
  
    const tableData = isSearching ? searchResults.map((result) => {
      let totalPayments = "Aucun versement.";
      if (
        result.versements &&
        result.versements[0] &&
        result.versements[0].total_payments
      ) {
        totalPayments = result.versements[0].total_payments;
      }
  
      return [
        "",
        result["vehicleID"],
        result["nom"] + " " + result["prenom"],
        result["numPermis"],
        result["contact_chauffeur"],
        result["contact_proche"],
        moment(result["date_recrutement"]).format("DD/MM/YYYY"),
        totalPayments + "F.CFA", // Ajoutez les versements ici
      ];
    }) : driverData.map((driver) => {
      let totalPayments = "Aucun versement.";
      if (
        driver.versements &&
        driver.versements[0] &&
        driver.versements[0].total_payments
      ) {
        totalPayments = driver.versements[0].total_payments;
      }
  
      return [
        "",
        driver["vehicleID"],
        driver["nom"] + " " + driver["prenom"],
        driver["numPermis"],
        driver["contact_chauffeur"],
        driver["contact_proche"],
        moment(driver["date_recrutement"]).format("DD/MM/YYYY"),
        totalPayments + "F.CFA", // Ajoutez les versements ici
      ];
    });
  
    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });
  
    doc.save("liste-conducteurs.pdf");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Conducteurs</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter un conducteur"
              className="ng-star-inserted"
            >
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={handlePDFClick} />
            </a>
            <a
              ref={downloadLink}
              title="Exporter en csv"
              className="ng-star-inserted"
            >
              <img
                src={excel}
                alt=""
                onClick={handleCSVClick}
                ref={downloadLink}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par nom</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par nom conducteur"
                  name="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par Permis</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par permis de conduire"
                  name="numPermis"
                  value={numPermis}
                  onChange={(e) => setNumpermis(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-black">{driverData.length}</span>
            <span className="label">Nombre de conducteurs</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Nom & Prenoms
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Contact
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Catégorie du permis
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    N° permis
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date d'expiration
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">Genre</th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Versements
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Utilisateur
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Contrat
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? driverData.map((drivers) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [drivers.numPermis]:
                                          !subMenuOpen[drivers.numPermis],
                                      })
                                    }
                                    open={!!subMenuOpen[drivers.numPermis]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[drivers.numPermis] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/personnels/conducteurs/profile/${drivers.numPermis}/${drivers.companieID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconView} />
                                      Consulter
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/personnels/conducteurs/edit/${drivers.numPermis}/${drivers.companieID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteModal(
                                          drivers.numPermis,
                                          drivers.nom,
                                          drivers.prenom,
                                          drivers.companieID
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconPrint} />
                                      Imprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.nom} {drivers.prenom}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.contact_chauffeur}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.categorie_permis}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.numPermis}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(
                              drivers.date_expiration_pc
                            ).toLocaleDateString("en-GB")}
                          </td>
                          <td className="ng-star-inserted">{drivers.genre}</td>
                          <td className="ng-star-inserted">
                            {drivers.versements && drivers.versements[0]
                              ? drivers.versements[0].total_payments + " F.CFA"
                              : "Aucun versement"}
                          </td>
                          <td className="ng-star-inserted">
                            {drivers.user.prenom.split(" ").pop()}{" "}
                            {drivers.user.nom}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    drivers.numPermis,
                                    drivers.companieID,
                                    drivers.driverContrat
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.numPermis]:
                                          !subMenuOpen[result.numPermis],
                                      })
                                    }
                                    open={!!subMenuOpen[result.numPermis]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.numPermis] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/personnels/conducteurs/profile/${result.numPermis}/${result.companieID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconView} />
                                      Consulter
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/personnels/conducteurs/edit/${result.numPermis}/${result.companieID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteModal(
                                          result.numPermis,
                                          result.nom,
                                          result.prenom,
                                          result.companieID
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconPrint} />
                                      Imprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.nom} {result.prenom}
                          </td>
                          <td className="ng-star-inserted">
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {result.contact_chauffeur}
                          </td>
                          <td className="ng-star-inserted">
                            {result.categorie_permis}
                          </td>
                          <td className="ng-star-inserted">
                            {result.numPermis}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(
                              result.date_expiration_pc
                            ).toLocaleDateString("en-GB")}
                          </td>
                          <td className="ng-star-inserted">{result.genre}</td>
                          <td className="ng-star-inserted">
                            {result.versements && result.versements[0]
                              ? result.versements[0].total_payments + " F.CFA"
                              : "Aucun versement"}
                          </td>
                          <td className="ng-star-inserted">
                            {result.user.prenom.split(" ").pop()}{" "}
                            {result.user.nom}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    result.numPermis,
                                    result.companieID,
                                    result.driverContrat
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="mySmallModalLabel"
          className="modal fade"
          id="popupDelete"
          role="dialog"
          tabIndex="-1"
        ></div>
      </div>
      {showDetailModal && setModal != null && (
        <DriverContratModal
          closeContratModal={setShowDetailModal}
          data={modal}
        />
      )}
      {showDeleteModal && setDeleteModal != null && (
        <DeleteDriverModal
          closeDeleteDriverModal={setShowDeleteModal}
          data={deleteModal}
        />
      )}
    </>
  );
};

export default ConducteurList;
