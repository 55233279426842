import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import { toast, Slide, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decrypt, encrypt } from "../../../../module/myCrypto";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthProvider";
import list from "../../../../../assets/icon40/list.svg";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import axiosPrivate from "../../../../services/api/axios";

const AjouterConstatation = () => {
  const { companieID } = useParams();
  const [date, setDate] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [formError, setFormError] = useState({});
  const [chauffeurs, setChauffeurs] = useState([]);
  const [isActive, setActive] = useState("#nav-s1");
  const toggleClass = (e) => {
    setActive(e.currentTarget.hash);
  };
  const constatboxRef = useRef(null);
  const rapportboxRef = useRef(null);
  const degatmaterielboxRef = useRef(null);
  const degatcorporelboxRef = useRef(null);
  const degatmortelboxRef = useRef(null);
  const reparationboxRefExpert1 = useRef(null);
  const reformeecoboxRefExpert1 = useRef(null);
  const reformetechboxRefExpert1 = useRef(null);
  const reparationboxRefExpert2 = useRef(null);
  const reformeecoboxRefExpert2 = useRef(null);
  const reformetechboxRefExpert2 = useRef(null);
  const [phoneExpert1, setPhoneExpert1] = useState("");
  const [phoneExpert2, setPhoneExpert2] = useState("");
  const [contactData, setContactData] = useState({
    step1: {
      date_declaration: "",
      type_sinistre: "",
      lieu_sinistre: "",
      constat: "",
      rapports: "",
      autorite_pv: "",
      date_pv: "",
      numero_pv: "",
      degat_materiel: "",
      degat_corporel: "",
      degat_mortel: "",
    },

    step2: {
      vehicleID: "",
      numPermis: "",
      kilometrage: "",
      degats: "",
      observation: "",
    },

    step3: {
      immatriculation_adverse: "",
      type_vehicule_adverse: "",
      marque_adverse: "",
      modele_adverse: "",
      nom_conducteur_adverse: "",
      prenom_conducteur_adverse: "",
      adresse_conducteur_adverse: "",
      nom_assure: "",
      prenom_assure: "",
      adresse_assure: "",
      compagnie_assurance: "",
      numPolice: "",
      numAttestation: "",
      autre_degat: "",
      observation_degat: "",
    },

    step4: {
      nom_expert1: "",
      prenom_expert1: "",
      date_expert1: "",
      nom_expert2: "",
      prenom_expert2: "",
      date_expert2: "",
    },

    step5: {
      nom_temoin: "",
      prenom_temoin: "",
      date_naissance_temoin: "",
      lieu_naissance_temoin: "",
      cni_temoin: "",
      adresse_temoin: "",
    },

    step6: {
      taux_remboursement: "",
      taux_responsabilite: "",
      montant_reparation: "",
      numero_utilisation: "",
      montant_franchise: "",
      date_cloture: "",
      montant_rembourse: "",
      reference_sinistre: "",
    },
  });

  useEffect(() => {
    const dateDuJour = new Date().toLocaleDateString();
    setDate(dateDuJour);
  }, []);

  useEffect(() => {
    const getAllVehicle = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companieID}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicle();
  }, []);

  const handleChange = (event, step) => {
    //setIsChecked(event.target.checked);
    setContactData({
      ...contactData,
      [step]: {
        ...contactData[step],
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleCheckboxChange = () => {
    const constatChecked = constatboxRef.current.checked;
    const rapportChecked = rapportboxRef.current.checked;
    const degatmaterielChecked = degatmaterielboxRef.current.checked;
    const degatcorporelChecked = degatcorporelboxRef.current.checked;
    const degatmortelChecked = degatmortelboxRef.current.checked;
    const reparationCheckedExpert1 = reparationboxRefExpert1.current.checked;
    const reformeecoCheckedExpert1 = reformeecoboxRefExpert1.current.checked;
    const reformetechCheckedExpert1 = reformetechboxRefExpert1.current.checked;
    const reparationCheckedExpert2 = reparationboxRefExpert2.current.checked;
    const reformeecoCheckedExpert2 = reformeecoboxRefExpert2.current.checked;
    const reformetechCheckedExpert2 = reformetechboxRefExpert2.current.checked;
    //console.log('Checkbox value:', isChecked);
  };

  useEffect(() => {
    const fetchChauffeurs = async () => {
      const response = await axiosPrivate.get(
        `drivers/getDriversByCar/${contactData.vehicleID}/${companieID}`
      );
      setChauffeurs(response.data);
    };

    if (contactData.vehicleID) {
      fetchChauffeurs();
    }
  }, [contactData.vehicleID]);

  useEffect(() => {
    if (chauffeurs.length > 0) {
      setContactData((prevData) => ({
        ...prevData,
        numPermis: chauffeurs[0].numPermis
      }));
    }
  }, [chauffeurs]);

  const validateForm = () => {
    let err = {};
    if (contactData.numero_pv === "") {
      err.numero_pv = toast.error("😡 Veuillez entrer le numéro du pv!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_declaration === "") {
      err.date_declaration = toast.error(
        "😡 Veuillez entrer la date declaration!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.type_sinistre === "") {
      err.type_sinistre = toast.error(
        "😡 Veuillez préciser le type de sinistre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.lieu_sinistre === "") {
      err.lieu_sinistre = toast.error(
        "😡 Veuillez préciser le lieu du sinistre",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.autorite_pv === "") {
      err.autorite_pv = toast.error(
        "😡 Veuillez selectionner l'autorité du constat!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_pv === "") {
      err.date_pv = toast.error(
        "😡 Veuillez preciser la date du pv",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.vehicleID === "") {
      err.vehicleID = toast.error(
        "😡 Veuillez preciser le véhicule concerné",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.kilometrage === "") {
      err.kilometrage = toast.error(
        "😡 Veuillez preciser le kilometrage!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.degats_vehicle === "") {
      err.degats_vehicle = toast.error(
        "😡 Veuillez preciser le dégat subi par le véhicule!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.immatriculation_adverse === "") {
      err.immatriculation_adverse = toast.error(
        "😡 Veuillez preciser l'immatriculation du véhicule impliqué'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.marque_adverse === "") {
      err.marque_adverse = toast.error(
        "😡 Veuillez preciser la marque du véhicule impliqué'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.nom_conducteur_adverse === "") {
      err.nom_conducteur_adverse = toast.error(
        "😡 Veuillez preciser le nom du conducteur du véhicule impliqué'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.prenom_conducteur_adverse === "") {
      err.prenom_conducteur_adverse = toast.error(
        "😡 Veuillez preciser le prenom du conducteur du véhicule impliqué'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.prenom_conducteur_adverse === "") {
      err.prenom_conducteur_adverse = toast.error(
        "😡 Veuillez preciser le prenom du conducteur du véhicule impliqué'!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    /*let isValid = validateForm();
  
      if (isValid) {
        let imgUrl = "";
        let contratUrl = "";
      }*/
  };

  return (
    <div className="ng-star-inserted">
      <div>
        <div id="header-main">
          <div className="title">
            <h2>Ajouter un sinistre</h2>
          </div>
          <div className="links">
            <a
              href={`/administratif/sinistre/${companieID}`}
              className="ng-star-inserted"
            >
              <img src={list} />
            </a>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="ng-untouched ng-pristine ng-valid"
        >
          <div className="formulaire">
            <div className="left">
              <nav>
                <div id="nav-tab" className="nav nav-tabs" role="tablist">
                  <a
                    aria-controls="nav-s1"
                    aria-solution-bloc="true"
                    className={
                      isActive === "#nav-s1"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-s1"
                    id="nav-s1-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s1" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Circonstances sinistre
                  </a>
                  <a
                    aria-controls="nav-s10"
                    aria-solution-bloc="true"
                    href="#nav-s10"
                    className={
                      isActive === "#nav-s10"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    id="nav-s2-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s10" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Véhicule
                  </a>
                  <a
                    _ngcontent-ltc-c11=""
                    aria-controls="nav-s2"
                    aria-solution-bloc="true"
                    className={
                      isActive === "#nav-s2"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-s2"
                    id="nav-s2-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s2" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Info adverse{" "}
                  </a>
                  <a
                    _ngcontent-ltc-c11=""
                    aria-controls="nav-s3"
                    aria-solution-bloc="true"
                    className={
                      isActive === "#nav-s3"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-s3"
                    id="nav-s3-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s3" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Expert{" "}
                  </a>
                  <a
                    _ngcontent-ltc-c11=""
                    aria-controls="nav-s4"
                    aria-solution-bloc="true"
                    className={
                      isActive === "#nav-s4"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-s4"
                    id="nav-s4-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s4" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Témoins{" "}
                  </a>
                  <a
                    _ngcontent-ltc-c11=""
                    aria-controls="nav-s5"
                    aria-solution-bloc="true"
                    className={
                      isActive === "#nav-s5"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-s5"
                    id="nav-s5-tab"
                    role="tab"
                    aria-selected={isActive === "#nav-s5" ? "true" : "false"}
                    onClick={toggleClass}
                  >
                    Autres{" "}
                  </a>
                </div>
              </nav>
            </div>
            <div className="right">
              <div className="tab-content" id="nav-tabContent">
                <div
                  aria-labelledby="nav-s1-tab"
                  className={
                    isActive === "#nav-s1"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-s1"
                  role="tab"
                  aria-selected={isActive === "#nav-s1" ? "true" : "false"}
                >
                  <div className="form-group col5">
                    <label className="form">Date déclaration</label>
                    <div className="date">
                      <input
                        type="datetime-local"
                        className="large addExpress ng-untouched ng-pristine ng-valid date-mask"
                        name="date_declaration"
                        value={contactData.date_declaration}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Type sinistre</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="type_sinistre"
                          value={contactData.type_sinistre}
                          onChange={handleChange}
                        >
                          <option>Type de sinistre</option>
                          <option value="Accident">Accident</option>
                          <option value="Glace brisée">Glace brisée</option>
                          <option value="Vol">Le vol</option>
                          <option value="Incendie">L’incendie</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form">Lieu</label>
                    <textarea
                      typeof="text"
                      className="large ng-untouched ng-pristine ng-valid"
                      rows="2"
                      name="lieu_sinistre"
                      value={contactData.lieu_sinistre}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="clear"></div>
                  <div className="form-group col3">
                    <label className="form">Constat</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={constatboxRef}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Rapports</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={rapportboxRef}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Autorité PV</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="autorite_pv"
                          value={contactData.autorite_pv}
                          onChange={handleChange}
                        >
                          <option>Choisir une autorité PV</option>
                          <option value="Gendarmerie">Gendarmerie</option>
                          <option value="Police">Police</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Date du PV</label>
                    <div>
                      <input
                        type="date"
                        name="date_pv"
                        className="large addExpress ng-untouched ng-pristine ng-valid date-mask"
                        value={contactData.date_pv}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Numéro du PV</label>
                    <div>
                      <input
                        type="text"
                        className="large addExpress ng-untouched ng-pristine ng-valid date-mask"
                        name="numero_pv"
                        value={contactData.numero_pv}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Dégât matériel</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={degatmaterielboxRef}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Dégât corporel</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={degatcorporelboxRef}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Dégât mortel</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={degatmortelboxRef}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div
                  aria-labelledby="nav-s10-tab"
                  id="nav-s10"
                  className={
                    isActive === "#nav-s10"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  role="tab"
                  aria-selected={isActive === "#nav-s10" ? "true" : "false"}
                >
                  <div className="form-group col5">
                    <label className="form">Véhicule</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="vehicleID"
                          value={contactData.vehicleID}
                          onChange={handleChange}
                        >
                          <option>Attribuer un véhicule</option>
                          {optionList.map((item) => (
                            <option
                              key={item.immatriculation}
                              value={item.immatriculation}
                            >
                              {item.immatriculation}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Conducteur</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="numPermis"
                          value={contactData.numPermis || (chauffeurs[0] && chauffeurs[0].numPermis)}
                          onChange={handleChange}
                        >
                          {chauffeurs.map((chauffeur, index) => (
                            <option key={index} value={chauffeur.numPermis}>
                              {chauffeur.nom} {chauffeur.prenom}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Kilométrage</label>
                    <input
                      type="number"
                      min="0"
                      className="ng-untouched ng-pristine ng-valid"
                      name="kilometrage"
                      value={contactData.kilometrage}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Compteur horaire</label>
                    <input
                      type="number"
                      min="0"
                      className="ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Dégâts</label>
                    <textarea
                      name="degats"
                      value={contactData.degats}
                      onChange={handleChange}
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Observations</label>
                    <textarea
                      name="observation"
                      value={contactData.observation}
                      onChange={handleChange}
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div
                  aria-labelledby="nav-s2-tab"
                  id="nav-s2"
                  className={
                    isActive === "#nav-s2"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  role="tab"
                  aria-selected={isActive === "#nav-s2" ? "true" : "false"}
                >
                  <h3>Véhicule</h3>
                  <div className="form-group col5">
                    <label className="form">Immatriculation</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="immatriculation_adverse"
                      value={contactData.immatriculation_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Type véhicule</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="type_vehicule_adverse"
                      value={contactData.type_vehicule_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Marque</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="marque_adverse"
                      value={contactData.marque_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Modèle</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="modele_adverse"
                      value={contactData.modele_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <h3>Conducteur</h3>
                  <div className="form-group col5">
                    <label className="form">Nom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="nom_conducteur_adverse"
                      value={contactData.nom_conducteur_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Prénoms</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="prenom_conducteur_adverse"
                      value={contactData.prenom_conducteur_adverse}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form">Adresse</label>
                    <textarea
                      type="text"
                      name="adresse_conducteur_adverse"
                      value={contactData.adresse_conducteur_adverse}
                      onChange={handleChange}
                      cols="30"
                      rows="3"
                      className="large ng-untouched ng-pristine ng-valid"
                    ></textarea>
                  </div>
                  <h3>Assuré</h3>
                  <div className="form-group col5">
                    <label className="form">Nom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="nom_assure"
                      value={contactData.nom_assure}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Prénoms</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="prenom_assure"
                      value={contactData.prenom_assure}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form">Adresse</label>
                    <textarea
                      type="text"
                      name="adresse_assure"
                      value={contactData.adresse_assure}
                      onChange={handleChange}
                      cols="30"
                      rows="3"
                      className="large ng-untouched ng-pristine ng-valid"
                    ></textarea>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Compagnie d'assurance</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="compagnie_assurance"
                      value={contactData.compagnie_assurance}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Numéro police</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="numPolice"
                      value={contactData.numPolice}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">N° attestation</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="numAttestation"
                      value={contactData.numAttestation}
                      onChange={handleChange}
                    />
                  </div>
                  <h3>Autres</h3>
                  <div className="form-group">
                    <label className="form">Dégâts</label>
                    <textarea
                      type="text"
                      name="autre_degat"
                      value={contactData.autre_degat}
                      onChange={handleChange}
                      cols="30"
                      rows="3"
                      className="large ng-untouched ng-pristine ng-valid"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label className="form">Observations</label>
                    <textarea
                      type="text"
                      name="observation_degat"
                      value={contactData.observation_degat}
                      onChange={handleChange}
                      cols="30"
                      rows="3"
                      className="large ng-untouched ng-pristine ng-valid"
                    ></textarea>
                  </div>
                </div>
                <div
                  className={
                    isActive === "#nav-s3"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-s3"
                  role="tab"
                  aria-selected={isActive === "#nav-s3" ? "true" : "false"}
                >
                  <h3>Expert 1</h3>
                  <div className="form-group col5">
                    <label className="form">Nom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="nom_expert1"
                      value={contactData.nom_expert1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Prénoms</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="prenom_expert1"
                      value={contactData.prenom_expert1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Date</label>
                    <input
                      type="date"
                      className="ng-untouched ng-pristine ng-valid"
                      name="date_expert1"
                      value={contactData.date_expert1}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">N° téléphone</label>
                    <PhoneInput
                      international={true}
                      countryCallingCodeEditable={false}
                      placeholder="Entrer le numero de l'expert 1"
                      value={phoneExpert1}
                      onChange={(phoneExpert1) => setPhoneExpert1(phoneExpert1)}
                      defaultCountry="CI"
                      flags={flags}
                      labels={fr}
                      limitMaxLength={10}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="clear"></div>
                  <div style={{ display: "block" }}>Décision</div>
                  <div className="form-group col3">
                    <label className="form">Réparation</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reparationboxRefExpert1}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Reforme économique</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reformeecoboxRefExpert1}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Reforme technique</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reformetechboxRefExpert1}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="clear"></div>
                  <h3>Expert 2</h3>
                  <div className="form-group col5">
                    <label className="form">Nom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="nom_expert2"
                      value={contactData.nom_expert2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Prénoms</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="prenom_expert2"
                      value={contactData.prenom_expert2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Date</label>
                    <input
                      type="date"
                      className="ng-untouched ng-pristine ng-valid"
                      name="date_expert2"
                      value={contactData.date_expert2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">N° téléphone</label>
                    <PhoneInput
                      international={true}
                      countryCallingCodeEditable={false}
                      placeholder="Entrer le numero de l'expert 2"
                      value={phoneExpert2}
                      onChange={(phoneExpert2) => setPhoneExpert2(phoneExpert2)}
                      defaultCountry="CI"
                      flags={flags}
                      labels={fr}
                      limitMaxLength={10}
                      rules={{ required: true }}
                    />
                  </div>
                  <div className="clear"></div>
                  <div style={{ display: "block" }}>Décision</div>
                  <div className="form-group col3">
                    <label className="form">Réparation</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reparationboxRefExpert2}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Reforme économique</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reformeecoboxRefExpert2}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="form-group col3">
                    <label className="form">Reforme technique</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="viewCheckbox ng-untouched ng-pristine ng-valid"
                        style={{ cursor: "pointer" }}
                        ref={reformetechboxRefExpert2}
                        onChange={handleCheckboxChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div
                  aria-labelledby="nav-s4-tab"
                  className={
                    isActive === "#nav-s4"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-s4"
                  role="tab"
                  aria-selected={isActive === "#nav-s4" ? "true" : "false"}
                >
                  <div id="header-main">
                    <div className="title">
                      <h2>Témoins</h2>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Nom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="nom_temoin"
                      value={contactData.nom_temoin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Prénom</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="prenom_temoin"
                      value={contactData.prenom_temoin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Date de naissance</label>
                    <input
                      type="date"
                      className="ng-untouched ng-pristine ng-valid"
                      name="date_naissance_temoin"
                      value={contactData.date_naissance_temoin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Lieu de naissance</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="lieu_naissance_temoin"
                      value={contactData.lieu_naissance_temoin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">N° CNI</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="cni_temoin"
                      value={contactData.cni_temoin}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Adresse Géographique</label>
                    <textarea
                      name="adresse_temoin"
                      value={contactData.adresse_temoin}
                      onChange={handleChange} cols="30" rows="3"></textarea>
                  </div>
                </div>
                <div
                  aria-labelledby="nav-s5-tab"
                  className={
                    isActive === "#nav-s5"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-s5"
                  role="tab"
                  aria-selected={isActive === "#nav-s5" ? "true" : "false"}
                >
                  <div className="form-group col5">
                    <label className="form">Taux remboursement</label>
                    <input
                      type="number"
                      min="0"
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      name="taux_remboursement"
                      value={contactData.taux_remboursement}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        %
                      </span>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Taux responsabilité</label>
                    <input
                      type="number"
                      min="0"
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      name="taux_responsabilite"
                      value={contactData.taux_responsabilite}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        %
                      </span>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Montant de réparation</label>
                    <input
                      type="number"
                      min="0"
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      name="montant_reparation"
                      value={contactData.montant_reparation}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        F.CFA
                      </span>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Numéro d'utilisation</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="numero_utilisation"
                      value={contactData.numero_utilisation}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Montant de franchise</label>
                    <input
                      type="number"
                      min="0"
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      name="montant_franchise"
                      value={contactData.montant_franchise}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        F.CFA
                      </span>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Date clotûre</label>
                    <input
                      type="date"
                      min="0"
                      className="large addExpress ng-untouched ng-pristine ng-valid date-mask"
                      name="date_cloture"
                      value={contactData.date_cloture}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col5">
                    <label className="form">Montant remboursé</label>
                    <input
                      type="number"
                      min="0"
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      name="montant_rembourse"
                      value={contactData.montant_rembourse}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        F.CFA
                      </span>
                    </div>
                  </div>
                  <div className="form-group col5">
                    <label className="form">Référence sinistre</label>
                    <input
                      type="text"
                      className="ng-untouched ng-pristine ng-valid"
                      name="reference_sinistre"
                      value={contactData.reference_sinistre}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-button-90 ng-star-inserted">
            <button className="save">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AjouterConstatation;
