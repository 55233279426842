import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import calendar from "../../../../../../assets/icon40/calendar.svg";
import driver from "../../../../../../assets/icon40/taxi-driver.svg";
import versement from "../../../../../../assets/icon40/money-bag.svg";
import taxi from "../../../../../../assets/images/car.jpg";
import contratIcon from "../../../../../../assets/icon40/folders-office.svg";
import settings from "../../../../../../assets/icon40/settings.svg";
import shopping from "../../../../../../assets/icon40/shopping-bag.svg";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../../../../services/api/axios";
import { useAuth } from "../../../../../context/AuthProvider";
import { decrypt } from "../../../../../module/myCrypto";

const VehicleProfile = () => {
  const { vehiculeId, acquisition, contrat } = useParams();
  const flag = useRef(false);
  const [carContrat, setContratdata] = useState("");
  const [carData, setCardata] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [image, setImage] = useState("");
  const [isActive, setActive] = useState("#nav-1");
  const toggleClass = (e) => {
    setActive(e.currentTarget.hash);
  };

  useEffect(() => {
    const getAllVehicles = () => {
      axiosPrivate
        .get(`vehicles/getVehicle/${vehiculeId}`)
        .then((res) => {
          setCardata(res.data);
          console.log(res.data);
          setImage(
            `https://apiserver.raymobileci.com/${companyID}/vehicules/images/${res.data.photo_voiture}`
          );
        })
        .catch((err) => console.log(err));
    };

    const getAllContrat = () => {
      axiosPrivate
        .get(`vehiclecontrat/getContrat/${contrat}`)
        .then((res) => {
          setContratdata(res.data);
          //
        })
        .catch((err) => console.log(err));
    };

    const getAllDriversByCar = () => {
      axiosPrivate
        .get(`drivers/getDriversByCar/${vehiculeId}/${companyID}`)
        .then((res) => {
          setDriverData(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicles();
    getAllContrat();
    getAllDriversByCar();
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const company = decrypt(user.companyName);
  const companyID = decrypt(user.companyID);

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Véhicule {vehiculeId}</h2>
          </div>
          <div className="links">
            <a
              href={`/flottes/vehicules/${companyID}`}
              className="ng-star-inserted"
            >
              <img src={list} />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="view flexbox-center border">
          <div className="viewIn width20">
            <div className="imgVehicule">
              {image ? <img src={image} alt="" /> : <img src={taxi} alt="" />}
              <img src={taxi} alt="" />
            </div>
          </div>
          <div className="viewIn width30">
            <div className="line">
              <span className="title1">{carData.immatriculation}</span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">{carData.marque}</span>
              <span className="circle"> </span>
              <span className="title3">{carData.modele}</span>
              <span className="circle"> </span>
              <span className="title3">{carData.couleur}</span>
              <span className="circle"> </span>
            </div>
            <div className="line flexbox-baseline">
              <div className="title2 c-blue">{company}</div>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">Date mise en circulation</span>
              <span className="vide"></span>
              <span className="title3">
                <b>
                  {new Date(carData.date_circulation).toLocaleDateString(
                    "en-GB"
                  )}
                </b>
              </span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title2">{carData.kilometrage}</span>
              <span className="title2"> Km</span>
              <span className="vide"></span>
              <span className="title3">{carData.recette}</span>
              <span className="title3"> F.CFA</span>
            </div>
          </div>
          <div className="viewIn width35">
            <div className="view-detail">
              <div className="view-detail-bloc">
                <label className="nom">Transport</label>
                <span>{carData.type_transport}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Carte grise</label>
                <span>{carData.carte_grise}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Acquisition</label>
                <span>{carData.type_acquisition}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">N° de châssis</label>
                <span>{carData.numero_chassis}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Heure d'Arrêt</label>
                <span>{carData.heure_arret}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Temps de repos</label>
                <span>{carData.temps_repos}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Contrat N°</label>
                <span>{carData.numContrat}</span>
              </div>
            </div>
          </div>
          <div className="viewInAlertes width15 border-left">
            <div className="line">
              <span className="title3">Nombres d'alertes</span>
              <span className="title1 c-red">0</span>
              <a
                className="title4 c-red"
                data-target="#exampleModalCenter"
                data-toggle="modal"
              >
                Voir toutes les alertes
              </a>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="exampleModalCenterTitle"
          className="modal fade"
          id="exampleModalCenter"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Alertes
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="viewInAlertes-bloc"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabPanelFiche">
          <div id="left" className="left" style={{ width: "25%" }}>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {carData.type_acquisition === "LEASING" ? (
                  <>
                    <a
                      aria-controls="nav-1"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-1"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-1"
                      id="nav-1-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-1" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={driver} alt="" />
                      <span className="title">Conducteur</span>
                    </a>
                    <a
                      aria-controls="nav-2"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-2"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-2"
                      id="nav-2-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-2" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={versement} alt="" />
                      <span className="title">Versements</span>
                    </a>
                    <a
                      aria-controls="nav-3"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-3"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-3"
                      id="nav-3-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-3" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={shopping} alt="" />
                      <span className="title">Dépenses administratives</span>
                    </a>
                    <a
                      aria-controls="nav-4"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-4"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-4"
                      id="nav-4-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-4" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={settings} alt="" />
                      <span className="title">Dépenses Maintenances</span>
                    </a>
                    <a
                      aria-controls="nav-5"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-5"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-5"
                      id="nav-5-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-5" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={calendar} alt="" />
                      <span className="title">Plan d'entretiens</span>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      aria-controls="nav-1"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-1"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-1"
                      id="nav-1-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-1" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={contratIcon} alt="" />
                      <span className="title">Contrat</span>
                    </a>
                    <a
                      aria-controls="nav-2"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-2"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-2"
                      id="nav-2-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-2" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={driver} alt="" />
                      <span className="title">Conducteur</span>
                    </a>
                    <a
                      aria-controls="nav-3"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-3"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-3"
                      id="nav-3-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-3" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={versement} alt="" />
                      <span className="title">Versements</span>
                    </a>
                    <a
                      aria-controls="nav-4"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-4"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-4"
                      id="nav-4-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-4" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={shopping} alt="" />
                      <span className="title">Dépenses administratives</span>
                    </a>
                    <a
                      aria-controls="nav-5"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-5"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-5"
                      id="nav-5-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-5" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={settings} alt="" />
                      <span className="title">Dépenses Maintenances</span>
                    </a>
                    <a
                      aria-controls="nav-6"
                      aria-solution-bloc="true"
                      className={
                        isActive === "#nav-6"
                          ? "nav-item nav-link active"
                          : "nav-item nav-link"
                      }
                      data-toggle="tab"
                      href="#nav-6"
                      id="nav-6-tab"
                      role="tab"
                      aria-selected={isActive === "#nav-6" ? "true" : "false"}
                      onClick={toggleClass}
                    >
                      <img src={calendar} alt="" />
                      <span className="title">Plan d'entretiens</span>
                    </a>
                  </>
                )}
              </div>
            </nav>
          </div>
          <div className="right" id="right" style={{ width: "75%" }}>
            <div className="tab-content" id="nav-tabContent">
              {carData.type_acquisition === "LEASING" ? (
                <>
              <div
                aria-labelledby="nav-1-tab"
                className={
                  isActive === "#nav-1"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-1"
                role="tab"
                aria-selected={isActive === "#nav-1" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Affectations</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black">
                            {driverData.length}
                          </span>
                          <span className="label">Conducteur(s) affectés</span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Nom & Prenoms
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  N° de téléphone
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de recrutement
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Versement
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              {driverData.map((drivers) => (
                                <tr>
                                  <td>
                                    {drivers.nom} {drivers.prenom}
                                  </td>
                                  <td>{drivers.contact_chauffeur}</td>
                                  <td>
                                    {new Date(
                                      drivers.date_recrutement
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                  <td>{carData.recette}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-2-tab"
                className={
                  isActive === "#nav-2"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-2"
                role="tab"
                aria-selected={isActive === "#nav-2" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Versements</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black">0 F.CFA</span>
                          <span className="label">Montant total versé</span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Conducteurs
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de versement
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Semaine
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              <tr>
                                <td>{carData.concessionnaire}</td>
                                <td>{carData.montantTTC_achat}</td>
                                <td>{carData.recette}</td>
                                <td>
                                  {new Date(
                                    carData.date_achat
                                  ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-3-tab"
                className={
                  isActive === "#nav-3"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-3"
                role="tab"
                aria-selected={isActive === "#nav-3" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Dépenses Administrative</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black">0 F.CFA</span>
                          <span className="label">
                            Montant total des dépenses
                          </span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Libellé
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de paiement
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Semaine
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              <tr>
                                <td>{carData.concessionnaire}</td>
                                <td>{carData.montantTTC_achat}</td>
                                <td>{carData.recette}</td>
                                <td>
                                  {new Date(
                                    carData.date_achat
                                  ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-4-tab"
                className={
                  isActive === "#nav-4"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-4"
                role="tab"
                aria-selected={isActive === "#nav-4" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Dépenses Maintenance</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black">0 F.CFA</span>
                          <span className="label">
                            Montant des maintenances
                          </span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Libellé
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de maintenance
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Semaine
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              <tr>
                                <td>{carData.concessionnaire}</td>
                                <td>{carData.montantTTC_achat}</td>
                                <td>{carData.recette}</td>
                                <td>
                                  {new Date(
                                    carData.date_achat
                                  ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-5-tab"
                className={
                  isActive === "#nav-5"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-5"
                role="tab"
                aria-selected={isActive === "#nav-5" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Plan d'entretiens</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black">0 </span>
                          <span className="label">Programme d'entretiens</span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Libellé
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de maintenance
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Semaine
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              <tr>
                                <td>{carData.concessionnaire}</td>
                                <td>{carData.montantTTC_achat}</td>
                                <td>{carData.recette}</td>
                                <td>
                                  {new Date(
                                    carData.date_achat
                                  ).toLocaleDateString("en-GB")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </>
              ) : (
                <>
                  <div
                    aria-labelledby="nav-1-tab"
                    className={
                      isActive === "#nav-1"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-1"
                    role="tab"
                    aria-selected={isActive === "#nav-1" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Contrat(s)</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            >
                              Contrat N° {carData.numContrat}
                            </a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black"></span>
                              <span className="label"></span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            {acquisition === "ACHAT" ? (
                              <div className="ui-table-wrapper ng-star-inserted">
                                <table>
                                  <thead className="ui-table-thead">
                                    <tr className="TRstyleOnglet ng-star-inserted">
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Concessionnaire
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Montant d'Achat
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Garantie
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Date d'achat
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ui-table-tbody">
                                    <tr>
                                      <td>{carContrat.concessionnaire}</td>
                                      <td>{carContrat.montantTTC_achat}</td>
                                      <td>{carContrat.garantie}</td>
                                      <td>
                                        {new Date(
                                          carContrat.date_achat
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : acquisition === "GESTION" ? (
                              <div className="ui-table-wrapper ng-star-inserted">
                                <table>
                                  <thead className="ui-table-thead">
                                    <tr className="TRstyleOnglet ng-star-inserted">
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Proprietaire
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Contrat
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Montant à verser
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Date de début
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Date de fin
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ui-table-tbody">
                                    <tr>
                                      <td>{carContrat.proprietaire}</td>
                                      <td>{carContrat.type_contrat}</td>
                                      <td>{carContrat.montantAVerser}</td>
                                      <td>
                                        {new Date(
                                          carContrat.date_debut_contrat_gestion
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                      <td>
                                        {new Date(
                                          carContrat.date_fin_contrat_gestion
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : acquisition === "LEASING" ? (
                              <div className="ui-table-wrapper ng-star-inserted"></div>
                            ) : (
                              <div className="ui-table-wrapper ng-star-inserted">
                                <table>
                                  <thead className="ui-table-thead">
                                    <tr className="TRstyleOnglet ng-star-inserted">
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Fournisseur
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Contrat
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Coût journalier
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Coût global
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Date debut
                                      </th>
                                      <th className="ui-sortable-column ng-star-inserted">
                                        Date de fin
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ui-table-tbody">
                                    <tr>
                                      <td>{carContrat.fournisseur}</td>
                                      <td>
                                        {carContrat.type_contrat_location}
                                      </td>
                                      <td>{carContrat.location_journaliere}</td>
                                      <td>{carContrat.location_global}</td>
                                      <td>
                                        {new Date(
                                          carContrat.date_debut_contrat_location
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                      <td>
                                        {new Date(
                                          carContrat.date_fin_contrat_location
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="nav-2-tab"
                    className={
                      isActive === "#nav-2"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-2"
                    role="tab"
                    aria-selected={isActive === "#nav-2" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Affectations</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            ></a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black">
                                {driverData.length}
                              </span>
                              <span className="label">
                                Conducteur(s) affectés
                              </span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            <div className="ui-table-wrapper ng-star-inserted">
                              <table>
                                <thead className="ui-table-thead">
                                  <tr className="TRstyleOnglet ng-star-inserted">
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Nom & Prenoms
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      N° de téléphone
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Date de recrutement
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Versement
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ui-table-tbody">
                                  {driverData.map((drivers) => (
                                    <tr>
                                      <td>
                                        {drivers.nom} {drivers.prenom}
                                      </td>
                                      <td>{drivers.contact_chauffeur}</td>
                                      <td>
                                        {new Date(
                                          drivers.date_recrutement
                                        ).toLocaleDateString("en-GB")}
                                      </td>
                                      <td>{carData.recette}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="nav-3-tab"
                    className={
                      isActive === "#nav-3"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-3"
                    role="tab"
                    aria-selected={isActive === "#nav-2" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Versements</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            ></a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black">0 F.CFA</span>
                              <span className="label">Montant total versé</span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            <div className="ui-table-wrapper ng-star-inserted">
                              <table>
                                <thead className="ui-table-thead">
                                  <tr className="TRstyleOnglet ng-star-inserted">
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Conducteurs
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Montant
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Date de versement
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Semaine
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ui-table-tbody">
                                  <tr>
                                    <td>{carData.concessionnaire}</td>
                                    <td>{carData.montantTTC_achat}</td>
                                    <td>{carData.recette}</td>
                                    <td>
                                      {new Date(
                                        carData.date_achat
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="nav-4-tab"
                    className={
                      isActive === "#nav-4"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-4"
                    role="tab"
                    aria-selected={isActive === "#nav-4" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Dépenses Administrative</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            ></a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black">0 F.CFA</span>
                              <span className="label">
                                Montant total des dépenses
                              </span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            <div className="ui-table-wrapper ng-star-inserted">
                              <table>
                                <thead className="ui-table-thead">
                                  <tr className="TRstyleOnglet ng-star-inserted">
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Libellé
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Montant
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Date de paiement
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Semaine
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ui-table-tbody">
                                  <tr>
                                    <td>{carData.concessionnaire}</td>
                                    <td>{carData.montantTTC_achat}</td>
                                    <td>{carData.recette}</td>
                                    <td>
                                      {new Date(
                                        carData.date_achat
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="nav-5-tab"
                    className={
                      isActive === "#nav-5"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-5"
                    role="tab"
                    aria-selected={isActive === "#nav-5" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Dépenses Maintenance</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            ></a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black">0 F.CFA</span>
                              <span className="label">
                                Montant des maintenances
                              </span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            <div className="ui-table-wrapper ng-star-inserted">
                              <table>
                                <thead className="ui-table-thead">
                                  <tr className="TRstyleOnglet ng-star-inserted">
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Libellé
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Montant
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Date de maintenance
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Semaine
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ui-table-tbody">
                                  <tr>
                                    <td>{carData.concessionnaire}</td>
                                    <td>{carData.montantTTC_achat}</td>
                                    <td>{carData.recette}</td>
                                    <td>
                                      {new Date(
                                        carData.date_achat
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    aria-labelledby="nav-6-tab"
                    className={
                      isActive === "#nav-6"
                        ? `tab-pane fade active show`
                        : "tab-pane fade"
                    }
                    id="nav-6"
                    role="tab"
                    aria-selected={isActive === "#nav-6" ? "true" : "false"}
                  >
                    <div id="viewOnglet">
                      <div className="ng-star-inserted">
                        <div id="header-main">
                          <div className="title">
                            <h3>Plan d'entretiens</h3>
                          </div>
                          <div className="links">
                            <a style={{ cursor: "pointer" }}></a>
                          </div>
                        </div>
                        <div className="ui-widget-header ng-star-inserted">
                          <p>
                            <a
                              aria-controls="collapseExample"
                              aria-expanded="false"
                              href="#collapseExample"
                              data-toggle="collapse"
                              className="collapse-filtre collapsed"
                            ></a>
                          </p>
                          <div
                            className="collapse ng-star-inserted"
                            id="collapseExample"
                          ></div>
                          <div id="recap" className="ng-star-inserted">
                            <div className="recap5">
                              <span className="value c-black">0 </span>
                              <span className="label">
                                Programme d'entretiens
                              </span>
                            </div>
                          </div>
                          <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                            <div className="ui-table-wrapper ng-star-inserted">
                              <table>
                                <thead className="ui-table-thead">
                                  <tr className="TRstyleOnglet ng-star-inserted">
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Libellé
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Montant
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Date de maintenance
                                    </th>
                                    <th className="ui-sortable-column ng-star-inserted">
                                      Semaine
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="ui-table-tbody">
                                  <tr>
                                    <td>{carData.concessionnaire}</td>
                                    <td>{carData.montantTTC_achat}</td>
                                    <td>{carData.recette}</td>
                                    <td>
                                      {new Date(
                                        carData.date_achat
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleProfile;
