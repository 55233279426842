import React, { useEffect, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import taxi from "../../../../../../assets/icon40/taxi.svg";
import versement from "../../../../../../assets/icon40/money-bag.svg";
import vehicule from "../../../../../../assets/images/car.jpg";
import settings from "../../../../../../assets/icon40/settings.svg";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../../../../services/api/axios";
import { useAuth } from "../../../../../context/AuthProvider";
import { decrypt } from "../../../../../module/myCrypto";

const ProfileConducteur = () => {
  const { numPermis, companieID } = useParams();
  const [alert, setAlert] = useState(false);
  const [allCashDriver, setAllCachDriver] = useState([]);
  const [driverData, setDriverData] = useState("");
  const [sumVersementDriver, setSumVersementDriver] = useState([]);
  const [getMaintenanceDriver, setMaintenanceDriver] = useState([]);
  const [image, setImage] = useState("");
  const [isActive, setActive] = useState("#nav-1");
  const toggleClass = (e) => {
    setActive(e.currentTarget.hash);
  };

  let options = { year: "numeric", month: "long", day: "numeric" };

  useEffect(() => {
    const getDriverInfo = () => {
      axiosPrivate
        .get(`drivers/getDriver/${numPermis}/${companieID}`)
        .then((res) => {
          setDriverData(res.data);
          //
          setImage(
            `https://apiserver.raymobileci.com/${companieID}/chauffeurs/avatars/${res.data.driverImage}`
          );
        })
        .catch((err) => console.log(err));
    };

    const getAllCashDriver = () => {
      axiosPrivate
        .get(`versements/find-allcash-driver/${numPermis}/${companieID}`)
        .then((res) => {
          setAllCachDriver(res.data);
        })
        .catch((err) => console.log(err));
    };

    const getAllVersementDriver = () => {
      axiosPrivate
        .get(`versements/sum-allcash-driver/${companieID}/${numPermis}`)
        .then((res) => {
          setSumVersementDriver(res.data[0].total_montant);
        })
        .catch((err) => console.log(err));
    };

    const getAllMaintenanceDriver = () => {
      axiosPrivate
        .get(`maintenance/find-intervention-driver/${numPermis}/${companieID}`)
        .then((res) => {
          setMaintenanceDriver(res.data);
        })
        .catch((err) => console.log(err));
    };

    getDriverInfo();
    getAllCashDriver();
    getAllVersementDriver();
    getAllMaintenanceDriver();
  }, []);

  useEffect(() => {
    const checkDate = () => {
      const today = new Date();
      const expirationDate = new Date(driverData.date_expiration_pc);
      const diffTime = Math.abs(expirationDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 7 || today > expirationDate) {
        setAlert(true);
      }
    };

    checkDate();

    // Vérifiez chaque jour
    const intervalId = setInterval(checkDate, 1000 * 60 * 60 * 24);

    // Nettoyez l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const company = decrypt(user.companyName);
  const nombreVersement = sumVersementDriver?.toLocaleString("fr-FR");
  //const nombreVersementAttente = sumVersementAttente?.toLocaleString("fr-FR");

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>
              Profile de {driverData.prenom} {driverData.nom}
            </h2>
          </div>
          <div className="links">
            <a
              href={`/personnels/conducteurs/${companieID}`}
              className="ng-star-inserted"
            >
              <img src={list} />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="view flexbox-center border">
          <div className="viewIn width20">
            <div className="imgVehicule">
              {image ? (
                <img src={image} alt="" />
              ) : (
                <img src={vehicule} alt="" />
              )}
              {/*<img src={taxi} alt="" />*/}
            </div>
          </div>
          <div className="viewIn width30">
            <div className="line">
              <span className="title1">{driverData.immatriculation}</span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">{driverData.marque}</span>
              <span className="circle"> </span>
              <span className="title3">{driverData.modele}</span>
              <span className="circle"> </span>
              <span className="title3">{driverData.couleur}</span>
              <span className="circle"> </span>
            </div>
            <div className="line flexbox-baseline">
              <div className="title2 c-blue">{company}</div>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">Nom & Prenoms</span>
              <span className="vide"></span>
              <span className="title3">
                <b>
                  {driverData.nom} {driverData.prenom}
                </b>
              </span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">véhicule attribué</span>
              <span className="vide"></span>
              <span className="title3">
                <b>{driverData.vehicleID}</b>
              </span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">Permis N°</span>
              <span className="vide"></span>
              <span className="title3">
                <b>{driverData.numPermis}</b>
              </span>
            </div>
            <div className="line flexbox-baseline">
              <span className="title3">Contact</span>
              <span className="vide"></span>
              <span className="title3">
                <b>{driverData.contact_chauffeur}</b>
              </span>
            </div>
          </div>
          <div className="viewIn width35">
            <div className="view-detail">
              <div className="view-detail-bloc">
                <label className="nom">Expire le </label>
                <span>
                  <b>
                    {new Date(driverData.date_expiration_pc).toLocaleDateString(
                      "fr-FR",
                      options
                    )}
                  </b>
                </span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Contact </label>
                <span>{driverData.nom_proche}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Catégorie</label>
                <span>{driverData.categorie_permis}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">N° d'urgence</label>
                <span>{driverData.contact_proche}</span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Recruté le</label>
                <span>
                  <b>
                    {new Date(driverData.date_recrutement).toLocaleDateString(
                      "fr-FR",
                      options
                    )}
                  </b>
                </span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom"></label>
                <span></span>
              </div>
              <div className="view-detail-bloc">
                <label className="nom">Genre</label>
                <span>{driverData.genre}</span>
              </div>
            </div>
          </div>
          {alert === true ? (
            <div className="viewInAlertes width15 border-left">
              <div className="line">
                <span className="title3">Alertes Permis</span>
                <span className="title1 c-red">1</span>
                <a
                  className="title4 c-red"
                  data-target="#exampleModalCenter"
                  data-toggle="modal"
                  href={`/alertes/permis-conduire/${companieID}`}
                >
                  Voir le detail de l'alerte
                </a>
              </div>
            </div>
          ) : (
            <div className="viewInAlertes width15 border-left">
              <div className="line">
                <span className="title3">Alertes Permis</span>
                <span className="title1">0</span>
                <a
                  className="title4"
                  data-target="#exampleModalCenter"
                  data-toggle="modal"
                >
                  Aucune alerte
                </a>
              </div>
            </div>
          )}
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="exampleModalCenterTitle"
          className="modal fade"
          id="exampleModalCenter"
          role="dialog"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Alertes
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="viewInAlertes-bloc"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabPanelFiche">
          <div id="left" className="left" style={{ width: "25%" }}>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a
                  aria-controls="nav-1"
                  aria-solution-bloc="true"
                  className={
                    isActive === "#nav-1"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  data-toggle="tab"
                  href="#nav-1"
                  id="nav-1-tab"
                  role="tab"
                  aria-selected={isActive === "#nav-1" ? "true" : "false"}
                  onClick={toggleClass}
                >
                  <img src={taxi} alt="" />
                  <span className="title">Véhicule</span>
                </a>
                <a
                  aria-controls="nav-2"
                  aria-solution-bloc="true"
                  className={
                    isActive === "#nav-2"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  data-toggle="tab"
                  href="#nav-2"
                  id="nav-2-tab"
                  role="tab"
                  aria-selected={isActive === "#nav-2" ? "true" : "false"}
                  onClick={toggleClass}
                >
                  <img src={versement} alt="" />
                  <span className="title">Versements</span>
                </a>
                <a
                  aria-controls="nav-4"
                  aria-solution-bloc="true"
                  className={
                    isActive === "#nav-4"
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  data-toggle="tab"
                  href="#nav-4"
                  id="nav-4-tab"
                  role="tab"
                  aria-selected={isActive === "#nav-4" ? "true" : "false"}
                  onClick={toggleClass}
                >
                  <img src={settings} alt="" />
                  <span className="title">Dépenses Maintenances</span>
                </a>
              </div>
            </nav>
          </div>
          <div className="right" id="right" style={{ width: "75%" }}>
            <div className="tab-content" id="nav-tabContent">
              <div
                aria-labelledby="nav-1-tab"
                className={
                  isActive === "#nav-1"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-1"
                role="tab"
                aria-selected={isActive === "#nav-1" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Véhicule(s)</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        >
                          Véhicule N° {driverData.vehicleID}
                        </a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap5">
                          <span className="value c-black"></span>
                          <span className="label"></span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Acquisition
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  N° Contrat
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Marque
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Type transport
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de début
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              {driverData && driverData.vehicule ? (
                                <tr>
                                  <td>
                                    {driverData.vehicule.type_acquisition}
                                  </td>
                                  <td>{driverData.vehicule.numContrat}</td>
                                  <td>{driverData.vehicule.marque}</td>
                                  <td>{driverData.vehicule.type_transport}</td>
                                  <td>
                                    {new Date(
                                      driverData.vehicule.date_circulation
                                    ).toLocaleDateString("en-GB")}
                                  </td>
                                </tr>
                              ) : (
                                <p>Chargement...</p>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-2-tab"
                className={
                  isActive === "#nav-2"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-2"
                role="tab"
                aria-selected={isActive === "#nav-2" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Versements</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap4">
                          <span className="value c-black">
                            {allCashDriver.length}
                          </span>
                          <span className="label">Nombre d'enregistrement</span>
                        </div>
                        <div className="recap4">
                          <span className="value c-blue bold">
                            {nombreVersement} F.CFA
                          </span>
                          <span className="label">Montant total versé</span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Véhicule
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de versement
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Utilisateur
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              {allCashDriver.map((versement) => {
                                return (
                                  <tr>
                                    <td>{versement.vehicleID}</td>
                                    <td>{versement.montant} F.CFA</td>
                                    <td>
                                      {new Date(
                                        versement.calendar_event.start
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td>
                                      {versement.user.nom}{" "}
                                      {versement.user.prenom}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                aria-labelledby="nav-4-tab"
                className={
                  isActive === "#nav-4"
                    ? `tab-pane fade active show`
                    : "tab-pane fade"
                }
                id="nav-4"
                role="tab"
                aria-selected={isActive === "#nav-4" ? "true" : "false"}
              >
                <div id="viewOnglet">
                  <div className="ng-star-inserted">
                    <div id="header-main">
                      <div className="title">
                        <h3>Liste Maintenance du {driverData.vehicleID}</h3>
                      </div>
                      <div className="links">
                        <a style={{ cursor: "pointer" }}></a>
                      </div>
                    </div>
                    <div className="ui-widget-header ng-star-inserted">
                      <p>
                        <a
                          aria-controls="collapseExample"
                          aria-expanded="false"
                          href="#collapseExample"
                          data-toggle="collapse"
                          className="collapse-filtre collapsed"
                        ></a>
                      </p>
                      <div
                        className="collapse ng-star-inserted"
                        id="collapseExample"
                      ></div>
                      <div id="recap" className="ng-star-inserted">
                        <div className="recap4">
                          <span className="value c-black">
                            {getMaintenanceDriver.length}
                          </span>
                          <span className="label">Nombre des maintenances</span>
                        </div>
                      </div>
                      <div className="scroll ui-table ui-widget ui-table-hoverable-rows">
                        <div className="ui-table-wrapper ng-star-inserted">
                          <table>
                            <thead className="ui-table-thead">
                              <tr className="TRstyleOnglet ng-star-inserted">
                                <th className="ui-sortable-column ng-star-inserted">
                                  Libellé
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Montant
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Date de maintenance
                                </th>
                                <th className="ui-sortable-column ng-star-inserted">
                                  Garage
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ui-table-tbody">
                              {getMaintenanceDriver.map((result) => {
                                return (
                                  <tr>
                                    <td>
                                      {result.demande_intervention.description}
                                    </td>
                                    <td>{result.montant_total}</td>
                                    <td>
                                      {new Date(
                                        result.date_fin
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td>{result.garage}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileConducteur;
