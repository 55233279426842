import React from "react";

const FormHeader = ({ title, info }) => {
  return (
    <div>
      <h2 className="formTitle">{title}</h2>
      <p className="formInfo">{info}</p>
    </div>
  );
};

export default FormHeader;
