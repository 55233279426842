import React, { useEffect, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import { useAuth } from "../../../../../context/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const TerminerReparation = () => {
  const { companieID, numDemandeIntervention } = useParams();
  const navigate = useNavigate();
  const [demandeIntervention, setDemandeIntervention] = useState([]);
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
  const [heure, setHeure] = useState(new Date());
  const [demandeInterventionList, setDemandeInterventionList] = useState([]);

  useEffect(() => {
    const dateDuJour = new Date().toLocaleDateString();
    setDate(dateDuJour);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setHeure(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);
  const companyName = decrypt(user.companyName);
  const userID = decrypt(user.userID);

  const splitString = companyName.split(" ");

  function generateId(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  let id = generateId(6);
  let dates = moment(new Date()).format("DD-MM-YY"); // La date actuelle

  let nbreInterval = Number(demandeInterventionList) + 1; //

  //console.log(`${splitString[0]}/${dates}/${id}`);

  const [contactData, setContactData] = useState({
    numIntervention: `${splitString[0]}-${dates}-${id}-${nbreInterval}`,
    traveau_realise: "",
    heure_demande: heure.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    montant_main_doeuvre: "",
    montant_piece: "",
    date_debut: "",
    date_fin: "",
  });

  useEffect(() => {
    const getDemandeIntervention = () => {
      axiosPrivate
        .get(
          `maintenance/demande-intervention-id/${numDemandeIntervention}/${companieID}`
        )
        .then((res) => {
          setDemandeIntervention(res.data);
        })
        .catch((err) => console.log(err));
    };

    getDemandeIntervention();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    if (contactData.traveau_realise === "") {
      err.traveau_realise = toast.error(
        "😡 Veuillez entrer les traveaux réalisés !",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.montant_main_doeuvre === "") {
      err.montant_main_doeuvre = toast.error(
        "😡 Veuillez entrer le montant de la main d'oeuvre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.montant_piece === "") {
      err.montant_piece = toast.error(
        "😡 Veuillez entrer le montant de la pièce!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_debut === "") {
      err.date_debut = toast.error("😡 Veuillez entrer la date de début", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_fin === "") {
      err.date_fin = toast.error("😡 Veuillez entrer la date de fin!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      await axiosPrivate
        .post(`maintenance/terminer-intervention/create/${companyId}/${numDemandeIntervention}`, {
          numIntervention: encrypt(contactData.numIntervention),
          garage: encrypt(contactData.garage),
          traveau_realise: contactData.traveau_realise,
          montant_main_doeuvre: encrypt(contactData.montant_main_doeuvre),
          montant_piece: encrypt(contactData.montant_piece),
          date_debut: encrypt(contactData.date_debut),
          date_fin: encrypt(contactData.date_fin),
          companieID: encrypt(companieID),
          userID: encrypt(userID),
          vehicleID: encrypt(demandeIntervention.vehicleID),
          numPermis: encrypt(demandeIntervention.numPermis),
          numDemandeIntervention: encrypt(
            demandeIntervention.numDemandeIntervention
          ),
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/maintenances/intervention-termine/${companieID}`);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  const conducteur =
    demandeIntervention.conducteur?.nom +
    " " +
    demandeIntervention.conducteur?.prenom;

  const handleClickOpen = () => {
    navigate(`/maintenances/demande-intervention/${companieID}`);
  };
  return (
    <div className="ng-star-inserted">
      <div className="silver">
        <div id="header-main">
          <div className="title">
            <h2>Reparation du véhicule {demandeIntervention.vehicleID}</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Liste des interventions"
              className="ng-star-inserted"
            >
              <img src={list} alt="" onClick={handleClickOpen} />
            </a>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="ng-untouched ng-pristine ng-valid"
        >
          <div className="form-l">
            <div className="form-content ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Véhicule *</label>
                <div className="form-group col6 ng-star-inserted">
                  <label className="form">Marque</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.vehicule?.marque}
                    readOnly
                  />
                </div>
                <div className="form-group col6 ng-star-inserted">
                  <label className="form">Modèle</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.vehicule?.modele}
                    readOnly
                  />
                </div>
                <div className="form-group col6 ng-star-inserted">
                  <label className="form">Type transport</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.vehicule?.type_transport}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="form-content ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Conducteurs *</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Conducteur du véhicule</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={conducteur}
                    readOnly
                  />
                </div>
                <div className="form-group col6 ng-star-inserted">
                  <label className="form">Contact du conducteur</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.conducteur?.contact_chauffeur}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Désignation</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Numéro</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numIntervention"
                    value={contactData.numIntervention}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Garage</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="garage"
                    value={contactData.garage}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant pièce</label>
                  <input
                    type="number"
                    min="0"
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    name="montant_piece"
                    value={contactData.montant_piece}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      F.CFA
                    </span>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant main d'oeuvre</label>
                  <input
                    type="number"
                    min="0"
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    name="montant_main_doeuvre"
                    value={contactData.montant_main_doeuvre}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      F.CFA
                    </span>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date début</label>
                  <input
                    type="date"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="date_debut"
                    value={contactData.date_debut}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date fin</label>
                  <input
                    type="date"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="date_fin"
                    value={contactData.date_fin}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group ng-star-inserted">
                  <label className="form">Travaux réalisés *</label>
                  <textarea
                    name="traveau_realise"
                    id=""
                    cols="30"
                    rows="4"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.traveau_realise}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Informations générales</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Catégorie d'entretien</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.categorie}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type intervention</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.type_intervention}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Degré de gravité</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.degre_gravite}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Degré d'urgence</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={demandeIntervention.degre_intervention}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="form-button-98">
              <button className="save" type="submit" disabled="">
                Terminer la reparation
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TerminerReparation;
