import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import add from "../../../../../assets/icon40/ray-add.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconPrint from "../../../../../assets/icons/dropdown-print.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import ChargeModal from "../../components/ChargeModal";
import DeleteChargeModal from "../../components/DeleteChargeModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const CarteGriseListe = () => {
  const flag = useRef(false);
  const navigate = useNavigate();
  const [carteGriseData, setCarteGrise] = useState([]);
  const [sumCarteGrise, setSumCarteGrise] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showDeleteChargeModal, setShowDeleteChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [numCarte, setNumCarte] = useState("");
  const [numVehicle, setNumVehicle] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getAllCarteGrise = () => {
      axiosPrivate
        .get(`charges/getCarteGrise/${companyId}`)
        .then((res) => {
          setCarteGrise(res.data);
          //
        })
        .catch((err) => console.log(err));
    };

    const getAllSUMCarteGrise = () => {
      axiosPrivate
        .get(`charges/getSumCarteGrise/${companyId}`)
        .then((res) => {
          setSumCarteGrise(res.data[0]);
        })
        .catch((err) => console.log(err));
    };

    const intervalId = setInterval(() => {
      getAllCarteGrise();
      getAllSUMCarteGrise();
    }, 1000); // Mettez à jour toutes les 5 secondes

    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((numVehicle || numCarte || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get("charges/search-carte-grise", {
          params: { numVehicle, numCarte, companyId },
        });
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numVehicle, numCarte, companyId]);

  const nombreFormate = sumCarteGrise.total_montant?.toLocaleString("fr-FR");

  const handleClickOpen = () => {
    navigate(`/charges/carte-grise/ajouter/${companyId}`);
  };

  function handleOnClickModal(
    attachement,
    vehicleID,
    cartegrise,
    title,
    companie
  ) {
    setShowDetailModal(true);
    setModal([attachement, vehicleID, cartegrise, title, companie]);
  }

  function handleOnClickDeleteChargeModal(
    numPolice,
    vehicleID,
    companie,
    content
  ) {
    setShowDeleteChargeModal(true);
    setChargeModal([numPolice, vehicleID, companie, content]);
  }

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");
  
    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;
  
    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;
  
    doc.addImage(image, "JPEG", 10, 5, 60, 45);
  
    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);
  
    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des Cartes grises";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre
  
    const headers = [
      "",
      "Véhicules",
      "N° carte grise",
      "Montant",
      "Date de début",
      "Date de fin",
      "Commentaire",
    ];
  
    const tableData = isSearching ? searchResults.map((result) => {
  
      return [
        "",
        result["vehicleID"],
        result["numCarteGrise"],
        result["frais"] +  "F.CFA",
        moment(result["date_debut"]).format("DD/MM/YYYY"),
        moment(result["date_fin"]).format("DD/MM/YYYY"),
        result["commentaire"],
      ];
    }) : carteGriseData.map((cartes) => {
  
      return [
        "",
        cartes["vehicleID"],
        cartes["numCarteGrise"],
        cartes["frais"] +  "F.CFA",
        moment(cartes["date_debut"]).format("DD/MM/YYYY"),
        moment(cartes["date_fin"]).format("DD/MM/YYYY"),
        cartes["commentaire"],
      ];
    });
  
    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });
  
    doc.save("liste-carte-grise.pdf");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Cartes grises</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter un conducteur"
              className="ng-star-inserted"
            >
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a style={{ cursor: "pointer"}} title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={handlePDFClick} />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="numVehicle"
                  value={numVehicle}
                  onChange={(e) => setNumVehicle(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par Carte grise</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par carte grise"
                  name="numCarte"
                  value={numCarte}
                  onChange={(e) => setNumCarte(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-blue">{carteGriseData.length}</span>
            <span className="label">Nombre d'enregistrements</span>
          </div>
          <div className="recap5">
            <span className="value c-red"></span>
            <span className="label"></span>
          </div>
          <div className="recap5">
            <span className="value c-red">{nombreFormate}</span>

            <span className="label">Montant total en F.CFA</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Numéro carte grise
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Montant
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date de début
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date de fin
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Commentaire
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? carteGriseData.map((cartes) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                          key={cartes.numCarteGrise}
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [cartes.numCarteGrise]:
                                          !subMenuOpen[cartes.numCarteGrise],
                                      })
                                    }
                                    open={!!subMenuOpen[cartes.numCarteGrise]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[cartes.numCarteGrise] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/charges/carte-grise/edit/${cartes.numCarteGrise}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteChargeModal(
                                          cartes.numCarteGrise,
                                          cartes.vehicleID,
                                          cartes.companieID,
                                          "La carte grise"
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconPrint} />
                                      Imprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {cartes.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {cartes.numCarteGrise}
                          </td>
                          <td className="ng-star-inserted">
                            {cartes.frais} F.CFA
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(cartes.date_debut).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(cartes.date_fin).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {cartes.commentaire}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    cartes.numCarteGrise,
                                    cartes.attachement,
                                    `La carte grise`,
                                    cartes.vehicleID,
                                    companyId
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                          key={result.numCarteGrise}
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.numCarteGrise]:
                                          !subMenuOpen[result.numCarteGrise],
                                      })
                                    }
                                    open={!!subMenuOpen[result.numCarteGrise]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.numCarteGrise] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/charges/carte-grise/edit/${result.numCarteGrise}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickDeleteChargeModal(
                                          result.numCarteGrise,
                                          result.vehicleID,
                                          result.companieID,
                                          "La carte grise"
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconPrint} />
                                      Imprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {result.numCarteGrise}
                          </td>
                          <td className="ng-star-inserted">
                            {result.frais} F.CFA
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_debut).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_fin).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {result.commentaire}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    result.numCarteGrise,
                                    result.attachement,
                                    `La carte grise`,
                                    result.vehicleID,
                                    companyId
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
        <div
          aria-hidden="true"
          aria-labelledby="mySmallModalLabel"
          className="modal fade"
          id="popupDelete"
          role="dialog"
          tabIndex="-1"
        ></div>
      </div>
      {showDetailModal && setModal != null && (
        <ChargeModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showDeleteChargeModal && setChargeModal != null && (
        <DeleteChargeModal
          closeDeleteChargeModal={setShowDeleteChargeModal}
          data={chargeModal}
        />
      )}
    </>
  );
};

export default CarteGriseListe;
