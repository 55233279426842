import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/list.svg";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";

const AjouterUtilisateur = () => {
  const fileInput = useRef();
  const flag = useRef(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [photoUser, setPhotoUser] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [contactData, setContactData] = useState({
    nom: "",
    prenom: "",
    contact: "",
    role: "",
    isBlocked: false,
    email: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    if (flag.current === false) {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", photoUser);
      const res = await axiosPrivate.post("upload-user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.nom === "") {
      err.nom = toast.error("😡 Veuillez entrer le nom de l'utilisateur!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.prenom === "") {
      err.prenom = toast.error(
        "😡 Veuillez choisir le prenom de l'utilisateur!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (phoneUser === "") {
      err.phoneUser = toast.error(
        "😡 Veuillez entrer le numéro de téléphone!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.role === "") {
      err.role = toast.error("😡 Veuillez choisir le rôle de l'utilisateur!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.email === "") {
      err.email = toast.error("😡 Veuillez entrer l'email de l'utilisateur", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      let imgUrl = "";

      if (photoUser) imgUrl = await upload();

      try {
        axiosPrivate
          .post(
            "auth/create",
            {
              companieID: user.companyID,
              userID: user.userID,
              nom: encrypt(contactData.nom),
              prenom: encrypt(contactData.prenom),
              contact: encrypt(phoneUser),
              role: encrypt(contactData.role),
              email: encrypt(contactData.email),
              userImage: imgUrl,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 200) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/personnels/utilisateur/${companyId}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    }
  };

  return (
    <div className="ng-star-inserted">
      <div id="header-main">
        <div className="title">
          <h2>Personnels</h2>
        </div>
        <div className="links">
          <a
            href={`/personnels/utilisateur/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="ng-untouched ng-pristine ng-valid"
      >
        <div className="form-l">
          <div className="form-content ng-star-inserted">
            <div className="form-block">
              <label className="title-content">
                Information de l'utilisateur
              </label>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Nom</label>
                <input
                  type="text"
                  placeholder="Nom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="nom"
                  value={contactData.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Prenoms</label>
                <input
                  type="text"
                  placeholder="Prenom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="prenom"
                  value={contactData.prenom}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Numéro de téléphone</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero du conducteur"
                  value={phoneUser}
                  onChange={(phoneUser) => setPhoneUser(phoneUser)}
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={10}
                  rules={{ required: true }}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Rôle</label>
                <div className="large">
                  <div className="box-select">
                    <select
                      name="role"
                      value={contactData.role}
                      onChange={handleChange}
                    >
                      <option>Attribuez un rôle</option>
                      <option value="admin">Administrateur</option>
                      <option value="comptable">Comptable</option>
                      <option value="user">Utilisateur</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-content">
            <div className="form-block ng-star-inserted">
              <label className="title-content">Informations de connexion</label>
              <div className="form-group">
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Email utilisateur</label>
                  <input
                    type="email"
                    placeholder="Veuillez entrer une adresse email"
                    className="ng-pristine ng-invalid ng-touched"
                    name="email"
                    value={contactData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Photo de l'utilisateur</label>
                  <input
                    type="file"
                    name="Photo"
                    id="Photo"
                    onChange={(event) => setPhotoUser(event.target.files[0])}
                    accept="image/*"
                    multiple={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AjouterUtilisateur;
