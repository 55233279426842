import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import encaissement from "../../../../../assets/icon40/encaissement.svg";
import decaissement from "../../../../../assets/icon40/decaissement.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconPrint from "../../../../../assets/icons/dropdown-print.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";

const ListeDecaissement = () => {
  const navigate = useNavigate();
  const [allCaisseData, setAllCaisseData] = useState([]);
  const [sumAllEncaissement, setSumAllEncaissement] = useState([]);
  const [sumAllDecaissement, setSumAllDecaissement] = useState([]);
  const [sumAllDecaissementJustifie, setSumAllDecaissementJustifie] = useState([]);
  const [sumAllDecaissementNonJustifie, setSumAllDecaissementNonJustifie] = useState([]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getAllCaisse = () => {
      axiosPrivate
        .get(`finances/decaissement/${companyId}`)
        .then((response) => {
          setAllCaisseData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllEncaissement = () => {
      axiosPrivate
        .get(`finances/sum-encaissement/${companyId}`)
        .then((response) => {
          setSumAllEncaissement(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllDecaissement = () => {
      axiosPrivate
        .get(`finances/sum-decaissement/${companyId}`)
        .then((response) => {
          setSumAllDecaissement(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllDecaissementJustifie = () => {
      axiosPrivate
        .get(`finances/sum-decaissement-justifie/${companyId}`)
        .then((response) => {
          console.log(response.data);
          setSumAllDecaissementJustifie(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getAllDecaissementNonJustifie = () => {
      axiosPrivate
        .get(`finances/sum-decaissement-non-justifie/${companyId}`)
        .then((response) => {
          setSumAllDecaissementNonJustifie(response.data[0].total_montant);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getAllCaisse();
    getAllEncaissement();
    getAllDecaissement();
    getAllDecaissementJustifie();
    getAllDecaissementNonJustifie();
  }, []);

  const handleClickDecaissementOpen = () => {
    navigate("/finances/caisses/decaissement/ajouter");
  };

  function handleOnClickChargeModal(
    numPolice,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([numPolice, attachement, vehicleID, cartegrise, companie]);
  }

  const soldeCaisse =  Number(sumAllEncaissement) - Number(sumAllDecaissement);

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title">
            <h2>Decaissement</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Ajouter décaissement"
              className="ng-star-inserted"
            >
              <img
                src={decaissement}
                alt=""
                onClick={handleClickDecaissementOpen}
              />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={"handlePDFClick"} />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded="false"
              className="collapse-filtre collapsed"
              data-toggle="collapse"
              href="#collapseExample"
            >
              Filtrage
            </a>
          </p>
          <div className="collapse" id="collapseExample">
            <div className="bloc-filtre5">
              <span>Date Début</span>
            </div>
          </div>
        </div>
          <div id="recap">
            <div className="recap5">
              <span className="value c-blue">{allCaisseData.length}</span>
              <span className="label">Nombre d'enregistrements</span>
            </div>
            <div className="recap5">
              <span className="value c-blue bold">
                {sumAllDecaissementJustifie} F.CFA
              </span>
              <span className="label">Decaissement justifié</span>
            </div>
            <div className="recap5">
              <span className="value c-red bold">
                {sumAllDecaissementNonJustifie} F.CFA
              </span>
              <span className="label">Decaissement non justifié</span>
            </div>
            <div className="recap5">
              <span className="value c-violet bold">
                {sumAllDecaissement} F.CFA
              </span>
              <span className="label">Total decaissement</span>
            </div>
            <div className="recap5">
              <span className="value c-orange bold">{soldeCaisse} F.CFA</span>
              <span className="label">Solde en caisse</span>
            </div>
          </div>
        <div className="scroll with-hover with-selected ui-table ui-widget">
          <table>
            <thead className="ui-table-thead">
              <tr className="ng-star-inserted">
                <th style={{ width: "50px", padding: "0.3rem 0.3rem" }}></th>
                <th className="ng-star-inserted">Opération</th>
                <th className="ng-star-inserted">Référence</th>
                <th className="ng-star-inserted">Type</th>
                <th className="ng-star-inserted">Date</th>
                <th className="ng-star-inserted">Montant</th>
                <th className="ng-star-inserted">Bénéficiaire</th>
                <th className="ng-star-inserted">Motif</th>
                <th className="ng-star-inserted">Intitulé</th>
                <th className="ng-star-inserted">Justificatif</th>
              </tr>
            </thead>
            <tbody className="ui-table-tbody">
              {allCaisseData.map((caisses) => (
                <tr className="ui-selectable-row ng-star-inserted" tabIndex="0">
                  <td
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  >
                    <div className={subMenuOpen ? "dropdown show" : "dropdown"}>
                      <button
                        aria-expanded="true"
                        aria-haspopup="true"
                        className="btn btn-default btn-xs dropdown-toggle-no-caret"
                        data-toggle="dropdown"
                        id="dropdownMenu1"
                        type="button"
                      >
                        <span className="glyphicon glyphicon-option-vertical">
                          <i
                            className="fa-solid fa-ellipsis-vertical"
                            onClick={() =>
                              setSubMenuOpen({
                                ...subMenuOpen,
                                [caisses.reference_decaissement]:
                                  !subMenuOpen[caisses.reference_decaissement],
                              })
                            }
                            open={!!subMenuOpen[caisses.reference_decaissement]}
                          ></i>
                        </span>
                      </button>
                      {subMenuOpen[caisses.reference_decaissement] ? (
                        <ul
                          aria-labelledby="dropdownMenu1"
                          className={
                            subMenuOpen
                              ? "dropdown-menu dropdown-menu-right show"
                              : "dropdown-menu dropdown-menu-right"
                          }
                          x-placement="top-end"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(0px, 38px, 0px)",
                          }}
                        >
                          <li className="c-blue ng-star-inserted">
                            <a
                              href={`/finances/caisses/decaissement/edit/${caisses.reference_decaissement}`}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={iconEdit} />
                              Modifier
                            </a>
                          </li>
                          <li
                            className="divider ng-star-inserted"
                            role="separator"
                          ></li>
                          <li className="c-red ng-star-inserted">
                            <a
                              data-target="#popupDelete"
                              data-toggle="modal"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleOnClickChargeModal(
                                  caisses.reference_decaissement,
                                  companyId,
                                  "L'Assurance"
                                )
                              }
                            >
                              <img src={iconDelete} />
                              Supprimer
                            </a>
                          </li>
                          <li
                            className="divider ng-star-inserted"
                            role="separator"
                          ></li>
                          <li className="c-blue ng-star-inserted">
                            <a
                              data-toggle="modal"
                              style={{ cursor: "pointer" }}
                            >
                              <img src={iconPrint} />
                              Imprimer
                            </a>
                          </li>
                        </ul>
                      ) : (
                        <ul></ul>
                      )}
                    </div>
                  </td>
                  <td className="ng-star-inserted">
                    {caisses.nature_decaissement}
                  </td>
                  <td className="ng-star-inserted">
                    {caisses.reference_decaissement}
                  </td>
                  <td className="ng-star-inserted">
                    {caisses.type_decaissement}
                  </td>
                  <td className="ng-star-inserted">
                    {new Date(caisses.date_decaissement).toLocaleDateString(
                      "en-GB"
                    )}
                  </td>
                  <td className="ng-star-inserted">
                    {caisses.montant_decaissement?.toLocaleString("fr-FR")}
                  </td>
                  <td className="ng-star-inserted">{caisses.beneficiaire}</td>
                  <td className="ng-star-inserted">{caisses.motif_decaissement}</td>
                  <td className="ng-star-inserted">{caisses.intitule_decaissement}</td>
                  {caisses.justification === "Decaissement justifie" && (
                    <td className="ng-star-inserted">
                      <span className="ng-star-inserted">
                        <a
                          data-target="#popupAttachement"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          /*onClick={() =>
                              handleOnClickModal(
                                caisses.numPolice,
                                caisses.attachement,
                                `L'Assurance`,
                                caisses.vehicleID,
                                companyId
                              )
                            }*/
                        >
                          <img src={attachement} />
                        </a>
                      </span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ListeDecaissement;
