import React, { useEffect, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import { useAuth } from "../../../../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const EffectueDemande = () => {
  const navigate = useNavigate();
  const [optionList, setOptionList] = useState([]);
  const [formError, setFormError] = useState({});
  const [chauffeurs, setChauffeurs] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [heure, setHeure] = useState(new Date());
  const [demandeInterventionList, setDemandeInterventionList] = useState([]);

  useEffect(() => {
    const dateDuJour = new Date().toLocaleDateString();
    setDate(dateDuJour);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setHeure(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);
  const companyName = decrypt(user.companyName);

  const splitString = companyName.split(" ");

  function generateId(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  let id = generateId(6);
  let dates = moment(new Date()).format("DD-MM-YY"); // La date actuelle

  let nbreInterval = Number(demandeInterventionList) + 1; //

  const [contactData, setContactData] = useState({
    numDemandeIntervention: `${splitString[0]}-${dates}-${id}-${nbreInterval}`,
    numPermis: "",
    date_demande: date,
    heure_demande: heure.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    }),
    kilometrage_demande: "",
    statut_vehicule: "Non-Immobilise",
    description: "",
    commentaire: "",
    categorie: "",
    type_intervention: "",
    degre_gravite: "",
    degre_intervention: "",
    statut_demande: "",
    companieID: "",
    userID: "",
    vehicleID: "",
  });

  useEffect(() => {
    const getAllVehicle = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    const getAllDemande = () => {
      axiosPrivate
        .get(`maintenance/demande-intervention/${companyId}`)
        .then((res) => {
          setDemandeInterventionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllDemande();
    getAllVehicle();
  }, []);

  useEffect(() => {
    const fetchChauffeurs = async () => {
      const response = await axiosPrivate.get(
        `drivers/getDriversByCar/${contactData.vehicleID}/${companyId}`
      );
      setChauffeurs(response.data);
      console.log(response.data);
    };

    if (contactData.vehicleID) {
      fetchChauffeurs();
    }
  }, [contactData.vehicleID]);

  useEffect(() => {
    if (chauffeurs.length > 0) {
      setContactData((prevData) => ({
        ...prevData,
        numPermis: chauffeurs[0].numPermis,
      }));
    }
  }, [chauffeurs]);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    if (contactData.vehicleID === "") {
      err.vehicleID = toast.error("😡 Veuillez choisir le véhicule!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.kilometrage_demande === "") {
      err.kilometrage_demande = toast.error(
        "😡 Veuillez entrer le kilometrage actuel!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.statut_vehicule === "") {
      err.statut_vehicule = toast.error(
        "😡 Veuillez préciser si le véhicule sera immobilisé ou pas!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.description === "") {
      err.description = toast.error(
        "😡 Veuillez entrer la description de la panne",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.categorie === "") {
      err.categorie = toast.error(
        "😡 Veuillez choisir une catégorie de maintenance!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.type_intervention === "") {
      err.type_intervention = toast.error(
        "😡 Veuillez preciser le type d'intervention",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.degre_gravite === "") {
      err.degre_gravite = toast.error(
        "😡 Veuillez preciser la degré de graviter",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.degre_intervention === "") {
      err.degre_intervention = toast.error(
        "😡 Veuillez preciser le degré d'intervention!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      await axiosPrivate
        .post("maintenance/demande-intervention/create", {
          numDemandeIntervention: encrypt(contactData.numDemandeIntervention),
          date_demande: encrypt(contactData.date_demande),
          heure_demande: encrypt(contactData.heure_demande),
          kilometrage_demande: encrypt(contactData.kilometrage_demande),
          statut_vehicule: encrypt(contactData.statut_vehicule),
          description: contactData.description,
          commentaire: contactData.commentaire,
          categorie: encrypt(contactData.categorie),
          type_intervention: encrypt(contactData.type_intervention),
          degre_gravite: encrypt(contactData.degre_gravite),
          degre_intervention: encrypt(contactData.degre_intervention),
          statut_demande: encrypt("En Attente"),
          companieID: user.companyID,
          userID: user.userID,
          vehicleID: encrypt(contactData.vehicleID),
          numPermis: encrypt(contactData.numPermis),
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/maintenances/demande-intervention/${companyId}`);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  const handleClickOpen = () => {
    navigate(`/maintenances/demande-intervention/${companyId}`);
  };

  return (
    <div className="ng-star-inserted">
      <div className="silver">
        <div id="header-main">
          <div className="title">
            <h2>Demande d'intervention</h2>
          </div>
          <div className="links">
            <a
              href=""
              title="Liste des interventions"
              className="ng-star-inserted"
            >
              <img src={list} alt="" onClick={handleClickOpen} />
            </a>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="ng-untouched ng-pristine ng-valid"
        >
          <div className="form-l">
            <div className="form-content ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Véhicule *</label>
                <div className="form-group col9 ng-star-inserted">
                  <label className="form">Sélectionner un véhicule</label>
                  <div className="large">
                    <div className="box-select-large">
                      <select
                        name="vehicleID"
                        value={contactData.vehicleID}
                        onChange={handleChange}
                      >
                        <option>Sélectionner un véhicule</option>
                        {optionList.map((item) => (
                          <option
                            key={item.immatriculation}
                            value={item.immatriculation}
                          >
                            {item.immatriculation}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-content ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Conducteurs *</label>
                <div className="form-group col9 ng-star-inserted">
                  <label className="form">Conducteur du véhicule</label>
                  <div className="large">
                    <div className="box-select-large">
                      <select
                        name="numPermis"
                        value={
                          contactData.numPermis ||
                          (chauffeurs[0] && chauffeurs[0].numPermis)
                        }
                        onChange={handleChange}
                      >
                        {chauffeurs.map((chauffeur) => (
                          <option
                            key={chauffeur.numPermis}
                            value={chauffeur.numPermis}
                          >
                            {chauffeur.nom} {chauffeur.prenom}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Désignation</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Numéro</label>
                  <input
                    type="text"
                    className="large ng-untouched ng-pristine ng-valid"
                    name="numDemandeIntervention"
                    value={contactData.numDemandeIntervention}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date</label>
                  <div className="date">
                    <input
                      type="date"
                      className="large addExpress ng-untouched ng-pristine date-mask"
                      name="date_demande"
                      value={contactData.date_demande}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="time">
                    <input
                      type="text"
                      className="ui-inputtext ui-corner-all ui-state-default ui-widget"
                      name="heure_demande"
                      value={contactData.heure_demande}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Kilométrage</label>
                  <input
                    type="number"
                    min="0"
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    name="kilometrage_demande"
                    value={contactData.kilometrage_demande}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      KM
                    </span>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Immobiliser le véhicule?</label>
                  <div className="radio-container">
                    <input
                      checked={contactData.statut_vehicule === "Immobilise"}
                      type="radio"
                      name="statut_vehicule"
                      value="Immobilise"
                      id="male"
                      onChange={handleChange}
                    />
                    <label for="male">Oui</label>
                    <input
                      checked={contactData.statut_vehicule === "Non-Immobilise"}
                      type="radio"
                      name="statut_vehicule"
                      value="Non-Immobilise"
                      id="female"
                      onChange={handleChange}
                    />
                    <label for="female">Non</label>
                  </div>
                </div>
                <div className="form-group ng-star-inserted">
                  <label className="form">Description *</label>
                  <textarea
                    name="description"
                    id=""
                    cols="30"
                    rows="4"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group ng-star-inserted">
                  <label className="form">Commentaire</label>
                  <textarea
                    name="commentaire"
                    id=""
                    cols="30"
                    rows="4"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.commentaire}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Informations générales</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Catégorie d'entretien</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="categorie"
                        value={contactData.categorie}
                        onChange={handleChange}
                      >
                        <option>Catégorie d'entretien</option>
                        <option value="Entretien courant">
                          Entretien courant
                        </option>
                        <option value="Entretien de la climatisation">
                          {" "}
                          Entretien de la climatisation
                        </option>
                        <option value="Redressage de châssis">
                          {" "}
                          Redressage de châssis
                        </option>
                        <option value="Remorquage">Remorquage</option>
                        <option value="Réparations mécaniques">
                          {" "}
                          Réparations mécaniques
                        </option>
                        <option value="Réparations de carrosserie">
                          Réparations de carrosserie
                        </option>
                        <option value="Soudage et coupage">
                          {" "}
                          Soudage et coupage
                        </option>
                        <option value="Travaux de peinture">
                          {" "}
                          Travaux de peinture
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type intervention</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="type_intervention"
                        value={contactData.type_intervention}
                        onChange={handleChange}
                      >
                        <option>Type intervention</option>
                        <option value="Administrative">Administrative</option>
                        <option value="Curative"> Curative</option>
                        <option value="Dépannage"> Dépannage</option>
                        <option value="Préventive">Préventive</option>
                        <option value="Sinistre"> Sinistre</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Degré de gravité</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="degre_gravite"
                        value={contactData.degre_gravite}
                        onChange={handleChange}
                      >
                        <option>Degré de gravité</option>
                        <option value="Gravité légère">Gravité légère</option>
                        <option value="Gravité moyenne">
                          {" "}
                          Gravité moyenne
                        </option>
                        <option value="Haute gravité"> Haute gravité</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Degré d'urgence</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="degre_intervention"
                        value={contactData.degre_intervention}
                        onChange={handleChange}
                      >
                        <option>Degré intervention</option>
                        <option value="Interventions non urgentes">
                          Interventions non urgentes
                        </option>
                        <option value="Maintenance régulière">
                          {" "}
                          Maintenance régulière
                        </option>
                        <option value="Urgence à court terme">
                          {" "}
                          Urgence à court terme
                        </option>
                        <option value="Urgence immédiate">
                          {" "}
                          Urgence immédiate
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-button-98">
              <button className="save" type="submit" disabled="">
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EffectueDemande;
