import React, { useEffect, useState } from "react";
import axiosPrivate from "../../../services/api/axios";
import "../../../../assets/css/home.css";

const PictureModal = ({ closeContratModal, data }) => {
  return (
    <div
          aria-labelledby="mySmallModalLabel"
          className="modal fade show"
          id="popupPicture"
          tabIndex="-1"
          aria-hidden="true"
          style={{ display: "block" }}
          aria-modal="true"
          role="dialog"
        >
          <form action="" className="ng-untouched ng-pristine ng-valid">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Photo
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                  >
                    <span aria-hidden="true" onClick={() => closeContratModal(false)}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <input accept=".jpg,.png,.jpeg" type="file" />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="save">Enregistrer</button>
                </div>
              </div>
            </div>
          </form>
        </div>
  )
}

export default PictureModal