import React, { useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import list from "../../../../../../assets/icon40/list.svg";
import { useAuth } from "../../../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { useNavigate, useParams } from "react-router-dom";

const ModifierAcces = () => {
  const { userID, companieID } = useParams();
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  const [isActive, setActive] = useState("#nav-mot-de-passe-tab");
  const [type, settype] = useState(false);
  const [types, setTypes] = useState(false);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [passwordConfirm, setpasswordConfirm] = useState("passwordConfirm");
  const [warnpassword, setwarnpassword] = useState(false);
  const toggleClass = (e) => {
    setActive(e.currentTarget.hash);
  };
  const [contactData, setContactData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const {
    authState: { user },
  } = useAuth();


  const userEmail = decrypt(user.email);

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };

  const Eyes = () => {
    if (passwordConfirm === "confirmPassword") {
      setpasswordConfirm("text");
      seteye(false);
      setTypes(true);
    } else {
      setpasswordConfirm("confirmPassword");
      seteye(true);
      setTypes(false);
    }
  };

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    // Au moins un caractère spécial
    let specialCharCheck = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // Au moins un chiffre
    let numberCheck = /\d/;
    // Au moins une lettre majuscule
    let upperCaseCheck = /[A-Z]/;
    if (contactData.newPassword === "" || contactData.confirmPassword === "") {
      err.newPassword =
        "Mot de passe et confirmation du mot de passe requis!";
    }
    if (contactData.newPassword !== contactData.confirmPassword) {
      err.newPassword = toast.error("😡 Les mots de passe sont différents!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.newPassword.length < 10) {
      err.newPassword = toast.error(
        "😡 Le mot de passe doit compter plus de 10 caractères!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!specialCharCheck.test(contactData.newPassword)) {
      err.newPassword = toast.error(
        "😡 Le mot de passe doit contenir au moins un caratère spécial!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!numberCheck.test(contactData.newPassword)) {
      err.newPassword = toast.error(
        "😡 Le mot de passe doit contenir au moins un chiffre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!upperCaseCheck.test(contactData.newPassword)) {
      err.newPassword = toast.error(
        "😡 Le mot de passe doit contenir au moins une lettre majuscule!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      try {
        await axiosPrivate
          .put(
            `auth/password-reset/${user.passwordResetToken}`,
            {
              email: encrypt(userEmail),
              password: encrypt(contactData.newPassword),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            if (response.data.code === 101 || response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/login`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    } else {
    }
  };

  const resetUserPassword = async () => {
    if (userEmail !== "") {
      await axiosPrivate.post(`auth/forgot-password/${userEmail}`).then((response) => {
        if (response) {
          return toast.success(`👏 ${response.data}`, { position: toast.POSITION.TOP_RIGHT, transition: Flip });
        } else {
          return toast.error(`😡 Erreur lors de la réinitialisation du mot de passe`, {
            position: toast.POSITION.TOP_RIGHT, transition: Bounce, style: {
              bodyClassName: "grow-font-size",
              progressClassName: "fancy-progress-bar",
            }
          });
        }
      });
      
    } else {
      return toast.error(`😡 Veuillez entrer votre adresse email`, {
        position: toast.POSITION.TOP_RIGHT, transition: Bounce, style: {
          bodyClassName: "grow-font-size",
          progressClassName: "fancy-progress-bar",
        }
      });
    }
  }

  return (
    <>
      <div className="ng-star-inserted">
        <div className="silver">
          <div id="header-main">
            <div className="title">
              <h2>Editer mon profil</h2>
            </div>
            <div className="links">
              <a href={`/`} className="ng-star-inserted">
                <img src={list} />
              </a>
            </div>
          </div>
          <div className="compte">
            <div className="left">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    aria-controls="nav-mot-de-passe-tab"
                    aria-selected={
                      isActive === "#nav-mot-de-passe-tab" ? "true" : "false"
                    }
                    onClick={toggleClass}
                    className={
                      isActive === "#nav-mot-de-passe-tab"
                        ? "nav-item nav-link active"
                        : "nav-item nav-link"
                    }
                    data-toggle="tab"
                    href="#nav-mot-de-passe-tab"
                    id="nav-mot-de-passe-tab"
                    role="tab"
                  >
                    Modifier le mot de passe
                  </a>
                </div>
              </nav>
            </div>
            <div className="right">
              <div id="nav-tabContent" className="tab-content">
                <div
                  aria-labelledby="nav-mot-de-passe-tab"
                  className={
                    isActive === "#nav-mot-de-passe-tab"
                      ? `tab-pane fade active show`
                      : "tab-pane fade"
                  }
                  id="nav-mot-de-passe"
                  role="tabpanel"
                >
                  <div className="form-content">
                    <div className="group">
                      <label className="title">Email</label>
                      <label className="value">{userEmail}</label>
                    </div>
                    <form
                      onSubmit={submitPassword}
                      className="ng-untouched ng-pristine ng-invalid"
                    >
                      <div className="form-content">
                        <div className="form-group">
                          <label className="form">Nouveau mot de passe</label>
                          <input
                            type={password}
                            className={` ${warnpassword ? "warning" : ""} ${
                              type ? "type_password" : ""
                            }`}
                            placeholder="Veuillez entrer le mot de passe"
                            value={contactData.newPassword}
                            onChange={handleChange}
                            name="newPassword"
                          />
                          <i
                            onClick={Eye}
                            className={`fa ${
                              eye ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                            }`}
                          ></i>
                        </div>
                        <div className="form-group">
                          <label className="form">
                            Confirmer votre mot de passe
                          </label>
                          <input
                            type={password}
                            className={` ${warnpassword ? "warning" : ""} ${
                              types ? "type_password" : ""
                            }`}
                            value={contactData.confirmPassword}
                            onChange={handleChange}
                            name="confirmPassword"
                            placeholder="Veuillez confirmer le mot de passe"
                          />
                          <i
                            onClick={Eyes}
                            className={`fa ${
                              eye ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"
                            }`}
                          ></i>
                        </div>
                      </div>
                      <div className="form-button">
                      {user.passwordResetToken ? (
                        <button className="save" type="submit">
                          Modifier le mot de passe
                        </button>
                        ) : (
                        <button className="save" onClick={resetUserPassword}>
                          Réinitialiser le mot de passe
                        </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModifierAcces;
