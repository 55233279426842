import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import edit from "../../../../../assets/icon40/edit.svg";
import available from "../../../../../assets/icons/available.svg";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";

const CompanieProfile = () => {
  const inputRef = useRef(null);
  const { companyID, userID } = useParams();
  const [companieData, setCompaniedata] = useState("");
  const [userData, setUserdata] = useState("");
  const [imageCompanie, setImageCompanie] = useState("");

  let options = { year: "numeric", month: "long", day: "numeric" };

  const handleImageClick = () => {
    // Trigger the file input
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // Handle the selected file
    console.log(selectedFile);
  };

  const {
    authState: { user },
  } = useAuth();


  const role = decrypt(user.role);

  useEffect(() => {
    const getCompanieByID = () => {
      axiosPrivate
        .get(`companies/find-companie/${companyID}`)
        .then((res) => {
          setCompaniedata(res.data);
          setImageCompanie(
            `https://apiserver.raymobileci.com/administrations/logo-company/${res.data.logo}`
          );
        })
        .catch((err) => console.log(err));
    };

    const getUserByID = () => {
      axiosPrivate
        .get(`users/find-user/${userID}`)
        .then((res) => {
          setUserdata(res.data);
        })
        .catch((err) => console.log(err));
    };

    getCompanieByID();
    getUserByID();
  }, []);

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title">
            <h2>Profile de mon entreprise</h2>
          </div>
          <div className="links">
            {role === "admin" ? (
              <a
                href={`/companie/editer/${companyID}`}
                className="ng-star-inserted"
                style={{ cursor: "pointer" }}
                title="Modifier le profile"
              >
                <img src={edit} alt="" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="view">
          <div className="view-detail-25">
            <label>
              {companieData.logo ? (
                <img
                  src={imageCompanie}
                  alt="Upload Icon"
                  //onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src={available}
                  alt="Upload Icon"
                  //onClick={handleImageClick}
                  style={{ cursor: "pointer" }}
                />
              )}
            </label>
            {role === "admin" ? (
              <input
                type="file"
                id="fileInput"
                ref={inputRef}
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the input visually
                accept=".jpg, .jpeg, .png"
              />
            ) : null}
          </div>
          <div className="view-detail w50">
            <label className="title">Informations générales</label>
            <div className="view-detail-bloc">
              <label>Nom</label>
              <span
                className="c-blue2"
                style={{
                  fontWeight: "bold",
                }}
              >
                {companieData.nom}
              </span>
            </div>
            <div className="view-detail-bloc">
              <label>Adresse</label>
              <span className="c-black">{companieData.adresse}</span>
            </div>
            <div className="view-detail-bloc">
              <label>Email</label>
              <span className="c-black">{companieData.email}</span>
            </div>
            <div className="view-detail-bloc">
              <label>Téléphone</label>
              <span
                className="c-blue2"
                style={{
                  fontWeight: "bold",
                }}
              >
                {companieData.contact}
              </span>
            </div>
            <div className="view-detail-bloc">
              <label>Type de Compte</label>
              <span className="c-black">{companieData.typeCompte}</span>
            </div>
            <div className="view-detail-bloc">
              <label>Statut du Compte</label>
              <span className="c-black">{companieData.status}</span>
            </div>
            <div className="view-detail-bloc">
              <label>Compte valide jusqu'au</label>
              <span className="c-black">
                {new Date(companieData.expirationDate).toLocaleDateString(
                  "fr-FR",
                  options
                )}
              </span>
            </div>
            {companieData.isBlocked === "Actif" ? (
              <div className="view-detail-bloc">
                <label>Situation du Compte</label>
                <span
                  className="c-black"
                  style={{
                    width: "70px",
                    minWidth: "100px",
                    color: "#fff",
                    padding: "5px 6px",
                    backgroundColor: "#026602",
                    fontSize: "11px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  Compte Actif
                </span>
              </div>
            ) : (
              <div className="view-detail-bloc">
                <label>Situation du Compte</label>
                <span
                  className="c-black"
                  style={{
                    width: "70px",
                    minWidth: "100px",
                    color: "#fff",
                    padding: "5px 6px",
                    backgroundColor: "#da3738",
                    fontSize: "11px",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  Compte suspendu
                </span>
              </div>
            )}
          </div>
          <div className="view-detail w25">
            <label className="title">Informations administratives</label>
            <div className="view-detail-bloc-large">
              <label>Registre de commerce</label>
              <span className="c-black">{companieData.registre_commerce}</span>
            </div>
            <div className="view-detail-bloc-large">
              <label>N° Compte contribuable</label>
              <span className="c-black">
                {companieData.compte_contribuable}
              </span>
            </div>
            <div className="view-detail-bloc-large">
              <label>Forme juridique</label>
              <span className="c-black">{companieData.forme_juridique}</span>
            </div>
            <div className="view-detail-bloc-large">
              <label>Numéro de IDU</label>
              <span className="c-black">{companieData.numero_idu}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanieProfile;
