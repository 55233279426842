import React, { useEffect, useRef, useState } from "react";
import axiosPrivate from "../../../services/api/axios";
import "../../../../assets/css/home.css";
import { Viewer } from "@react-pdf-viewer/core";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { openPlugin } from "@react-pdf-viewer/open";
import fr_FR from "@react-pdf-viewer/locales/lib/fr_FR.json";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/open/lib/styles/index.css";

function ChargeModal({ closeContratModal, data }) {

  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const openPluginInstance = openPlugin();
  const getFilePluginInstance = getFilePlugin();

  return (
    <div
      aria-labelledby="mySmallModalLabel"
      className="modal fade show"
      id="popupAttachement"
      tabIndex="-1"
      style={{ display: "block" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true" onClick={() => closeContratModal(false)}>
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
            <div id="viewOnglet">
              <div className="ng-star-inserted">
                <div id="header-main">
                  <div className="title ng-star-inserted">
                    <h3>
                      {data[2]} N° {data[0]} du {data[3]}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="ng-star-inserted">
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "550px",
                  }}
                >
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    {data[2] === "L'Assurance" && (
                      <Viewer
                        fileUrl={`https://apiserver.raymobileci.com/${data[4]}/charges/assurances/${data[1]}`}
                        plugins={[
                          defaultLayoutPluginInstance,
                          getFilePluginInstance,
                          openPluginInstance,
                        ]}
                        localization={fr_FR}
                      />
                    )}
                    {data[2] === "La carte grise" && (
                      <Viewer
                        fileUrl={`https://apiserver.raymobileci.com/${data[4]}/charges/carte-grise/${data[1]}`}
                        plugins={[
                          defaultLayoutPluginInstance,
                          getFilePluginInstance,
                          openPluginInstance,
                        ]}
                        localization={fr_FR}
                      />
                    )}
                    {data[2] === "La vignette" && (
                      <Viewer
                        fileUrl={`https://apiserver.raymobileci.com/${data[4]}/charges/vignette/${data[1]}`}
                        plugins={[
                          defaultLayoutPluginInstance,
                          getFilePluginInstance,
                          openPluginInstance,
                        ]}
                        localization={fr_FR}
                      />
                    )}
                    {data[2] === "La visite technique" && (
                      <Viewer
                        fileUrl={`https://apiserver.raymobileci.com/${data[4]}/charges/visite-technique/${data[1]}`}
                        plugins={[
                          defaultLayoutPluginInstance,
                          getFilePluginInstance,
                          openPluginInstance,
                        ]}
                        localization={fr_FR}
                      />
                    )}
                  </Worker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargeModal;
