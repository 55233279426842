import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconView from "../../../../../assets/icons/dropdown-view.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import ContratModal from "../../components/ContratModal";
import DeleteModal from "../../components/DeleteModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

const ref = React.createRef();

const ListeVehicule = () => {
  const [carDatas, setCardata] = useState([]);
  const downloadLink = useRef(null);
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [vehicleNum, setVehicleNum] = useState("");
  const [typeTransport, setTypeTransport] = useState("");
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    if (!showDeleteModal && deleteModal != null) {
      window.location.reload();
    }
  }, [showDeleteModal, deleteModal]);

  const handleClickOpen = () => {
    navigate("/flottes/vehicules/ajouter");
  };

  function handleOnClickModal(vehicule, contrat) {
    setShowDetailModal(true);
    setModal([vehicule, contrat]);
  }

  function handleOnClickDeleteModal(vehiculeID, numContrat, companie, content) {
    setShowDeleteModal(true);
    setDeleteModal([vehiculeID, numContrat, companie, content]);
  }

  useEffect(() => {
    const getAllVehicules = async () => {
      await axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setCardata(res.data);
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicules();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (vehicleNum || typeTransport || companyId) {
        setIsSearching(true);
        const response = await axiosPrivate.get("vehicles/search-vehicle", {
          params: { vehicleNum, typeTransport, companyId },
        });
        setSearchResults(response.data);
      } else if (!vehicleNum && !typeTransport && !companyId) {
        setIsSearching(false);
        setSearchResults([]); // Vide les résultats de la recherche
      }
    };

    fetchData();
  }, [vehicleNum, typeTransport, companyId]);

  const handleCSVClick = async () => {
    const headers = [
      "",
      "Immatriculation",
      "N° Contrat",
      "Acquisition",
      "Marque",
      "N° chassis",
      "Type",
      "Recette",
      "Conducteurs",
      "Date debut",
    ];
    const tableData = carDatas.map((carData) => [
      "",
      carData["immatriculation"],
      carData["numContrat"],
      carData["type_acquisition"],
      carData["marque"],
      carData["numero_chassis"],
      carData["type_transport"],
      carData["recette"],
      carData["conducteurs"]
        ? carData["conducteurs"].map(
            (conducteur) => `${conducteur.nom} ${conducteur.prenom}`
          )
        : "Pas de conducteur",
      moment(carData["date_circulation"]).format("DD/MM/YYYY"),
    ]);
    tableData.unshift(headers);

    // Convertir chaque sous-tableau en une chaîne de caractères, séparée par des virgules
    const csvData = tableData.map((row) => row.join(","));

    // Convertir le tableau de chaînes de caractères en une seule chaîne de caractères, avec chaque élément séparé par un saut de ligne
    const csvString = csvData.join("\n");

    if (downloadLink.current) {
      downloadLink.current.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csvString
      )}`;
      downloadLink.current.download = "liste-vehicules.csv";
      downloadLink.current.click();
    }
  };

  const handlePDFClick = async () => {
    // Créez une instance de jsPDF en format paysage
    const doc = new jsPDF("landscape");

    const companieID = decrypt(user.companyID);
    const companieName = decrypt(user.companyName);
    const companieLogo = user.companyLogo;

    //const image = `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`;
    const image = companieLogo
      ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
      : defaultLogo;

    doc.addImage(image, "JPEG", 10, 5, 60, 45);

    doc.setFont(undefined, "bold");
    doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
    doc.text(companieName, 210, 25);

    // Ajoutez un titre
    doc.setFont("helvetica"); // Définissez la police
    doc.setFontSize(18); // Définissez la taille de la police
    doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
    const title = "Liste des véhicules";
    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre

    const headers = [
      "",
      "Immatriculation",
      "N° Contrat",
      "Acquisition",
      "Marque",
      "N° chassis",
      "Type",
      "Recette",
      "Conducteurs",
      "Date debut",
    ];

    const tableData = isSearching ? searchResults.map((result) => {
  
      return [
        "",
        result["immatriculation"],
        result["numContrat"],
        result["type_acquisition"],
        result["marque"],
        result["numero_chassis"],
        result["type_transport"],
        result["recette"],
        result["conducteurs"]
          ? result["conducteurs"]
              .map((conducteur) => `${conducteur.nom} ${conducteur.prenom}`)
              .join("; ")
          : "Pas de conducteur",
        moment(result["date_circulation"]).format("DD/MM/YYYY"),
      ];
    }) : carDatas.map((carData) => {
  
      return [
        "",
        carData["immatriculation"],
        carData["numContrat"],
        carData["type_acquisition"],
        carData["marque"],
        carData["numero_chassis"],
        carData["type_transport"],
        carData["recette"],
        carData["conducteurs"]
          ? carData["conducteurs"]
              .map((conducteur) => `${conducteur.nom} ${conducteur.prenom}`)
              .join("; ")
          : "Pas de conducteur",
        moment(carData["date_circulation"]).format("DD/MM/YYYY"),
      ];
    });

    //tableData.unshift(headers);

    doc.autoTable({
      startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
      head: [headers],
      body: tableData,
    });

    doc.save("liste-vehicules.pdf");

    // Convertir chaque sous-tableau en une chaîne de caractères, séparée par des virgules
    const csvData = tableData.map((row) => row.join(","));

    // Convertir le tableau de chaînes de caractères en une seule chaîne de caractères, avec chaque élément séparé par un saut de ligne
    const csvString = csvData.join("\n");
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Mon Parc</h2>
          </div>
          <div className="links">
            <a href="" title="Ajouter véhicule" className="ng-star-inserted">
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={handlePDFClick} />
            </a>
            <a
              ref={downloadLink}
              title="Exporter en csv"
              className="ng-star-inserted"
            >
              <img
                src={excel}
                alt=""
                onClick={handleCSVClick}
                ref={downloadLink}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Immatriculation</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="vehicleNum"
                  value={vehicleNum}
                  onChange={(e) => setVehicleNum(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par type transport</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par permis de conduire"
                  name="typeTransport"
                  value={typeTransport}
                  onChange={(e) => setTypeTransport(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-black">{carDatas.length}</span>
            <span className="label">Nombre de véhicules</span>
          </div>
        </div>
        {isSearching ? (
          <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
            <div className="ui-table-wrapper ng-star-inserted">
              <table>
                <thead className="ui-table-thead">
                  <tr className="ng-star-inserted">
                    <th
                      style={{ width: "40px", padding: ".3rem .3rem" }}
                      className="ng-star-inserted"
                    ></th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Immatriculation
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      N° Contrat
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Acquisition
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Marque
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Type
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Recette
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Conducteurs
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Date debut
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Statut
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Utilisateur
                    </th>
                  </tr>
                </thead>
                <tbody className="ui-table-tbody">
                  {searchResults.map((result) => (
                    <tr
                      className="ui-selectable-row ng-star-inserted"
                      tabIndex="0"
                    >
                      <td
                        style={{ width: "40px", padding: ".3rem .3rem" }}
                        className="ng-star-inserted"
                      >
                        <div
                          className={subMenuOpen ? "dropdown show" : "dropdown"}
                        >
                          <button
                            aria-expanded="true"
                            aria-haspopup="true"
                            className="btn btn-default btn-xs dropdown-toggle-no-caret"
                            data-toggle="dropdown"
                            id="dropdownMenu1"
                            type="button"
                          >
                            <span className="glyphicon glyphicon-option-vertical">
                              <i
                                className="fa-solid fa-ellipsis-vertical"
                                onClick={() =>
                                  setSubMenuOpen({
                                    ...subMenuOpen,
                                    [result.immatriculation]:
                                      !subMenuOpen[result.immatriculation],
                                  })
                                }
                                open={!!subMenuOpen[result.immatriculation]}
                              ></i>
                            </span>
                          </button>
                          {subMenuOpen[result.immatriculation] ? (
                            <ul
                              aria-labelledby="dropdownMenu1"
                              className={
                                subMenuOpen
                                  ? "dropdown-menu dropdown-menu-right show"
                                  : "dropdown-menu dropdown-menu-right"
                              }
                              x-placement="top-end"
                              style={{
                                position: "absolute",
                                willChange: "transform",
                                top: "0px",
                                left: "0px",
                                transform: "translate3d(0px, 38px, 0px)",
                              }}
                            >
                              <li className="c-blue ng-star-inserted">
                                <a
                                  href={`/flottes/vehicules/profile/${result.immatriculation}/${result.type_acquisition}/${result.numContrat}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={iconView} />
                                  Consulter
                                </a>
                              </li>
                              <li
                                className="divider ng-star-inserted"
                                role="separator"
                              ></li>
                              <li className="c-blue ng-star-inserted">
                                <a
                                  href={`/flottes/vehicules/edit/${result.immatriculation}/${result.type_acquisition}/${result.numContrat}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={iconEdit} />
                                  Modifier
                                </a>
                              </li>
                              <li
                                className="divider ng-star-inserted"
                                role="separator"
                              ></li>
                              <li className="c-red ng-star-inserted">
                                <a
                                  data-target="#popupDelete"
                                  data-toggle="modal"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleOnClickDeleteModal(
                                      result.immatriculation,
                                      result.numContrat,
                                      companyId,
                                      "le véhicule"
                                    )
                                  }
                                >
                                  <img src={iconDelete} />
                                  Supprimer
                                </a>
                              </li>
                            </ul>
                          ) : (
                            <ul></ul>
                          )}
                        </div>
                      </td>

                      <td className="ng-star-inserted">
                        {result.immatriculation}
                      </td>
                      <td className="ng-star-inserted">
                        {result.numContrat}
                      </td>
                      <td className="ng-star-inserted">
                        {result.type_acquisition}
                      </td>
                      <td className="ng-star-inserted">{result.marque}</td>
                      <td className="ng-star-inserted">
                        {result.type_transport}
                      </td>
                      <td className="ng-star-inserted">{result.recette}</td>
                      <td className="ng-star-inserted">
                        {result.conducteurs
                          ? result.conducteurs.map((conducteur) => (
                              <tr>
                                <td
                                  style={{
                                    border: "none",
                                    alignItems: "center",
                                  }}
                                >
                                  {conducteur.nom} {conducteur.prenom}
                                </td>
                              </tr>
                            ))
                          : "Pas de conducteur"}
                      </td>
                      <td className="ng-star-inserted">
                        {new Date(result.date_circulation).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      {result.status_vehicle === "Actif" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          {result.status_vehicle}
                        </td>
                      )}
                      {result.status_vehicle === "En Attente" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          {result.status_vehicle}
                        </td>
                      )}
                      {result.status_vehicle === "En Maintenance" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {result.status_vehicle}
                        </td>
                      )}
                      {result.status_vehicle === "Immobilisé" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {result.status_vehicle}
                        </td>
                      )}
                      {result.status_vehicle === "accidenté" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {result.status_vehicle}
                        </td>
                      )}
                      <td className="ng-star-inserted">
                        {result.user.prenom.split(" ").pop()}{" "}
                        {result.user.nom}
                      </td>
                      <td className="ng-star-inserted">
                        <span className="ng-star-inserted">
                          <a
                            data-target="#popupAttachement"
                            data-toggle="modal"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOnClickModal(
                                result.immatriculation,
                                result.numContrat,
                                result.type_acquisition
                              )
                            }
                          >
                            <img src={attachement} />
                          </a>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
            <div className="ui-table-wrapper ng-star-inserted">
              <table>
                <thead className="ui-table-thead">
                  <tr className="ng-star-inserted">
                    <th
                      style={{ width: "40px", padding: ".3rem .3rem" }}
                      className="ng-star-inserted"
                    ></th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Immatriculation
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      N° Contrat
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Acquisition
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Marque
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Type
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Recette
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Conducteurs
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Date debut
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Statut
                    </th>
                    <th className="ui-sortable-column ng-star-inserted">
                      Utilisateurs
                    </th>
                  </tr>
                </thead>
                <tbody className="ui-table-tbody">
                  {carDatas.map((vehicles) => (
                    <tr
                      className="ui-selectable-row ng-star-inserted"
                      tabIndex="0"
                    >
                      <td
                        style={{ width: "40px", padding: ".3rem .3rem" }}
                        className="ng-star-inserted"
                      >
                        <div
                          className={subMenuOpen ? "dropdown show" : "dropdown"}
                        >
                          <button
                            aria-expanded="true"
                            aria-haspopup="true"
                            className="btn btn-default btn-xs dropdown-toggle-no-caret"
                            data-toggle="dropdown"
                            id="dropdownMenu1"
                            type="button"
                          >
                            <span className="glyphicon glyphicon-option-vertical">
                              <i
                                className="fa-solid fa-ellipsis-vertical"
                                onClick={() =>
                                  setSubMenuOpen({
                                    ...subMenuOpen,
                                    [vehicles.immatriculation]:
                                      !subMenuOpen[vehicles.immatriculation],
                                  })
                                }
                                open={!!subMenuOpen[vehicles.immatriculation]}
                              ></i>
                            </span>
                          </button>
                          {subMenuOpen[vehicles.immatriculation] ? (
                            <ul
                              aria-labelledby="dropdownMenu1"
                              className={
                                subMenuOpen
                                  ? "dropdown-menu dropdown-menu-right show"
                                  : "dropdown-menu dropdown-menu-right"
                              }
                              x-placement="top-end"
                              style={{
                                position: "absolute",
                                willChange: "transform",
                                top: "0px",
                                left: "0px",
                                transform: "translate3d(0px, 38px, 0px)",
                              }}
                            >
                              <li className="c-blue ng-star-inserted">
                                <a
                                  href={`/flottes/vehicules/profile/${vehicles.immatriculation}/${vehicles.type_acquisition}/${vehicles.numContrat}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={iconView} />
                                  Consulter
                                </a>
                              </li>
                              <li
                                className="divider ng-star-inserted"
                                role="separator"
                              ></li>
                              <li className="c-blue ng-star-inserted">
                                <a
                                  href={`/flottes/vehicules/edit/${vehicles.immatriculation}/${vehicles.type_acquisition}/${vehicles.numContrat}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={iconEdit} />
                                  Modifier
                                </a>
                              </li>
                              <li
                                className="divider ng-star-inserted"
                                role="separator"
                              ></li>
                              <li className="c-red ng-star-inserted">
                                <a
                                  data-target="#popupDelete"
                                  data-toggle="modal"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleOnClickDeleteModal(
                                      vehicles.immatriculation,
                                      vehicles.numContrat,
                                      companyId,
                                      "le véhicule"
                                    )
                                  }
                                >
                                  <img src={iconDelete} />
                                  Supprimer
                                </a>
                              </li>
                            </ul>
                          ) : (
                            <ul></ul>
                          )}
                        </div>
                      </td>

                      <td className="ng-star-inserted">
                        {vehicles.immatriculation}
                      </td>
                      <td className="ng-star-inserted">
                        {vehicles.numContrat}
                      </td>
                      <td className="ng-star-inserted">
                        {vehicles.type_acquisition}
                      </td>
                      <td className="ng-star-inserted">{vehicles.marque}</td>
                      <td className="ng-star-inserted">
                        {vehicles.type_transport}
                      </td>
                      <td className="ng-star-inserted">{vehicles.recette}</td>
                      <td className="ng-star-inserted">
                        {vehicles.conducteurs
                          ? vehicles.conducteurs.map((conducteur) => (
                              <tr>
                                <td
                                  style={{
                                    border: "none",
                                    alignItems: "center",
                                  }}
                                >
                                  {conducteur.nom} {conducteur.prenom}
                                </td>
                              </tr>
                            ))
                          : "Pas de conducteur"}
                      </td>
                      <td className="ng-star-inserted">
                        {new Date(vehicles.date_circulation).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      {vehicles.status_vehicle === "Actif" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "green", fontWeight: "bold" }}
                        >
                          {vehicles.status_vehicle}
                        </td>
                      )}
                      {vehicles.status_vehicle === "En Attente" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          {vehicles.status_vehicle}
                        </td>
                      )}
                      {vehicles.status_vehicle === "En Maintenance" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {vehicles.status_vehicle}
                        </td>
                      )}
                      {vehicles.status_vehicle === "Immobilisé" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {vehicles.status_vehicle}
                        </td>
                      )}
                      {vehicles.status_vehicle === "accidenté" && (
                        <td
                          className="ng-star-inserted"
                          style={{ color: "red", fontWeight: "bold" }}
                        >
                          {vehicles.status_vehicle}
                        </td>
                      )}
                      <td className="ng-star-inserted">
                        {vehicles.user.prenom.split(" ").pop()}{" "}
                        {vehicles.user.nom}
                      </td>
                      <td className="ng-star-inserted">
                        <span className="ng-star-inserted">
                          <a
                            data-target="#popupAttachement"
                            data-toggle="modal"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleOnClickModal(
                                vehicles.immatriculation,
                                vehicles.numContrat,
                                vehicles.type_acquisition
                              )
                            }
                          >
                            <img src={attachement} />
                          </a>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
              <a
                className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
                tabIndex="-1"
              >
                <span className="ui-paginator-icon pi pi-step-backward"></span>
              </a>
              <a
                className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
                tabIndex="-1"
              >
                <span className="ui-paginator-icon pi pi-caret-left"></span>
              </a>
              <span className="ui-paginator-pages">
                <a
                  className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                  tabIndex="0"
                >
                  1
                </a>
              </span>
              <a
                className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
                tabIndex="-1"
              >
                <span className="ui-paginator-icon pi pi-caret-right"></span>
              </a>
              <a
                className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
                tabIndex="-1"
              >
                <span className="ui-paginator-icon pi pi-step-forward"></span>
              </a>
            </div>
            <div
              className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
              style={{ display: "none" }}
            ></div>
          </div>
        )}
      </div>
      {showDetailModal && setModal != null && (
        <ContratModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showDeleteModal && setDeleteModal != null && (
        <DeleteModal closeDeleteModal={setShowDeleteModal} data={deleteModal} />
      )}
    </>
  );
};

export default ListeVehicule;
