import React, { useContext, useEffect, useRef, useState } from "react";
import { toast, Flip, Bounce } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import "../../../../assets/css/registration/style.css";
import axiosPrivate from "../../../services/api/axios";
import { encrypt } from "../../../module/myCrypto";
import AuthContext, { useAuth } from "../../../context/AuthProvider";
import Logo from "../../../../assets/images/raymobileci.avif";

const LoginPage = () => {
  const mainRef = useRef();
  const [isSignUp, setIsSignUp] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [formError, setFormError] = useState({});
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { step, setStep, setCompanie, companie } = useContext(AuthContext);
  const [contactCompany, setContactCompany] = useState();
  const [logoCompanie, setLogoCompanie] = useState("");
  const [contact, setContact] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const [formData, setFormData] = useState({
    nomCompany: "",
    emailCompany: "",
    adresseCompany: "",
    typeCompte: "",
    registre_commerce: "",
    compte_contribuable: "",
    forme_juridique: "",
    numero_idu: "",
    email: "",
    password: "",
  });
  const [formDatas, setFormDatas] = useState({
    nom: "",
    prenom: "",
    photo: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const inputs = mainRef.current.querySelectorAll(".input-field");
    const toggle_btn = mainRef.current.querySelectorAll(".toggle");
    const bullets = mainRef.current.querySelectorAll(".bullets span");
    const images = mainRef.current.querySelectorAll(".image");

    inputs.forEach((inp) => {
      inp.addEventListener("focus", () => {
        inp.classList.add("active");
      });
      inp.addEventListener("blur", () => {
        if (inp.value !== "") return;
        inp.classList.remove("active");
      });
    });

    function moveSlider() {
      let index = this.dataset.value;

      let currentImage = mainRef.current.querySelector(`.img-${index}`);
      images.forEach((img) => img.classList.remove("show"));
      currentImage.classList.add("show");

      const textSlider = mainRef.current.querySelector(".text-group");
      textSlider.style.transform = `translateY(${-(index - 1) * 2.2}rem)`;

      bullets.forEach((bull) => bull.classList.remove("active"));
      this.classList.add("active");
    }

    bullets.forEach((bullet) => {
      bullet.addEventListener("click", moveSlider);
    });
  }, []);

  const { setAuthState } = useAuth();

  {
    /*====================== Login ====================*/
  }
  const handleLoginChange = (event) => {
    setLoginData(() => ({
      ...loginData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateLoginForm = () => {
    let err = {};
    if (loginData.email === "") {
      err.email = "Veuillez entrer un Email!";
    } else {
      let regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(loginData.email)) {
        err.email = "Email invalide!";
      }
    }

    if (loginData.password === "") {
      err.password = "Mot de passe et confirmation du mot de passe requis!";
    } else {
      let regexPass =
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
      if (loginData.password.length < 8) {
        err.password = "Le mot de passe doit compter plus de 8 caractères!";
      } else {
        if (!regexPass.test(loginData.password)) {
          err.password =
            "Le mot de passe doit contenir au moins un chiffre, une lettre Majuscule et un caratère spécial";
        }
      }
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateLoginForm();

    var emailHash = encrypt(loginData.email);
    var passwordHash = encrypt(loginData.password);

    if (isValid) {
      try {
        await axiosPrivate
          .post("auth/login", {
            email: emailHash,
            password: passwordHash,
          })
          .then((response) => {
            if (response.data.code === 101)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 104)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 105)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 106)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              localStorage.setItem("token", response.data.token);
              setAuthState((prevState) => ({
                ...prevState,
                user: response.data.users,
                loggedIn: true,
                autoLogout: false,
              }));
              navigate(`/`, {
                replace: true,
              });
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
            }
          });
      } catch (error) {
        toast.error(error.response.data);
        console.log(error);
      }
    } else {
      return toast.error(`Email ou mot de passe invalide ⛔️❗️`, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
        style: {
          bodyClassName: "grow-font-size",
          progressClassName: "fancy-progress-bar",
        },
      });
    }
  };

  const resetUserPassword = async () => {
    if (formData.email !== "") {
      await axiosPrivate
        .post(`auth/forgot-password/${formData.email}`)
        .then((response) => {
          if (response) {
            return toast.success(`👏 ${response.data}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else {
            return toast.error(
              `😡 Erreur lors de la réinitialisation du mot de passe`,
              {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              }
            );
          }
        });
    } else {
      return toast.error(`😡 Veuillez entrer votre adresse email`, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
        style: {
          bodyClassName: "grow-font-size",
          progressClassName: "fancy-progress-bar",
        },
      });
    }
  };

  {
    /*====================== Register companie ====================*/
  }
  const handleCompanyChange = (event) => {
    // console.log(event);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateCompanyForm = () => {
    let err = {};
    if (formData.nomCompany === "") {
      err.nomCompany = toast.error("😡 Veuillez entrer un nom de compagnie!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.emailCompany === "") {
      err.emailCompany = toast.error("😡 Veuillez entrer un Email!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(formData.emailCompany)) {
        err.emailCompany = toast.error("😡 Email invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (contactCompany === "") {
      err.contactCompany = toast.error(
        "😡 Veuillez entrer un contact de la compagnie!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    } else {
      let regexPhone = /^\+?[1-9][0-9]{12,14}$/;
      if (!regexPhone.test(contactCompany)) {
        err.contactCompany = toast.error("😡 Numéro de téléphone invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (formData.adresseCompany === "") {
      err.adresseCompany = toast.error(
        "😡 Veuillez entrer la situation géographique!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (formData.typeCompte === "") {
      err.typeCompte = toast.error("😡 Veuillez entrer le type de compte!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", logoCompanie);
      const res = await axiosPrivate.post("upload-logo-company", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const submitCompanyHandler = async (e) => {
    e.preventDefault();
    let isValid = validateCompanyForm();

    let imgUrl = "";

    if (logoCompanie) imgUrl = await upload();

    if (isValid) {
      axiosPrivate
        .post("companies/create", {
          nom: encrypt(formData.nomCompany),
          email: encrypt(formData.emailCompany),
          contact: encrypt(contactCompany),
          adresse: formData.adresseCompany,
          typeCompte: encrypt(formData.typeCompte),
          logo: imgUrl,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 103)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setStep(step + 1);
            setIsAdmin(true);
            setCompanie(response.data.company);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
    }
  };

  {
    /*====================== Register Admin ====================*/
  }
  const uploadUser = async () => {
    try {
      const formData = new FormData();
      formData.append("file", userPhoto);
      const res = await axiosPrivate.post("upload-user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserChange = (event) => {
    // console.log(event);
    setFormDatas(() => ({
      ...formDatas,
      [event.target.name]: event.target.value,
    }));
  };

  const validateUserForm = () => {
    let err = {};
    // Au moins un caractère spécial
    let specialCharCheck = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // Au moins un chiffre
    let numberCheck = /\d/;
    // Au moins une lettre majuscule
    let upperCaseCheck = /[A-Z]/;

    if (formDatas.nom === "") {
      err.nom = toast.error("😡 Veuillez entrer un nom du gérant!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formDatas.prenom === "") {
      err.prenom = toast.error("😡 Veuillez entrer le prenom du gérant", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(formDatas.email)) {
        err.email = toast.error("😡 Email invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (contact === "") {
      err.contact = toast.error("😡 Veuillez entrer un contact du gérant!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regexPhone = /^\+?[1-9][0-9]{12,14}$/;
      if (!regexPhone.test(contact)) {
        err.contact = toast.error("😡 Numéro de téléphone invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (formDatas.password === "" || formDatas.confirm_password === "") {
      err.password = "Mot de passe et confirmation du mot de passe requis!";
    }
    if (formDatas.password !== formDatas.confirm_password) {
      err.password = toast.error("😡 Les mots de passe sont différents!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formDatas.password.length < 10) {
      err.password = toast.error(
        "😡 Le mot de passe doit compter plus de 10 caractères!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!specialCharCheck.test(formDatas.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un caratère spécial!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!numberCheck.test(formDatas.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un chiffre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!upperCaseCheck.test(formDatas.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins une lettre majuscule!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  //console.log(companieID)
  const submitUserHandler = async (e) => {
    e.preventDefault();
    let isValid = validateUserForm();

    let imgUrl = "";

    if (userPhoto) imgUrl = await uploadUser();

    if (isValid) {
      axiosPrivate
        .post("auth/register", {
          nom: encrypt(formDatas.nom),
          prenom: encrypt(formDatas.prenom),
          email: encrypt(formDatas.email),
          contact: encrypt(contact),
          password: encrypt(formDatas.password),
          companie: encrypt(companie),
          photo: imgUrl,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 103)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setIsSignUp(!isSignUp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };

  return (
    <main ref={mainRef} className={isSignUp ? "sign-up-mode" : ""}>
      <div className="box">
        <div className="inner-box">
          <div className={isSignUp ? "forms-wrap" : "sign-up-mode forms-wrap"}>
            <form
              encType="multipart/form-data"
              onSubmit={handleLoginSubmit}
              className="sign-in-form"
              style={{
                opacity: isSignUp ? 0 : 1,
                pointerEvents: isSignUp ? "none" : "all",
              }}
            >
              <div className="logo">
                <img src={Logo} alt="easyclass" />
                <h4>RAYMOBILE-CI</h4>
              </div>

              <div className="heading">
                <h2>CONNEXION</h2>
                <h6>Vous n'avez pas de compte? </h6>
                <a href="#" className="toggle" onClick={toggleForm}>
                  {isSignUp ? "Connectez-vous" : "Inscrivez-vous"}
                </a>
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="email"
                    className="input-field"
                    value={loginData.email}
                    onChange={handleLoginChange}
                    name="email"
                    required
                  />
                  <label>Nom utilisateur</label>
                </div>

                <div className="input-wrap">
                  <input
                    className="input-field"
                    type={showPassword ? "text" : "password"}
                    value={loginData.password}
                    onChange={handleLoginChange}
                    name="password"
                  />
                  <label>Mot de passe</label>
                  <span className="password-wraps" onClick={toggleShowPassword}>
                    <i
                      className={`fa ${
                        showPassword
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }`}
                    ></i>
                  </span>
                </div>

                <input
                  type="submit"
                  value="Se Connecter"
                  className="sign-btn"
                />

                <p className="text">
                  <a href="#" onClick={resetUserPassword}>
                    Mot de passe oublié ?
                  </a>
                </p>
              </div>
            </form>

            <form
              className="sign-up-form"
              onSubmit={submitCompanyHandler}
              style={{
                opacity: isSignUp && !isAdmin ? 1 : 0,
                pointerEvents: isSignUp && !isAdmin ? "all" : "none",
              }}
            >
              <div className="logo">
                <img src={Logo} alt="easyclass" />
                <h4>RAYMOBILE-CI</h4>
              </div>

              <div className="heading">
                <h2>Création de compte</h2>
                <h6>Vous avez un compte? </h6>
                <a href="#" className="toggle" onClick={toggleForm}>
                  {isSignUp ? "Connectez-vous" : "Inscrivez-vous"}
                </a>
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="text"
                    className="input-field"
                    name="nomCompany"
                    value={formData.nomCompany}
                    onChange={handleCompanyChange}
                  />
                  <label>Nom de l'entreprise ou du proprietaire</label>
                </div>

                <div className="input-wrap">
                  <input
                    type="email"
                    className="input-field"
                    name="emailCompany"
                    value={formData.emailCompany}
                    onChange={handleCompanyChange}
                  />
                  <label>Email de l'entreprise</label>
                </div>
                <div className="input-wrap">
                  <PhoneInput
                    international={true}
                    countryCallingCodeEditable={false}
                    placeholder="Entrer le numero de la compagnie"
                    value={contactCompany}
                    onChange={(contactCompany) =>
                      setContactCompany(contactCompany)
                    }
                    defaultCountry="CI"
                    flags={flags}
                    labels={fr}
                    limitMaxLength={true}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "none",
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid #bbb",
                      padding: 0,
                      fontSize: "0.95rem",
                      color: "#151111",
                      transition: "0.4s",
                    }}
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    className="input-field"
                    name="adresseCompany"
                    value={formData.adresseCompany}
                    onChange={handleCompanyChange}
                  />
                  <label>Situation géographique de l'entreprise</label>
                </div>
                <div className="input-wrap">
                  <select
                    value={formData.typeCompte}
                    onChange={handleCompanyChange}
                    name="typeCompte"
                    placeholder="Le type de compte"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "none",
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid #bbb",
                      padding: 0,
                      fontSize: "0.95rem",
                      color: "#151111",
                      transition: "0.4s",
                    }}
                  >
                    <option>Le type de Compte</option>
                    <option value="Entreprise">Compte Entreprise</option>
                    <option value="Particulier">Compte Particulier</option>
                  </select>
                </div>
                <div className="input-wrap">
                  <input
                    type="file"
                    id="fileCompany"
                    className="input-field"
                    name="file"
                    accept="image/*"
                    onChange={(event) => setLogoCompanie(event.target.files[0])}
                  />
                </div>

                <input type="submit" value="Suivant" className="sign-btn" />
              </div>
            </form>

            <form
              className="sign-up-admin"
              encType="multipart/form-data" onSubmit={submitUserHandler}
              style={{
                opacity: isSignUp && isAdmin ? 1 : 0,
                pointerEvents: isSignUp && isAdmin ? "all" : "none",
              }}
            >
              <div className="logo">
                <img src={Logo} alt="easyclass" />
                <h4>RAYMOBILE-CI</h4>
              </div>

              <div className="heading">
                <h2>Inscription Admin</h2>
                <h6>Vous avez un compte? </h6>
                <a href="#" className="toggle" onClick={toggleForm}>
                  {isSignUp ? "Connectez-vous" : "Inscrivez-vous"}
                </a>
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="text"
                    className="input-field"
                    name="nom"
                    value={formDatas.nom}
                    onChange={handleUserChange}
                  />
                  <label>Nom de l'administrateur</label>
                </div>

                <div className="input-wrap">
                  <input
                    type="text"
                    className="input-field"
                    name="prenom"
                    value={formDatas.prenom}
                    onChange={handleUserChange}
                  />
                  <label>Prenom de l'administrateur</label>
                </div>
                <div className="input-wrap">
                  <PhoneInput
                    international={true}
                    countryCallingCodeEditable={false}
                    placeholder="Entrer le numero de l'administrateur'"
                    value={contact}
                    onChange={(contact) =>
                      setContact(contact)
                    }
                    defaultCountry="CI"
                    flags={flags}
                    labels={fr}
                    limitMaxLength={true}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "none",
                      border: "none",
                      outline: "none",
                      borderBottom: "1px solid #bbb",
                      padding: 0,
                      fontSize: "0.95rem",
                      color: "#151111",
                      transition: "0.4s",
                    }}
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="email"
                    className="input-field"
                    name="email"
                    value={formDatas.email}
                    onChange={handleUserChange}
                  />
                  <label>Email de l'administrateur</label>
                </div>
                <div className="input-wrap">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-field"
                  value={formDatas.password}
                  onChange={handleUserChange}
                  name="password"
                />
                  <label>Mot de passe</label>
                <span className="password-wraps" onClick={toggleShowPassword}>
                    <i
                      className={`fa ${
                        showPassword
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }`}
                    ></i>
                  </span>
                </div>
                <div className="input-wrap">
                <input
                  type={showPassword ? "text" : "password"}
                  className="input-field"
                  value={formDatas.confirm_password}
                  onChange={handleUserChange}
                  name="confirm_password"
                />
                  <label>Confirmer Mot de passe</label>
                <span className="password-wraps" onClick={toggleShowPassword}>
                    <i
                      className={`fa ${
                        showPassword
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }`}
                    ></i>
                  </span>
                </div>

                <input type="submit" value="S'inscrire" className="sign-btn" />
              </div>
            </form>
          </div>

          <div className="carousel">
            <div className="images-wrapper">
              <img src={Logo} className="image img-1 show" alt="" />
              <img src="" className="image img-2" alt="" />
              <img src="" className="image img-3" alt="" />
            </div>
          </div>

          <div className="text-slider">
            <div className="text-wrap">
              <div className="text-group"></div>
            </div>

            <div className="bullets">
              <span className="active" data-value="1"></span>
              <span data-value="2"></span>
              <span data-value="3"></span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
