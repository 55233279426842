import React, { useContext } from 'react'
import useMobile from '../app/hooks/useMobile';
import "../../assets/css/registration/register.css";
import CompanyInformation from './FormSteps/CompanyInformation';
import ManagerInformation from './FormSteps/ManagerInformation';
import AuthContext from '../context/AuthProvider';

const RegisterForm = () => {
  const { step } = useContext(AuthContext);
  const mobile = useMobile("(max-width: 940px)");

  switch (step) {
    case 1:
      return (
        <div
          className="registerBody"
        >
          <CompanyInformation />
        </div>
      );

    case 2:
      return (
        <div
          className="registerBody"
        >
          <ManagerInformation />
        </div>
      );

    default:
      break;
  }
}

export default RegisterForm