import React, { useEffect, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import { decrypt } from "../../../../module/myCrypto";
import ChargeModal from "../../components/ChargeModal";
import DeleteChargeModal from "../../components/DeleteChargeModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

const ListeAssurance = () => {
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [allAssuranceData, setAllAssuranceData] = useState([]);
  const [sumAllAssurance, setSumAllAssurance] = useState([]);
  const [assurCar, setAssurCar] = useState("");
  const [numPolice, setNumPolice] = useState("");
  const [assuCO, setAssuCO] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const maintenant = new Date();

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchAssuranceData = async () => {
      await axiosPrivate
        .get(`charges/getAllAssurances/${companyId}`)
        .then((res) => {
          setAllAssuranceData(res.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchSumAssuranceData = async () => {
      await axiosPrivate
        .get(`charges/getSumAssurances/${companyId}`)
        .then((res) => {
          setSumAllAssurance(res.data[0]);
        })
        .catch((err) => console.log(err));
    };

    const intervalId = setInterval(() => {
      fetchAssuranceData();
      fetchSumAssuranceData();
    }, 1000); // Mettez à jour toutes les 5 secondes

    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((assurCar || numPolice || assuCO || companyId) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get("charges/search-assurance", {
          params: { assurCar, numPolice, assuCO, companyId },
        });
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [assurCar, numPolice, assuCO, companyId]);

  const handleClickOpen = () => {
    navigate("/charges/assurances/ajouter");
  };

  function handleOnClickModal(
    numPolice,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([numPolice, attachement, vehicleID, cartegrise, companie]);
  }

  function handleOnClickChargeModal(numPolice, vehicleID, companie, content) {
    setShowChargeModal(true);
    setChargeModal([numPolice, vehicleID, companie, content]);
  }

  const nombreSumAllAssurance =
    sumAllAssurance.total_montant?.toLocaleString("fr-FR");
  
    const handlePDFClick = async () => {
      // Créez une instance de jsPDF en format paysage
      const doc = new jsPDF("landscape");
    
      const companieID = decrypt(user.companyID);
      const companieName = decrypt(user.companyName);
      const companieLogo = user.companyLogo;
    
      const image = companieLogo
        ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
        : defaultLogo;
    
      doc.addImage(image, "JPEG", 10, 5, 60, 45);
    
      doc.setFont(undefined, "bold");
      doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
      doc.text(companieName, 210, 25);
    
      // Ajoutez un titre
      doc.setFont("helvetica"); // Définissez la police
      doc.setFontSize(18); // Définissez la taille de la police
      doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
      const title = "Liste des Assurances";
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre
    
      const headers = [
        "",
        "Véhicules",
        "Assureur",
        "N° Police",
        "N° attestation",
        "Catégorie",
        "Montant",
        "Date édition",
        "Date échéance",
      ];
    
      const tableData = isSearching ? searchResults.map((result) => {
        
    
        return [
          "",
          result["vehicleID"],
          result["assureur"],
          result["numPolice"],
          result["numAttestation"],
          result["categorie"],
          result["montant"],
          moment(result["date_edition"]).format("DD/MM/YYYY"),
          moment(result["date_echeance"]).format("DD/MM/YYYY"),
        ];
      }) : allAssuranceData.map((assurance) => {
    
        return [
          "",
          assurance["vehicleID"],
          assurance["assureur"],
          assurance["numPolice"],
          assurance["numAttestation"],
          assurance["categorie"],
          assurance["montant"],
          moment(assurance["date_edition"]).format("DD/MM/YYYY"),
          moment(assurance["date_echeance"]).format("DD/MM/YYYY"),
        ];
      });
    
      doc.autoTable({
        startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
        head: [headers],
        body: tableData,
      });
    
      doc.save("liste-assurances.pdf");
    };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Assurances</h2>
          </div>
          <div className="links">
            <a href="" title="Ajouter assurance" className="ng-star-inserted">
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted" style={{cursor: "pointer"}}>
              <img src={pdf} alt="" onClick={handlePDFClick} />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre50">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par nom compagnie"
                  name="assurCar"
                  value={assurCar}
                  onChange={(e) => setAssurCar(e.target.value)}
                />
              </div>
              <div className="bloc-filtre50">
                <span>Par Numéro police</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par permis de conduire"
                  name="numPolice"
                  value={numPolice}
                  onChange={(e) => setNumPolice(e.target.value)}
                />
              </div>
              <div className="bloc-filtre50">
                <span>Par Compagnie</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par permis de conduire"
                  name="assuCO"
                  value={assuCO}
                  onChange={(e) => setAssuCO(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-blue">{allAssuranceData.length}</span>
            <span className="label">Nombre d'enregistrement</span>
          </div>
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-red">{nombreSumAllAssurance}</span>
            <span className="label">Montant Total</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Assureur
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Numéro police
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Numéro attestation
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Clé sécurité
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Montant
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Catégorie
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date édition
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date échéance
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false ? allAssuranceData.map((assurances) => {
                  return (
                    <tr
                    className="ui-selectable-row ng-star-inserted"
                    tabIndex="0"
                  >
                    <td
                      style={{ width: "40px", padding: ".3rem .3rem" }}
                      className="ng-star-inserted"
                    >
                      <div
                        className={subMenuOpen ? "dropdown show" : "dropdown"}
                      >
                        <button
                          aria-expanded="true"
                          aria-haspopup="true"
                          className="btn btn-default btn-xs dropdown-toggle-no-caret"
                          data-toggle="dropdown"
                          id="dropdownMenu1"
                          type="button"
                        >
                          <span className="glyphicon glyphicon-option-vertical">
                            <i
                              className="fa-solid fa-ellipsis-vertical"
                              onClick={() =>
                                setSubMenuOpen({
                                  ...subMenuOpen,
                                  [assurances.numPolice]:
                                    !subMenuOpen[assurances.numPolice],
                                })
                              }
                              open={!!subMenuOpen[assurances.numPolice]}
                            ></i>
                          </span>
                        </button>
                        {subMenuOpen[assurances.numPolice] ? (
                          <ul
                            aria-labelledby="dropdownMenu1"
                            className={
                              subMenuOpen
                                ? "dropdown-menu dropdown-menu-right show"
                                : "dropdown-menu dropdown-menu-right"
                            }
                            x-placement="top-end"
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "0px",
                              left: "0px",
                              transform: "translate3d(0px, 38px, 0px)",
                            }}
                          >
                            {maintenant <=
                              new Date(assurances.date_echeance) && (
                              <>
                                <li className="c-blue ng-star-inserted">
                                  <a
                                    href={`/charges/assurances/edit/${assurances.numPolice}/${assurances.vehicleID}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={iconEdit} />
                                    Modifier
                                  </a>
                                </li>
                                <li
                                  className="divider ng-star-inserted"
                                  role="separator"
                                ></li>
                                <li className="c-red ng-star-inserted">
                                  <a
                                    data-target="#popupDelete"
                                    data-toggle="modal"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleOnClickChargeModal(
                                        assurances.numPolice,
                                        assurances.vehicleID,
                                        companyId,
                                        "L'Assurance"
                                      )
                                    }
                                  >
                                    <img src={iconDelete} />
                                    Supprimer
                                  </a>
                                </li>
                              </>
                            )}
                          </ul>
                        ) : (
                          <ul></ul>
                        )}
                      </div>
                    </td>
                    <td className="ng-star-inserted">{assurances.vehicleID}</td>
                    <td className="ng-star-inserted">{assurances.assureur}</td>
                    <td className="ng-star-inserted">{assurances.numPolice}</td>
                    <td className="ng-star-inserted">
                      {assurances.numAttestation}
                    </td>
                    <td className="ng-star-inserted">
                      {assurances.cle_securite}
                    </td>
                    <td className="ng-star-inserted">{assurances.montant}</td>
                    <td className="ng-star-inserted">{assurances.categorie}</td>
                    <td className="ng-star-inserted">
                      {new Date(assurances.date_edition).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td className="ng-star-inserted">
                      {new Date(assurances.date_echeance).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td className="ng-star-inserted">
                      <span className="ng-star-inserted">
                        <a
                          data-target="#popupAttachement"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOnClickModal(
                              assurances.numPolice,
                              assurances.attachement,
                              `L'Assurance`,
                              assurances.vehicleID,
                              companyId
                            )
                          }
                        >
                          <img src={attachement} />
                        </a>
                      </span>
                    </td>
                  </tr>
                  );
                }): searchResults.map((assurances) => {
                  return (<tr
                    className="ui-selectable-row ng-star-inserted"
                    tabIndex="0"
                  >
                    <td
                      style={{ width: "40px", padding: ".3rem .3rem" }}
                      className="ng-star-inserted"
                    >
                      <div
                        className={subMenuOpen ? "dropdown show" : "dropdown"}
                      >
                        <button
                          aria-expanded="true"
                          aria-haspopup="true"
                          className="btn btn-default btn-xs dropdown-toggle-no-caret"
                          data-toggle="dropdown"
                          id="dropdownMenu1"
                          type="button"
                        >
                          <span className="glyphicon glyphicon-option-vertical">
                            <i
                              className="fa-solid fa-ellipsis-vertical"
                              onClick={() =>
                                setSubMenuOpen({
                                  ...subMenuOpen,
                                  [assurances.numPolice]:
                                    !subMenuOpen[assurances.numPolice],
                                })
                              }
                              open={!!subMenuOpen[assurances.numPolice]}
                            ></i>
                          </span>
                        </button>
                        {subMenuOpen[assurances.numPolice] ? (
                          <ul
                            aria-labelledby="dropdownMenu1"
                            className={
                              subMenuOpen
                                ? "dropdown-menu dropdown-menu-right show"
                                : "dropdown-menu dropdown-menu-right"
                            }
                            x-placement="top-end"
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "0px",
                              left: "0px",
                              transform: "translate3d(0px, 38px, 0px)",
                            }}
                          >
                            {maintenant <=
                              new Date(assurances.date_echeance) && (
                              <>
                                <li className="c-blue ng-star-inserted">
                                  <a
                                    href={`/charges/assurances/edit/${assurances.numPolice}/${assurances.vehicleID}`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={iconEdit} />
                                    Modifier
                                  </a>
                                </li>
                                <li
                                  className="divider ng-star-inserted"
                                  role="separator"
                                ></li>
                                <li className="c-red ng-star-inserted">
                                  <a
                                    data-target="#popupDelete"
                                    data-toggle="modal"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleOnClickChargeModal(
                                        assurances.numPolice,
                                        assurances.vehicleID,
                                        companyId,
                                        "L'Assurance"
                                      )
                                    }
                                  >
                                    <img src={iconDelete} />
                                    Supprimer
                                  </a>
                                </li>
                              </>
                            )}
                          </ul>
                        ) : (
                          <ul></ul>
                        )}
                      </div>
                    </td>
                    <td className="ng-star-inserted">{assurances.vehicleID}</td>
                    <td className="ng-star-inserted">{assurances.assureur}</td>
                    <td className="ng-star-inserted">{assurances.numPolice}</td>
                    <td className="ng-star-inserted">
                      {assurances.numAttestation}
                    </td>
                    <td className="ng-star-inserted">
                      {assurances.cle_securite}
                    </td>
                    <td className="ng-star-inserted">{assurances.montant}</td>
                    <td className="ng-star-inserted">{assurances.categorie}</td>
                    <td className="ng-star-inserted">
                      {new Date(assurances.date_edition).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td className="ng-star-inserted">
                      {new Date(assurances.date_echeance).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td className="ng-star-inserted">
                      <span className="ng-star-inserted">
                        <a
                          data-target="#popupAttachement"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOnClickModal(
                              assurances.numPolice,
                              assurances.attachement,
                              `L'Assurance`,
                              assurances.vehicleID,
                              companyId
                            )
                          }
                        >
                          <img src={attachement} />
                        </a>
                      </span>
                    </td>
                  </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
      {showDetailModal && setModal != null && (
        <ChargeModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showChargeModal && setChargeModal != null && (
        <DeleteChargeModal
          closeDeleteChargeModal={setShowChargeModal}
          data={chargeModal}
        />
      )}
    </>
  );
};

export default ListeAssurance;
