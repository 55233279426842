import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, Flip, Bounce } from "react-toastify";
import useMobile from "../../app/hooks/useMobile";
import FormHeader from "../FormComponent/FormHeader";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import AuthContext from "../../context/AuthProvider";
import axiosPrivate from "../../services/api/axios";
import "../../../assets/css/registration/register.css";
import "react-toastify/dist/ReactToastify.css";
import { encrypt } from "../../module/myCrypto";
import Plan from "../../../assets/images/ray-assur.webp";
import Logo from "../../../assets/images/raymobileci.avif";

const ManagerInformation = () => {
  const { companie } = useContext(AuthContext);
  const mobile = useMobile("(max-width:940px)");
  const [formError, setFormError] = useState({});
  const [contact, setContact] = useState("");
  const [eye, seteye] = useState(true);
  const [userPhoto, setUserPhoto] = useState("");
  const [warnpassword, setwarnpassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [type, settype] = useState(false);
  const [types, setTypes] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    photo: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const Eye = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const Eyes = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", userPhoto);
      const res = await axiosPrivate.post("upload-user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event) => {
    // console.log(event);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    // Au moins un caractère spécial
    let specialCharCheck = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // Au moins un chiffre
    let numberCheck = /\d/;
    // Au moins une lettre majuscule
    let upperCaseCheck = /[A-Z]/;

    if (formData.nom === "") {
      err.nom = toast.error("😡 Veuillez entrer un nom du gérant!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.prenom === "") {
      err.prenom = toast.error("😡 Veuillez entrer le prenom du gérant", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!regex.test(formData.email)) {
        err.email = toast.error("😡 Email invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (contact === "") {
      err.contact = toast.error("😡 Veuillez entrer un contact du gérant!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    } else {
      let regexPhone = /^\+?[1-9][0-9]{12,14}$/;
      if (!regexPhone.test(contact)) {
        err.contact = toast.error("😡 Numéro de téléphone invalide!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (formData.typeCompte === "") {
      err.typeCompte = toast.error("😡 Veuillez entrer le type de compte!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.password === "" || formData.confirm_password === "") {
      err.password = "Mot de passe et confirmation du mot de passe requis!";
    }
    if (formData.password !== formData.confirm_password) {
      err.password = toast.error("😡 Les mots de passe sont différents!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.password.length < 10) {
      err.password = toast.error(
        "😡 Le mot de passe doit compter plus de 10 caractères!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!specialCharCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un caratère spécial!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!numberCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un chiffre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!upperCaseCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins une lettre majuscule!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  //console.log(companieID)
  const submitHandler = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    let imgUrl = "";

    if (userPhoto) imgUrl = await upload();

    if (isValid) {
      axiosPrivate
        .post("auth/register", {
          nom: encrypt(formData.nom),
          prenom: encrypt(formData.prenom),
          email: encrypt(formData.email),
          contact: encrypt(contact),
          password: encrypt(formData.password),
          companie: encrypt(companie),
          photo: imgUrl,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 103)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  };

  return (
    <div className="registerContainer">
      <div className="form-image">
        <img src={Plan} alt="" />
      </div>
      <div className="container">
        <div className="form-header">
          <div className="title">
            <h1>Raymobile-CI</h1>
            <FormHeader
              //title="Information de la compagnie"
              info="Veuillez renseigner les informations de l'administrateur"
            />
          </div>
          <div className="login-button">
            <img src={Logo} alt="" />
          </div>
        </div>
        <div className="form-container">
          <form encType="multipart/form-data" onSubmit={submitHandler}>
            <div className="user-details">
              <div className="input-box">
                <span className="details">Nom de l'administrateur</span>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  placeholder="Nom du gérant"
                  value={formData.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Prenom de l'administrateur</span>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  placeholder="Prenom du gérant"
                  value={formData.prenom}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Contact de l'administrateur</span>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero du gérant"
                  value={contact}
                  onChange={(contact) => setContact(contact)}
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={true}
                />
              </div>
              <div className="input-box">
                <span className="details">Email de l'administrateur</span>
                <input
                  type="email"
                  id="emailCompany"
                  name="email"
                  placeholder="Email du gérant"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="input-box">
                <span className="details">Mot de passe</span>
                <input
                  type={passwordType}
                  className={` ${warnpassword ? "warning" : ""} ${
                    type ? "type_password" : ""
                  }`}
                  placeholder="Veuillez entrer le mot de passe"
                  value={formData.password}
                  onChange={handleChange}
                  name="password"
                />
                <i
                  onClick={Eye}
                  className={`fa ${
                    passwordType === "text"
                      ? "fa-solid fa-eye-slash"
                      : "fa-solid fa-eye"
                  }`}
                ></i>
              </div>
              <div className="input-box">
                <span className="details">Confirmer Mot de passe</span>
                <input
                  type={confirmPasswordType}
                  className={` ${warnpassword ? "warning" : ""} ${
                    types ? "type_password" : ""
                  }`}
                  placeholder="Veuillez confirmer le mot de passe"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  name="confirm_password"
                />
                <i
                  onClick={Eyes}
                  className={`fa ${
                    confirmPasswordType === "text"
                      ? "fa-solid fa-eye-slash"
                      : "fa-solid fa-eye"
                  }`}
                ></i>
              </div>
              <div className="input-box">
                <span className="details">Photo de l'administrateur</span>
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept="image/*"
                  onChange={(event) => setUserPhoto(event.target.files[0])}
                />
              </div>
            </div>
            <div className="flexParent">
              <div className="buttonWrapper">
                <button className="button" type="submit">
                  Suivant
                </button>
              </div>
            </div>
            <p className="sign-in">
              Vous avez un compte?
              <Link to="/"> Connectez-vous</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManagerInformation;
