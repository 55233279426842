import React, { useState, useEffect } from 'react';
import axiosPrivate from "../../../services/api/axios";
import { Pie } from "react-chartjs-2";
import { useAuth } from '../../../context/AuthProvider';
import { decrypt } from '../../../module/myCrypto';

function MonthlyPaymentChart () {
  const [countEventsTaxi, setCountEventsTaxi] = useState("");
  const [countEventsYango, setCountEventsYango] = useState("");
  const [countTaxiVersements, setCountTaxiVersements] = useState([]);
  const [countYangoVersements, setCountYangoVersements] = useState([]);
  
  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getCountEventTaxi = async () => {
      await axiosPrivate
        .get(`calendar/count-events-taxi/${companyId}`)
        .then((res) => {
          setCountEventsTaxi(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getCountEventYango = async () => {
      await axiosPrivate
        .get(`calendar/count-events-yango/${companyId}`)
        .then((res) => {
          setCountEventsYango(res.data);
        })
        .catch((err) => console.log(err));
    };
    const countTaxiVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-taxi/${companyId}`)
        .then((res) => {
          if (res.data && res.data[0] && res.data[0].total !== null) {
            setCountTaxiVersements(res.data[0].total);
          } else {
            setCountTaxiVersements(0);
          }
        })
        .catch((err) => console.log(err));
    };
    const countYangoVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-yango/${companyId}`)
        .then((res) => {
          setCountYangoVersements(res.data[0].total)
        })
        .catch((err) => console.log(err));
    };

    getCountEventTaxi();
    getCountEventYango();
    countTaxiVersement();
    countYangoVersement();
  }, []);
  const nombreTaxiAttente =
    countEventsTaxi?.toLocaleString("fr-FR");
  const nombreYangoAttente =
  countEventsYango?.toLocaleString("fr-FR");
  const nombreTaxiVersement =
  countTaxiVersements?.toLocaleString("fr-FR");
  const nombreYangoVersement =
  countYangoVersements?.toLocaleString("fr-FR");
  const totalaVerser = countEventsTaxi + countEventsYango;
  const totalVerser = countTaxiVersements + countYangoVersements;
  const nombreMontantaVerse =
  totalaVerser?.toLocaleString("fr-FR");
  const nombreMontantVerse =
  totalVerser?.toLocaleString("fr-FR");

  const data = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [countEventsTaxi, countEventsYango, countTaxiVersements, countYangoVersements],
        backgroundColor: [
          'rgba(250, 142, 142, 1)',
          'rgba(150, 59, 59, 1)',
          'rgba(20, 245, 133, 1)',
          'rgba(2, 94, 48, 1)',
        ],
        borderColor: [
          'rgba(250, 142, 142, 1)',
          'rgba(150, 59, 59, 1)',
          'rgba(20, 245, 133, 1)',
          'rgba(2, 94, 48, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      labels: {
        fontSize: 20 // Taille de la légende
      }
    }
  };

  return (
    <div style={{ margin: "auto", width: "20vw" }}>
      <Pie data={data} options={options} />
    </div>
  );
}

export default MonthlyPaymentChart;
