import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/ray-list.svg";

const VehicleEdit = () => {
  const { vehiculeId, acquisition, numContrat } = useParams();
  const flag = useRef(false);
  const fileInput = useRef();
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [pdfFile, setPDFFile] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [contactData, setContactData] = useState({
    immatriculation: "",
    type_acquisition: "",
    marque: "",
    modele: "",
    carte_grise: "",
    numero_chassis: "",
    couleur: "",
    kilometrage: "",
    type_transport: "",
    recette: "",
    date_circulation: "",
    heure_arret: "",
    temps_repos: "",
    energie: "",
    prix_litre: "",
    quantite_carburant: "",
    status_vehicle: "",
    numeroContrat: "",
    concessionnaire: "",
    date_achat: "",
    garantie: "",
    montantTTC_achat: "",
    proprietaire: "",
    type_contrat: "",
    date_debut_contrat_gestion: "",
    date_fin_contrat_gestion: "",
    montantAVerser: "",
    fournisseur: "",
    type_contrat_location: "",
    kilometrage_location: "",
    date_debut_contrat_location: "",
    date_fin_contrat_location: "",
    location_journaliere: "",
    location_global: "",
    oldPhotoVoiture: "",
    oldContrat: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const company = decrypt(user.companyID);

  useEffect(() => {
    const getCar = `vehicles/getVehicle/${vehiculeId}`;
    const getContrat = `vehiclecontrat/getContrat/${numContrat}`;

    Promise.all([axiosPrivate.get(getCar), axiosPrivate.get(getContrat)]).then(
      ([responseCar, responseContrat]) => {
        setContactData({
          ...contactData,
          immatriculation: responseCar.data.immatriculation,
          type_acquisition: responseCar.data.type_acquisition,
          marque: responseCar.data.marque,
          modele: responseCar.data.modele,
          carte_grise: responseCar.data.carte_grise,
          numero_chassis: responseCar.data.numero_chassis,
          couleur: responseCar.data.couleur,
          kilometrage: responseCar.data.kilometrage,
          type_transport: responseCar.data.type_transport,
          recette: responseCar.data.recette,
          date_circulation: responseCar.data.date_circulation,
          heure_arret: responseCar.data.heure_arret,
          temps_repos: responseCar.data.temps_repos,
          energie: responseCar.data.energie,
          prix_litre: responseCar.data.prix_litre,
          quantite_carburant: responseCar.data.quantite_carburant,
          status_vehicle: responseCar.data.status_vehicle,
          oldPhotoVoiture: responseCar.data.photo_voiture,
          numeroContrat: responseContrat.data.numeroContrat,
          concessionnaire: responseContrat.data.concessionnaire,
          date_achat: responseContrat.data.date_achat,
          garantie: responseContrat.data.garantie,
          montantTTC_achat: responseContrat.data.montantTTC_achat,
          proprietaire: responseContrat.data.proprietaire,
          type_contrat: responseContrat.data.type_contrat,
          date_debut_contrat_gestion:
            responseContrat.data.date_debut_contrat_gestion,
          date_fin_contrat_gestion:
            responseContrat.data.date_fin_contrat_gestion,
          montantAVerser: responseContrat.data.montantAVerser,
          fournisseur: responseContrat.data.fournisseur,
          type_contrat_location: responseContrat.data.type_contrat_location,
          kilometrage_location: responseContrat.data.kilometrage_location,
          date_debut_contrat_location:
            responseContrat.data.date_debut_contrat_location,
          date_fin_contrat_location:
            responseContrat.data.date_fin_contrat_location,
          location_journaliere: responseContrat.data.location_journaliere,
          location_global: responseContrat.data.location_global,
          oldContrat: responseContrat.data.contrat,
        });
      }
    );
    /*axiosPrivate.get(`vehicles/getVehicle/${vehiculeId}`).then((res) => {
      console.log(res.data)
      setContactData({
        ...contactData,
        immatriculation: res.data.immatriculation,
        type_acquisition: res.data.type_acquisition,
        marque: res.data.marque,
        modele: res.data.modele,
        carte_grise: res.data.carte_grise,
        numero_chassis: res.data.numero_chassis,
        couleur: res.data.couleur,
        kilometrage: res.data.kilometrage,
        type_transport: res.data.type_transport,
        recette: res.data.recette,
        date_circulation: res.data.date_circulation,
        heure_arret: res.data.heure_arret,
        temps_repos: res.data.temps_repos,
        energie: res.data.energie,
        prix_litre: res.data.prix_litre,
        quantite_carburant: res.data.quantite_carburant,
        photo_voiture: res.data.photo_voiture,
      });
    });*/
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", imgFile);
      const res = await axiosPrivate.post("upload-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", pdfFile);
      const res = await axiosPrivate.post("upload-contrat", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.immatriculation === "") {
      err.immatriculation = toast.error(
        "😡 Veuillez entrer l'immatriculation!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.type_acquisition === "") {
      err.type_acquisition = toast.error(
        "😡 Veuillez choisir le type d'acquisition!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.marque === "") {
      err.marque = toast.error("😡 Veuillez entrer la marque!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.modele === "") {
      err.modele = toast.error("😡 Veuillez entrer le modele!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.carte_grise === "") {
      err.carte_grise = toast.error(
        "😡 Veuillez entrer le numéro de la carte grise",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.numero_chassis === "") {
      err.numero_chassis = toast.error(
        "😡 Veuillez entrer le numéro de chassis",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.couleur === "") {
      err.couleur = toast.error("😡 Veuillez entrer la couleur", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.kilometrage === "") {
      err.kilometrage = toast.error("😡 Veuillez entrer le kilometrage", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.type_transport === "") {
      err.type_transport = toast.error(
        "😡 Veuillez selectionner le type de transport",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.recette === "") {
      err.recette = toast.error("😡 Veuillez entrer la recette", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_circulation === "") {
      err.date_circulation = toast.error(
        "😡 Veuillez entrer la date de mise en circulation",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.heure_arret === "") {
      err.heure_arret = toast.error(
        "😡 Veuillez entrer l'heure de stationnement'",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.temps_repos === "") {
      err.temps_repos = toast.error("😡 Veuillez entrer le temps de repos", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.energie === "") {
      err.energie = toast.error("😡 Veuillez entrer l'energie'", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.prix_litre === "") {
      err.prix_litre = toast.error("😡 Veuillez entrer le prix du litre'", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.quantite_carburant === "") {
      err.quantite_carburant = toast.error(
        "😡 Veuillez entrer la quantité prise'",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.numeroContrat === "") {
      err.numeroContrat = toast.error(
        "😡 Veuillez entrer le numéro de contrat d'achat!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.type_acquisition === "ACHAT") {
      if (contactData.concessionnaire === "") {
        err.concessionnaire = toast.error(
          "😡 Veuillez entrer le concessionnaire!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.date_achat === "") {
        err.date_achat = toast.error("😡 Veuillez entrer la date d'achat!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.garantie === "") {
        err.garantie = toast.error("😡 Veuillez entrer la garantie achat!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.montantTTC_achat === "") {
        err.montantTTC_achat = toast.error(
          "😡 Veuillez entrer le montant TTC d'achat!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    } else if (contactData.type_acquisition === "GESTION") {
      if (contactData.proprietaire === "") {
        err.proprietaire = toast.error(
          "😡 Veuillez entrer le nom du proprietaire!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.type_contrat === "") {
        err.type_contrat = toast.error(
          "😡 Veuillez entrer le type de contrat de gestion!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.date_debut_contrat_gestion === "") {
        err.date_debut_contrat_gestion = toast.error(
          "😡 Veuillez entrer le date de debut de gestion!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.date_fin_contrat_gestion === "") {
        err.date_fin_contrat_gestion = toast.error(
          "😡 Veuillez entrer le date de fin de gestion!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.montantAVerser === "") {
        err.montantAVerser = toast.error(
          "😡 Veuillez entrer le montant à verser!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    } else if (contactData.type_acquisition === "LEASING") {
    } else {
      if (contactData.fournisseur === "") {
        err.fournisseur = toast.error("😡 Veuillez entrer le fournisseur!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.type_contrat_location === "") {
        err.type_contrat_location = toast.error(
          "😡 Veuillez choisir le type de contrat de location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.kilometrage_location === "") {
        err.kilometrage_location = toast.error(
          "😡 Veuillez entrer le kilometrage de location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.date_debut_contrat_location === "") {
        err.date_debut_contrat_location = toast.error(
          "😡 Veuillez entrer la date de début de location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.date_fin_contrat_location === "") {
        err.date_fin_contrat_location = toast.error(
          "😡 Veuillez entrer la date de fin de location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.location_journaliere === "") {
        err.location_journaliere = toast.error(
          "😡 Veuillez entrer le montant de la location journalière!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
      if (contactData.location_global === "") {
        err.location_global = toast.error(
          "😡 Veuillez entrer le montant global de location!",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    let datas;

    let imgUrl = "";
    let contratUrl = "";

    if (imgFile) imgUrl = await upload();

    if (pdfFile) contratUrl = await uploadContrat();

    

    if (contactData.type_acquisition === "ACHAT") {
      datas = {
        companieID: user.companyID,
        userID: user.userID,
        immatriculation: encrypt(contactData.immatriculation),
        type_acquisition: encrypt(contactData.type_acquisition),
        marque: encrypt(contactData.marque),
        modele: encrypt(contactData.modele),
        carte_grise: encrypt(contactData.carte_grise),
        numero_chassis: encrypt(contactData.numero_chassis),
        couleur: encrypt(contactData.couleur),
        kilometrage: encrypt(contactData.kilometrage),
        date_circulation: encrypt(contactData.date_circulation),
        type_transport: encrypt(contactData.type_transport),
        recette: encrypt(contactData.recette),
        heure_arret: encrypt(contactData.heure_arret),
        temps_repos: encrypt(contactData.temps_repos),
        photo_vehicle: imgUrl,
        energie: encrypt(contactData.energie),
        prix_litre: encrypt(contactData.prix_litre),
        quantite_carburant: encrypt(contactData.quantite_carburant),
        status_vehicle: encrypt(contactData.status_vehicle),
        concessionnaire: encrypt(contactData.concessionnaire),
        date_achat: encrypt(contactData.date_achat),
        numContrat: encrypt(contactData.numeroContrat),
        garantie: encrypt(contactData.garantie),
        montantTTC_achat: encrypt(contactData.montantTTC_achat),
        contratPDF: contratUrl,
        oldPhotoUrl: contactData.oldPhotoVoiture,
        oldContratPDF: contactData.oldContrat
      };
    } else if (contactData.type_acquisition === "GESTION") {
      datas = {
        companieID: user.companyID,
        userID: user.userID,
        immatriculation: encrypt(contactData.immatriculation),
        type_acquisition: encrypt(contactData.type_acquisition),
        marque: encrypt(contactData.marque),
        modele: encrypt(contactData.modele),
        carte_grise: encrypt(contactData.carte_grise),
        numero_chassis: encrypt(contactData.numero_chassis),
        couleur: encrypt(contactData.couleur),
        kilometrage: encrypt(contactData.kilometrage),
        date_circulation: encrypt(contactData.date_circulation),
        type_transport: encrypt(contactData.type_transport),
        recette: encrypt(contactData.recette),
        heure_arret: encrypt(contactData.heure_arret),
        temps_repos: encrypt(contactData.temps_repos),
        photo_vehicle: imgUrl,
        energie: encrypt(contactData.energie),
        prix_litre: encrypt(contactData.prix_litre),
        quantite_carburant: encrypt(contactData.quantite_carburant),
        status_vehicle: encrypt(contactData.status_vehicle),
        proprietaire: encrypt(contactData.proprietaire),
        numContrat: encrypt(contactData.numeroContrat),
        type_contrat: encrypt(contactData.type_contrat),
        date_debut_contrat_gestion: encrypt(
          contactData.date_debut_contrat_gestion
        ),
        date_fin_contrat_gestion: encrypt(contactData.date_fin_contrat_gestion),
        montantAVerser: encrypt(contactData.montantAVerser),
        contratPDF: contratUrl,
        oldPhotoUrl: contactData.oldPhotoVoiture,
        oldContratPDF: contactData.oldContrat
      };
    } else if (contactData.type_acquisition === "LEASING") {
      datas = {
        companieID: user.companyID,
        userID: user.userID,
        immatriculation: encrypt(contactData.immatriculation),
        type_acquisition: encrypt(contactData.type_acquisition),
        marque: encrypt(contactData.marque),
        modele: encrypt(contactData.modele),
        carte_grise: encrypt(contactData.carte_grise),
        numero_chassis: encrypt(contactData.numero_chassis),
        couleur: encrypt(contactData.couleur),
        kilometrage: encrypt(contactData.kilometrage),
        date_circulation: encrypt(contactData.date_circulation),
        type_transport: encrypt(contactData.type_transport),
        recette: encrypt(contactData.recette),
        heure_arret: encrypt(contactData.heure_arret),
        temps_repos: encrypt(contactData.temps_repos),
        photo_vehicle: imgUrl,
        energie: encrypt(contactData.energie),
        prix_litre: encrypt(contactData.prix_litre),
        quantite_carburant: encrypt(contactData.quantite_carburant),
        status_vehicle: encrypt(contactData.status_vehicle),
        numContrat: encrypt(contactData.numeroContrat),
        contratPDF: contratUrl,
        oldPhotoUrl: contactData.oldPhotoVoiture,
        oldContratPDF: contactData.oldContrat
      };
    } else {
      datas = {
        companieID: user.companyID,
        userID: user.userID,
        immatriculation: encrypt(contactData.immatriculation),
        type_acquisition: encrypt(contactData.type_acquisition),
        marque: encrypt(contactData.marque),
        modele: encrypt(contactData.modele),
        carte_grise: encrypt(contactData.carte_grise),
        numero_chassis: encrypt(contactData.numero_chassis),
        couleur: encrypt(contactData.couleur),
        kilometrage: encrypt(contactData.kilometrage),
        date_circulation: encrypt(contactData.date_circulation),
        type_transport: encrypt(contactData.type_transport),
        recette: encrypt(contactData.recette),
        heure_arret: encrypt(contactData.heure_arret),
        temps_repos: encrypt(contactData.temps_repos),
        photo_vehicle: imgUrl,
        energie: encrypt(contactData.energie),
        prix_litre: encrypt(contactData.prix_litre),
        quantite_carburant: encrypt(contactData.quantite_carburant),
        status_vehicle: encrypt(contactData.status_vehicle),
        fournisseur: encrypt(contactData.fournisseur),
        numContrat: encrypt(contactData.numeroContrat),
        type_contrat_location: encrypt(contactData.type_contrat_location),
        kilometrage_location: encrypt(contactData.kilometrage_location),
        date_debut_contrat_location: encrypt(
          contactData.date_debut_contrat_location
        ),
        date_fin_contrat_location: encrypt(
          contactData.date_fin_contrat_location
        ),
        location_journaliere: encrypt(contactData.location_journaliere),
        location_global: encrypt(contactData.location_global),
        contratPDF: contratUrl,
        oldPhotoUrl: contactData.oldPhotoVoiture,
        oldContratPDF: contactData.oldContrat
      };
    }

    if (isValid) {
      await axiosPrivate
        .put(`vehicles/update/${vehiculeId}`, datas)
        .then((response) => {
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(`/flottes/vehicules/${company}`);
            
          } else {
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
            
          }
          
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };
  

  return (
    <div className="ng-star-inserted">
      <div className="silver">
        <div id="header-main">
          <div className="title">
            <h2>Modifier le véhicule {vehiculeId}</h2>
          </div>
          <div className="links">
            <a
              href={`/flottes/vehicules/${company}`}
              className="ng-star-inserted"
            >
              <img src={list} />
            </a>
          </div>
        </div>
        <form
          className="ng-pristine ng-invalid ng-touched"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="form-l">
            <div className="form-content left">
              <div className="form-block">
                <label className="title-content">Désignation</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Immatriculation</label>
                  <input
                    type="text"
                    placeholder="Immatriculation"
                    name="immatriculation"
                    className="ng-pristine ng-invalid ng-touched"
                    value={contactData.immatriculation}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type d'acquisition</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="type_acquisition"
                        value={contactData.type_acquisition}
                        onChange={handleChange}
                      >
                        <option>Type d'acquisition</option>
                        <option value="ACHAT">ACHAT</option>
                        <option value="GESTION">GESTION</option>
                        <option value="LEASING">LEASING</option>
                        <option value="LOCATION">LOCATION</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Marque</label>
                  <input
                    type="text"
                    placeholder="Marque"
                    className="ng-pristine ng-invalid ng-touched"
                    name="marque"
                    value={contactData.marque}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Modèle</label>
                  <input
                    type="text"
                    placeholder="Modèle"
                    className="ng-pristine ng-invalid ng-touched"
                    name="modele"
                    value={contactData.modele}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Carte grise</label>
                  <input
                    type="text"
                    placeholder="Carte grise"
                    className="ng-pristine ng-invalid ng-touched"
                    name="carte_grise"
                    value={contactData.carte_grise}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">N° de châssis</label>
                  <input
                    type="text"
                    placeholder="Numéro de châssis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="numero_chassis"
                    value={contactData.numero_chassis}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Couleur</label>
                  <input
                    type="text"
                    placeholder="Couleur"
                    className="ng-pristine ng-invalid ng-touched"
                    name="couleur"
                    value={contactData.couleur}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Kilométrage</label>
                  <input
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    pinputtext=""
                    pkeyfilter="int"
                    type="number"
                    min="0"
                    name="kilometrage"
                    value={contactData.kilometrage}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      Km
                    </span>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type transport</label>
                  <div className="large">
                    <div className="box-select">
                      <select
                        name="type_transport"
                        value={contactData.type_transport}
                        onChange={handleChange}
                      >
                        <option>Type de transport</option>
                        <option value="TAXI">TAXI</option>
                        <option value="LOCATION">LOCATION</option>
                        <option value="YANGO">YANGO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Recette</label>
                  <input
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    pinputtext=""
                    pkeyfilter="int"
                    type="number"
                    min="0"
                    step="5"
                    name="recette"
                    value={contactData.recette}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      F.CFA
                    </span>
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date mise en circulation</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="date"
                      name="date_circulation"
                      value={contactData.date_circulation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {contactData.photo_voiture ? (
                  <div></div>
                ) : (
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Photo principale</label>
                    <input
                      id="image"
                      ref={fileInput}
                      accept=".jpg,.png,.jpeg"
                      className="large"
                      type="file"
                      name="photo"
                      onChange={(event) => setImgFile(event.target.files[0])}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-content right ng-star-inserted">
              <div className="form-block">
                <label className="title-content">Horaires & Energie & Statut</label>
                <div className="form-group noHeight">
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Heure d'arrêt</label>
                    <input
                      type="time"
                      placeholder="Heure d'arrêt"
                      className="ng-pristine ng-invalid ng-touched"
                      name="heure_arret"
                      value={contactData.heure_arret}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Temps de repos</label>
                    <input
                      type="time"
                      placeholder="Heure de repos"
                      className="ng-pristine ng-invalid ng-touched"
                      name="temps_repos"
                      value={contactData.temps_repos}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Statut du véhicule</label>
                    <div className="large">
                      <div className="box-select-medium">
                        <select
                          name="status_vehicle"
                          value={contactData.status_vehicle}
                          onChange={handleChange}
                        >
                          <option value="Accidenté">Accidenté</option>
                          <option value="Actif">Actif</option>
                          <option value="En Attente">En Attente</option>
                          <option value="En Maintenance">En Maintenance</option>
                          <option value="Immobilisé">Immobilisé</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group noHeight">
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Energie</label>
                    <div className="large">
                      <div className="box-select-medium">
                        <select
                          name="energie"
                          value={contactData.energie}
                          onChange={handleChange}
                        >
                          <option>Energie</option>
                          <option value="Essence">Essence</option>
                          <option value="Gasoil">Gasoil</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Prix du litre</label>
                    <input
                      type="number"
                      min="0"
                      step="5"
                      placeholder="Prix du litre"
                      className="ng-pristine ng-invalid ng-touched"
                      name="prix_litre"
                      value={contactData.prix_litre}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Quantité prise</label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Quantité prise"
                      className="ng-pristine ng-invalid ng-touched"
                      name="quantite_carburant"
                      value={contactData.quantite_carburant}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {contactData.type_acquisition === "ACHAT" && (
              <div className="form-content right ng-star-inserted">
                <div className="form-block">
                  <label className="title-content">Acquisition achat</label>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Concessionnaire</label>
                    <div className="large">
                      <input
                        type="text"
                        placeholder="Concessionnaire"
                        className="ng-pristine ng-invalid ng-touched"
                        name="concessionnaire"
                        value={contactData.concessionnaire}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {acquisition === "ACHAT" && contactData.contrat ? (
                    <div></div>
                  ) : (
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Télécharger le contrat</label>
                      <div className="large">
                        <input
                          accept=".pdf"
                          className="large"
                          type="file"
                          name="contrat"
                          id="contrat"
                          ref={fileInput}
                          onChange={(event) =>
                            setPDFFile(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Numéro contrat</label>
                    <div className="large">
                      <input
                        className="large ng-untouched ng-pristine ng-valid"
                        placeholder="Numéro contrat"
                        type="text"
                        name="numeroContrat"
                        value={contactData.numeroContrat}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Montant TTC</label>
                    <input
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      pinputtext=""
                      pkeyfilter="int"
                      type="number"
                      min="0"
                      name="montantTTC_achat"
                      value={contactData.montantTTC_achat}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        F.CFA
                      </span>
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Garantie</label>
                    <div className="large">
                      <input
                        className="large ng-untouched ng-pristine ng-valid"
                        placeholder="Garantie"
                        type="number"
                        min="0"
                        name="garantie"
                        value={contactData.garantie}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Date d'achat</label>
                    <div className="large">
                      <input
                        id="bdate"
                        type="date"
                        name="date_achat"
                        value={contactData.date_achat}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {contactData.type_acquisition === "GESTION" && (
              <div className="form-content right ng-star-inserted">
                <div className="form-block">
                  <label className="title-content">Acquisition gestion</label>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Proprietaire</label>
                    <div className="large">
                      <input
                        type="text"
                        placeholder="Proprietaire"
                        className="ng-pristine ng-invalid ng-touched"
                        name="proprietaire"
                        value={contactData.proprietaire}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {acquisition === "GESTION" && contactData.contrat ? (
                    <div></div>
                  ) : (
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Télécharger le contrat</label>
                      <div className="large">
                        <input
                          accept=".pdf"
                          className="large"
                          type="file"
                          name="contrat"
                          id="contrat"
                          ref={fileInput}
                          onChange={(event) =>
                            setPDFFile(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Numéro contrat</label>
                    <input
                      type="text"
                      className="large ng-pristine ng-valid ng-touched"
                      name="numeroContrat"
                      value={contactData.numeroContrat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Type contrat</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="type_contrat"
                          value={contactData.type_contrat}
                          onChange={handleChange}
                        >
                          <option>Type contrat</option>
                          <option value="COURTE DUREE">COURTE DUREE</option>
                          <option value="MOYENNE DUREE">MOYENNE DUREE</option>
                          <option value="LONGUE DUREE">LONGUE DUREE</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Date début</label>
                    <div className="large">
                      <input
                        id="bdate"
                        type="date"
                        name="date_debut_contrat_gestion"
                        value={contactData.date_debut_contrat_gestion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Date fin</label>
                    <div className="large">
                      <input
                        id="bdate"
                        type="date"
                        name="date_fin_contrat_gestion"
                        value={contactData.date_fin_contrat_gestion}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group ng-star-inserted">
                    <label className="form">Rente mensuel à verser</label>
                    <input
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      pinputtext=""
                      pkeyfilter="int"
                      type="number"
                      min="0"
                      placeholder="Montant à verser"
                      name="montantAVerser"
                      value={contactData.montantAVerser}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        F.CFA
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {contactData.type_acquisition === "LEASING" && (
              <div className="form-content right ng-star-inserted">
                <div className="form-block">
                  <label className="title-content">Acquisition leasing</label>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Numéro du contrat</label>
                    <div className="large">
                      <input
                        type="text"
                        placeholder="Numéro du contrat"
                        className="ng-pristine ng-invalid ng-touched"
                        name="numeroContrat"
                        value={contactData.numeroContrat}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {acquisition === "LEASING" && contactData.contrat ? (
                    <div></div>
                  ) : (
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Télécharger le contrat</label>
                      <div className="large">
                        <input
                          accept=".pdf"
                          className="large"
                          type="file"
                          name="contrat"
                          id="contrat"
                          ref={fileInput}
                          onChange={(event) =>
                            setPDFFile(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {contactData.type_acquisition === "LOCATION" && (
              <div className="form-content right ng-star-inserted">
                <div className="form-block">
                  <label className="title-content">Acquisition location</label>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Fournisseur</label>
                    <div className="large">
                      <input
                        type="text"
                        placeholder="Fournisseur"
                        className="ng-pristine ng-invalid ng-touched"
                        name="fournisseur"
                        value={contactData.fournisseur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {acquisition === "LOCATION" && contactData.contrat ? (
                    <div></div>
                  ) : (
                    <div className="form-group col2 ng-star-inserted">
                      <label className="form">Télécharger le contrat</label>
                      <div className="large">
                        <input
                          accept=".pdf"
                          className="large"
                          type="file"
                          name="contrat"
                          id="contrat"
                          ref={fileInput}
                          onChange={(event) =>
                            setPDFFile(event.target.files[0])
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Numéro contrat</label>
                    <input
                      type="text"
                      className="large ng-pristine ng-valid ng-touched"
                      name="numeroContrat"
                      value={contactData.numeroContrat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Type contrat</label>
                    <div className="large">
                      <div className="box-select">
                        <select
                          name="type_contrat_location"
                          value={contactData.type_contrat_location}
                          onChange={handleChange}
                          require
                        >
                          <option>Type contrat</option>
                          <option value="COURTE DUREE">COURTE DUREE</option>
                          <option value="MOYENNE DUREE">MOYENNE DUREE</option>
                          <option value="LONGUE DUREE">LONGUE DUREE</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Kilométrage</label>
                    <input
                      className="inputGroup ng-untouched ng-pristine ng-valid"
                      pinputtext=""
                      pkeyfilter="int"
                      type="number"
                      min="0"
                      name="kilometrage_location"
                      value={contactData.kilometrage_location}
                      onChange={handleChange}
                    />
                    <div className="labelGroup">
                      <span className="labelGroupText" id="basic-addon2">
                        Km
                      </span>
                    </div>
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Date debut</label>
                    <div className="large">
                      <input
                        id="bdate"
                        type="date"
                        name="date_debut_contrat_location"
                        value={contactData.date_debut_contrat_location}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col6 ng-star-inserted">
                    <label className="form">Date fin</label>
                    <div className="large">
                      <input
                        id="bdate"
                        type="date"
                        name="date_fin_contrat_location"
                        value={contactData.date_fin_contrat_location}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Location journalière</label>
                    <div className="large">
                      <input
                        className="large ng-untouched ng-pristine ng-valid"
                        placeholder="Montant journalier"
                        type="number"
                        min="0"
                        name="location_journaliere"
                        value={contactData.location_journaliere}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Coût global de location</label>
                    <div className="large">
                      <input
                        className="large ng-untouched ng-pristine ng-valid"
                        placeholder="Coût global de location"
                        type="number"
                        min="0"
                        name="location_global"
                        value={contactData.location_global}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VehicleEdit;
