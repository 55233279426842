import React, { useState } from "react";
import { toast, Bounce } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useMobile from "../../hooks/useMobile";
import "../../../../assets/css/registration/style.css";
import axiosPrivate from "../../../services/api/axios";
import { encrypt } from "../../../module/myCrypto";
import Logo from "../../../../assets/images/raymobileci.avif";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = () => {
    navigate("/login");
  };

  const handleChange = (event) => {
    // console.log(event);
    setFormData(() => ({
      ...formData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    // Au moins un caractère spécial
    let specialCharCheck = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // Au moins un chiffre
    let numberCheck = /\d/;
    // Au moins une lettre majuscule
    let upperCaseCheck = /[A-Z]/;
    if (formData.password === "" || formData.confirm_password === "") {
      err.password = "Mot de passe et confirmation du mot de passe requis!";
    }
    if (formData.password !== formData.confirm_password) {
      err.password = toast.error("😡 Les mots de passe sont différents!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (formData.password.length < 10) {
      err.password = toast.error(
        "😡 Le mot de passe doit compter plus de 10 caractères!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!specialCharCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un caratère spécial!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!numberCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins un chiffre!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (!upperCaseCheck.test(formData.password)) {
      err.password = toast.error(
        "😡 Le mot de passe doit contenir au moins une lettre majuscule!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let isValid = validateForm();

    if (isValid) {
      await axiosPrivate
        .put(`auth/password-reset/${token}`, {
          password: encrypt(formData.password),
        })
        .then((response) => {
          if (response.data.code === 100) {
            navigate("/login");
            return toast.success(response.data.msg, { theme: "colored" });
          } else {
            if (response.data.code === 101)
              return toast.error(response.data.msg, { theme: "colored" });
            if (response.data.code === 102)
              return toast.error(response.data.msg, { theme: "colored" });
            if (response.data.code === 103)
              return toast.error(response.data.msg, { theme: "colored" });
            if (response.data.code === 104)
              return toast.error(response.data.msg, { theme: "colored" });
            if (response.data.code === 105)
              return toast.error(response.data.msg, { theme: "colored" });
          }
        });
    } else {
      toast("Veuillez remplir tous les champs");
    }
  };

  return (
    <main>
      <div className="box">
        <div className="inner-box">
          <div className="forms-wrap">
            <form
              encType="multipart/form-data"
              onSubmit={submitHandler}
              className="sign-in-form"
            >
              <div className="logo">
                <img src={Logo} alt="easyclass" />
                <h4>Raymobile-CI</h4>
              </div>

              <div className="heading">
                <h2 style={{ color: "#4800ff" }}>Reinitialiser mon compte </h2>
                <h6>
                  Veuillez entrer vos mot de passe pour reïnitialiser votre
                  compte
                </h6>
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Veuillez entrer votre mot de passe"
                    value={formData.password}
                    onChange={handleChange}
                    name="password"
                    required
                  />
                  <span className="password-wraps" onClick={toggleShowPassword}>
                    <i
                      className={`fa ${
                        showPassword
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }`}
                    ></i>
                  </span>
                </div>
                <div className="input-wrap">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Veuillez entrer le mot de passe"
                    value={formData.confirm_password}
                    onChange={handleChange}
                    name="confirm_password"
                    required
                  />
                  <span className="password-wraps" onClick={toggleShowPassword}>
                    <i
                      className={`fa ${
                        showPassword
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye"
                      }`}
                    ></i>
                  </span>
                </div>
                <input
                  type="submit"
                  value="Reinitialiser"
                  className="sign-btn"
                />

                <p className="sign-in">
                  Vous avez un compte ?
                  <a href="#" className="toggle" onClick={handleRegister}>
                    Connectez-vous
                  </a>
                </p>
              </div>
            </form>
          </div>
          <div className="carousel">
            <div className="images-wrapper">
              <img src={Logo} alt="" className="image img-1 show" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
