import axios from "axios";
const BASE_URL = "https://apiserver.raymobileci.com/api/";

/*axios.create({
  baseURL: BASE_URL,
});*/

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});



export default axiosPrivate;
