import React from 'react'
import Sidebar from '../../../components/FormComponent/Sidebar';
import RegisterForm from '../../../components/RegisterForm';

const RegisterPage = () => {
  return (
    <div className="registerBody">
        <RegisterForm />
    </div>
  )
}

export default RegisterPage;