import { createContext, useContext, useEffect, useState } from "react";
import axiosPrivate from "../services/api/axios";

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState("Arcade");
  const [duration, setDuration] = useState("");
  const [addOns, setAddOns] = useState([]);
  const [companie, setCompanie] = useState("");
  const [authState, setAuthState] = useState({
    user: null,
    loggedIn: false,
  });


  useEffect(() => {
    const fetchProtectedData = async () => {
      let token = localStorage.getItem("token");

      if (!token && window.location.pathname !== "/login") {
        window.location.href = "/login";
        return;
      }

      try {
        await axiosPrivate
          .get(`users/get-user-token/${token}`, {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            withCredentials: true,
            method: "GET",
          })
          .then((response) => {
            if (response.data.autoLogout) {
              localStorage.removeItem("token");
              window.location.href = "/login";
            } else if (response) {
              setAuthState((prevState) => ({
                ...prevState,
                user: response.data.user,
                loggedIn: response.data.loggedIn,
              }));
            }
          });
      } catch (error) {
        console.error("Erreur de récupération des données protégées:", error);
        localStorage.removeItem("token");
      }
    };

    fetchProtectedData();
  }, []);

  const logout = () => {
    setAuthState((prevState) => ({
      ...prevState,
      user: null,
      loggedIn: false,
    }));
  };

  return (
    <AuthContext.Provider
      value={{
        step,
        setStep,
        duration,
        setDuration,
        plan,
        setPlan,
        addOns,
        setAddOns,
        companie,
        setCompanie,
        authState,
        setAuthState,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
