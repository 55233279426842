import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import add from "../../../../../assets/icon40/ray-add.svg";
import pdf from "../../../../../assets/icon40/ray-pdf.svg";
import excel from "../../../../../assets/icon40/excel.svg";
import attachement from "../../../../../assets/icon40/prints.svg";
import iconEdit from "../../../../../assets/icons/dropdown-edit.svg";
import iconDelete from "../../../../../assets/icons/dropdown-delete.svg";
import { useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import { useAuth } from "../../../../context/AuthProvider";
import ChargeModal from "../../components/ChargeModal";
import DeleteChargeModal from "../../components/DeleteChargeModal";
import defaultLogo from "../../../../../assets/images/raymobileci.jpeg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { decrypt } from "../../../../module/myCrypto";

const ListVisiteTechnique = () => {
  const { companieID } = useParams();
  const [carDatas, setCardata] = useState([]);
  const flag = useRef(false);
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modal, setModal] = useState([]);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [chargeModal, setChargeModal] = useState([]);
  const [allVisiteData, setAllVisiteData] = useState([]);
  const [sumAllVisite, setSumAllVisite] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [numVisite, setNumVisite] = useState("");
  const [numCar, setNumCar] = useState("");
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const {
    authState: { user },
  } = useAuth();

  useEffect(() => {
    const fetchVisiteData = async () => {
      await axiosPrivate
        .get(`charges/all-visite-technique/${companieID}`)
        .then((res) => {
          setAllVisiteData(res.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchSumVisiteData = async () => {
      await axiosPrivate
        .get(`charges/sum-visite-technique/${companieID}`)
        .then((res) => {
          setSumAllVisite(res.data[0]);
        })
        .catch((err) => console.log(err));
    };

    fetchVisiteData();
    fetchSumVisiteData();
    const intervalId = setInterval(() => {
      fetchVisiteData();
      fetchSumVisiteData();
    }, 1000); // Mettez à jour toutes les 5 secondes

    // N'oubliez pas de nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      if ((numVisite || numCar || companieID) && !isCancelled) {
        setIsSearching(true);
        const response = await axiosPrivate.get(
          "charges/search-visite-technique",
          {
            params: { numVisite, numCar, companieID },
          }
        );
        if (!isCancelled) {
          setSearchResults(response.data);
        }
      } else {
        setIsSearching(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [numVisite, numCar, companieID]);

  const handleClickOpen = () => {
    navigate(`/charges/visite-technique/create/${companieID}`);
  };

  function handleOnClickModal(
    numVisite,
    attachement,
    vehicleID,
    cartegrise,
    companie
  ) {
    setShowDetailModal(true);
    setModal([numVisite, attachement, vehicleID, cartegrise, companie]);
  }

  function handleOnClickChargeModal(numVisite, vehicleID, companie, content) {
    setShowChargeModal(true);
    setChargeModal([numVisite, vehicleID, companie, content]);
  }

  const nombreSumAllAssurance =
    sumAllVisite.total_montant?.toLocaleString("fr-FR");

    const handlePDFClick = async () => {
      // Créez une instance de jsPDF en format paysage
      const doc = new jsPDF("landscape");
    
      const companieID = decrypt(user.companyID);
      const companieName = decrypt(user.companyName);
      const companieLogo = user.companyLogo;
    
      const image = companieLogo
        ? `https://apiserver.raymobileci.com/${companieID}/images/logos/${companieLogo}`
        : defaultLogo;
    
      doc.addImage(image, "JPEG", 10, 5, 60, 45);
    
      doc.setFont(undefined, "bold");
      doc.setTextColor(31, 59, 212); // Définissez la couleur du texte en bleu
      doc.text(companieName, 210, 25);
    
      // Ajoutez un titre
      doc.setFont("helvetica"); // Définissez la police
      doc.setFontSize(18); // Définissez la taille de la police
      doc.setTextColor(0, 93, 148); // Définissez la couleur du texte en bleu
      const title = "Liste des visite technique";
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      doc.text(title, pageWidth / 2, 40, { align: "center" }); // Centrez le titre
    
      const headers = [
        "",
        "Véhicules",
        "N° Visite",
        "Montant",
        "Date début",
        "Date fin",
      ];
    
      const tableData = isSearching ? searchResults.map((result) => {
    
        return [
          "",
          result["vehicleID"],
          result["numVisite"],
          result["montant_visite"],
          moment(result["date_debut"]).format("DD/MM/YYYY"),
          moment(result["date_fin"]).format("DD/MM/YYYY"),
        ];
      }) : allVisiteData.map((visites) => {
    
        return [
          "",
          visites["vehicleID"],
          visites["numVisite"],
          visites["montant_visite"],
          moment(visites["date_debut"]).format("DD/MM/YYYY"),
          moment(visites["date_fin"]).format("DD/MM/YYYY"),
        ];
      });
    
      doc.autoTable({
        startY: 50, // Déplacez le début du tableau vers le bas pour faire de la place pour le titre
        head: [headers],
        body: tableData,
      });
    
      doc.save("liste-visite-technique.pdf");
    };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title ng-star-inserted">
            <h2>Visite technique</h2>
          </div>
          <div className="links">
            <a href="" title="Ajouter Vignette" className="ng-star-inserted">
              <img src={add} alt="" onClick={handleClickOpen} />
            </a>
            <a title="Exporter en PDF" className="ng-star-inserted">
              <img src={pdf} alt="" onClick={handlePDFClick} style={{ cursor: "pointer" }} />
            </a>
            <a href="" title="Exporter en excel" className="ng-star-inserted">
              <img src={excel} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="ng-star-inserted">
        <div className="ui-widget-header ng-star-inserted">
          <p>
            <a
              aria-controls="collapseExample"
              aria-expanded={isOpen ? "true" : "false"}
              className={
                isOpen ? "collapse-filtre" : "collapse-filtre collapsed"
              }
              data-toggle="collapse"
              href="#collapseExample"
              onClick={handleClick}
            >
              Filtrer
            </a>
          </p>
          {isOpen && (
            <div className="collapse show" id="collapseExample">
              <div className="bloc-filtre55">
                <span>Par Véhicule</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par immatriculation"
                  name="numCar"
                  value={numCar}
                  onChange={(e) => setNumCar(e.target.value)}
                />
              </div>
              <div className="bloc-filtre55">
                <span>Par Visite technique</span>
                <input
                  type="text"
                  className="ng-untouched ng-pristine ng-valid"
                  placeholder="Rechercher par Visite technique"
                  name="numVisite"
                  value={numVisite}
                  onChange={(e) => setNumVisite(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-blue">{allVisiteData.length}</span>
            <span className="label">Nombre d'enregistrement</span>
          </div>
          <div className="recap5">
            <span className="value c-black"></span>
            <span className="value c-red">{nombreSumAllAssurance}</span>
            <span className="label">Montant Total</span>
          </div>
        </div>
        <div className="ui-table ui-widget ui-table-resizable ui-table-resizable-fit ui-table-hoverable-rows">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-thead">
                <tr className="ng-star-inserted">
                  <th
                    style={{ width: "40px", padding: ".3rem .3rem" }}
                    className="ng-star-inserted"
                  ></th>
                  <th className="ui-sortable-column ng-star-inserted">
                    N° Visite
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Véhicule
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Montant
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date de début
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    Date échéance
                  </th>
                  <th className="ui-sortable-column ng-star-inserted">
                    commentaire
                  </th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {isSearching === false
                  ? allVisiteData.map((visites) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [visites.numVisite]:
                                          !subMenuOpen[visites.numVisite],
                                      })
                                    }
                                    open={!!subMenuOpen[visites.numVisite]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[visites.numVisite] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/charges/visite-technique/edit/${visites.numVisite}/${visites.vehicleID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          visites.numVisite,
                                          visites.vehicleID,
                                          companieID,
                                          "La visite technique"
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {visites.numVisite}
                          </td>
                          <td className="ng-star-inserted">
                            {visites.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {visites.montant_visite}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(visites.date_debut).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(visites.date_fin).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {visites.commentaire}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    visites.numVisite,
                                    visites.attachement,
                                    `La visite technique`,
                                    visites.vehicleID,
                                    companieID
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  : searchResults.map((result) => {
                      return (
                        <tr
                          className="ui-selectable-row ng-star-inserted"
                          tabIndex="0"
                        >
                          <td
                            style={{ width: "40px", padding: ".3rem .3rem" }}
                            className="ng-star-inserted"
                          >
                            <div
                              className={
                                subMenuOpen ? "dropdown show" : "dropdown"
                              }
                            >
                              <button
                                aria-expanded="true"
                                aria-haspopup="true"
                                className="btn btn-default btn-xs dropdown-toggle-no-caret"
                                data-toggle="dropdown"
                                id="dropdownMenu1"
                                type="button"
                              >
                                <span className="glyphicon glyphicon-option-vertical">
                                  <i
                                    className="fa-solid fa-ellipsis-vertical"
                                    onClick={() =>
                                      setSubMenuOpen({
                                        ...subMenuOpen,
                                        [result.numVisite]:
                                          !subMenuOpen[result.numVisite],
                                      })
                                    }
                                    open={!!subMenuOpen[result.numVisite]}
                                  ></i>
                                </span>
                              </button>
                              {subMenuOpen[result.numVisite] ? (
                                <ul
                                  aria-labelledby="dropdownMenu1"
                                  className={
                                    subMenuOpen
                                      ? "dropdown-menu dropdown-menu-right show"
                                      : "dropdown-menu dropdown-menu-right"
                                  }
                                  x-placement="top-end"
                                  style={{
                                    position: "absolute",
                                    willChange: "transform",
                                    top: "0px",
                                    left: "0px",
                                    transform: "translate3d(0px, 38px, 0px)",
                                  }}
                                >
                                  <li className="c-blue ng-star-inserted">
                                    <a
                                      href={`/charges/visite-technique/edit/${result.numVisite}/${result.vehicleID}`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img src={iconEdit} />
                                      Modifier
                                    </a>
                                  </li>
                                  <li
                                    className="divider ng-star-inserted"
                                    role="separator"
                                  ></li>
                                  <li className="c-red ng-star-inserted">
                                    <a
                                      data-target="#popupDelete"
                                      data-toggle="modal"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleOnClickChargeModal(
                                          result.numVisite,
                                          result.vehicleID,
                                          companieID,
                                          "La visite technique"
                                        )
                                      }
                                    >
                                      <img src={iconDelete} />
                                      Supprimer
                                    </a>
                                  </li>
                                </ul>
                              ) : (
                                <ul></ul>
                              )}
                            </div>
                          </td>
                          <td className="ng-star-inserted">
                            {result.numVisite}
                          </td>
                          <td className="ng-star-inserted">
                            {result.vehicleID}
                          </td>
                          <td className="ng-star-inserted">
                            {result.montant_visite}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_debut).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {new Date(result.date_fin).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td className="ng-star-inserted">
                            {result.commentaire}
                          </td>
                          <td className="ng-star-inserted">
                            <span className="ng-star-inserted">
                              <a
                                data-target="#popupAttachement"
                                data-toggle="modal"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleOnClickModal(
                                    result.numVisite,
                                    result.attachement,
                                    `La visite technique`,
                                    result.vehicleID,
                                    companieID
                                  )
                                }
                              >
                                <img src={attachement} />
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div className="ui-paginator-bottom ui-paginator ui-widget ui-widget-header ui-unselectable-text ui-helper-clearfix ng-star-inserted">
            <a
              className="ui-paginator-first ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-backward"></span>
            </a>
            <a
              className="ui-paginator-prev ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-left"></span>
            </a>
            <span className="ui-paginator-pages">
              <a
                className="ui-paginator-page ui-paginator-element ui-state-default ui-corner-all ui-state-active ng-star-inserted"
                tabIndex="0"
              >
                1
              </a>
            </span>
            <a
              className="ui-paginator-next ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-caret-right"></span>
            </a>
            <a
              className="ui-paginator-last ui-paginator-element ui-state-default ui-corner-all ui-state-disabled"
              tabIndex="-1"
            >
              <span className="ui-paginator-icon pi pi-step-forward"></span>
            </a>
          </div>
          <div
            className="ui-column-resizer-helper ui-state-highlight ng-star-inserted"
            style={{ display: "none" }}
          ></div>
        </div>
      </div>
      {showDetailModal && setModal != null && (
        <ChargeModal closeContratModal={setShowDetailModal} data={modal} />
      )}
      {showChargeModal && setChargeModal != null && (
        <DeleteChargeModal
          closeDeleteChargeModal={setShowChargeModal}
          data={chargeModal}
        />
      )}
    </>
  );
};

export default ListVisiteTechnique;
