import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../../../assets/css/home.css";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt } from "../../../module/myCrypto";
import { FaTaxi, FaLandmark, FaUsers, FaUserTag, FaVestPatches, FaChevronDown, FaChevronUp, FaTools, FaArchive, FaDiceD20, FaDna, FaExclamation, FaWrench, FaFirstOrder, FaCogs, FaCog, FaCarCrash, FaExpeditedssl, FaMoneyBill, FaCashRegister, FaWallet, FaCalendarDay } from "react-icons/fa";

const Sidebar = ({ inactive }) => {
  const [open, setOpen] = useState(null);
  const [activeMenu, setActiveMenu] = useState('');
  const [activeSubMenu, setActiveSubMenu] = useState('');
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const {
    authState: { user },
  } = useAuth();

  const companyID = decrypt(user.companyID);
  const userID = decrypt(user.userID);
  const role = decrypt(user.role);

  const SidebarData = [
    {
      path: "/",
      exact: true,
      icon: <FaLandmark />,
      name: "Accueil",
      section: "",
      navId: 1,
    },
    {
      path: `/flottes/vehicules/${companyID}`,
      exact: true,
      icon: <FaTaxi />,
      name: "Véhicule",
      section: "flottes",
      navId: 2,
    },
    {
      path: "#",
      exact: true,
      icon: <FaUsers />,
      name: "Personnels",
      section: "personnels",
      navId: 3,
      childrens: [
        {
          path: `/personnels/conducteurs/${companyID}`,
          name: "Conducteurs",
          icon: <FaVestPatches />,
        },
        {
          path: `/personnels/utilisateur/${companyID}`,
          name: "Utilisateurs",
          icon: <FaUserTag />,
        },
      ],
    },
    {
      path: "#",
      exact: true,
      icon: <FaArchive />,
      name: "Charges",
      section: "charges",
      navId: 4,
      childrens: [
        {
          path: `/charges/assurances/${companyID}`,
          name: "Assurances",
          icon: <FaExclamation />,
        },
        {
          path: `/charges/carte-grise/${companyID}`,
          name: "Carte grise",
          icon: <FaDna />,
        },
        {
          path: `/charges/vignettes/${companyID}`,
          name: "Vignettes",
          icon: <FaDiceD20 />,
        },
        {
          path: `/charges/visite-technique/${companyID}`,
          name: "Visites techniques",
          icon: <FaFirstOrder />,
        },
      ],
    },
    {
      path: "/maintenances",
      exact: true,
      icon: <FaTools />,
      name: "Maintenances",
      section: "maintenances",
      navId: 5,
      childrens: [
        {
          path: `/maintenances/demande-intervention/${companyID}`,
          name: "Demandées",
          icon: <FaCogs />,
        },
        {
          path: `/maintenances/intervention-en-cours/${companyID}`,
          name: "En cours",
          icon: <FaWrench />,
        },
        {
          path: `/maintenances/intervention-termine/${companyID}`,
          name: "Terminées",
          icon: <FaCog />,
        }
      ],
    },
    {
      path: "/finances",
      exact: true,
      icon: <FaMoneyBill />,
      name: "Finances",
      section: "finances",
      navId: 6,
      childrens: [
        {
          path: `/finances/caisses/encaissement/${companyID}`,
          name: "Encaissement",
          icon: <FaCashRegister />,
        },
        {
          path: `/finances/caisses/decaissement/${companyID}`,
          name: "Decaissement",
          icon: <FaWallet />,
        }
      ],
    },
    {
      path: `/versements/${companyID}`,
      path: "/versements",
      exact: true,
      icon: <FaCashRegister />,
      name: "Versements",
      section: "versements",
      navId: 7,
    },
    {
      path: `/planning/planification/${companyID}`,
      exact: true,
      icon: <FaCalendarDay />,
      name: "Planification",
      section: "planning",
      navId: 8,
    },
    {
      path: `/administratif/constatation/${companyID}`,
      exact: true,
      icon: <FaCarCrash />,
      name: "Constatations",
      section: "administratif",
      navId: 9,
    },
    {
      path: `/companie/profile/${companyID}/${userID}`,
      exact: true,
      icon: <FaExpeditedssl />,
      name: "Compte entreprise",
      section: "compte",
      navId: 10,
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;
    const splitPath = path.split("/");
    setActiveMenu(splitPath[1]);
    setActiveSubMenu(path);
    const activeItem = SidebarData.find(item => item.section === splitPath[1]);
    if (activeItem) {
      setOpen(activeItem.navId);
    }
  }, []);

  const toggle = (navId) => {
    setOpen(open === navId ? null : navId);
  };

  return (
    <div className={`menu ${inactive ? 'inactive' : ''}`}>
      <ul>
        {SidebarData.map((item, index) => {
          if (item.childrens) {
            return (
              <li
                className={""}
                onClick={() => toggle(item.navId)}
                key={index}
              >
                <a href="#">
                  <i className="icon">{item.icon}</i>
                  <span className="text">{item.name}</span>
                  {open === item.navId ? (
                    <FaChevronUp className="arrow" />
                  ) : (
                    <FaChevronDown className="arrow" />
                  )}
                </a>
                {open === item.navId && (
                  <ul className="sub-menu">
                    {item.childrens.map((child, index) => (
                      <li
                        key={index}
                        className={
                          activeSubMenu === child.path ? "active" : ""
                        }
                      >
                        <a href={child.path}>
                          <i className="icon">{child.icon}</i>
                          <span className="text">{child.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            );
          } else {
            // Si le rôle est admin et que l'index est le dernier élément, alors afficher le menu
            if (role === 'admin' && index === SidebarData.length - 1) {
              return (
                <li className={activeMenu === item.section ? "active" : ""}>
                  <a href={item.path || "#"}>
                    <i className="icon">{item.icon}</i>
                    <span className="text">{item.name}</span>
                  </a>
                </li>
              );
            }
            // Sinon, afficher tous les autres menus
            else if (index !== SidebarData.length - 1) {
              return (
                <li className={activeMenu === item.section ? "active" : ""}>
                  <a href={item.path || "#"}>
                    <i className="icon">{item.icon}</i>
                    <span className="text">{item.name}</span>
                  </a>
                </li>
              );
            }
          }
        })}
      </ul>
    </div>
  );
  
};

export default Sidebar;
