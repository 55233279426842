import React, { useState } from "react";
import "../../../../assets/css/home.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt } from "../../../module/myCrypto";
import logoClient from "../../../../assets/images/raymobileci.avif";
import userImage from "../../../../assets/images/avt.jpg";
import Sidebar from "../components/Sidebar";
import { FaSignOutAlt, FaUniversalAccess, FaUnlockAlt, FaEdit, FaGasPump } from "react-icons/fa";

const HomePage = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [inactive, setInactive] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [showModal, setShowModal] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const toggleSidebarStyle = inactive ? { width: '75px' } : { width: '265px' };
  const sidebarContentStyle = inactive ? { left: '75px' } : { left: '250px' };
  const divisionLeftStyle = inactive ? { width: '75px' } : { width: '250px' };

  const {
    authState: { user },
  } = useAuth();

  const nom = decrypt(user.nom);
  const prenom = decrypt(user.prenom);
  const role = decrypt(user.role);
  const company = decrypt(user.companyName);
  const companieID = decrypt(user.companyID);
  const userID = decrypt(user.userID);
  const companieLogo = decrypt(user.companyLogo);
  const userPhoto = decrypt(user.photo);

  const handleDeconnectProfile = () => {
    localStorage.removeItem("token");
    window.location.href = "/login"; // redirige vers la page de connexion
  };

  return (
    <body>
      <div className="ng-star-inserted">
        <div class="ui-toast">
          <div className="topContainer">
            <div className="inputBox">
              <div className="header-logo">
                <img src={logoClient} alt="" />
              </div>
              <h1 className="header-title">
                <span className="raymobile">RAYMOBILE</span>
                <span className="ci">-CI</span>
              </h1>
            </div>

            <div className="profileContainer">
              <div className="profileImage">
                {userPhoto ? (
                  <img
                    src={`https://apiserver.raymobileci.com/${companieID}/Users/avatars/${userPhoto}`}
                    alt=""
                  />
                ) : (
                  <img src={userImage} alt="" />
                )}
              </div>
              <div className="profileInfo">
                <p
                  className="profileName"
                  onClick={toggleOpen}
                  onMouseLeave={toggleOpen}
                >
                  {prenom.split(" ").pop()} {nom}
                </p>
                <span className="adminLabel">ADMIN</span>
              </div>
              {isOpen && (
                <div className="menuContainer">
                  <ul>
                    <li>
                      <a
                        href={`/personnels/users/profile/${companieID}/${userID}`}
                      >
                        <FaUniversalAccess className="icons"  /> Mon profil
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/personnels/users/edit/${companieID}/${userID}`}
                      >
                        <FaEdit className="icons"  /> Editer profil
                      </a>
                    </li>
                    <li>
                      <a
                        href={`/personnels/users/reset-password/${companieID}/${userID}`}
                      >
                        <FaUnlockAlt className="icons"  /> Editer accès
                      </a>
                    </li>
                    <li>
                      <a href="" onClick={handleDeconnectProfile}>
                        <FaSignOutAlt className="icons"  /> Déconnexion
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div id="toggleSideBar" style={toggleSidebarStyle}>
          <div id="divisionLeft" style={divisionLeftStyle}>
            <div className="head">
              <div className="user-img">
                {companieLogo ? (
                  <img
                    src={`https://apiserver.raymobileci.com/administrations/logo-company/${companieLogo}`}
                    alt=""
                  />
                ) : (
                  <img src={logoClient} alt="" />
                )}
              </div>
              <div className={inactive ? 'user-details inactive' : 'user-details'}>
                <p className="company" title="Profile de l'entreprise">
                  {company}
                </p>
                <p className="title"></p>
                <p className="name" title="Voir mon profil">
                  {prenom.split(" ").pop()} {nom}
                </p>
              </div>
            </div>
            <Sidebar inactive={inactive} />
          </div>
          <div className={inactive ? 'menu-btn inactive' : 'menu-btn'}>
            <i
              className="bx bx-chevron-left"
              onClick={() => setInactive(!inactive)}
            ></i>
          </div>
        </div>
        <div id="sidebarContent" style={sidebarContentStyle}>
          <content>
            <div id="content">
              <Outlet />
            </div>
          </content>
        </div>
      </div>
    </body>
  );
};

export default HomePage;
