import { Calendar, Views, Navigate } from 'react-big-calendar';
import { useState } from 'react';

const CustomToolbar = ({ onNavigate, onView, label }) => {
    const goToBack = () => {
        onNavigate(Navigate.PREVIOUS);
    };

    const goToNext = () => {
        onNavigate(Navigate.NEXT);
    };

    const goToToday = () => {
        onNavigate(Navigate.TODAY);
    };

    const changeView = (view) => {
        onView(view);
    };

    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-control" onClick={goToBack}><i className="fa fa-arrow-left"></i> Préc</button>
            </span>
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-today" onClick={goToToday}>Aujourd'hui</button>
            </span>
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-control" onClick={goToNext}>Suiv <i className="fa fa-arrow-right"></i></button>
            </span>
            <span className="rbc-toolbar-label">{label}</span>
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-day" onClick={() => changeView(Views.DAY)}>Jour</button>
            </span>
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-week" onClick={() => changeView(Views.WEEK)}>Semaine</button>
            </span>
            <span className="rbc-btn-group">
                <button type="button" className="btn btn-month" onClick={() => changeView(Views.MONTH)}>Mois</button>
            </span>
        </div>
    )
}

export default CustomToolbar;