import React, { useEffect, useRef, useState } from "react";
import "../../../../assets/css/home.css";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt, encrypt } from "../../../module/myCrypto";
import axiosPrivate from "../../../services/api/axios";
import pdf from "../../../../assets/icon40/ray-pdf.svg";
import list from "../../../../assets/icon40/list.svg";
import add from "../../../../assets/icons/add.svg";
import moment from "moment";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CarteGrise = () => {
  const fileInput = useRef();
  const [carteGriseDatas, setCarteGriseDatas] = useState([]);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [data, setData] = useState(null); // Ajoutez cet état pour stocker les données
  const [contrat, setContrat] = useState("");
  const [contactData, setContactData] = useState({
    vehicleID: "",
    numero: "",
    montantTTC: "",
    date_debut: "",
    date_fin: "",
    commentaire: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const getAssurance = async () => {
      await axiosPrivate
        .get(`charges/carte-grise-deadline/${companyId}`)
        .then((res) => {
          setCarteGriseDatas(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAssurance();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOpenModalClick = (numPolice, vehicleID) => {
    // Votre logique ici...
    setData([numPolice, vehicleID]);
    setIsOpen(true);
  };

  const handleCloseModalClick = () => {
    setIsOpen(false);
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", contrat);
      const res = await axiosPrivate.post("uploadCarteGrise", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.numero === "") {
      err.numero = toast.error(
        "😡 Veuillez entrer le numero de la carte grise!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.montantTTC === "") {
      err.montantTTC = toast.error(
        "😡 Veuillez entrer le montant de la carte grise!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.date_debut === "") {
      err.date_debut = toast.error("😡 Veuillez choisir la date de debut!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_fin === "") {
      err.date_fin = toast.error("😡 Veuillez selectionner la date de fin", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    let datas;

    let contratUrl = "";

    if (contrat) contratUrl = await uploadContrat();

    if (isValid) {
      await axiosPrivate
        .post("charges/carte-grise/create", {
          companieID: user.companyID,
          userID: user.userID,
          vehicleID: encrypt(data[1]),
          numero: encrypt(contactData.numero),
          montantTTC: encrypt(contactData.montantTTC),
          date_debut: encrypt(contactData.date_debut),
          date_fin: encrypt(contactData.date_fin),
          commentaire: contactData.commentaire,
          contratUrl: contratUrl,
        })
        .then((response) => {
          if (response.data.code === 101)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 102)
            return toast.error(`😡 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Bounce,
              style: {
                bodyClassName: "grow-font-size",
                progressClassName: "fancy-progress-bar",
              },
            });
          if (response.data.code === 100) {
            toast.success(`👏 ${response.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            setIsOpen(false);
          }
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log(error.response.message);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
    }
  };

  return (
    <>
      <div id="header-main">
        <div className="title">
          <h2>Alertes cartes grises des véhicules</h2>
        </div>
        <div className="links">
          <a href="" title="Exporter en PDF" className="ng-star-inserted">
            <img src={pdf} alt="" />
          </a>
          <a
            href={`/charges/carte-grise/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <div className="ui-widget-header ng-star-inserted">
        <p>
          <a
            aria-controls="collapseExample"
            aria-expanded="false"
            className="collapse-filtre collapsed"
            data-toggle="collapse"
            href="#collapseExample"
          >
            Filtre
          </a>
        </p>
        <div className="collapse" id="collapseExample">
          <div className="bloc-filtre55"></div>
        </div>
        <div id="recap" className="ng-star-inserted">
          <div className="recap5">
            <span className="value c-blue">{carteGriseDatas.length}</span>
            <span className="label">Nombre d'alertes</span>
          </div>
        </div>
        <div className="scroll with-hover with-selected ui-table ui-widget">
          <div className="ui-table-wrapper ng-star-inserted">
            <table>
              <thead className="ui-table-head">
                <tr className="ng-star-inserted">
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Numéro carte grise
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Immatriculation
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Montant
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Date de début
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Date de fin
                  </th>
                  <th
                    className="ui-sortable-column ng-star-inserted"
                    tabIndex="0"
                  >
                    Nombre de jours restants
                  </th>
                  <th
                    style={{ width: "50px", padding: "0.3rem 0.3rem" }}
                    className="ng-star-inserted"
                  ></th>
                </tr>
              </thead>
              <tbody className="ui-table-tbody">
                {carteGriseDatas.map((assurances) => {
                  const dateEcheance = new Date(assurances.date_fin);
                  const today = moment();
                  const diffDays = today.diff(moment(dateEcheance), "days");
                  const daysMessage =
                    diffDays >= 0
                      ? `${diffDays} jours passés depuis la date d'échéance`
                      : `${Math.abs(
                          diffDays
                        )} jours restants jusqu'à la date d'échéance`;

                  return (
                    <tr
                      className="ui-selectable-row ng-star-inserted"
                      tabIndex="0"
                    >
                      <td
                        style={{ width: "40px", padding: ".3rem .3rem" }}
                        className="ng-star-inserted"
                      >
                        {assurances.numCarteGrise}
                      </td>
                      <td className="ng-star-inserted">
                        {assurances.vehicleID}
                      </td>
                      <td className="ng-star-inserted">{assurances.frais}</td>
                      <td className="ng-star-inserted">
                        {new Date(assurances.date_debut).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td className="ng-star-inserted">
                        {new Date(assurances.date_fin).toLocaleDateString(
                          "en-GB"
                        )}
                      </td>
                      <td
                        style={{ fontWeight: "bold" }}
                        className="ng-star-inserted c-red"
                      >
                        {daysMessage}
                      </td>
                      <td className="ng-star-inserted">
                        <a
                          data-target="#popupAddAction-assurance"
                          data-toggle="modal"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleOpenModalClick(
                              assurances.numPolice,
                              assurances.vehicleID
                            )
                          }
                        >
                          <img src={add} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isOpen && data && (
        <div className="ng-star-inserted">
          <div
            aria-labelledby="mySmallModalLabel"
            className="modal fade show"
            id="popupAddAction-assurance"
            tabIndex="-1"
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <form
              novalidate=""
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="ng-untouched ng-pristine ng-valid"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Carte Grise
                    </h5>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={handleCloseModalClick}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group col2">
                      <label className="form">Véhicule</label>
                      <input
                        type="text"
                        className="large addExpress ng-untouched ng-pristine ng-valid"
                        name="vehicleID"
                        value={data[1]}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Numéro </label>
                      <input
                        type="text"
                        placeholder="Numéro"
                        className="ng-pristine ng-invalid ng-touched"
                        name="numero"
                        value={contactData.numero}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Coût de la carte grise</label>
                      <input
                        type="number"
                        min="0"
                        placeholder="Montant de la carte grise"
                        className="inputGroup ng-untouched ng-pristine ng-valid"
                        name="montantTTC"
                        value={contactData.montantTTC}
                        onChange={handleChange}
                      />
                      <div className="labelGroup">
                        <span className="labelGroupText" id="basic-addon2">
                          F.CFA
                        </span>
                      </div>
                    </div>
                    <div className="form-group col2">
                      <label className="form">Date de debut</label>
                      <input
                        type="date"
                        placeholder="Date de debut"
                        className="ng-pristine ng-invalid ng-touched"
                        name="date_debut"
                        value={contactData.date_debut}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Date de fin</label>
                      <input
                        type="date"
                        placeholder="Date de fin"
                        className="ng-pristine ng-invalid ng-touched"
                        name="date_fin"
                        value={contactData.date_fin}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col2">
                      <label className="form">Télécharger la carte grise</label>
                      <input
                        accept=".pdf"
                        className="large"
                        type="file"
                        name="carte"
                        id="carte"
                        ref={fileInput}
                        onChange={(event) => setContrat(event.target.files[0])}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form">Commentaire</label>
                      <textarea
                        name="commentaire"
                        type="text"
                        cols="3"
                        rows="5"
                        className="larg ng-untouched ng-pristine ng-valid"
                        value={contactData.commentaire}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button className="save">Enregistrer</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CarteGrise;
