import React from "react";
import axiosPrivate from "../../../services/api/axios";
import "../../../../assets/css/home.css";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DeleteDriverModal({ closeDeleteDriverModal, data }) {

  

  // Function to handle delete
  const handleDelete = async () => {
    await axiosPrivate
        .post(
          `drivers/delete-driver/${data[0]}/${data[3]}`
        )
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteDriverModal(false);
          }
        })
      .catch(error => console.error('Error deleting data: ', error));
  };

  return (
    <div
      aria-labelledby="mySmallModalLabel"
      className="modal fade show"
      id="popupDelete"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h6 className="center c-red">
              Voulez-vous vraiment supprimer le conducteur {data[1]} {data[2]} ?
            </h6>
            <h6 className="center c-red"></h6>
            <div className="c-red">
              <ul></ul>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel"
              data-dismiss="modal"
              id="confirm-modaldismiss"
              type="cancel"
              onClick={() => closeDeleteDriverModal(false)}
            >
              Annuler
            </button>
            <button className="delete" type="submit" onClick={() => handleDelete()}>
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteDriverModal;
