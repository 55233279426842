import React, { useEffect, useRef, useState } from "react";
import "../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import list from "../../../../../assets/icon40/list.svg";
import { decrypt, encrypt } from "../../../../module/myCrypto";
import { useAuth } from "../../../../context/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../../../../services/api/axios";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";
import * as moment from "moment";

const AddVersement = () => {
  const { event } = useParams();
  const fileInput = useRef();
  const flag = useRef(false);
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [photoDriver, setPhotoDriver] = useState("");
  const [contrat, setContrat] = useState("");
  const [phoneChauffeur, setPhoneChauffeur] = useState("");
  const [contactProche, setContactProche] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [contactData, setContactData] = useState({
    numPermis: "",
    nom: "",
    prenom: "",
    phoneChauffeur: "",
    vehicleID: "",
    marque: "",
    type_transport: "",
    recette: "",
    start: "",
    end: "",
    montant_recette: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  function generateId(length) {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  let id = generateId(6);
  let dates = moment(new Date()).format("DD-MM-YY"); // La date actuelle

  useEffect(() => {
    const getEventID = () => {
      axiosPrivate
        .get(`calendar/find-event/${event}`)
        .then((res) => {
          setContactData({
            ...contactData,
            numPermis: res.data.numPermis,
            nom: res.data.conducteur.nom,
            prenom: res.data.conducteur.prenom,
            phoneChauffeur: res.data.conducteur.contact_chauffeur,
            vehicleID: res.data.vehicleID,
            marque: res.data.vehicule.marque,
            modele: res.data.vehicule.modele,
            type_transport: res.data.vehicule.type_transport,
            recette: res.data.vehicule.recette,
            start: res.data.start,
            end: res.data.end,
          })
        })
        .catch((err) => console.log(err));
      }

      getEventID();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const validateForm = () => {
    let err = {};
    if (contactData.montant_recette === "") {
      err.numPermis = toast.error("😡 Veuillez entrer le montant versé!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    
    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    if (isValid) {

      try {
        axiosPrivate
          .post(
            `versements/add-cash/${event}`,
            {
              companieID: user.companyID,
              userID: user.userID,
              numPermis: encrypt(contactData.numPermis),
              vehicleID: encrypt(contactData.vehicleID),
              montant: encrypt(contactData.montant_recette),
              idEvent: event,
              nom: encrypt(contactData.nom),
              prenom: encrypt(contactData.prenom),
              start: encrypt(moment(contactData.start).format("DD/MM/YYYY")),
              codeVersement: `${"versement"}-${contactData.nom}-${moment(contactData.start).format("DD-MM-YYYY")}-${event}`
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/versements/${companyId}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    }
  };

  return (
    <div className="ng-star-inserted">
      <div id="header-main">
        <div className="title">
          <h2>Versement de {contactData.nom} {contactData.prenom.split(" ").pop()} course du {moment(contactData.start).format("DD/MM/YYYY")}</h2>
        </div>
        <div className="links">
          <a
            href={`/planning/planification/${companyId}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="ng-untouched ng-pristine ng-valid"
      >
        <div className="form-l">
          <div className="form-content ng-star-inserted">
            <div className="form-block">
              <label className="title-content">Information du conducteur</label>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Nom</label>
                <input
                  type="text"
                  placeholder="Nom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="nom"
                  value={contactData.nom}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Prenoms</label>
                <input
                  type="text"
                  placeholder="Prenom du conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="prenom"
                  value={contactData.prenom}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Numéro de téléphone</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero du conducteur"
                  value={contactData.phoneChauffeur}
                  onChange={(phoneChauffeur) =>
                    setPhoneChauffeur(phoneChauffeur)
                  }
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={10}
                  rules={{ required: true }}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Permis N°</label>
                <input
                  type="text"
                  placeholder="Lieu de naissance"
                  className="ng-pristine ng-invalid ng-touched"
                  name="numPermis"
                  value={contactData.numPermis}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <label className="title-content">
                Informations Du véhicule
              </label>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Immatriculation</label>
                <input
                  type="text"
                  placeholder="Numéro de la pièce"
                  className="ng-pristine ng-invalid ng-touched"
                  name="vehicleID"
                  value={contactData.vehicleID}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Type transport</label>
                <input
                  type="text"
                  placeholder="Date de validité"
                  className="ng-pristine ng-invalid ng-touched"
                  name="date_piece"
                  value={contactData.type_transport}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Marque</label>
                <input
                  type="text"
                  placeholder="Addresse géographique conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="adresse_chauffeur"
                  value={contactData.marque}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Modèle</label>
                <input
                  type="text"
                  placeholder="Addresse géographique conducteur"
                  className="ng-pristine ng-invalid ng-touched"
                  name="adresse_chauffeur"
                  value={contactData.modele}
                  onChange={handleChange}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="form-content">
            <div className="form-block ng-star-inserted">
              <label className="title-content">
                Informations du versement
              </label>
              <div className="form-group">
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date début</label>
                  <input
                    type="datetime-local"
                    placeholder="Numéro du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="start"
                    value={moment(contactData.start).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date fin</label>
                  <input
                    type="datetime-local"
                    placeholder="Numéro du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="end"
                    value={moment(contactData.end).format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Recette définie</label>
                  <input
                    type="text"
                    placeholder="Numéro du permis"
                    className="ng-pristine ng-invalid ng-touched"
                    name="date_delivrance_pc"
                    value={contactData.recette}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant de la recette</label>
                  <input
                    type="number"
                    min="0"
                    step="5"
                    placeholder="Entrer le montant"
                    className="ng-pristine ng-invalid ng-touched"
                    name="montant_recette"
                    value={contactData.montant_recette}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddVersement