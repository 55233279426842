import React, { useEffect, useRef, useState } from "react";
import "../../../../assets/css/home.css";
import watch from "../../../../assets/icon40/watch.svg";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt } from "../../../module/myCrypto";
import axiosPrivate from "../../../services/api/axios";
import { Pie } from "react-chartjs-2";
import PieChart from "../components/PieChart";
import MonthlyPaymentChart from "../components/MonthlyPaymentChart";

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const DashboardPage = () => {
  const flag = useRef(false);
  const [carDatas, setCardata] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allDepenseDatas, setAllDepenseDatas] = useState([]);
  const [allVehicleActifDatas, setAllVehicleActifDatas] = useState([]);
  const [allVehicleImmobiliseDatas, setAllVehicleImmobiliseDatas] = useState(
    []
  );
  const [allVehicleAttenteDatas, setAllVehicleAttenteDatas] = useState([]);
  const [sumAcquisition, setSumAcquisition] = useState([]);
  const [sumAdministratif, setSumAdministratif] = useState([]);
  const [sumEnergie, setSumEnergie] = useState([]);
  const [sumMaintenanceDepense, setSumMaintenanceDepense] = useState([]);
  const [sumMaintenance, setSumMaintenance] = useState([]);
  const [assuranceDatas, setAssuranceDatas] = useState([]);
  const [carteGriseDatas, setCarteGriseDatas] = useState([]);
  const [visiteTechniqueDatas, setVisiteTechniqueDatas] = useState([]);
  const [driverPermisDatas, setDriverPermisDatas] = useState([]);
  const [countEventsTaxi, setCountEventsTaxi] = useState("");
  const [countEventsYango, setCountEventsYango] = useState("");
  const [countTaxiVersements, setCountTaxiVersements] = useState([]);
  const [countYangoVersements, setCountYangoVersements] = useState([]);

  const typeDepense = "Acquisition";

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchAllVehicules = async (req, res) => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setCardata(res.data);
          //
        })
        .catch((err) => console.log(err));
    };
    const fetchSumAllDepense = async (req, res) => {
      axiosPrivate
        .get(`depenses/sumAllDepense/${companyId}`)
        .then((res) => {
          setAllDepenseDatas(res.data[0]);
          //
        })
        .catch((err) => console.log(err));
    };
    const fetchAllData = async () => {
      await axiosPrivate
        .get(`depenses/sumAllDepense/${companyId}`)
        .then((res) => {
          setAllDepenseDatas(res.data[0]);
        })
        .catch((err) => console.log(err));
    };
    const fetchData = async () => {
      await axiosPrivate
        .get(`depenses/sumAcquisitionDepense/${companyId}/${typeDepense}`)
        .then((res) => {
          setSumAcquisition(res.data[0]);
        })
        .catch((err) => console.log(err));
    };
    const fetchAdministratif = async () => {
      await axiosPrivate
        .get(`depenses/sumAdministratifDepense/${companyId}/Administratif`)
        .then((res) => {
          setSumAdministratif(res.data[0]);
        })
        .catch((err) => console.log(err));
    };
    const fetchEnergie = async () => {
      await axiosPrivate
        .get(`depenses/sumEnergieDepense/${companyId}/Energie`)
        .then((res) => {
          setSumEnergie(res.data[0]);
        })
        .catch((err) => console.log(err));
    };
    const fetchMaintenance = async () => {
      await axiosPrivate
        .get(`depenses/sumMaintenanceDepense/${companyId}/Maintenance`)
        .then((res) => {
          setSumMaintenanceDepense(res.data[0]);
        })
        .catch((err) => console.log(err));
    };
    const getAllVehiclesActif = async () => {
      axiosPrivate
        .get(`vehicles/getAllVehiclesActif/${companyId}`)
        .then((res) => {
          setAllVehicleActifDatas(res.data);
          //
        })
        .catch((err) => console.log(err));
    };
    const getAllVehiclesImmobilise = async () => {
      axiosPrivate
        .get(`vehicles/getAllVehiclesImmobilise/${companyId}`)
        .then((res) => {
          setAllVehicleImmobiliseDatas(res.data);
          //
        })
        .catch((err) => console.log(err));
    };
    const getAllVehiclesAttente = async () => {
      axiosPrivate
        .get(`vehicles/getAllVehiclesAttente/${companyId}`)
        .then((res) => {
          setAllVehicleAttenteDatas(res.data);
          //
        })
        .catch((err) => console.log(err));
    };
    const getAllMaintenanceEnCours = async () => {
      axiosPrivate
        .get(`maintenance/maintenance-en-cours/${companyId}`)
        .then((res) => {
          setSumMaintenance(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getAssurance = async () => {
      await axiosPrivate
        .get(`charges/assurance-deadline/${companyId}`)
        .then((res) => {
          setAssuranceDatas(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getCarteGrise = async () => {
      await axiosPrivate
        .get(`charges/carte-grise-deadline/${companyId}`)
        .then((res) => {
          setCarteGriseDatas(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getVisiteTechnique = async () => {
      await axiosPrivate
        .get(`charges/visite-technique-deadline/${companyId}`)
        .then((res) => {
          setVisiteTechniqueDatas(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getDriverPermis = async () => {
      await axiosPrivate
        .get(`drivers/drivers-deadline/${companyId}`)
        .then((res) => {
          setDriverPermisDatas(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getCountEventTaxi = async () => {
      await axiosPrivate
        .get(`calendar/count-events-taxi/${companyId}`)
        .then((res) => {
          setCountEventsTaxi(res.data);
        })
        .catch((err) => console.log(err));
    };
    const getCountEventYango = async () => {
      await axiosPrivate
        .get(`calendar/count-events-yango/${companyId}`)
        .then((res) => {
          setCountEventsYango(res.data);
        })
        .catch((err) => console.log(err));
    };
    const countTaxiVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-taxi/${companyId}`)
        .then((res) => {
          setCountTaxiVersements(res.data[0].total);
        })
        .catch((err) => console.log(err));
    };
    const countYangoVersement = async () => {
      await axiosPrivate
        .get(`versements/count-versement-yango/${companyId}`)
        .then((res) => {
          setCountYangoVersements(res.data[0].total);
        })
        .catch((err) => console.log(err));
    };

    fetchAllVehicules();
    fetchSumAllDepense();
    fetchAllData();
    fetchData();
    fetchAdministratif();
    fetchEnergie();
    fetchMaintenance();
    getAllVehiclesActif();
    getAllVehiclesImmobilise();
    getAllVehiclesAttente();
    getAssurance();
    getCarteGrise();
    getVisiteTechnique();
    getDriverPermis();
    getCountEventTaxi();
    getCountEventYango();
    countTaxiVersement();
    countYangoVersement();
    getAllMaintenanceEnCours();
  }, []);

  const nombreAcquisition =
    sumAcquisition.total_montant?.toLocaleString("fr-FR");
  const nombreAdministratif =
    sumAdministratif.total_montant?.toLocaleString("fr-FR");
  const nombreEnergie = sumEnergie.total_montant?.toLocaleString("fr-FR");
  const nombreAllDepense =
    allDepenseDatas.total_montant?.toLocaleString("fr-FR");
  const nombreMaintenanceDepense =
    sumMaintenanceDepense.total_montant?.toLocaleString("fr-FR");
  const nombreTaxiAttente = countEventsTaxi?.toLocaleString("fr-FR");
  const nombreYangoAttente = countEventsYango?.toLocaleString("fr-FR");
  const nombreTaxiVersement = countTaxiVersements?.toLocaleString("fr-FR");
  const nombreYangoVersement = countYangoVersements?.toLocaleString("fr-FR");
  const totalaVerser = countEventsTaxi + countEventsYango;
  const totalVerser = countTaxiVersements + countYangoVersements;
  const nombreMontantaVerse = totalaVerser?.toLocaleString("fr-FR");
  const nombreMontantVerse = totalVerser?.toLocaleString("fr-FR");

  return (
    <div className="dashboardLayout">
      <div className="dashboardHeader ng-star-inserted">
        <div className="title">
          <h2>Tableau de bord</h2>
        </div>
        <div className="links"></div>
        <div className="dashboardContent">
          <div className="firstRow">
            <div className="widget size100 position-r ng-star-inserted">
              <span className="titleWidget">Mon Parc</span>
              <div className="boxWidget width25">
                <span className="valueWidget c-black">
                  <div className="ng-star-inserted">{carDatas.length}</div>
                </span>
                <span className="textWidget">Véhicule(s)</span>
              </div>
              <div className="boxWidget width25">
                <span className="valueWidget c-green">
                  <div className="ng-star-inserted">
                    {allVehicleActifDatas.length}
                  </div>
                </span>
                <span className="textWidget">Actif(s)</span>
              </div>
              <div className="boxWidget width25">
                <span className="valueWidget c-red">
                  <div className="ng-star-inserted">
                    {allVehicleImmobiliseDatas.length}
                  </div>
                </span>
                <span className="textWidget">Immobilisé(s)</span>
              </div>
              <div className="boxWidget width25">
                <span className="valueWidget c-blue">
                  <div className="ng-star-inserted">
                    {allVehicleAttenteDatas.length}
                  </div>
                </span>
                <span className="textWidget">En attente(s)</span>
              </div>
            </div>
            <div className="widget size100 position-r ng-star-inserted">
              <span className="titleWidget">Statistique versement</span>
              <div className="boxWidget width50">
                <div height="28vh" type="pie" width="110%">
                  <div
                    style={{
                      position: "relative",
                      width: "110%",
                      height: "28vh",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        height: "128px",
                        width: "345px;",
                      }}
                      className="chartjs-render-monitor"
                    >
                      <MonthlyPaymentChart />
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxWidget width50">
                <table className="tbw" style={{ fontWeight: "600" }}>
                  <tr>
                    <td style={{ color: "#fa8e8e" }}>
                      {" "}
                      Versement taxi en attente
                    </td>
                    <td style={{ textAlign: "right" }}>{nombreTaxiAttente} </td>
                  </tr>
                  <tr>
                    <td style={{ color: "#963b3b" }}>
                      {" "}
                      Versement yango en attente
                    </td>
                    <td style={{ textAlign: "right" }}>{nombreYangoAttente}</td>
                  </tr>
                  <tr>
                    <td style={{ color: "#14f585" }}>
                      {" "}
                      Versement taxi éffectué
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      {nombreTaxiVersement}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ color: "#025e30" }}>
                      {" "}
                      Versement yango éffectué
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      {nombreYangoVersement}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "700" }}> Montant à verser</th>
                    <th
                      style={{
                        textAlign: "right",
                        fontWeight: "700",
                        color: "#f7191c",
                      }}
                    >
                      {nombreMontantaVerse}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "700" }}> Versement total</th>
                    <th
                      style={{
                        textAlign: "right",
                        fontWeight: "700",
                        color: "#049458",
                      }}
                    >
                      {nombreMontantVerse}
                    </th>
                  </tr>
                </table>
              </div>
              <span className="titleWidgetYear">Année: 2024</span>
            </div>
            <div className="widget size100 position-r ng-star-inserted">
              <span className="titleWidget">Statistique Dépenses</span>
              <div className="boxWidget width50">
                <div height="28vh" type="pie" width="110%">
                  <div
                    style={{
                      position: "relative",
                      width: "110%",
                      height: "28vh",
                    }}
                  >
                    <div
                      style={{
                        display: "block",
                        height: "128px",
                        width: "345px;",
                      }}
                      className="chartjs-render-monitor"
                    >
                      <PieChart />
                    </div>
                  </div>
                </div>
              </div>
              <div className="boxWidget width50">
                <table className="tbw" style={{ fontWeight: "600" }}>
                  <tr>
                    <td style={{ color: "#f50579" }}> Acquisitions Véhicule</td>
                    <td style={{ textAlign: "right" }}>{nombreAcquisition} </td>
                  </tr>
                  <tr>
                    <td style={{ color: "#0585f5" }}>
                      {" "}
                      Dépenses Administratives
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {nombreAdministratif}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ color: "#f56105" }}> Dépenses Energies</td>
                    <td style={{ textAlign: "right" }}> {nombreEnergie} </td>
                  </tr>
                  <tr>
                    <td style={{ color: "#666569" }}> Dépenses Maintenances</td>
                    <td style={{ textAlign: "right" }}>
                      {" "}
                      {nombreMaintenanceDepense}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontWeight: "700" }}> Total dépense</th>
                    <th style={{ textAlign: "right", fontWeight: "700" }}>
                      {nombreAllDepense}
                    </th>
                  </tr>
                </table>
              </div>
              <span className="titleWidgetYear">Année: 2024</span>
            </div>
          </div>
          <div className="secondRow ng-star-inserted">
            <div className="widget size100">
              <div className="rpt-refresh">
                <img src={watch} alt="" />
                <span>Date: {formatDate(currentDate)}</span>
              </div>
              <h3 className="alerte">Alertes</h3>
              <span className="Alerte_titre ng-star-inserted">
                Administratifs
              </span>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/alertes/assurances/${companyId}`}
                  className="same-line"
                >
                  Assurances
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">
                    {assuranceDatas.length}
                  </div>
                </span>
              </div>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/alertes/carte-grise/${companyId}`}
                  className="same-line"
                >
                  Carte-grises
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">
                    {carteGriseDatas.length}
                  </div>
                </span>
              </div>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/alertes/permis-conduire/${companyId}`}
                  className="same-line"
                >
                  Permis de conduire
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">
                    {driverPermisDatas.length}
                  </div>
                </span>
              </div>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/alertes/vignettes/${companyId}`}
                  className="same-line"
                >
                  Vignettes
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">0</div>
                </span>
              </div>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/alertes/visite-technique/${companyId}`}
                  className="same-line"
                >
                  Visite-techniques
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">
                    {visiteTechniqueDatas.length}
                  </div>
                </span>
              </div>

              <span className="Alerte_titre ng-star-inserted">
                Alertes techniques
              </span>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/maintenances/intervention-en-cours/${companyId}`}
                  className="same-line"
                >
                  Véhicules en maintenance
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">{sumMaintenance.length}</div>
                </span>
              </div>
              <div className="boxWidget ligne100 ng-star-inserted">
                <a
                  href={`/maintenances/intervention-en-cours/${companyId}`}
                  className="same-line"
                >
                  Véhicules en réparation
                </a>
                <span className="valueWidget c-red same-line">
                  <div className="count-alerte ng-star-inserted">{allVehicleImmobiliseDatas.length}</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
