import React, { useEffect, useState } from "react";
import "../../../../../../assets/css/home.css";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosPrivate from "../../../../../services/api/axios";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../../context/AuthProvider";
import list from "../../../../../../assets/icon40/list.svg";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import fr from "react-phone-number-input/locale/fr";

const CompanieEditer = () => {
  const { companyID } = useParams();
  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const [photoDriver, setPhotoDriver] = useState("");
  const [nomCompany, setNomCompany] = useState("");
  const [phoneChauffeur, setPhoneChauffeur] = useState("");
  const [contactData, setContactData] = useState({
    companyId: "",
    nom: "",
    email_companie: "",
    contact: "",
    typeCompte: "",
    logo: "",
    adresse: "",
    status: "",
    expirationDate: "",
    isBlocked: "",
    registre_commerce: "",
    compte_contribuable: "",
    forme_juridique: "",
    numero_idu: "",
    companieOldImage: "",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);
  const userID = decrypt(user.userID);

  useEffect(() => {
    const fetchDriverData = async () => {
      await axiosPrivate
        .get(`companies/find-companie/${companyId}`)
        .then((res) => {
          let data = {
            ...contactData,
            companyId: res.data.companyId,
            nom: res.data.nom,
            email_companie: res.data.email,
            contact: res.data.contact,
            typeCompte: res.data.typeCompte,
            logo: res.data.logo,
            adresse: res.data.adresse,
            status: res.data.status,
            expirationDate: res.data.expirationDate,
            isBlocked: res.data.isBlocked,
          };
  
          if (res.data.typeCompte === "entreprise") {
            data = {
              ...data,
              registre_commerce: res.data.registre_commerce,
              compte_contribuable: res.data.compte_contribuable,
              forme_juridique: res.data.forme_juridique,
              numero_idu: res.data.numero_idu,
              companieOldImage: res.data.logo,
            };
          }
  
          setContactData(data);
          setPhoneChauffeur(res.data.contact);
          setPhotoDriver(res.data.logo);
          setNomCompany(res.data.nom);
        })
        .catch((err) => console.log(err));
    };

    fetchDriverData();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", photoDriver);
      const res = await axiosPrivate.post("upload-logo-company", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};
    if (contactData.nom === "") {
      err.nom = toast.error("😡 Veuillez entrer le nom de l'entreprise!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.email_companie === "") {
      err.email_companie = toast.error("😡 Veuillez entrer le email de l'entreprise!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.typeCompte === "") {
      err.typeCompte = toast.error("😡 Veuillez choisir le type de compte!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.typeCompte === "entreprise") {
      if (contactData.registre_commerce === "") {
        err.registre_commerce = toast.error("😡 Veuillez entrer le registre de commerce!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.compte_contribuable === "") {
        err.compte_contribuable = toast.error("😡 Veuillez entrer le compte contribuable!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.forme_juridique === "") {
        console.log(err.forme_juridique)
        err.forme_juridique = toast.error("😡 Veuillez entrer la forme juridique!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
      if (contactData.numero_idu === "") {
        err.numero_idu = toast.error("😡 Veuillez entrer le numéro IDU!", {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        });
      }
    }
    if (contactData.adresse === "") {
      err.adresse = toast.error(
        "😡 Veuillez entrer l'adresse de l'entreprise!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (phoneChauffeur === "") {
      err.phoneChauffeur = toast.error(
        "😡 Veuillez entrer le contact de l'entreprise!",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();

    let imgUrl = "";
    let numeroIdu = "";
    let registreCommerce = "";
    let compteContribuable = "";
    let formeJuridique = "";
  
    if (contactData.typeCompte === "Entreprise") {
      numeroIdu = encrypt(contactData.numero_idu);
      registreCommerce = encrypt(contactData.registre_commerce);
      compteContribuable = encrypt(contactData.compte_contribuable);
      formeJuridique = encrypt(contactData.forme_juridique);
    } else {
      numeroIdu = ""
      registreCommerce = "";
      compteContribuable = "";
      formeJuridique = "";
    }

    if (photoDriver) imgUrl = await upload();

    if (isValid) {
      try {
        axiosPrivate
          .put(
            `companies/update-companie/${companyID}`,
            {
              nom: encrypt(contactData.nom),
              email: encrypt(contactData.email_companie),
              contact: encrypt(phoneChauffeur),
              typeCompte: encrypt(contactData.typeCompte),
              logo: imgUrl,
              adresse: contactData.adresse,
              status: contactData.status,
              expirationDate: contactData.expirationDate,
              isBlocked: contactData.isBlocked,
              registre_commerce: registreCommerce,
              compte_contribuable: compteContribuable,
              forme_juridique: formeJuridique,
              numero_idu: numeroIdu,
              companieOldImage: contactData.companieOldImage,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/companie/profile/${companyId}/${userID}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    }
  };

  return (
    <div className="ng-star-inserted">
      <div id="header-main">
        <div className="title">
          <h2>Modifier le profil de {nomCompany}</h2>
        </div>
        <div className="links">
          <a
            href={`/companie/profile/${companyId}/${userID}`}
            className="ng-star-inserted"
          >
            <img src={list} />
          </a>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="ng-untouched ng-pristine ng-valid"
      >
        <div className="form-l">
          <div className="form-content ng-star-inserted">
            <div className="form-block">
              <label className="title-content">Informations générales</label>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Nom</label>
                <input
                  type="text"
                  placeholder="Nom de l'entreprise"
                  className="ng-pristine ng-invalid ng-touched"
                  name="nom"
                  value={contactData.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Email</label>
                <input
                  type="email"
                  placeholder="Email de l'entreprise"
                  className="ng-pristine ng-invalid ng-touched"
                  name="email_companie"
                  value={contactData.email_companie}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Numéro de téléphone</label>
                <PhoneInput
                  international={true}
                  countryCallingCodeEditable={false}
                  placeholder="Entrer le numero du conducteur"
                  value={phoneChauffeur}
                  onChange={(phoneChauffeur) =>
                    setPhoneChauffeur(phoneChauffeur)
                  }
                  defaultCountry="CI"
                  flags={flags}
                  labels={fr}
                  limitMaxLength={10}
                  rules={{ required: true }}
                />
              </div>
              <div className="form-group col2 ng-star-inserted">
                <label className="form">Type de compte</label>
                <div className="large">
                  <div className="box-select">
                    <select
                      name="typeCompte"
                      value={contactData.typeCompte}
                      onChange={handleChange}
                    >
                      <option>le type de Compte</option>
                      <option value="Compte Entreprise">Compte Entreprise</option>
                      <option value="Compte Particulier">Compte Particulier</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group ng-star-inserted">
                <label className="form">Adresse Géographique</label>
                <input
                  type="text"
                  placeholder="L'adresse Géographique'"
                  className="ng-pristine ng-invalid ng-touched"
                  name="adresse"
                  value={contactData.adresse}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="form-content">
            <div className="form-block ng-star-inserted">
              <label className="title-content">
              Informations Administratives
              </label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Registre de commerce</label>
                  <input
                    type="text"
                    placeholder="Registre de commerce"
                    className="ng-pristine ng-invalid ng-touched"
                    name="registre_commerce"
                    value={contactData.registre_commerce}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">N° compte contribuable</label>
                  <input
                    type="text"
                    placeholder="N° compte contribuable"
                    className="ng-pristine ng-invalid ng-touched"
                    name="compte_contribuable"
                    value={contactData.compte_contribuable}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Forme juridique</label>
                  <input
                    type="text"
                    placeholder="Forme juridique"
                    className="ng-pristine ng-invalid ng-touched"
                    name="forme_juridique"
                    value={contactData.forme_juridique}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Numéro d'Identifiant Unique</label>
                  <input
                    type="text"
                    placeholder="Numéro d'Identifiant Unique"
                    className="ng-pristine ng-invalid ng-touched"
                    name="numero_idu"
                    value={contactData.numero_idu}
                    onChange={handleChange}
                  />
                </div>
              <div className="form-group ng-star-inserted">
                <label className="form">Logo de l'entreprise</label>
                <input
                  type="file"
                  name="Photo"
                  id="Photo"
                  onChange={(event) => setPhotoDriver(event.target.files[0])}
                  accept="image/*"
                  multiple={false}
                />
              </div>
            </div>
          </div>
          <div className="form-button-98">
            <button className="save" type="submit" disabled="">
              Modifier
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanieEditer;
