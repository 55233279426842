import React, { useEffect, useRef, useState } from "react";
import axiosPrivate from "../../../services/api/axios";
import "../../../../assets/css/home.css";
import { useAuth } from "../../../context/AuthProvider";
import { decrypt } from "../../../module/myCrypto";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function DeleteChargeModal({ closeDeleteChargeModal, data }) {
  const [vehicleData, setVehicleData] = useState("");
  const [contratData, setContratData] = useState("");
  const flag = useRef(false);

  console.log(data);

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const fetchVehicleData = async () => {
      axiosPrivate
        .get(`vehicles/getVehicle/${data[0]}`)
        .then((res) => {
          setVehicleData(res.data);
          //
        })
        .catch((err) => console.log(err));
    };

    const fetchContratData = async () => {
      await axiosPrivate
        .get(`vehiclecontrat/getContrat/${data[1]}`)
        .then((res) => {
          setContratData(res.data);
        })
        .catch((err) => console.log(err));
    };

    fetchVehicleData();
    fetchContratData();
  }, []);

  // Function to handle delete
  const handleDelete = async () => {
    if (data[3] === "L'Assurance") {
      await axiosPrivate
        .post(`charges/delete-assurance/${data[0]}`)
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteChargeModal(false);
          }
        })
        .catch((error) => console.error("Error deleting data: ", error));
    }
    
    if (data[3] === "La carte grise") {
      await axiosPrivate
        .post(`charges/delete-carte-grise/${data[0]}`)
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteChargeModal(false);
          }
        })
      .catch(error => console.error('Error deleting data: ', error));
    } 
    
    if (data[3] === "La vignette") {
      await axiosPrivate
        .post(`charges/delete-vignette/${data[0]}`)
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteChargeModal(false);
          }
        })
      .catch(error => console.error('Error deleting data: ', error));
    } 
    
    if (data[3] === "La visite technique") {
      await axiosPrivate
        .post(`charges/delete-visite-technique/${data[0]}`)
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteChargeModal(false);
          }
        })
      .catch(error => console.error('Error deleting data: ', error));
    } 
    
    if (data[3] === "La demande d'intervention") {
      await axiosPrivate
        .post(`maintenance/delete-demande-intervention/${data[0]}/${data[2]}`)
        .then((res) => {
          if (res.data.code === 100) {
            toast.success(`👏 ${res.data.msg}`, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            closeDeleteChargeModal(false);
          }
        })
      .catch(error => console.error('Error deleting data: ', error));
    }
  };

  return (
    <div
      aria-labelledby="mySmallModalLabel"
      className="modal fade show"
      id="popupDelete"
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h6 className="center c-red">
              Voulez-vous vraiment supprimer {data[3]} N° {data[0]} du {data[1]} ?
            </h6>
            <h6 className="center c-red"></h6>
            <div className="c-red">
              <ul></ul>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="cancel"
              data-dismiss="modal"
              id="confirm-modaldismiss"
              type="cancel"
              onClick={() => closeDeleteChargeModal(false)}
            >
              Annuler
            </button>
            <button
              className="delete"
              type="submit"
              onClick={() => handleDelete()}
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteChargeModal;
