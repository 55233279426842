import React, { useEffect, useRef, useState } from "react";
import "../../../../../../assets/css/home.css";
import list from "../../../../../../assets/icon40/list.svg";
import { useAuth } from "../../../../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../../../../../module/myCrypto";
import axiosPrivate from "../../../../../services/api/axios";
import { toast, Flip, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const AjouterDecaissement = () => {
  const fileInput = useRef();
  const [optionList, setOptionList] = useState([]);
  const navigate = useNavigate();
  const [contrat, setContrat] = useState("");
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [contactData, setContactData] = useState({
    reference_decaissement: "",
    nature_decaissement: "Decaissement",
    type_decaissement: "",
    date_decaissement: date,
    motif_decaissement: "",
    montant_decaissement: "",
    attachement: "",
    intitule_decaissement: "",
    beneficiaire: "",
    observation: "",
    justification: "Decaissement justifie",
  });

  const {
    authState: { user },
  } = useAuth();

  const companyId = decrypt(user.companyID);

  useEffect(() => {
    const dateDuJour = new Date().toLocaleDateString();
    setDate(dateDuJour);
  }, []);

  useEffect(() => {
    const getAllVehicles = () => {
      axiosPrivate
        .get(`vehicles/getAllVehicles/${companyId}`)
        .then((res) => {
          setOptionList(res.data);
        })
        .catch((err) => console.log(err));
    };

    getAllVehicles();
  }, []);

  const handleChange = (event) => {
    setContactData(() => ({
      ...contactData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickOpen = () => {
    navigate(`/finances/caisses/decaissement/${companyId}`);
  };

  const uploadContrat = async () => {
    try {
      const formData = new FormData();
      formData.append("file", contrat);
      const res = await axiosPrivate.post("upload-decaissement", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
        method: "POST",
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    let err = {};

    if (contactData.type_decaissement === "") {
      err.type_decaissement = toast.error("😡 Veuillez entrer le type de decaissement!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.beneficiaire === "") {
      err.beneficiaire = toast.error("😡 Veuillez entrer le bénéficiaire!", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.date_decaissement === "") {
      err.date_decaissement = toast.error(
        "😡 Veuillez selectionner la date de décaissement",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.motif_decaissement === "") {
      err.motif_decaissement = toast.error("😡 Veuillez entrer le motif de decaissement", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.reference_decaissement === "") {
      err.reference_decaissement = toast.error(
        "😡 Veuillez entrer la référence de decaissement",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.montant_decaissement === "") {
      err.montant_decaissement = toast.error("😡 Veuillez entrer le montant décaissé", {
        position: toast.POSITION.TOP_RIGHT,
        transition: Bounce,
      });
    }
    if (contactData.intitule_decaissement === "") {
      err.intitule_decaissement = toast.error(
        "😡 Veuillez entrer l'intitulé de décaissement",
        {
          position: toast.POSITION.TOP_RIGHT,
          transition: Bounce,
        }
      );
    }
    if (contactData.justification === "Decaissement justifie") {
      if (contrat === "") {
        err.attachement = toast.error(
          "😡 Veuillez télécharger le reçu de justification",
          {
            position: toast.POSITION.TOP_RIGHT,
            transition: Bounce,
          }
        );
      }
    }

    setFormError({
      ...err,
    });

    return Object.keys(err).length < 1;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    let datas;

    if (isValid) {
      let contratUrl = "";

      if (contrat) contratUrl = await uploadContrat();

      try {
        await axiosPrivate
          .post(
            "finances/decaissement/create",
            {
              reference_decaissement: encrypt(contactData.reference_decaissement),
              nature_decaissement: encrypt(contactData.nature_decaissement),
              type_decaissement: encrypt(contactData.type_decaissement),
              date_decaissement: encrypt(contactData.date_decaissement),
              motif_decaissement: encrypt(contactData.motif_decaissement),
              montant_decaissement: encrypt(contactData.montant_decaissement),
              attachement: contratUrl,
              intitule_decaissement: encrypt(contactData.intitule_decaissement),
              beneficiaire: encrypt(contactData.beneficiaire),
              observation: contactData.observation,
              justification: encrypt(contactData.justification),
              userID: user.userID,
              companyID: user.companyID,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              method: "POST",
            }
          )
          .then((response) => {
            if (response.data.code === 102)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 103)
              return toast.error(`😡 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Bounce,
                style: {
                  bodyClassName: "grow-font-size",
                  progressClassName: "fancy-progress-bar",
                },
              });
            if (response.data.code === 100) {
              toast.success(`👏 ${response.data.msg}`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(`/finances/caisses/decaissement/${companyId}`);
            }
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              console.log(error.response.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } catch (error) {}
    } else {
    }
  };

  return (
    <>
      <div className="ng-star-inserted">
        <div id="header-main">
          <div className="title">
            <h2>Decaissement</h2>
          </div>
          <div className="links">
            <a href="" title="Ajouter Decaissement" className="ng-star-inserted">
              <img src={list} alt="" onClick={handleClickOpen} />
            </a>
            </div>
        </div>
        <form className="ng-untouched ng-pristine ng-valid" onSubmit={handleSubmitForm}>
          <div className="form-l">
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Critères du mouvement</label>
                <div className="form-group col2">
                  <label className="form">Type d'opération</label>
                  <div className="large">
                  <input
                      id="bdate"
                      type="text"
                      name="nature_decaissement"
                      value={contactData.nature_decaissement}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Date Decaissement</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="date"
                      name="date_decaissement"
                      value={contactData.date_decaissement}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Référence</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="text"
                      name="reference_decaissement"
                      value={contactData.reference_decaissement}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Montant</label>
                  <input
                    className="inputGroup ng-untouched ng-pristine ng-valid"
                    pinputtext=""
                    pkeyfilter="int"
                    type="number"
                    min="0"
                    name="montant_decaissement"
                    value={contactData.montant_decaissement}
                    onChange={handleChange}
                  />
                  <div className="labelGroup">
                    <span className="labelGroupText" id="basic-addon2">
                      F.CFA
                    </span>
                  </div>
                </div>
                <div className="form-group ng-star-inserted">
                  <label className="form">Motif</label>
                  <div className="large">
                    <input
                      id="bdate"
                      type="text"
                      name="motif_decaissement"
                      value={contactData.motif_decaissement}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form">Intitulé</label>
                  <textarea
                    type="text"
                    name="intitule_decaissement"
                    cols="3"
                    rows="5"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.intitule_decaissement}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-content">
              <div className="form-block">
                <label className="title-content">Données du mouvement</label>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Type decaissement</label>
                  <input
                    type="text"
                    placeholder="Type decaissement"
                    className="ng-pristine ng-invalid ng-touched"
                    name="type_decaissement"
                    value={contactData.type_decaissement}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Bénéficiaire</label>
                  <input
                    type="text"
                    placeholder="Auteur"
                    className="ng-pristine ng-invalid ng-touched"
                    name="beneficiaire"
                    value={contactData.beneficiaire}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="form">Observation</label>
                  <textarea
                    type="text"
                    name="observation"
                    cols="30"
                    rows="3"
                    className="large ng-untouched ng-pristine ng-valid"
                    value={contactData.observation}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group col2 ng-star-inserted">
                  <label className="form">Justification?</label>
                  <div className="radio-container">
                    <input
                      checked={contactData.justification === "Decaissement justifie"}
                      type="radio"
                      name="justification"
                      value="Decaissement justifie"
                      id="male"
                      onChange={handleChange}
                    />
                    <label for="male">Justifié</label>
                    <input
                      checked={contactData.justification === "Decaissement non-justifie"}
                      type="radio"
                      name="justification"
                      value="Decaissement non-justifie"
                      id="female"
                      onChange={handleChange}
                    />
                    <label for="female">Non-justifié</label>
                  </div>
                </div>
                {contactData.justification === "Decaissement justifie" && (
                  <div className="form-group col2 ng-star-inserted">
                    <label className="form">Justificatif décaissement</label>
                    <input
                      id="image"
                      accept=".pdf"
                      className="large"
                      type="file"
                      name="photo"
                      ref={fileInput}
                      onChange={(event) => setContrat(event.target.files[0])}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-button">
              <button className="save" type="submit">Enregistrer</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AjouterDecaissement;
